import { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";

import axios from "axios";
import DashboardContainer from "../UI/DashboardContainer";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import BarChart from "../BarChart";

import Title from "../Title";
import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
//import TableCell from "@mui/material/TableCell";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.common.ludisMain,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PaymentInfoOrder(props) {
  const dispatch = useDispatch();

  const [paymentsOnOrder, setPaymentsOnOrder] = useState([]);

  const [isLoading1, setIsLoading1] = useState(false);
  
  
  //const [isLoading2, setIsLoading2] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));

  const [showPaymentsOnOrder, setShowPaymentsOnOrder] = useState(true);

  //variables for the popover (hoover)
  const [paymentRef, setPaymentRef] = useState("");

  const showPaymentsHandler = (event) => {
    event.preventDefault();
    setShowPaymentsOnOrder((prev) => !showPaymentsOnOrder);
  };

  //isLoading1_allPayOnOrder is to pass to the parent component Income.js info about loading to share with sibling componente paymentinfoOrderSummary.js
  const { selectedOrder, newPayment, isLoading1_allPayOnOrderHandler } = props;

  let totalPaidOnOrder = 0;

  let confoId = "";
  if (selectedOrder.length > 0) {
    confoId = selectedOrder[0].CONFIRMATION_ID;
  }

  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
  ];

  //POP OVER CODE --------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, reference) => {
    //console.log(reference)
    setAnchorEl(event.currentTarget);
    setPaymentRef(reference);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPaymentRef("");
  };

  const open = Boolean(anchorEl);

  //END POP OVER CODE ----------------------------------------------------------

  //   if (selectedOrder.length > 0) {
  //     orderInfo1 = [
  //       {
  //         label: "Company: ",
  //         value: selectedOrder[0].COMPANY_NAME.substring(0, 50),
  //       },
  //       {
  //         label: "Contact: ",
  //         value: selectedOrder[0].MAIN_CONTACT.substring(0, 50),
  //       },
  //       {
  //         label: "Address: ",
  //         value: (
  //           selectedOrder[0].ADDRESS1 +
  //           " " +
  //           selectedOrder[0].ADDRESS2
  //         ).substring(0, 50),
  //       },
  //       { label: "Town: ", value: selectedOrder[0].TOWN.substring(0, 50) },
  //       {
  //         label: "County: ",
  //         value: selectedOrder[0].ClientCounty.substring(0, 50),
  //       },
  //       {
  //         label: "Post C.: ",
  //         value: selectedOrder[0].ClientPost.substring(0, 50),
  //       },
  //     ];
  //   }

  //const selectedDatesFromInitial = props.tabInitialDate;

  const getPaymentsOnOrder = (confoId) => {
    // console.log(
    //   "getPaymentsOnOrder ............... in the salesbyconsultantsumary for GPaymentinfoorder component called !!!!"
    // );
    setIsLoading1(true);
    isLoading1_allPayOnOrderHandler(true); //this is the same info of isloading1 passed to the parent component to share with Siblings....

    axios
      .get(variables.API_URL + "payments/paymentsonorder/" + confoId, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log("payments on order here....", res.data.paymentsOnOrder);
        setPaymentsOnOrder(res.data.paymentsOnOrder);
        setIsLoading1(false);
        isLoading1_allPayOnOrderHandler(false); //this is the same info of isloading1 passed to the parent component to share with Siblings....
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All Cancelations by Consultant ------------------------------------

  //   const getCancellationsByConsultantSummary = (
  //     tabInitialDate,
  //     tabFinalDate
  //   ) => {
  //     const selectedDatesFrom = tabInitialDate;

  //     const selectedDatesTo = tabFinalDate;

  //     setIsLoading2(true);

  //     axios
  //       .get(
  //         variables.API_URL +
  //           "sales/cancellationsbyconsultantsummary/" +
  //           selectedDatesFrom +
  //           "/" +
  //           selectedDatesTo,
  //         { withCredentials: true }
  //       )
  //       .then((res) => {
  //         console.log(
  //           "From Report ....all cancellations by consultant summary...",
  //           res.data.cancellationsByConsultantSummary
  //         );
  //         setCancellationsByConsultantsList(
  //           res.data.cancellationsByConsultantSummary
  //         );

  //         setIsLoading2(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //end fetching the info from db....

  useEffect(() => {
    if (confoId) {
      getPaymentsOnOrder(confoId);
    }
  }, [confoId, newPayment]);

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)

    if (paymentsOnOrder.length > 0) {
      totalPaidOnOrder =
        paymentsOnOrder[paymentsOnOrder.length - 1].cumulative_sum;
    } else {
      totalPaidOnOrder = 0;
    }

    // dispatch(
    //   orderActions.updateTotalPaidOnOrder({
    //     selectedOrderTotalPaid: totalPaidOnOrder,
    //   })
    //   );

    dispatch(
      paymentInActions.updatePaymentIn({
        //amountPaid: totalPaidOnOrder,
        selectedOrderTotalPaid: totalPaidOnOrder,
      })
    );
  }, [paymentsOnOrder]);

  return selectedOrder.length > 0 ? (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showPaymentsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />

          {/* {props.changeDates ? "View This Week" : "View All Year"} */}

          {showPaymentsOnOrder ? "Hide Payments" : "Show Payments"}
        </Button>
      </Box>

      {showPaymentsOnOrder ? (
        !isLoading1 ? (
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title>Payments on Order ...</Title>

            <Divider sx={{ width: "60vw" }} />

            {paymentsOnOrder.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: matches2 ? "80vw" : "60vw", marginTop: 2 }}
              >
                <MyTable size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell align="left">
                        Amount&nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Reference
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Payment Type
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {paymentsOnOrder.map((item) => (
                      <MyTableRow key={item.CLIENTPAYMENT_ID}>
                        <TableCell>
                          <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                            {new Date(item.DATEONSTATEMENT).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          £{" "}
                          {parseFloat(item.AMOUNTPAID).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                        <TableCell>
                          <Typography
                            aria-owns={open ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={(event) =>
                              handlePopoverOpen(event, item.CHEQUEREF)
                            }
                            onMouseLeave={handlePopoverClose}
                            sx={{ fontSize: matches ? 11 : 14 }}
                          >
                            {matches
                              ? item.CHEQUEREF.substring(0, 40)
                              : item.CHEQUEREF.substring(0, 70)}
                          </Typography>
                        </TableCell>
                        <StyledTableCell align="right">
                          {matches ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                          {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                        </StyledTableCell>
                      </MyTableRow>
                    ))}
                  </TableBody>
                </MyTable>
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      <Popover
        id="mouse-over-popover"
        //id={item.CLIENTPAYMENT_ID}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* <Typography sx={{ p: 1 }}>{item.CHEQUEREF}</Typography> */}
        <Typography
          sx={{
            p: 1,
            backgroundColor: "#e7dddd",
            color: theme.palette.common.ludisMain,
            fontSize: 20,
          }}
        >
          {paymentRef}
        </Typography>
      </Popover>
    </Fragment>
  ) : (
    "..."
  );
}
