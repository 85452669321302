import {Fragment, useState} from 'react';

import { FadeLoader } from "react-spinners";


//---------------------MUI IMPORTS-------------------------
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import Badge from "@mui/material/Badge";
// import PropTypes from 'prop-types';

import theme from "../UI/Theme";

//STYLED Badge ------------------------------------------------------
  export const MyBadge = styled(Badge)(({ theme }) => ({
    //color: "#7a1125",
    marginRight:'0',
  
    //color: "#270c10",
    color: "#fff",
    
    "& .MuiBadge-badge":{

      backgroundColor:"#e7dddd",
      

    },
  
    // height: 240,
     [theme.breakpoints.down("sm")]: {
      marginRight:'1rem',
     },
    
  }));




const StandardConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7A1125',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7A1125',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));





const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      'linear-gradient( 95deg,rgb(233,64,87) 0%,rgb(122,17,37) 50%,rgb(78,23,33) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      'linear-gradient( 95deg,rgb(233,64,87) 0%,rgb(122,17,37) 50%,rgb(78,23,33) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));







const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(ownerState.active && {
    color: '#7A1125',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#7A1125',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));




const QontoStepIcon = (props)=> {
  const { active, completed, className } = props;

  //difference between mobile and the rest....
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const icons = {
    1: active? <Badge color='secondary'  badgeContent='1' sx={{marginRight: matches? '1rem' :'0'}}  /> : <MyBadge  badgeContent='1' />,
    2:  active? <Badge color='secondary'  badgeContent='2' sx={{marginRight: matches? '1rem' :'0'}} /> : <MyBadge  badgeContent='2' />,
    3:  active? <Badge color='secondary'  badgeContent='3' sx={{marginRight: matches? '1rem' :'0'}} /> : <MyBadge  badgeContent='3' />,
    4:  active? <Badge color='secondary'  badgeContent='4' sx={{marginRight: matches? '1rem' :'0'}} /> : <MyBadge  badgeContent='4' />,

  };
  
  return (
    <QontoStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        icons[String(props.icon)]
      )}
    </QontoStepIconRoot>
  );
}





export default function ChangePayDateSteps(props) {

  const {steps, showModalHandler} = props

  

  const [activeStep, setActiveStep] = useState(0);

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 
  const handleReset = (event,item) => {
    showModalHandler(event,item);
    //setActiveStep(0);
  };

  //show the correct component as per the step


  return (
    <Box sx={{ width: '100%' }}>
      <Stepper 
      alternativeLabel={matches? false : true}
      connector={ matches? <StandardConnector/>  : <ColorlibConnector />}

      activeStep={activeStep} orientation={ matches? "vertical": "horizontal"}>
        {steps.map((item, index) => {
          const stepProps = {};
          const labelProps = {};
          
          return (
            <Step key={item.label} {...stepProps}>
              <Box
              sx={{
                display:'flex',
                flexWrap: 'wrap',
                flexDirection: matches? 'row' :'column',
                alignContent:'space-around',

              }}
              >

              <StepLabel StepIconComponent={QontoStepIcon}  {...labelProps} ></StepLabel>
              <StepLabel icon={activeStep < item.id ?item.icon : item.iconCompleted} {...labelProps}   >{item.label}</StepLabel>

              </Box>
            
            </Step>
          );
        })}
      </Stepper>

      


      {activeStep === steps.length-1 ? (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep].info}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* <Button onClick={(event)=>handleReset(event,1)}> Finish</Button> */}
            <FadeLoader color="#7A1125" size={12} />
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          
          <Typography sx={{ mt: 2, mb: 1 }}>{steps[activeStep].info}</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            <Button onClick={handleNext}>
              {activeStep === steps.length - 2 ? 'Save' : 'Next'}
            </Button>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}