import { Fragment, useState } from "react";

import PaymentsIn from "./PaymentsIn";

const PaymentsInMain = (props) => {

  const [showBank, setShowBank] = useState(false); //shows Recorded Date for Payments as default  
  const { tabInitialDate, tabFinalDate, refresh } = props

  //to show payments list as per Bank Statements or Recorde Date-----------------------------------
  const showBankHandler = (event) => {
    event.preventDefault();
    setShowBank((prev) => !showBank)
  };

  return (
    <Fragment>
      <PaymentsIn
        tabInitialDate={tabInitialDate}
        tabFinalDate={tabFinalDate}
        refresh={refresh}
        showBank={showBank}
        showBankHandler={showBankHandler}
        MainContentReport={true}
      />
    </Fragment>
  );
};

export default PaymentsInMain;
