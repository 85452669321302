import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
//import { paymentInActions } from "../../store/payment-slice";
import { purchasePaymentOutActions } from "../../store/purchasePayment-slice";
import { supplierActions } from "../../store/supplier-slice";

import axios from "axios";
import { variables } from "../../Variables";

// MUI IMPORTS---------------------------------------------------
import Button from "@mui/material/Button";
import AddCardIcon from "@mui/icons-material/AddCard";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

const AddPurchasePaymentStep_1 = (props) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);
  


  const AutoCompletePaymentType = useRef();

  const [showAddPayment, setShowAddPayment] = useState(false);

  //const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentTypeListFiltered, setPaymentTypeListFiltered] = useState([]);

  const [enteredInput, setEnteredInput] = useState(null);
  const [enteredInputName, setEnteredInputName] = useState(null);

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());

  const { purchasePaymentsTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = "de";

  //-------------------------------------------
  //   const [open, setOpen] = useState(false);
  //   const handleClickOpen = () => {setOpen(true);};
  //   const handleClose = () => {setOpen(false);};
  //===========================================

  //Show the modal with form to submit new payment in-----------
  // const showAddPaymentModalHandler = (event) => {
  //   event.preventDefault();
  //   setShowAddPayment((prev) => !showAddPayment);
  // };

  //console.log("this is the type payments list....",purchasePaymentsTypeList)

  //Add the Selected Order to the Payments In information
//   dispatch(
//     paymentInActions.updatePaymentIn({
//       selectedOrder: selectedOrder,
//     })
//   );

  //--------------------------------------------------------datemanager---------
  const handleDatesChangesFromTo = (date) => {
    const dateIsValid = date !== null && date !== "Invalid Date";

    //console.log("choosen date from AddPayment component....", date);

    setSelectedPaymentDate(date);

    if (dateIsValid) {
      //Add the Selected Order to the Payments In information
      dispatch(
        purchasePaymentOutActions.updatePurchasePaymentOut({
          purchasePaymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        })
      );

      // reportVariables = {
      //   ...reportVariables,
      //   selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
      // };
    } else {
      console.log("error, date not usable only 2 options available");
      return;
    }
  };
  //-------------------------------------------------------END datemanager------

    const handleInputChange = (value,name) => {

      //console.log("value from the function handlpriceChange...", value);
      //console.log("name from the function handlpriceChange...", name );

      setEnteredInput(value);
      setEnteredInputName(name);

    };

  //fetching products from DB-------------------------------------------
  //   const getPaymentsTypeList = () => {
  //     axios
  //       .get(
  //         variables.API_URL + "payments/paymentstypelist",
  //         { withCredentials: true })

  //       .then((res) => {
  //         //console.log("fetching products...",res.data.productListInfo)
  //         setPaymentTypeList(res.data.paymentTypeListInfo);
  //         //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //for payment Type list
  useEffect(() => {
    if (purchasePaymentsTypeList?.length > 0) {
      const myPaymentTypeListFiltered = purchasePaymentsTypeList.map((option) => {
        const firstLetter = option.PAYTYPE[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setPaymentTypeListFiltered(myPaymentTypeListFiltered);
    }
  }, [purchasePaymentsTypeList]);

  const handlePaymentInChange = (event, value, name) => {
    // console.log(
    //   "value from the function handlePayment Type Change on Payments In recorder...",
    //   value
    // );

    // console.log(
    //   "name from the function handlePaymentTypeChange on Payments IN recorder...",
    //   name
    // );

    if (value) {
      dispatch(
        purchasePaymentOutActions.updatePurchasePaymentOut({
          [name]: value,
        })
      );
    }
  };



  //Debouncer..............

  useEffect(() => {
    const identifier = setTimeout(() => {
      //console.log("Checking input...");
      
  
      //console.log("Reference and payment Amount", enteredInput, enteredInputName)
  
      if (enteredInput) {
        dispatch(
          purchasePaymentOutActions.updatePurchasePaymentOut({
            [enteredInputName]: enteredInput,
          })
        );
      }else{
  
        dispatch(
          purchasePaymentOutActions.updatePurchasePaymentOut({
            [enteredInputName]: null,
          })
        );
  
      }
  
  
    }, 500);
  
    return () => {
      // console.log("CleanUp");
      clearTimeout(identifier);
    };
  }, [enteredInput, enteredInputName]);

  //Debouncer End..........






  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        {/* <Button
          onClick={(event) => {
            event.preventDefault();
            showAddPaymentModalHandler(event);
          }}
          sx={{ color: "#7A1125", textTransform: "capitalize" }}
          disabled={!selectedOrder.length ? true : false}
        >
          <AddCardIcon
            sx={{
              fontSize: 20,
              color: !selectedOrder.length ? "#ccc" : "#4E1721",
              marginRight: 2,
              marginTop: 2,
            }}
          />
          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2 }}
          >
            Add Payment
          </Typography>
        </Button> */}

        {/* <div> */}
        {/* <Button 
        variant="outlined" 
        //onClick={handleClickOpen}
        onClick={showAddPaymentModalHandler}
        >
        Open form dialog
    </Button> */}
        {/* <Dialog
            open={showAddPayment}
            //open={open}
            //onClose={handleClose}
            onClose={showAddPaymentModalHandler}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle>Add New Payment In</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Record New Payment on Order selected...
              </DialogContentText> */}

        {/* //=============================================== */}


        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >



        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* Payment In Amount */}
          <MyTextField
            //name="amountPaid"
            name="purchaseInvoiceAmountPaid"
            id="outlined-number"
            label="£ Payment"
            type="number"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 150, margin: "1em" }
            }
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
              // handlePaymentInChange(
              //   event,
              //   event.target.value,
              //   event.target.name
              // )
            }
          />

          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="From"
            value={selectedPaymentDate}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params} 
                sx={{ margin: matches ? "0.2em" : "1em" }}
              />
            )}
          />
        </Box>

        <Divider />

        {/* Purchase Payment Type */}
        <Autocomplete
          ref={AutoCompletePaymentType}
          //name="paymentType"
          name="purchasePaymentType"
          margin="auto"
          id="grouped-demo"
          options={paymentTypeListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          defaultValue={{ PAYTYPE: "BACS TRANSFER",
          PAYTYPE_ID: 1,
          PAYTYPE_REF: "Bacs",
          firstLetter: "B", }}
          getOptionLabel={(option) => option.PAYTYPE}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event, value, name) =>
            handlePaymentInChange(
              event,
              value,
              AutoCompletePaymentType.current.getAttribute("name")
            )
          }
          renderInput={(params) => (
            <MyTextField {...params} label="Payment Type" defaultValue="BACS TRANSFER" />
          )}
        />

<Divider />

        {/* Payment In Reference */}
        <MyTextField
          //name="paymentReference"
          name="purchasePaymentReference"
          id="outlined-search"
          label="Reference"
          type="search"
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event) =>handleInputChange(event.target.value, event.target.name)}
          // onChange={(event) =>
          //   handlePaymentInChange(event, event.target.value, event.target.name)
          // }
        />

</Box>

        {/* //=============================================== */}

        {/* <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        /> */}
        {/* </DialogContent>
            <DialogActions> */}
        {/* <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Subscribe</Button> */}
        {/* </DialogActions>
          </Dialog> */}
        {/* </div> */}
      </Fragment>
    </LocalizationProvider>
  );
};

export default AddPurchasePaymentStep_1;
