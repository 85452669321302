import { createSlice } from "@reduxjs/toolkit";

export const quoteSlice = createSlice({
  name: 'quote',
  initialState:{
    quoteInfo: {
      project:{},
      product:{},
      price:null,
      companyName:"",
      clientFirstName:"",
      clientLastName:"",
      companyMarket:{},
      companyType:{},
      quoteKey:""

    },
    QuoteStatus:{
      status:true,
    }
  },
  reducers:{

    updateQuote: (state, action)=>{

      const {payload} = action 

      //const newState = payload;
      
     
      state.quoteInfo = { ...state.quoteInfo, ...payload };



      console.log("full payload from quote reducer new state...",payload)
      
      

     

    },


  },
})

export const {updateQuote} = quoteSlice.actions;


export default quoteSlice;
