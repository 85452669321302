import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateQuote } from "../../store/quote-slice";

//-------------------IMPORTS MUI---------------------------------------
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

const QuoteStep_2 = (props) => {
  const dispatch = useDispatch();
  //const quoteinfo = useSelector((state) => state.quote);

  const [marketListFiltered, setMarketListFiltered] = useState([]);
  const [companyTypeListFiltered, setCompanyTypeListFiltered] = useState([]);

  const [enteredInput, setEnteredInput] = useState(null);
  const [enteredInputName, setEnteredInputName] = useState(null);

  const AutoCompleteCompanyType = useRef();
  const AutoCompleteCompanyMarket = useRef();

  const { marketList, companyTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const handleQuoteChange = (event, value, name) => {
    console.log("value from the function handleProjectsChange...", value);
    console.log("name from the function handleProjectsChange...", name);

    if (value) {
      dispatch(
        updateQuote({
          [name]: value,
        })
      );
    }
  };

  const handleInputChange = (value, name) => {
    console.log("value from the function handlpriceChange...", value);
    console.log("name from the function handlpriceChange...", name);

    setEnteredInput(value);
    setEnteredInputName(name);
  };

  //for the Market list
  useEffect(() => {
    if (marketList.length > 0) {
      const myMarketListFiltered = marketList.map((option) => {
        const firstLetter = option.COMARKET_NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setMarketListFiltered(myMarketListFiltered);
    }
  }, [marketList]);

  //for Company Type list
  useEffect(() => {
    if (companyTypeList.length > 0) {
      const myCompanyTypeListFiltered = companyTypeList.map((option) => {
        const firstLetter = option.COTYPE[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setCompanyTypeListFiltered(myCompanyTypeListFiltered);
    }
  }, [companyTypeList]);

  //Debouncing input box ... waiting until the field is fully typed before dispatching action...
  useEffect(() => {
    const identifier = setTimeout(() => {
      console.log("Checking input...");
      console.log("price info and key", enteredInput, enteredInputName);

      if (enteredInput) {
        dispatch(
          updateQuote({
            [enteredInputName]: enteredInput,
          })
        );
      } else {
        dispatch(
          updateQuote({
            [enteredInputName]: null,
          })
        );
      }
    }, 500);

    return () => {
      // console.log("CleanUp");
      clearTimeout(identifier);
    };
  }, [enteredInput, enteredInputName]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
         
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            // width: matches ? "100%" : "80%",
            width:  "100%" 

          }}
        >
          {/* Company Name */}
          <MyTextField
            name="companyName"
            id="outlined-search"
            label="Company Name."
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />

          {/* Company Type */}
          <Autocomplete
            ref={AutoCompleteCompanyType}
            name="companyType"
            margin="auto"
            // id="grouped-demo"
            id="project"
            options={companyTypeListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.COTYPE}
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            onChange={(event, value) =>
              handleQuoteChange(
                event,
                value,
                AutoCompleteCompanyType.current.getAttribute("name")
              )
            }
            renderInput={(params) => (
              <MyTextField
                {...params}
                label="company Type"
                //name="project"
                onSelect={(event) => console.log(event.target.name)}
              />
            )}
            renderOption={(props, option) => {
              //display value in Popper elements

              return (
                <li {...props} key={option.PROJECT_ID}>{`${option.COTYPE}`}</li>
              );
            }}
          />
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* Contact First Name */}
          <MyTextField
            name="clientFirstName"
            id="outlined-search"
            label="Client First Name"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />

          {/* Contact Last Name */}
          <MyTextField
            name="clientLastName"
            id="outlined-search"
            label="Client Last Name"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>console.log(event, event.target.value, event.target.name)}
            //   onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* Contact Email Address */}
          <MyTextField
            name="clientEmail"
            id="outlined-search"
            label="Client Email"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>console.log(event, event.target.value, event.target.name)}
            // onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name) }
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />

          {/* <Divider /> */}

          {/* Company Market  */}
          <Autocomplete
            ref={AutoCompleteCompanyMarket}
            name="companyMarket"
            margin="auto"
            id="grouped-demo"
            options={marketListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.COMARKET_NAME}
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            onChange={(event, value, name) =>
              handleQuoteChange(
                event,
                value,
                AutoCompleteCompanyMarket.current.getAttribute("name")
              )
            }
            renderInput={(params) => (
              <MyTextField {...params} label="Company Market" />
            )}
          />
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default QuoteStep_2;
