import {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
//import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';

import Avatar from "@mui/material/Avatar";


const Input = styled('input')({
  display: 'none',
});

export default function UploadButtons(props) {

    const [preview, setPreview] = useState(null)

    const {picRef, userImage, handleImageChange} = props;

    console.log("from the UploadButton component...............",userImage)

useEffect(()=>{

    if (userImage){

        const reader = new FileReader();

        reader.onloadend = ()=>{
            setPreview(reader.result)
        }

        reader.readAsDataURL(userImage);

    }else{

        setPreview(null);

    }

},[userImage])


  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" multiple type="file" />
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label> */}
      <label htmlFor="icon-button-file">
        <Input ref={picRef} accept="image/*" id="icon-button-file" type="file" onChange={(event)=>handleImageChange(event.target.files[0])} />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      
      <Avatar 
      alt="user pic" 
      src={preview}
      sx={{ width: '5vw', height: '5vw', cursor:'pointer' }}
      onClick={(event)=>{
          event.preventDefault();
          picRef.current.click();
        }}
      />
    </Stack>
  );
}