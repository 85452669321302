import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";
//import { updateQuote } from "../../store/quote-slice";
import {updateUser} from "../../store/users-slice";
import { v4 as uuidv4 } from "uuid";

import { MyTable, MyTableRow, StyledTableCell } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';




//to be added to the styledComponent file......
// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // backgroundColor: theme.palette.common.black,
//     backgroundColor: "#4E1721",

//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));



const ChangePayDateStep_2 = () => {
  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //console.log("current Payment state from step 3", currentPaymentInfo.paymentType);
//   console.log(
//     "current new Payment state from step 3 one dipper",
//     //currentQuoteInfo.companyType.COTYPE
//   );

  let paymentInfo1 = [];

  if (currentPaymentInfo?.selectedOrder.length > 0) {
    //console.log("Order number...",currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    paymentInfo1 = [
      { label: "Order : ", value: currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE },
      { label: "Company: ", value: currentPaymentInfo.selectedOrder[0].COMPANY_NAME },
      { label: "Total Paid: ", value: "£ " + parseFloat(currentPaymentInfo.amountPaid).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      }) },
      { label: "Payment Ref: ", value: currentPaymentInfo.paymentReference },
      { label: "Payment Type: ", value: currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_REF, id:currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_ID,
      icon:
      [
      <AccountBalanceOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <ReceiptLongOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <CreditCardOffOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <ContactlessOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <AlternateEmailOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      ], 
    },
    { label: "... ", value: "..." },
    { label: "Payment Date: ", value: new Date(currentPaymentInfo.paymentDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }),
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"500"
     },
      { label: "New Payment Date: ", value: new Date(currentPaymentInfo.paymentDateUpdated).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }),
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"900"
     },

    ];
  }

  //checking if bank date is different from recorded date and if reference hasn't been marked as changed then mark the reference with -x
  useEffect(()=>{

    if (new Date(currentPaymentInfo.paymentDate).toDateString() !== new Date(currentPaymentInfo.paymentDateUpdated).toDateString() ) {
      //alert("NO Equal")
      //console.log("comparing the dates.....",currentPaymentInfo.paymentDate,"and", currentPaymentInfo.paymentDateUpdated)
      //console.log("comparing the Types on dates.....",typeof(currentPaymentInfo.paymentDate),"and", typeof(currentPaymentInfo.paymentDateUpdated))
      
      //Add the Selected Order to the Payments In information
      dispatch(
        paymentInActions.updatePaymentIn({
            //paymentDateUpdated: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
            paymentReference: currentPaymentInfo.paymentReference + "-x"
            //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        })
      );

    } 

  },[])

  //Generate the unique QuoteKey when rendering the page
//   useEffect(() => {
//     const value = uuidv4();
//     dispatch(
//       updateUser({
//         userKey: value,
//       })
//     );
//   }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
          {/* <Avatar
          alt="user pic"
          src={currentUserInfo.userPicUrl}
          sx={{ width: "8vw", height: "8vw", cursor: "pointer", margin:1}}
        //   onClick={(event) => {
        //     event.preventDefault();
        //     document.getElementById("userPicUrl").click();
        //   }}
        /> */}

        <TableContainer
          component={Paper}
          //sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
          sx={{ margin:1, width: matches ? "45vw": "40vw"  }}

        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Payment Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: info.myFontWeight }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    //style={{ color: "#4E1721", fontWeight: 500 }}
                    style={{color: info.myFont, fontWeight: info.myFontWeight}}
                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
  );
};

export default ChangePayDateStep_2;
