import { useState, useRef, Fragment } from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";

import Graph from "./Graph";

import Grid from "@mui/material/Grid";

const SalesReportGraph = (props) => {
  const [checked, setChecked] = useState(false);
  const containerRef = useRef(null);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const { salesByConsultantList } = props;

 

  return (
    <Fragment>
      <Box ref={containerRef} >
        <FormControlLabel
          sx={{ margin: 0 }}
          control={<Switch checked={checked} onChange={handleChange} />}
          label="Show Graph"
        />
      </Box>
      <Slide
        // sx={{ height: salesByConsultantList.length>0 ? "auto" : 0 }}
        sx={{ height: checked ? "auto" : 0, overflow: "hidden" }}
        direction="right"
        in={checked}

        //in={salesByConsultantList.length>0}

        container={containerRef.current}
      >
        <Grid>
          {salesByConsultantList?.length > 0 &&
          <Graph salesByConsultantList={salesByConsultantList} />
          }
        </Grid>
      </Slide>
    </Fragment>
  );
};

export default SalesReportGraph;
