//import axios from 'axios';
import { useState, useEffect, useContext, Fragment } from "react";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import DashboardContainer from "../UI/DashboardContainer";
//import UserDetails from "./UserDetails";
import { variables } from "../../Variables";
import AddPaymentSteps from "./AddPaymentSteps";
import AddPaymentStep_1 from "./AddPaymentStep_1";
import AddPaymentStep_2 from "./AddPaymentStep_2";
import AddPaymentStep_3 from "./AddPaymentStep_3";

import AddPplanSignedSteps from "./AddPplanSignedSteps";
import AddPplanSignedStep_1 from "./AddPplanSignedStep_1";
import AddPplanSignedStep_2 from "./AddPplanSignedStep_2";
import AddPplanSignedStep_3 from "./AddPplanSignedStep_3";

import PaymentPlanInfo from "./PaymentPlanInfo";

//import UserStep_1 from "./UserStep_1";
//import UserStep_2 from "./UserStep_2";
//import UserStep_3 from "./UserStep_3";
//import UserStep_4 from "./UserStep_4";

import { SocketContext } from "../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";

import { paymentInActions } from "../../store/payment-slice";
import { paymentPlanActions } from "../../store/paymentPlan-slice";

//MUI IMPORTS--------------------------------------
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CreateIcon from "@mui/icons-material/Create";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Avatar from "@mui/material/Avatar";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
//import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
//import GroupIcon from '@mui/icons-material/Group';
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../UI/Theme";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddCardIcon from "@mui/icons-material/AddCard";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";
//import Paper from "@mui/material/Paper";

//import Box from "@mui/material/Box";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AddPaymentsBase = (props) => {
  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const currentOrderSelected = useSelector(
    (state) => state.orders.selectedOrder
  );
  const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );



  //const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);


  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );

  //const [value, setValue] = useState(0);

  const [usersTitleList, setUsersTitleList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const {
    paymentTypeList,
    isNewPayment,
    isNewPaymentPlan,
    newPaymentPlan,
    isLoading1_allPayOnOrder,
    isLoading1_PayPlanOnOrder,
    isLoading1_PayplanOnOrderHandler,
  } = props;
  const [showAddPayment, setShowAddPayment] = useState(false);

  const [showPplanSigned, setShowPplanSigned] = useState(false);
  const [showPplanSet, setShowPplanSet] = useState(false);
  const [showPplanSignedDetails, setShowPplanSignedDetails] = useState(false);

  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //Show the modal with form to submit new payment in-----------
  const showAddPaymentModalHandler = (event, action) => {
    if (event) {
      event.preventDefault();
    }
    if (action === 1) {
      setShowAddPayment((prev) => !showAddPayment);
    } else if (action === 2) {
      setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      //console.log("receiving a message object", data);
      //console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      //console.log("receiving a notification object for PAYMENTS INNNNNNNNNNNNNNN", data);
      //console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  //Reseting the slice for paymentsIn redux
  useEffect(() => {
    //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
    dispatch(
      paymentInActions.resetPaymentIn({
        paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        //paymentDate: new Date(currentPaymentInfo?.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

    //Reset The Payment Plan Slice to initial values function to be used after payment is recorded -----
    dispatch(
      paymentPlanActions.resetPaymentPlan({
        paymentPlanSignedDate: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  }, []);

  //Steps for creating a new Payment-------------------------------------
  const steps = [
    {
      label: "Payment Details",
      info: (
        <AddPaymentStep_1
          usersTitleList={usersTitleList}
          paymentTypeList={paymentTypeList}
        />
      ),
      icon: <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <PaymentOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <AddPaymentStep_2 />,
      //info: "Payment Summary component...",
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <AddPaymentStep_3
          socket={socket}
          userInfo={userInfo}
          showAddPaymentModalHandler={showAddPaymentModalHandler}
          isNewPayment={isNewPayment}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },
  ];

  //Steps for Add a Signed Payment Plan to the order-------------------------------------
  const steps_signed = [
    {
      label: "P.Plan Signed Details",
      info: (
        <AddPplanSignedStep_1
          usersTitleList={usersTitleList}
          paymentTypeList={paymentTypeList}
        />
      ),
      icon: (
        <DriveFileRenameOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />
      ),
      iconCompleted: (
        <DriveFileRenameOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <AddPplanSignedStep_2 />,
      //info: "Payment Summary component...",
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <AddPplanSignedStep_3
          socket={socket}
          userInfo={userInfo}
          showAddPaymentModalHandler={showAddPaymentModalHandler}
          isNewPaymentPlan={isNewPaymentPlan}
          newPaymentPlan={newPaymentPlan}
          isLoading1_PayPlanOnOrder={isLoading1_PayPlanOnOrder}
          isLoading1_PayplanOnOrderHandler={isLoading1_PayplanOnOrderHandler}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },
  ];

  //console.log("The payment plan info so far", currentPaymentPlanInfo);
  // console.log(
  //   "The payment plan ID so far",
  //   currentPaymentPlanInfo.payPlanInfo.PaymentPlanId
  // );

  //alert("the status loading on payplan starting - II..." +  isLoading1_PayPlanOnOrder)

  return (
    <Fragment>
      <div>
        <Button
          onClick={(event) => {
            event.preventDefault();
            showAddPaymentModalHandler(event, 1);
          }}
          sx={{ color: "#7A1125", textTransform: "capitalize" }}
          disabled={!selectedOrder.length ? true : false}
        >
          <AddCardIcon
            sx={{
              fontSize: 20,
              color: !selectedOrder.length ? "#ccc" : "#4E1721",
              marginRight: 2,
              marginTop: 2,
            }}
          />
          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, fontSize: 12, }}
          >
            Add Payment
          </Typography>
        </Button>

        <Divider />

        {!isLoading1_PayPlanOnOrder ? (
          <Button
            onClick={(event) => {
              event.preventDefault();
              {
                currentPaymentPlanInfo.paymentPlanSignedId
                  ? showAddPaymentModalHandler(event, 4)
                  : currentPaymentPlanInfo.payPlanInfo.PaymentPlanId
                  ? showAddPaymentModalHandler(event, 3)
                  : showAddPaymentModalHandler(event, 2);
              }
            }}
            sx={{
              color: "#7A1125",
              textTransform: "capitalize",
              margin: "auto",
              width: "100%",
            }}
            disabled={!selectedOrder.length ? true : false}
          >
            {/* {currentPaymentPlanInfo.payPlanInfo.selectedOrder.length > 0 ? "si" : "no"} */}
            {currentPaymentPlanInfo.paymentPlanSignedId ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <CreateIcon
                  sx={{
                    fontSize: 20,
                    //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                    color: !selectedOrder.length
                      ? "#ccc"
                      : theme.palette.common.arcBlue,
                    marginRight: 2,
                    //marginTop: 2,
                  }}
                />
                <Avatar
                  alt="user pic"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/GC-logo_MKBls7sH9n.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653995686593"
                />
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: 20,
                    //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                    color: !selectedOrder.length
                      ? "#ccc"
                      : theme.palette.common.arcBlue,
                    marginLeft: 2,
                    //marginTop: 2,
                  }}
                />
              </Box>
            ) : currentPaymentPlanInfo.payPlanInfo.PaymentPlanId ? (
              <>
                <CreateIcon
                  sx={{
                    fontSize: 20,
                    color: !selectedOrder.length ? "#ccc" : "#4E1721",
                    marginRight: 2,
                    marginTop: 2,
                  }}
                />
                <Typography
                  align="center"
                  //variant="body2"
                  //color="text.secondary"
                  sx={{ marginTop: 2, fontSize: 12, }}
                >
                  Signed P.Plan
                </Typography>
              </>
            ) : (
              <>
                <InsertInvitationIcon
                  sx={{
                    fontSize: 20,
                    color: !selectedOrder.length ? "#ccc" : "#4E1721",
                    marginRight: 2,
                    marginTop: 2,
                  }}
                />
                <Typography
                  align="center"
                  //variant="body2"
                  //color="text.secondary"
                  sx={{ marginTop: 2, fontSize: 12, }}
                >
                  New P.Plan
                </Typography>
              </>
            )}
          </Button>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )}

        {/* Modal for case: add a New payment ---------------------- */}
        <Dialog
          open={showAddPayment}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showAddPaymentModalHandler(event, 1)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Add New Payment In</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            <AddPaymentSteps steps={steps} />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: add payment plan signed ---------------------- */}
        <Dialog
          open={showPplanSigned}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showAddPaymentModalHandler(event, 3)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Add Signed P. Plan</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            <AddPplanSignedSteps
              steps={steps_signed}
              showAddPaymentModalHandler={showAddPaymentModalHandler}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: add payment plan SET ---------------------- */}
        <Dialog
          open={showPplanSet}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showAddPaymentModalHandler(event, 2)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Add New P. Plan</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            <AddPplanSignedSteps
              steps={steps_signed}
              showAddPaymentModalHandler={showAddPaymentModalHandler}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: Show payment plan signed Details ---------------------- */}
        <Dialog
          open={showPplanSignedDetails}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showAddPaymentModalHandler(event, 4)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Signed P. Plan Details</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <Fragment>
                <DialogContentText sx={{ margin: 2 }}>
                  On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME}
                </DialogContentText>
                
                <Typography
                  align="center"
                  //variant="body2"
                  //color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                   Payment Plan Signed.  please see details as follows...
                </Typography>
               

                <PaymentPlanInfo currentPaymentPlanInfo={currentPaymentPlanInfo}/>

              </Fragment>
            )}
            {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default AddPaymentsBase;
