import axios from "axios";
import React, { useState, useEffect } from "react";
import DashboardContainer from "../UI/DashboardContainer";
import QuoteSteps from "./QuoteSteps";
import QuoteStep1 from "./QuoteStep_1";
import QuoteStep2 from "./QuoteStep_2";
import QuoteStep3 from "./QuoteStep_3";
import QuoteStep4 from "./QuoteStep_4";

//import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { updateMessagesList, updateNotificationsTypeList } from "../../store/notifications-slice";
import {variables} from '../../Variables';
import { SocketContext } from "../../utils/socket-client";

//------IMPORTS MUI --------------------------------
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';



const Quote = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const messageListInitial = useSelector((state) => state.notifications.messages);
  const notificationsListInitial = useSelector((state)=>state.notifications.notificationsType);
  const [projectList, setProjectList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [companyTypeList, setCompanyTypeList] = useState([]);

  const [socket, setSocket] = useState(null);
  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(notificationsListInitial);

  const mySocket = React.useContext(SocketContext);


  //fetching projects from DB-------------------------------------------
  //"http://localhost:5000/projects",
  const getProjects = () => {
    axios
      .get(
        variables.API_URL + "projects", 
        { withCredentials: true })

      .then((res) => {
        console.log("fetching projects...",res.data.projectListInfo)
        setProjectList(res.data.projectListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetching products from DB-------------------------------------------
  //"http://localhost:5000/products",
  const getProducts = () => {
    axios
      .get(
        variables.API_URL + "products", 
        { withCredentials: true })

      .then((res) => {
        //console.log("fetching products...",res.data.productListInfo)
        setProductList(res.data.productListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetching Markets from DB-------------------------------------------
  //"http://localhost:5000/clients/company-market",
  const getMarketList = () => {
    axios
      .get(
        variables.API_URL + "clients/company-market", 
        {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching products...",res.data.productListInfo)
        setMarketList(res.data.marketListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetching Company Types from DB-------------------------------------------
  //"http://localhost:5000/clients/company-type",
  const getCompanyTypeList = () => {
    axios
      .get( 
        variables.API_URL + "clients/company-type",
        {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching products...",res.data.productListInfo)
        setCompanyTypeList(res.data.companyTypeListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProjects();
    getProducts();
    getMarketList();
    getCompanyTypeList();
  }, []);

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      console.log("receiving a message object", data);
      console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      console.log("receiving a notification object", data);
      console.log("receiving a notification", data.type);
    });
  }, [socket]);
  

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  const steps = [
    {
      label: "Product Offer Information",
      info: <QuoteStep1 projectList={projectList} productList={productList} />,
      icon: <SettingsIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
      iconCompleted: <SettingsIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
      id: 0
    },
    {
      label: "Client Details",
      info: (
        <QuoteStep2
          marketList={marketList}
          companyTypeList={companyTypeList}
        />
      ),
      icon: <GroupAddIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
      iconCompleted: <GroupAddIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
      id: 1
    },
    { 
      label: "Quote Summary", 
      info: <QuoteStep3 />, 
      icon: <ScreenSearchDesktopIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
      iconCompleted: <ScreenSearchDesktopIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
      id: 2
    },
    { 
      label: "Confirmation", 
      info: <QuoteStep4 socket={socket} 
      userInfo={userInfo} />, 
      icon:  <MarkEmailReadIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
      iconCompleted:  <MarkEmailReadIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
      id: 3
    },
  ];

  // if(productList){

  //   console.log("the product list state outside....", productList)
  // }

  return (
    <>
      <DashboardContainer 
      reportTitle="Create a New Quote"
      myMarginTop={5}
      icon={<AttachEmailIcon
        sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
      />}

      >
        <QuoteSteps steps={steps} />
      </DashboardContainer>
    </>
  );
};

export default Quote;
