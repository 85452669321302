import { useState, useEffect, Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";

import axios from "axios";
import DashboardContainer from "../UI/DashboardContainer";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import BarChart from "../BarChart";

import Title from "../Title";
import {TablePaginationActions} from "../UI/Pagination";
import { MyTable, MyTableRow, MyTable2,StyledTableCell, MyTableCell } from "../UI/styledComponents/StyledComponents";
import ChangePayDateSteps from "../paymentsComponent/ChangePayDateSteps";
import ChangePayDateStep_1 from "../paymentsComponent/ChangePayDateStep_1";
import ChangePayDateStep_2 from "../paymentsComponent/ChangePayDateStep_2";
import ChangePayDateStep_3 from "../paymentsComponent/ChangePayDateStep_3";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import Draggable from "react-draggable";

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
//import TableCell from "@mui/material/TableCell";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Collapse from "@mui/material/Collapse";

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // backgroundColor: theme.palette.common.black,
//     backgroundColor: theme.palette.common.ludisMain,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


export const DisplayExtraInfo = (props)=>{

  const {open1, item, matches2, matches3} = props;

  return (
    <TableRow>
  {/* Extra information collapsable  */}
  {/* <TableRow key={item.CLIENTPAYMENT_ID}> */}
                            <MyTableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                              >
                              <Collapse in={open1[item.CLIENTPAYMENT_ID]} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0, }}
                                    >
                                    Payment Details....
                                  </Typography>

                                  <MyTable2 size="small" aria-label="payments" >
                                    <TableHead >
                                      <TableRow sx={{backgroundColor: "#fff"}}>
                                        <TableCell >Date Recorded</TableCell>
                                        <TableCell>Date Bank</TableCell>
                                        <TableCell align="center">Reference</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key={item.CLIENTPAYMENT_ID}>
                                        <TableCell component="th" scope="row">
                                          {new Date(
                                            item.PAYMENTDATE
                                            ).toLocaleString("default", {
                                              day: "numeric",
                                              month: "short",
                                              year: "numeric",
                                            })}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {new Date(
                                            item.DATEONSTATEMENT
                                            ).toLocaleString("default", {
                                              day: "numeric",
                                              month: "short",
                                              year: "numeric",
                                            })}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.CHEQUEREF.substring(0, 60)}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </MyTable2>
                                </Box>
                              </Collapse>
                            </MyTableCell>
                          {/* End Extra information collapsable */}
                          </TableRow>
                          
  )
                          
                          
                        }
                          
                          
  export default function PaymentsReportByDate(props) {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.user);

  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);


  const [showPaymentsList, setShowPaymentsList] = useState(true);
  const [totalPayments, setTotalPayments] = useState(null);

  //variables for the popover (hoover)
  const [paymentRef, setPaymentRef] = useState("");

  const [showPaymentDateChange, setShowPaymentDateChange] = useState(false);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  //isLoading1_allPayOnOrder is to pass to the parent component Income.js info about loading to share with sibling componente paymentinfoOrderSummary.js
  const {
    paymentsByDateList,
    isLoading1,
    showBank,
    matches,
    matches2,
    matches3,
    isUpdatedPayment,
    showAddPaymentModalHandler,
  } = props;


  let repDate = "";


  //Functions for Table Pagination--------------------------------------
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - paymentsByDateList.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    
    };
  //END Functions for TAble Pagination----------------------------------

  const showPaymentsHandler = (event) => {
    event.preventDefault();
    setShowPaymentsList((prev) => !showPaymentsList);
  };

  const resetPaymentInfo = () => {
    dispatch(
      paymentInActions.resetPaymentIn({
        paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        //paymentDate: new Date(currentPaymentInfo.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),
      })
    );
  };

  //Update Paymnet In details into the slice---------------------
  const updatePaymentInfo = (paymentInfo) => {
    console.log("payment info insde the update function....", paymentInfo);

    if (paymentInfo) {
      //Add the Selected Order to the Payments In information
      dispatch(
        paymentInActions.updatePaymentIn({
          //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(), //---here so far....
          selectedOrder: [
            {
              // "confirmationId":paymentInfo.CONFIRMATION_ID
              CONFIRMATION_ID: paymentInfo.CONFIRMATION_ID,
              CONFIRMATION_REFERENCE: paymentInfo.CONFIRMATION_REFERENCE,
              COMPANY_NAME: paymentInfo.COMPANY_NAME,
            },
          ],
          amountPaid: paymentInfo.AMOUNTPAID,
          paymentType: {
            //firstLetter: "B",
            CLIENTPAYMENTTYPE_ID: paymentInfo.CLIENTPAYMENTTYPE_ID,
            CLIENTPAYMENTTYPE: paymentInfo.CLIENTPAYMENTTYPE,
            CLIENTPAYMENTTYPE_REF: paymentInfo.CLIENTPAYMENTTYPE_REF,
          },

          //paymentDateUpdated: paymentInfo.DATEONSTATEMENT,
          paymentReference: paymentInfo.CHEQUEREF,
          paymentDate: paymentInfo.DATEONSTATEMENT,
          selectedPayment_id: paymentInfo.CLIENTPAYMENT_ID,
        })
      );
    } else {
      resetPaymentInfo();
      //console.log("error, date not usable only 2 options available");
      //return;
    }
  };

  //Show the modal with form to submit new payment in-----------
  const showModalHandler = (event, action, item) => {
    resetPaymentInfo();
    if (event) {
      event.preventDefault();
      //console.log("Order Id here....", item);
    }
    if (action === 1) {
      setShowPaymentDateChange((prev) => !showPaymentDateChange);
      updatePaymentInfo(item);
    } else if (action === 2) {
      //setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      //setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      //setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //let totalPaidOnOrder = 0;

  //   let confoId = "";
  //   if (selectedOrder.length > 0) {
  //     confoId = selectedOrder[0].CONFIRMATION_ID;
  //   }

  // const Paymenticons = [
  //   <AccountBalanceOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <ReceiptLongOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <CreditCardOffOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <PaymentOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <PaymentOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <ContactlessOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  //   <AlternateEmailOutlinedIcon
  //     sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
  //   />,
  // ];

  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
  ];

  //POP OVER CODE --------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);

  const [open1, setOpen1] = useState(false);

  const handlePopoverOpen = (event, reference) => {
    //console.log(reference)
    setAnchorEl(event.currentTarget);
    setPaymentRef(reference);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPaymentRef("");
  };

  const handleCollapse = (event, item) => {
    event.preventDefault();
    // console.log("el eventoooooo....", event.currentTarget);
    // console.log("el eventoooooote....", item.CLIENTPAYMENT_ID);

    setOpen1((prev) => ({ ...prev, [ item.CLIENTPAYMENT_ID]: !prev[ item.CLIENTPAYMENT_ID] }))

    //test to be deleted------
    paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };


  //Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC ------------
  const paymentListFiltered = (payType)=>{
    
    // if(payType===5){
      //for CARD payments--------
      const paymentsByDateListFiltered_current = paymentsByDateList.filter((item)=>item.CLIENTPAYMENTTYPE_ID === payType)
      //console.log("here is the filtered list for card paymetns....",paymentsByDateListFiltered_current)
      //console.log("here is the original list for all paymetns....",paymentsByDateList)

      //setPaymentsByDAteListFiltered(paymentsByDateListFiltered_current)
    // }
  }
  //END Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC --------





  const open = Boolean(anchorEl);

  //Steps for Add a Signed Payment Plan to the order-------------------------------------
  const steps_changeDate = [
    {
      label: "Payment Details",
      info: (
        <ChangePayDateStep_1
        // usersTitleList={usersTitleList}
        //paymentTypeList={paymentTypeList}
        />
      ),
      icon: (
        <DriveFileRenameOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />
      ),
      iconCompleted: (
        <DriveFileRenameOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <ChangePayDateStep_2 />,
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <ChangePayDateStep_3
          //socket={socket}
          //userInfo={userInfo}
          showModalHandler={showModalHandler}
          isUpdatedPayment={isUpdatedPayment}
          //updatePayment={updatedPayment}
          //newPaymentPlan={newPaymentPlan}
          isLoading1={isLoading1}
          //isLoading1_PayplanOnOrderHandler={isLoading1_PayplanOnOrderHandler}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },
  ];

  //   const getPaymentsOnOrder = (confoId) => {

  //     setIsLoading1(true);
  //     isLoading1_allPayOnOrderHandler(true); //this is the same info of isloading1 passed to the parent component to share with Siblings....

  //     axios
  //       .get(variables.API_URL + "payments/paymentsonorder/" + confoId, {
  //         withCredentials: true,
  //       })
  //       .then((res) => {
  //         //console.log("payments on order here....", res.data.paymentsOnOrder);
  //         setPaymentsOnOrder(res.data.paymentsOnOrder);
  //         setIsLoading1(false);
  //         isLoading1_allPayOnOrderHandler(false); //this is the same info of isloading1 passed to the parent component to share with Siblings....
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //   useEffect(() => {
  //     if (confoId) {
  //       //getPaymentsOnOrder(confoId);
  //     }
  //   }, [confoId, newPayment]);

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)

    if (paymentsByDateList.length > 0) {
      setTotalPayments(
        paymentsByDateList[paymentsByDateList.length - 1].cumulative_sum
      );
    } else {
      setTotalPayments(0);
    }

    // dispatch(
    //   paymentInActions.updatePaymentIn({
    //     selectedOrderTotalPaid: totalPaidOnOrder,
    //   })
    // );
  }, [paymentsByDateList]);

  return (
    //paymentsByDateList.length > 0 ?
    <Fragment>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          //alignItems: "flex-start",
          alignItems: "center",

        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showPaymentsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />

          {showPaymentsList ? "Hide Payments" : "Show Payments"}
        </Button>
      </Box>

      {showPaymentsList ? (
        !isLoading1 ? (
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
              //backgroundColor:"#000",
              // height: "100vh",
              // overflow: "auto",
              
            }}
          >
            <Title>Payments In...</Title>

            <Divider sx={{ width: "60vw" }} />

            {paymentsByDateList?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: matches3?"70vw":matches2 ? "60vw" : "60vw", marginTop: 2, padding:0  }}
                // sx={{ width: matches2 ? "80vw" : "60vw", marginTop: 2 }}
              >
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <MyTableRow>
                      <StyledTableCell align="left">...</StyledTableCell>
                      <StyledTableCell align="left">Order</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell align="left">
                        Amount&nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align={matches?"left":"center"}>Company</StyledTableCell>
                      {!matches3 &&<StyledTableCell align="right">P. Type</StyledTableCell>}
                      {userInfo.permission === 6 &&<StyledTableCell align="right">Action</StyledTableCell>}
                    </MyTableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {
                    (rowsPerPage > 0? paymentsByDateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):
                    paymentsByDateList).map((item) => {
                      showBank
                        ? (repDate = item.DATEONSTATEMENT)
                        : (repDate = item.PAYMENTDATE);
                      return (
                        <Fragment>
                          <MyTableRow key={item.CLIENTPAYMENTTYPE_ID}>
                            {/* <TableCell> */}
                            <MyTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                              >
                                {open1[item.CLIENTPAYMENT_ID] ? (
                                  // <KeyboardArrowUpIcon />
                                  <KeyboardArrowUpIcon sx={{fontSize:matches3?"0.6rem":"1rem", padding:0}} />
                                ) : (
                                  // <KeyboardArrowDownIcon />
                                  <KeyboardArrowDownIcon sx={{fontSize:matches3?"0.6rem":"1rem",padding:0}} />
                                )}
                              </IconButton>
                            {/* </TableCell> */}
                            </MyTableCell>


                            <MyTableCell>
                            {/* <TableCell> */}
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0 }}>
                                {item.CONFIRMATION_REFERENCE}
                              </Typography>
                            {/* </TableCell> */}
                            </MyTableCell>

                            <MyTableCell>
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0, }}>
                                {new Date(repDate).toLocaleString("default", 
                                matches2?
                                {day: "numeric", month: "short"}:
                                {day: "numeric", month: "short", year: "numeric",}
                                )}
                              </Typography>
                            </MyTableCell>

                            <MyTableCell>
                              £{" "}
                              {parseFloat(item.AMOUNTPAID).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </MyTableCell>
                            <MyTableCell>
                              <Typography
                                aria-owns={
                                  open ? "mouse-over-popover" : undefined
                                }
                                aria-haspopup="true"
                                onMouseEnter={(event) =>
                                  handlePopoverOpen(event, item.CHEQUEREF)
                                }
                                onMouseLeave={handlePopoverClose}
                                //sx={{ fontSize: matches ? 11 : 14 }}
                                sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0, }}
                              >
                                {matches3?
                                item.COMPANY_NAME.substring(0, 10)
                                :matches2
                                  ? //   ? item.CHEQUEREF.substring(0, 30)
                                    //   : item.CHEQUEREF.substring(0, 60)
                                    item.COMPANY_NAME.substring(0, 30)
                                  : item.COMPANY_NAME.substring(0, 60)}
                              </Typography>
                            </MyTableCell>
                            {!matches3 &&
                            <StyledTableCell align="right">
                              {matches ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                              {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                            </StyledTableCell>
                            }      

                            {userInfo.permission === 6 &&<StyledTableCell align="right">
                              <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  showModalHandler(event, 1, item);
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 1,
                                  }}
                                />
                              </Button>
                            </StyledTableCell>}
                          </MyTableRow>

                          {open1[ item.CLIENTPAYMENT_ID] && <DisplayExtraInfo open1={open1}  item={item} matches2={matches2} matches3={matches3}/>}

                          
                        </Fragment>
                      );
                    }
                    )}
                  </TableBody>

                  {/* Footer--------------------------------------------------------------- */}
                  <TableFooter sx={{backgroundColor:theme.palette.common.ludisMain, color:theme.palette.common.ludisLight1}}>
                    <TableRow key={Math.random()}>
                      <TablePagination
                        //component="div"
                        
                        rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                        colSpan={matches3?6:7}
                        count={paymentsByDateList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native:true,
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        //extras------------------------------------------------
                        labelRowsPerPage={<span>{matches3?"":"rows"} </span>}
                        //labelDisplayedRows={({page})=>{return `Page: ${page}`}}
                        labelDisplayedRows={ matches3? ({page})=>{return `Page: ${page}`} :
                          (from=page) => `${from.from}-${from.to === -1 ? from.count : from.to} of ${from.count}`
                        }
                        //sx={{ color:theme.palette.common.ludisLight1 }}
                        sx={{ ".MuiTablePagination-toolbar":{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"baseline",
                              justifyContent:"center",
                              alignContent:"center",

                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              pt:1,
                              //margin:"auto"
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              width: "auto",
                              ml: matches3?"0":"0",
                              pl: matches3?"0":"1"


                            },
                            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":{
                              //display:"flex",
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              //margin:"auto"
                              //backgroundColor:"#000"
                            } ,
                            ".MuiTablePagination-selectIcon":{
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.8rem" : matches?"0.9rem": "1rem",
                              //backgroundColor:"#000"

                            },
                            ".MuiTablePagination-menuItem":{
                              color:theme.palette.common.ludisMain,
                              fontSize:"1rem",
                              
                              //backgroundColor:"#000"
                            }

                      }}
                      />
                    </TableRow>
                  </TableFooter>
                  {/* END Footer----------------------------------------------------------- */}



                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      {/* <Popover
        id="mouse-over-popover"
        //id={item.CLIENTPAYMENT_ID}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      > */}
      {/* <Typography sx={{ p: 1 }}>{item.CHEQUEREF}</Typography> */}
      {/* <Typography
          sx={{
            p: 1,
            backgroundColor: "#e7dddd",
            color: theme.palette.common.ludisMain,
            fontSize: 20,
          }}
        >
          {paymentRef}
        </Typography> */}
      {/* </Popover> */}

      {/* Modal for case: Show payment plan signed Details ---------------------- */}
      <Dialog
        open={showPaymentDateChange}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showModalHandler(event, 1)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>Payment Details</DialogTitle>
        <DialogContent>
          {/* {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
              On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
              Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )} */}
          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          Update Payment Date as per Bank Statements.....
          <ChangePayDateSteps
            steps={steps_changeDate}
            showModalHandler={showModalHandler}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
  //   : ("..." );
}
