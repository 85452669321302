import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import DashboardContainer from "../UI/DashboardContainer";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import BarChart from "../BarChart";

import { MyListItemText } from "../UI/styledComponents/StyledComponents"

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TimelineIcon from '@mui/icons-material/Timeline';

//loader Imports--------------
import { SyncLoader } from "react-spinners";

export default function SalaryChart(props) {
  const [payOutBySuppliersList, setPayOutBySuppliersList] = useState([]);
  const [payOutByCostList, setPayOutByCostList] = useState([]);
  
  
  const [salesByConsultantsList, setSalesByConsultantsList] = useState([]);
  const [cancellationsByConsultantsList, setCancellationsByConsultantsList] =
    useState([]);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  //const [mySalaryData_FixOps, setMySalaryData_FixOps]=useState([])

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
//
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //const { tabInitialDate, tabFinalDate, reportType, totalPaymentsDIRByDate } = props;

  const [showSalesGraph, setShowSalesGraph] = useState(true);

  const showSalesGraphHandler = (event) => {
    event.preventDefault();
    setShowSalesGraph((prev) => !showSalesGraph);
  };

  //alert("my props inside..."+tabInitialDate+"and"+ tabFinalDate)

  //let netSales = 0;
  let BarChartData = [];
  //let mySalaryData_FixOps = []; //this is to check if the salary data contain Fix Operational salary payments or not to display the chart or default Icon


  const {salaryDataDIR, salaryDataFIX, salaryData, reportType } = props; 

  // console.log("DIR Salary DATA....", salaryDataDIR)
  // console.log("FIX Salary DATA....", salaryDataFIX)
  // console.log("Salary DATA....", salaryData)
  // console.log("reportType....", reportType)

  // if(salaryData?.length>0 && reportType.typeSubId === 1){
  //   setMySalaryData_FixOps(salaryData.filter((data)=>data.TYPECOST_ID===1))
  //   console.log("es el Unoooooooooooooooooo",mySalaryData_FixOps)
  // }

  


  return (
    <Fragment>
      <Button
        onClick={(event) => {
          event.preventDefault();
          showSalesGraphHandler(event);
        }}
        sx={{ color: "#7A1125", textTransform: "capitalize" }}
      >
        <TimelineIcon sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }} />

        {/* {props.changeDates ? "View This Week" : "View All Year"} */}

        {showSalesGraph ? "Hide Graph" : "Show Graph " + reportType.type + reportType.typeSubIdName || "..."}
      </Button>

      {showSalesGraph ? (
        !isLoading1 && !isLoading2 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={12} lg={8}>
              {/* <DashboardContainer
          reportTitle="Reporting Module"
          myMarginTop={5}
          icon={
            <QueryStatsIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
          }
          myWidth={10}
          > */}

              {/* <Box> */}
              {
              //salaryDataDIR?.length > 0 || salaryDataFIX?.length > 0?
              salaryData?.length > 0  ?
              <>
              <BarChart BarChartData={BarChartData} myLabel="Paid" myBarColor= {theme.palette.common.ludisMain2} mySmall={250} />
              
              </>
              : 
              <EqualizerIcon
              key={`${Math.random()}`}
              sx={{ fontSize: 30, color: "#4E1721", mr: 1, }}
              />
              }

              {/* </Box> */}
              {/* </DashboardContainer> */}
            </Grid>

            {/* <DashboardContainer
          reportTitle="Reporting Module"
          myMarginTop={10}
          icon={
            <QueryStatsIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
          }
          myWidth={12}
        > */}
            {/* <h6>Date From: ...{selectedDatesFromInitial}</h6> */}

            {/* <h6>Date To: ...{new Date(Date.now()).toISOString()}</h6> */}

            {/* <Grid item xs={12} md={1} lg={1}></Grid> */}

            <Grid item xs={12} md={12} lg={4}>
              <Box
                sx={{
                  //p: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",

                  //margin: 2,
                  // width: matches ? "100%" : 400,
                }}
              >
                

                {/* CODE FOR DIR WAGES PAID REPORT BY CONSULTANT    */}
                {(reportType.typeId===1)&&
                  
                  <List key={`${Math.random()}`}>
                  {
                    //salaryDataDIR.map((dir) => {
                    salaryData.map((dir) => {

                    BarChartData.push({
                        Name: (matches3?dir.Firstname.charAt(0):dir.Firstname.charAt(0) + dir.Firstname.toLowerCase().slice(1)) +
                        " " +
                        dir.Lastname.charAt(0),
                      Paid: dir.subTotal                      ,
                      Sales: dir.subTotal.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      }),
                    });

                    return (
                      <Fragment key={`${Math.random()}`}>
                        <ListItem
                          key={`${Math.random()}`}
                          sx={{
                            width: matches ? "100%" : 300,
                            color: "#4E1721",
                          }}
                        >
                          <ListItemAvatar key={`${Math.random()}`}>
                            <Avatar sx={{ bgcolor: "#e7dddd" }}>
                              <AccountCircleIcon
                                key={`${Math.random()}`}
                                sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <MyListItemText
                            key={`${Math.random()}`}
                            primary={
                              //consultant.NAME +
                              dir.Firstname.charAt(0) + dir.Firstname.toLowerCase().slice(1).substring(0,10) + " " + dir.Lastname.charAt(0) +
                              " :  £ " +
                              dir.subTotal.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                            }

                            //secondary={newClientStatus ? newClientStatus : "No Info..."}
                          />
                        </ListItem>
                        <Divider sx={{ width: "80%" }} />
                      </Fragment>
                    );
                  })}
                  </List>
                }




                {/* CODE FOR FIX WAGES PAID REPORT BY CONSULTANT OPERATIONAL ONLY    */}
                {/* typeId =2 for Wages Invoice FIX  ---- typeId=22 for wages Paid on Fix */}
                {(reportType.typeId===2 || reportType.typeId===22)&&
                  
                  <List key={`${Math.random()}`}>
                  {salaryData.map((fix) => {

                    let condition="";

                    if(reportType.typeId===22){
                      
                      condition =fix.TYPECOST_ID === reportType.typeSubId
                    }else{
                      condition = fix.TITLE_ID !== 14

                    }

                    condition &&
                    
                    BarChartData.push({
                      Name: (matches3?fix.Firstname.charAt(0):fix.Firstname.charAt(0)+fix.Firstname.toLowerCase().slice(1)) +
                      " " +
                      fix.Lastname.charAt(0),
                      Paid: fix.subTotal,
                      Sales: fix.subTotal.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      }),
                    });

                    return (

                      //fix.TYPECOST_ID === reportType.typeSubId &&
                      condition &&
                      



                      <Fragment key={`${Math.random()}`}>
                        
                        <ListItem
                          key={`${Math.random()}`}
                          sx={{
                            width: matches ? "100%" : 300,
                            color: "#4E1721",
                          }}
                        >
                          <ListItemAvatar key={`${Math.random()}`}>
                            <Avatar sx={{ bgcolor: "#e7dddd" }}>
                              <AccountCircleIcon
                                key={`${Math.random()}`}
                                sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <MyListItemText
                            key={`${Math.random()}`}
                            primary={
                              //consultant.NAME +
                              fix.Firstname.charAt(0) + fix.Firstname.toLowerCase().slice(1).substring(0,10) + " " + fix.Lastname.charAt(0) +
                              " :  £ " +
                              fix.subTotal.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                            }

                            //secondary={newClientStatus ? newClientStatus : "No Info..."}
                          />
                        </ListItem>
                        <Divider sx={{ width: "80%" }} />
                      </Fragment>




                    );
                  })}
                  </List>
                }  




                {/* CODE FOR NON OPS INVOICES REPORT GROUP BY CONSULTANTS - NON-OPERATIONAL ONLY   */}
                {(reportType.typeId===3)&&
                  
                  <List key={`${Math.random()}`}>
                  {salaryData?.map((fix) => {

                    //console.log("this is the title...",dir.TITLE_ID)

                   fix.TITLE_ID === 14  && 

                    BarChartData.push({
                        Name: (matches3?fix.Firstname.charAt(0):fix.Firstname) +
                        " " +
                        fix.Lastname.charAt(0),
                      Paid: fix.subTotal                      ,
                      Sales: fix.subTotal.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      }),
                    });

                    return (
                      fix.TITLE_ID === 14  && 
                      <Fragment key={`${Math.random()}`}>
                        <ListItem
                          key={`${Math.random()}`}
                          sx={{
                            width: matches ? "100%" : 300,
                            color: "#4E1721",
                          }}
                        >
                          <ListItemAvatar key={`${Math.random()}`}>
                            <Avatar sx={{ bgcolor: "#e7dddd" }}>
                              <AccountCircleIcon
                                key={`${Math.random()}`}
                                sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <MyListItemText
                            key={`${Math.random()}`}
                            primary={
                              //consultant.NAME +
                              fix.Firstname.substring(0,10) + " " + fix.Lastname.charAt(0) +
                              " :  £ " +
                              fix.subTotal.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                            }

                            //secondary={newClientStatus ? newClientStatus : "No Info..."}
                          />
                        </ListItem>
                        <Divider sx={{ width: "80%" }} />
                      </Fragment>
                    );
                  })}
                  </List>
                }




                {/* </Grid> */}
                {/* </DashboardContainer> */}
              </Box>
            </Grid>
            {/* </DashboardContainer> */}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      
    </Fragment>
  );
}
