import { Fragment } from "react";
import { useSelector } from "react-redux";

import InvoicingSteps from "../adminComponents/InvoicingSteps";
import InvoicingStep_1 from "../adminComponents/InvoicingStep_1";
import InvoicingStep_2 from "../adminComponents/InvoicingStep_2";
import InvoicingStep_3 from "../adminComponents/InvoicingStep_3";

//MUI IMPORTS--------------------------------------
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const InvoicingBaseDialog = (props) => {
  const userInfo = useSelector((state) => state.user);
  const currentOrderSelected = useSelector(
    (state) => state.orders.selectedOrder
  );

  const {
    showCreateNewInvoice,
    showBookInvoiceModalHandler,
    socket,
    paymentTypeList,
    isNewInvoice,
    newInvoice,
    isLoading_BookInvoiceHandler,
    isLoading_BookInvoice,
    usersTitleList,
    invoiceNumber,
  } = props;

  const steps_bookInvoice = [
    {
      label: "Invoice Details",
      info: (
        <InvoicingStep_1
          usersTitleList={usersTitleList}
          paymentTypeList={paymentTypeList}
        />
      ),
      icon: (
        <DriveFileRenameOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />
      ),
      iconCompleted: (
        <DriveFileRenameOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <InvoicingStep_2 invoiceNumber={invoiceNumber} />,
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <InvoicingStep_3
          socket={socket}
          userInfo={userInfo}
          showBookInvoiceModalHandler={showBookInvoiceModalHandler}
          isNewInvoice={isNewInvoice}
          newInvoice={newInvoice}
          isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
          isLoading_BookInvoice={isLoading_BookInvoice}
          invoiceNumber={invoiceNumber}
        />
      ),
      icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },
  ];

  return (
    <Fragment>
      <Dialog
        open={showCreateNewInvoice}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showBookInvoiceModalHandler(event, 1, "...")}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>Book a New Invoice ..</p>
            <p>
              {currentOrderSelected.length > 0 &&
                currentOrderSelected[0].CLUB_CODE +
                  " " +
                  currentOrderSelected[0].CONFIRMATION_REFERENCE + " - " +
                  invoiceNumber
                  //"-1"
                  }
            </p>
          </Box>
        </DialogTitle>
        <DialogContent>
          {currentOrderSelected.length > 0 && (
            <DialogContentText sx={{ margin: 2 }}>
              On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
              Client: {currentOrderSelected[0].COMPANY_NAME}
            </DialogContentText>
          )}

          <InvoicingSteps
            steps={steps_bookInvoice}
            showAddPaymentModalHandler={showBookInvoiceModalHandler}
            //invoiceNumber={invoiceNumber}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default InvoicingBaseDialog;
