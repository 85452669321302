import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";
import { paymentPlanActions } from "../../store/paymentPlan-slice";
import { invoicingActions } from "../../store/invoicing-slice";



// MUI IMPORTS---------------------------------------------------

import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Typography from "@mui/material/Typography";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

const InvoicingStep_1 = (props) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);
  const currentInvoiceInfo = useSelector((state) => state.invoicing);



  const [invoiceBookDate, setInvoiceBookDate] = useState(new Date());

  //const { paymentTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = "de";

  //--------------------------------------------------------datemanager---------
  const handleDatesChangesFromTo = (date) => {
    const dateIsValid = date !== null && date !== "Invalid Date";

    //console.log("choosen date from New Invoice component....", date);

    setInvoiceBookDate(date);

    if (dateIsValid) {
      //Add the Selected Order to the Payments In information
      dispatch(
        invoicingActions.updateInvoiceInfo({
          invoiceBookDate: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        })
      );
    } else {
      console.log("error, date not usable only 2 options available");
      return;
    }
  };
  //-------------------------------------------------------END datemanager------

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >

          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2 }}
          >
            {currentInvoiceInfo.invoicingInfo.selectedOrder.length > 0 &&
            "Order Taken on ...."  + new Date(currentInvoiceInfo.invoicingInfo.selectedOrder[0].DATE).toLocaleString("default", {
                day: "numeric",
                month: "short",
                year:"numeric",
            })
            }
          </Typography>


            <DatePicker
              mask={maskMap[locale]}
              format="YYY/MM/dd"
              label="Inv.Date"
              value={invoiceBookDate}
              onChange={(date) => handleDatesChangesFromTo(date, 0)}
              renderInput={(params) => (
                <MyTextField
                  {...params}
                  //sx={{ margin: matches ? "0.2em" : "1em" }}
                  sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 400, margin: "1em" }
                  }
                />
              )}
            />
          </Box>

          <Divider />
        </Box>
      </Fragment>
    </LocalizationProvider>
  );
};

export default InvoicingStep_1;
