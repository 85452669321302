import React, { Fragment, useState, useEffect, memo } from "react";
import axios from "axios";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import Deposits from "../Deposits";
import SalaryChart from "../graphComponents/SalaryChart";

import { SocketContext } from "../../utils/socket-client";

//loader Imports--------------
import { SyncLoader } from "react-spinners";
import { PropagateLoader } from "react-spinners";

//MUI IMPORTS-------------------------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// function preventDefault(event) {
//   event.preventDefault();
// }

const PaymentsOut = (props) => {

  const mySocket = React.useContext(SocketContext)
  const [socket, setSocket] = useState(null)

  const [paymentsByDateList, setPaymentsByDateList] = useState([]);
  const [totalPaymentsSuppByDate, setTotalPaymentsSuppByDate] = useState([]);

  const [totalPaymentsDIRByDate, setTotalPaymentsDIRByDate] = useState(null);
  const [DIRPaymentsByDateList, setDIRPaymentsByDateList] = useState([]);

  const [totalPaymentsFIXByDate, setTotalPaymentsFIXByDate] = useState(null);
  const [FIXPaymentsByDateList, setFIXPaymentsByDateList] = useState([]);

  const [totalPaymentsFIXByDate_Ops, setTotalPaymentsFIXByDate_Ops] = useState(null);
  const [totalPaymentsFIXByDate_NonOps, setTotalPaymentsFIXByDate_NonOps] = useState(null);


  const [changeDates, setChangeDates] = useState(null);
  const [showDetails, setShowDetails] = useState(null)

  const [IsLoading1, setIsLoading1] = useState(false);
  const [IsLoading2, setIsLoading2] = useState(false);
  const [IsLoading3, setIsLoading3] = useState(false);

  const [autoRefresh, setAutoRefresh] = useState(null)

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const {refresh} = props

  // let netSales = 0;
  // let BarChartData = [];

  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  //const selectedDatesFromInitial = new Date(prevMonday).toISOString();

  //console.log("props for payments out....................",props)

  const selectedDatesFromInitial = props.tabInitialDate;
  const selectedDatesTo = props.tabFinalDate;
  const myTitle = props.myTitle;
  //const updateSalary = props.updateSalary;

  //Get All (Standard) Payments by Date (Suppliers)------------------------------------
  const getPaymentsOutByDate = (selectedDatesFromInitial, selectedDatesTo) => {
    // console.log(
    //   "getPaymentsOutByDate............... in the Payments OUT component called !!!!"
    // );
    setIsLoading1(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "purchase/paymentsoutbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",
        //   res.data.paymentsOutByDate
        // );
        setPaymentsByDateList(res.data.paymentsOutByDate);

        if (res.data.paymentsOutByDate.length === 0) {
          setTotalPaymentsSuppByDate(0);
        } else {
          const totalStandardPayments = res.data.paymentsOutByDate.slice(-1);
          setTotalPaymentsSuppByDate(totalStandardPayments[0].cumulative_sum);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (DIR) Salary Payments by Date------------------------------------
  const getDIRSalariesPayByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {
    //console.log("getDIRSalariesPayByDate............... in the Payments OUT component called !!!!")
    setIsLoading2(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "salaries/dirsalariespaidbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
          "/" +
          //true
          "1" 
          ,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",
        //   res.data.DIRSalariesPayByDate
        // );
        setDIRPaymentsByDateList(res.data.salariesPayByDate);
        if (res.data.salariesPayByDate.length === 0) {
          setTotalPaymentsDIRByDate(0);
        } else {
          const totalDIRPayments = res.data.salariesPayByDate.slice(-1);
          setTotalPaymentsDIRByDate(totalDIRPayments[0].cumulative_sum);
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (FIX) Salary Payments by Date------------------------------------
  const getFIXSalariesPayByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {
    //console.log("getFIXSalariesPayByDate............... in the Payments OUT component called !!!!")
    setIsLoading3(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "salaries/fixsalariespaidbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo+
          "/" +
          "1"
          //true 
          ,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",
        //   res.data.FIXSalariesPayByDate
        // );
        //setFIXPaymentsByDateList(res.data.FIXSalariesPayByDate);
        setFIXPaymentsByDateList(res.data.salariesPayByDate);


        //if (res.data.FIXSalariesPayByDate.length === 0) {
        if (res.data.salariesPayByDate.length === 0) {

          setTotalPaymentsFIXByDate(0);
        } else {
          //const totalFIXPayments = res.data.FIXSalariesPayByDate.slice(-1);
          const totalFIXPayments = res.data.salariesPayByDate.slice(-1);
          //console.log("here is the FIX Total....", totalFIXPayments[0].cumulative_sum)
          setTotalPaymentsFIXByDate(totalFIXPayments[0].cumulative_sum);
        }
        setIsLoading3(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const changeDatesHandler = () => {
  //   setChangeDates(!changeDates);
  // };

  const showDetailsHandler = ()=>{
    setShowDetails((prev)=> !showDetails)
  }

  useEffect(() => {
    //setChangeDates(false);
    setShowDetails(false)
  }, []);

  useEffect(() => {
    //console.log("first time switch...", changeDates);

    if (selectedDatesFromInitial) {
      //console.log("inside the use Effect....", selectedDatesFromInitial);

      // if (!changeDates) {

      if (totalPaymentsSuppByDate.length === 0) {
      }

      getPaymentsOutByDate(selectedDatesFromInitial, selectedDatesTo);

      getDIRSalariesPayByDate(selectedDatesFromInitial, selectedDatesTo);
      getFIXSalariesPayByDate(selectedDatesFromInitial, selectedDatesTo);

      //getPaymentsUnallocatedByDate(selectedDatesFromInitial);
      //console.log("is true...!!!");
      // } else {
      //   //console.log("is false...!!!");
      //   const myDateFrom= new Date().getFullYear() +"-01-01";
      //   getPaymentsOutByDate(myDateFrom, selectedDatesTo);
      //   getDIRSalariesPayByDate(myDateFrom, selectedDatesTo);
      //   getFIXSalariesPayByDate(myDateFrom, selectedDatesTo);
      //   //getPaymentsOutByDate("2022-01-01");
      //   //getPaymentsUnallocatedByDate("2022-01-01");
      // }
    }

    //return () => {};
    //  }, [changeDates]);
  }, [refresh,autoRefresh]);




  //Separating FIX Cost into Operational and NON operational for Totals--------------------------------------
  useEffect(()=>{

    if(FIXPaymentsByDateList.length > 0){

      const FIXPaymentsByDateListFiltered_Ops = FIXPaymentsByDateList.filter((item)=>item.TYPECOST_ID === 1)
      const FIXPaymentsByDateListFiltered_Ops_total = FIXPaymentsByDateListFiltered_Ops.reduce(
        (prevValue, currentValue) => prevValue + currentValue.TOTALPAID_FIX,0
      );

      setTotalPaymentsFIXByDate_Ops(FIXPaymentsByDateListFiltered_Ops_total)

      

      const FIXPaymentsByDateListFiltered_NonOps = FIXPaymentsByDateList.filter((item)=>item.TYPECOST_ID === 2)
      const FIXPaymentsByDateListFiltered_NonOps_total = FIXPaymentsByDateListFiltered_NonOps.reduce(
        (prevValue, currentValue) => prevValue + currentValue.TOTALPAID_FIX,0
      );

      setTotalPaymentsFIXByDate_NonOps(FIXPaymentsByDateListFiltered_NonOps_total)

      // console.log("AFTER REDUCE METHOD just before......",FIXPaymentsByDateListFiltered_Ops)
      // console.log("AFTER REDUCE METHOD...",FIXPaymentsByDateListFiltered_Ops_total)
      // console.log("AFTER REDUCE METHOD just before NON......",FIXPaymentsByDateListFiltered_NonOps)
      // console.log("AFTER REDUCE METHOD NON...",FIXPaymentsByDateListFiltered_NonOps_total)

    }


  },[FIXPaymentsByDateList, refresh,autoRefresh])
  //End Separating FIX Cost into Operational and NON operationa for Totals-----------------------------------




    //Socket logic------------------------------------------------------------------------
    useEffect(() => {
      //setSocket(io('http://localhost:5002'));
      setSocket(mySocket);
      //console.log("connecting...");
    }, [mySocket]);
  
    //listen notifications
    //Listening to Notifications
    useEffect(() => {
      socket?.on("getNotification", (data) => {
        if(data.type.notificationType===4 ||data.type.notificationType===5 || data.type.notificationType===6){
  
          console.log("this is a payment Out",data)
          setAutoRefresh(Math.random())
        }
        // else if(data.type.notificationType===4){
  
        //   console.log("this is a payment to supplier update...",data)
  
        // }
    
      });
    }, [socket]);
    //END Socket Logic--------------------------------------------------------------------







  return (
      <Fragment>
        {!IsLoading1 && !IsLoading2 && !IsLoading3 ? (
          <Deposits
            title={myTitle || "Payments Out"}
            totalPaymentsByDate={
              myTitle === "Salary Paid"
                ? totalPaymentsDIRByDate + totalPaymentsFIXByDate
                : 
                myTitle === "Payments Suppliers"?
                totalPaymentsSuppByDate
                :
                totalPaymentsSuppByDate +
                totalPaymentsDIRByDate +
                totalPaymentsFIXByDate
            }
            selectedDatesFromInitial = {selectedDatesFromInitial}
            selectedDatesTo  = {selectedDatesTo }
            paymentsByDateList = {paymentsByDateList}
            DIRPaymentsByDateList = {DIRPaymentsByDateList}
            FIXPaymentsByDateList = {FIXPaymentsByDateList}
            totalPaymentsSuppByDate={totalPaymentsSuppByDate}
            totalPaymentsDIRByDate={totalPaymentsDIRByDate}
            totalPaymentsFIXByDate={totalPaymentsFIXByDate}
            totalPaymentsFIXByDate_Ops={totalPaymentsFIXByDate_Ops}
            totalPaymentsFIXByDate_NonOps={totalPaymentsFIXByDate_NonOps}

            totalPaymentsUnallocatedByDate={0}
            //changeDates={changeDates}
            showDetails={showDetails}
            //changeDatesHandler={changeDatesHandler}
            showDetailsHandler={showDetailsHandler}
            icon={
              <CreditCardOffIcon
                sx={{
                  fontSize: 20,
                  color: "#4E1721",
                  marginRight: 2,
                  marginTop: -1,
                }}
              />
            }
          />
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )}
      </Fragment>
    
  );
};

export default React.memo(PaymentsOut);
