import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../store/users-slice";
import UploadButtons from "../UI/UploadButton";
import UploadImageKit from "../UI/UploadImageKit";


//-------------------IMPORTS MUI---------------------------------------
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

const UserStep_2 = (props) => {
  const dispatch = useDispatch();
  const currentUserInfo = useSelector((state) => state.userDetails.userInfo);
  //const quoteinfo = useSelector((state) => state.quote);
  const AutoCompleteUsersTeamList = useRef();
  const AutoCompleteUsersCommissionType = useRef();

  const userPic = useRef();
  const userPic2 = useRef();

  const userPic1 = currentUserInfo.firstName


  const { usersTeamList, usersCommissionList } = props;

  const [usersTeamListFiltered, setUsersTeamListFiltered] = useState([]);
  const [usersCommissionListFiltered, setUsersCommissionListFiltered] =
    useState([]);

  const [enteredInput, setEnteredInput] = useState(null);
  const [enteredInputName, setEnteredInputName] = useState(null);

  const [userImage, setUserImage] = useState(null);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //for the User Team list
  useEffect(() => {
    if (usersTeamList?.length > 0) {
      const myUsersTeamListFiltered = usersTeamList.map((option) => {
        const firstLetter = option.TEAM[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setUsersTeamListFiltered(myUsersTeamListFiltered);
    }
  }, [usersTeamList]);

  //for the User Commission Type list
  useEffect(() => {
    if (usersCommissionList?.length > 0) {
      const myUsersCommissionListFiltered = usersCommissionList.map(
        (option) => {
          const firstLetter = option.COMMISSIONTYPE[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        }
      );

      setUsersCommissionListFiltered(myUsersCommissionListFiltered);
    }
  }, [usersCommissionList]);

  const handleUserChange = (event, value, name) => {
    //console.log("value from the function handleProjectsChange...", value);

    //console.log("name from the function handleProjectsChange...", name);

    if (value) {
      dispatch(
        updateUser({
          [name]: value,
        })
      );
    }
  };


  //test to be deleted
  const handleInputChange = (value, name) => {
    //console.log("value from the function handlpriceChange...", value);
    //console.log("name from the function handlpriceChange...", name);

    setEnteredInput(value);
    setEnteredInputName(name);
  };

  //Handle Image change before upload------------------------------------------
  const handleImageChange = (file) => {
    //console.log("value from the function handlpriceChange...", value);
    // console.log(
    //   "Image File from Uploading yessssssssssssssssssssssssssssss...",
    //   file
    // );

    if (file && file.type.substr(0, 5) === "image") {
      setUserImage(file);
    } else {
      setUserImage(null);
    }

    //setEnteredInputName(name);
  };






  useEffect(() => {
    const identifier = setTimeout(() => {
      // console.log("Checking input...");

      // console.log("price info and key", enteredInput, enteredInputName);

      if (enteredInput) {
        dispatch(
          updateUser({
            [enteredInputName]: enteredInput,
          })
        );
      } else {
        dispatch(
          updateUser({
            [enteredInputName]: null,
          })
        );
      }
    }, 500);

    return () => {
      // console.log("CleanUp");
      clearTimeout(identifier);
    };
  }, [enteredInput, enteredInputName]);

  //end test to be deleted

  // const handleProductChange = (event, value) => {
  //   console.log("value from the function handleProductsChange...", value);
  // };

  return (
    <>
      <Box
        sx={
          // matches
          // ? { width: "100%", margin: "0.2em" }
          // : { width: 350, margin: "1em" },
          {p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",}
          
        }
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "row" : "column",
            alignItems: "flex-start",
            justifyContent: "center",
            margin: "auto",
            marginLeft:'1rem',
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* Upload UserImage */}
          {/* <UploadButtons
            picRef={userPic}
            userImage={userImage}
            handleImageChange={handleImageChange}
          /> */}

          {/* Upload UserImage to ImageKit.io */}
          <UploadImageKit 
            picRef1={userPic1+".png"}
            picRef2={userPic2}
            userImage={userImage}
            handleImageChange={handleImageChange}
            handleUserChange={handleUserChange}

            />




        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* User Basic per Week */}
          <MyTextField
            name="wBasic"
            id="outlined-number"
            label="£ Basic (Weekly)"
            type="number"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />

          {/* User phone Bill per Week */}
          <MyTextField
            name="userPhoneBill"
            id="outlined-number"
            label="£ Phone Bill (Weekly)"
            type="number"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* User Team  */}
          <Autocomplete
            ref={AutoCompleteUsersTeamList}
            name="userTeam"
            margin="auto"
            id="grouped-demo"
            options={usersTeamListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.TEAM}
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            onChange={(event, value, name) =>
              handleUserChange(
                event,
                value,
                AutoCompleteUsersTeamList.current.getAttribute("name")
              )
            }
            renderInput={(params) => (
              <MyTextField {...params} label="User Team" />
            )}
          />

          {/* <Divider /> */}

          {/* User Commission  */}
          <Autocomplete
            ref={AutoCompleteUsersCommissionType}
            name="userCommissionType"
            margin="auto"
            id="grouped-demo"
            options={usersCommissionListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.COMMISSIONTYPE}
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            onChange={(event, value, name) =>
              handleUserChange(
                event,
                value,
                AutoCompleteUsersCommissionType.current.getAttribute("name")
              )
            }
            renderInput={(params) => (
              <MyTextField {...params} label="User Commission" />
            )}
          />
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            // backgroundColor: "#ccc",
            width: "100%",
          }}
        >
          {/* User U.T.R */}
          <MyTextField
            name="userUTR"
            id="outlined-search"
            label="User U.T.R"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />

          {/* User N.Insurance */}
          <MyTextField
            name="userNI"
            id="outlined-search"
            label="User N.I"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>console.log(event, event.target.value, event.target.name)}
            //   onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          />
        </Box>

        {/* <input
        style={{display:'none'}}
        type='file'
        accept='image/*'
        name='media'
        ref={userPic}
        
         />

         <div>
           
         </div> */}
      </Box>
    </>
  );
};

export default UserStep_2;
