import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { variables } from "../../Variables";

import { useDispatch } from "react-redux";
import { paymentPlanActions } from "../../store/paymentPlan-slice";
import { invoicingActions } from "../../store/invoicing-slice";


const GeneralInfoOrder = (props) => {
  const dispatch = useDispatch();

  const [payPlanInfoOnOrder, setPayPlanInfoOnOrder] = useState([]);
  const [invoicesOnOrder_1, setInvoicesOnOrder_1] = useState([]);
  const [invoicesOnOrder_2, setInvoicesOnOrder_2] = useState([]);


  const { 
    selectedOrder, 
    newPaymentPlan, 
    newInvoice,
    isLoading_BookInvoiceHandler,

   } = props;

  let confoId = "";
  if (selectedOrder.length > 0) {
    confoId = selectedOrder[0].CONFIRMATION_ID;
  }

  let preConfoId = "";
  if (selectedOrder.length > 0) {
    preConfoId = selectedOrder[0].PRECONFIRMATION_ID;
  }

  const getPayPlanInfoOnOrder = (confoId) => {
    //setIsLoading1(true);
    //isLoading1_allPayOnOrderHandler(true); //this is the same info of isloading1 passed to the parent component to share with Siblings....

    axios
      .get(variables.API_URL + "payments/paymentplanonorder/" + confoId, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(
        //   "payment plan info on order here....",
        //   res.data.paymentPlanInfoOnOrder
        // );
        setPayPlanInfoOnOrder(res.data.paymentPlanInfoOnOrder);
        //setIsLoading1(false);
        //isLoading1_allPayOnOrderHandler(false); //this is the same info of isloading1 passed to the parent component to share with Siblings....
      })
      .catch((error) => {
        console.log(error);
      });
  };



  //Get all Invoices Book against selected order-----------------------------------------------------------------------------------------
  const getInvoicesOnOrder = (preConfoId,invCode) => {
    //setIsLoading1(true);
    //isLoading1_allPayOnOrderHandler(true); //this is the same info of isloading1 passed to the parent component to share with Siblings....
    isLoading_BookInvoiceHandler(true);

    axios
      .get(variables.API_URL + "orders/invoices/" + preConfoId + "/" + invCode, {
        withCredentials: true,
      })
      .then((res) => {
        // console.log(
        //   "payment plan info on order here....",
        //   res.data.paymentPlanInfoOnOrder
        // );

          //checking if is Invoice number 1 or number 2-------
          if(invCode === 1){ 
            setInvoicesOnOrder_1(res.data.invoicesOnOrder);
            //console.log("the invoice info from the axios...",res.data.invoicesOnOrder)
          }else{
            setInvoicesOnOrder_2(res.data.invoicesOnOrder);
          }
          
        

        isLoading_BookInvoiceHandler(false);
        //setIsLoading1(false);
        //isLoading1_allPayOnOrderHandler(false); //this is the same info of isloading1 passed to the parent component to share with Siblings....
      })
      .catch((error) => {
        console.log(error);
      });
  };




  // useEffect(() => {
  //   if (confoId) {
  //     getPayPlanInfoOnOrder(confoId,1); //to find invoice number 1
  //     getPayPlanInfoOnOrder(confoId,2); //to find invoice number 2
  //   }
  // }, [confoId, newPaymentPlan]);

  useEffect(() => {
    if (confoId) {
      getPayPlanInfoOnOrder(confoId);
    }
  }, [confoId, newPaymentPlan]);


  //To get all information on Invoices booked on selected order---------------------------------------
  useEffect(() => {
    if (preConfoId) {
      getInvoicesOnOrder(preConfoId, 1);
      getInvoicesOnOrder(preConfoId, 2);

    }
  }, [preConfoId, newInvoice]);

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)
    // console.log(
    //   "this is the length of pay plan info array....",
    //   payPlanInfoOnOrder.length
    // );
    // console.log(
    //   "this is the array of pay plan info array....",
    //   payPlanInfoOnOrder
    // );

    if (payPlanInfoOnOrder.length > 0) {
      dispatch(
        paymentPlanActions.updatePayPlanInfo({
          //selectedOrderTotalPaid: totalPaidOnOrder,
          selectedOrder: selectedOrder,
          paymentPlanSetDate: payPlanInfoOnOrder[0].PAYPLAN_DATE_SET,
          PaymentPlanRepaymentNumber: payPlanInfoOnOrder[0].N_PAYMENTS,
          PaymentPlanId: payPlanInfoOnOrder[0].CCPAYPLAN_ID,
        })
      );

      if (payPlanInfoOnOrder[0].CCPAYPLAN_SIGNED_ID !== null) {
        dispatch(
          paymentPlanActions.updatePayPlanSigned({
            //selectedOrderTotalPaid: totalPaidOnOrder,
            paymentPlanSignedDate: payPlanInfoOnOrder[0].PAYPLAN_DATE_SIGNED,
            paymentPlanSignedId: payPlanInfoOnOrder[0].CCPAYPLAN_SIGNED_ID,
          })
        );
      } else {
        //set to original values to reset the prvious order payment plan info--------------------------------
        dispatch(
          paymentPlanActions.updatePayPlanSigned({
            //selectedOrderTotalPaid: totalPaidOnOrder,
            paymentPlanSignedDate: new Date().toISOString(),
            paymentPlanSignedId: null,
          })
        );
      }
    } else {
      if (selectedOrder.length > 0) {
        //Add the Selected Order to the Payments In information
        dispatch(
          paymentPlanActions.updatePayPlanInfo({
            selectedOrder: selectedOrder,
          })
        );
      } else {
        //Reset all PaymentPlan info to the initial state to reset the previous order info-------------------------
        dispatch(
          paymentPlanActions.resetPaymentPlan({
            paymentPlanSignedDate: new Date().toISOString(),
          })
        );
      }
    }
  }, [payPlanInfoOnOrder]);



  //updating the information when booked a new invoice----------------------------------------------------------------
  useEffect(() => {
 
    // if there is invoice 1 and invoice 2 raised-----------------------
    if (invoicesOnOrder_1.length > 0 && invoicesOnOrder_2.length > 0) {
      dispatch(
        invoicingActions.updateInvoice({
          //selectedOrderTotalPaid: totalPaidOnOrder,
          //invoicesOnOrder_1:invoicesOnOrder_1[0], 
          //invoicesOnOrder_1: invoicesOnOrder_1, 
          invoice1: invoicesOnOrder_1,
          invoice2: invoicesOnOrder_2


        })
        );
        //Add the Selected Order to the InvoiceInfo 
        dispatch(
          invoicingActions.updateInvoiceInfo({
            selectedOrder: selectedOrder,
          })
        );  
        
    
    } else if (invoicesOnOrder_1.length > 0) { //if there is only Invoice 1 raised----------------
      dispatch(
        invoicingActions.updateInvoice({
          //selectedOrderTotalPaid: totalPaidOnOrder,
          //invoicesOnOrder_1:invoicesOnOrder_1[0], 
          //invoicesOnOrder_1: invoicesOnOrder_1, 
          invoice1: invoicesOnOrder_1,
          invoice2: []


        })
        );
        //Add the Selected Order to the InvoiceInfo 
        dispatch(
          invoicingActions.updateInvoiceInfo({
            selectedOrder: selectedOrder,
          })
        );  
        
    
    }else if(invoicesOnOrder_2.length > 0){  //if there is only Invoice 2 raised-----------------------

      dispatch(
        invoicingActions.updateInvoice({
          //selectedOrderTotalPaid: totalPaidOnOrder,
          //invoicesOnOrder_1:invoicesOnOrder_1[0], 
          //invoicesOnOrder_1: invoicesOnOrder_1, 
          invoice2: invoicesOnOrder_2,
          invoice1: []

        })
        );
        //Add the Selected Order to the InvoiceInfo 
        dispatch(
          invoicingActions.updateInvoiceInfo({
            selectedOrder: selectedOrder,
          })
        );

    } else { // there is no invoice raised at all on that order--------------------
      if (selectedOrder.length > 0) {
        //Add the Selected Order to the InvoiceInfo information
        dispatch(
          invoicingActions.updateInvoiceInfo({
            selectedOrder: selectedOrder,
          })
        );

        dispatch(
          invoicingActions.updateInvoice({
            //selectedOrderTotalPaid: totalPaidOnOrder,
            //invoicesOnOrder_1:invoicesOnOrder_1[0], 
            //invoicesOnOrder_1: invoicesOnOrder_1, 
            invoice2: [],
            invoice1: []
  
          })
          );

      } else {
        //Reset all Invoicing info to the initial state -------------------------
        dispatch(
          invoicingActions.resetInvoice({
            invoiceBookDate: new Date().toISOString(),
          })
        );
      }
    }
  }, [invoicesOnOrder_1, invoicesOnOrder_2]);


  //this component doesn't return any jxs but does fetch payment plan info and update slices-----
  return <Fragment></Fragment>;
};

export default GeneralInfoOrder;
