import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";
import { paymentPlanActions } from "../../store/paymentPlan-slice";



// MUI IMPORTS---------------------------------------------------

import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

const AddPplanSignedStep_1 = (props) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);
  


//   const AutoCompletePaymentType = useRef();

//   const [showAddPayment, setShowAddPayment] = useState(false);

  //const [paymentTypeList, setPaymentTypeList] = useState([]);
  //const [paymentTypeListFiltered, setPaymentTypeListFiltered] = useState([]);

//   const [enteredInput, setEnteredInput] = useState(null);
//   const [enteredInputName, setEnteredInputName] = useState(null);

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());

  //const { paymentTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = "de";

 

  //Add the Selected Order to the Payments In information
//   dispatch(
//     paymentPlanActions.updatePayPlanInfo({
//       selectedOrder: selectedOrder,
//     })
//   );

  //--------------------------------------------------------datemanager---------
  const handleDatesChangesFromTo = (date) => {
    const dateIsValid = date !== null && date !== "Invalid Date";

    //console.log("choosen date from Add Signed P.plan component....", date);

    setSelectedPaymentDate(date);

    if (dateIsValid) {
      //Add the Selected Order to the Payments In information
      dispatch(
        paymentPlanActions.updatePayPlanSigned({
          paymentPlanSignedDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        })
      );

    } else {
      console.log("error, date not usable only 2 options available");
      return;
    }
  };
  //-------------------------------------------------------END datemanager------

    // const handleInputChange = (value,name) => {

    //   setEnteredInput(value);
    //   setEnteredInputName(name);

    // };

 

  //for payment Type list
//   useEffect(() => {
//     if (paymentTypeList?.length > 0) {
//       const myPaymentTypeListFiltered = paymentTypeList.map((option) => {
//         const firstLetter = option.CLIENTPAYMENTTYPE[0].toUpperCase();
//         return {
//           firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
//           ...option,
//         };
//       });

//       setPaymentTypeListFiltered(myPaymentTypeListFiltered);
//     }
//   }, [paymentTypeList]);

//   const handlePaymentInChange = (event, value, name) => {
//     console.log(
//       "value from the function handlePayment Type Change on Payments In recorder...",
//       value
//     );

//     console.log(
//       "name from the function handlePaymentTypeChange on Payments IN recorder...",
//       name
//     );

//     if (value) {
//       dispatch(
//         paymentPlanActions.updatePayPlanInfo({
//           [name]: value,
//         })
//       );
//     }
//   };



  //Debouncer..............

//   useEffect(() => {
//     const identifier = setTimeout(() => {
//       //console.log("Checking input...");
      
  
//       console.log("Reference and payment Amount", enteredInput, enteredInputName)
  
//       if (enteredInput) {
//         dispatch(
//           paymentInActions.updatePaymentIn({
//             [enteredInputName]: enteredInput,
//           })
//         );
//       }else{
  
//         dispatch(
//           paymentInActions.updatePaymentIn({
//             [enteredInputName]: null,
//           })
//         );
  
//       }
  
  
//     }, 500);
  
//     return () => {
//       // console.log("CleanUp");
//       clearTimeout(identifier);
//     };
//   }, [enteredInput, enteredInputName]);

  //Debouncer End..........






  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >



        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* Payment In Amount */}
          {/* <MyTextField
            name="amountPaid"
            id="outlined-number"
            label="£ Payment"
            type="number"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 150, margin: "1em" }
            }
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
             
            }
          /> */}

          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="Signed on"
            value={selectedPaymentDate}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params} 
                //sx={{ margin: matches ? "0.2em" : "1em" }}
                sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 400, margin: "1em" }
                  }
              />
            )}
          />
        </Box>

        {/* <Divider /> */}

        {/* Payment In Type */}
        {/* <Autocomplete
          ref={AutoCompletePaymentType}
          name="paymentType"
          margin="auto"
          id="grouped-demo"
          options={paymentTypeListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          defaultValue={{ CLIENTPAYMENTTYPE: "BACS TRANSFER",
          CLIENTPAYMENTTYPE_ID: 1,
          CLIENTPAYMENTTYPE_REF: "Bacs",
          firstLetter: "B", }}
          getOptionLabel={(option) => option.CLIENTPAYMENTTYPE}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event, value, name) =>
            handlePaymentInChange(
              event,
              value,
              AutoCompletePaymentType.current.getAttribute("name")
            )
          }
          renderInput={(params) => (
            <MyTextField {...params} label="Payment Type" defaultValue="BACS TRANSFER" />
          )}
        /> */}

<Divider />

        {/* Payment In Reference */}
        {/* <MyTextField
          name="paymentReference"
          id="outlined-search"
          label="Reference"
          type="search"
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event) =>handleInputChange(event.target.value, event.target.name)}
          
        /> */}

</Box>

       
      </Fragment>
    </LocalizationProvider>
  );
};

export default AddPplanSignedStep_1;
