import { useState, useEffect } from "react";

//----------REDUX IMPORTS --------------------------------
import { useSelector } from "react-redux";


// import axios from "axios";
// import { variables } from "../Variables";
import theme from "./UI/Theme";
import MyTabs from "./UI/MyTabs";
import PaymentsIn from "../components/paymentsComponent/PaymentsIn";
import Outstanding from "./creditControlComponents/Outstanding";
import PaymentsOut from "./purchaseComponents/PaymentsOut";
import PurchaseChart from "./purchaseComponents/PurchaseChart";
import SalaryReport from "./purchaseComponents/SalaryReport";


import PurchaseByCostSummaryGraph from "./graphComponents/PurchaseByCostSummaryGraph";


//loader Imports--------------
// import { SyncLoader } from "react-spinners";
// import { PropagateLoader } from "react-spinners";

//MUI IMPORTS--------------------------------------------

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";



export default function Purchase(props) {

  const userInfo = useSelector((state) => state.user);
 
  const [showSalesGraph, setShowSalesGraph] = useState(false);


  const matches = useMediaQuery(theme.breakpoints.down("sm"));


  //Set the dates from another component --- with DataSelector component---------------------------------
  const dateSelectorHandler = (dateFrom, dateTo)=>{
    //setTabInitialDate(new Date(dateFrom.).toISOString());
    let dateFrom2 = new Date(new Date(dateFrom.setDate(dateFrom.getUTCDate())).setHours(0, 0, 0)).toISOString();
    let dateTo2 = new Date(new Date(dateTo.setDate(dateTo.getUTCDate())).setHours(23, 59, 59)).toISOString();
    
    setTabInitialDate(dateFrom2);
    //setTabFinalDate(new Date(dateTo).toISOString());
    //setTabFinalDate(new Date(dateTo).toISOString());
    setTabFinalDate(dateTo2);

  }






  //calculate the previous Monday date------------------------------------------------------------
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getUTCDate() - ((prevMonday.getDay() + 6) % 7))
  );
  // prevMonday = new Date(
  //   prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  // );
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  let selectedDatesFromInitial = new Date(prevMonday).toISOString();
  const selectedDatesFromInitial_raw = new Date(prevMonday);

  let selectedDatesTo = new Date(Date.now()).toISOString();

  // const changeDatesHandler = () => {
  //   setChangeDates(!changeDates);
  // };
  // const showSalesGraphHandler = () => {
  //   setShowSalesGraph(!showSalesGraph);
  // };

  const [tabInitialDate, setTabInitialDate] = useState(
    selectedDatesFromInitial
  );
  const [tabFinalDate, setTabFinalDate] = useState(selectedDatesTo);

  const changeTabsHandler = (index) => {
    // console.log(
    //   "my index from the Tab on Main content....................................",
    //   index
    // );
    if (index === 0) {
      selectedDatesFromInitial = new Date(prevMonday).toISOString();
      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 1) {
      //Previous Week-----------------
      //selectedDatesFromInitial = new Date(new Date(prevMonday.setDate(prevMonday.getDate() - 7)).setHours(0, 0, 0)).toISOString();
      selectedDatesFromInitial = new Date(
        new Date(prevMonday.setDate(prevMonday.getUTCDate() - 7)).setHours(0, 0, 0)
      ).toISOString();

      //selectedDatesTo = new Date(new Date(prevMonday.setDate(prevMonday.getDate() + 7)).setHours(0, 0, 0)).toISOString();
      selectedDatesTo = new Date(
        new Date(prevMonday.setDate(prevMonday.getUTCDate() + 7)).setHours(0, 0, 0)
      ).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 2) {
      //selectedDatesFromInitial =new Date(Date.now()).getFullYear() +"-" +(new Date(Date.now()).getMonth() + 1) +"-01";
      selectedDatesFromInitial =
        new Date(Date.now()).getUTCFullYear() +
        "-" +
        (new Date(Date.now()).getUTCMonth() + 1) +
        "-01";

      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 3) {

      let myMonth = ""
      let myFinalMonth=""
      let myYear = ""
      
      myMonth =  new Date(Date.now()).getUTCMonth() + 1
      if(myMonth===1){
        myYear = new Date(Date.now()).getUTCFullYear() - 1 
        myFinalMonth = "12" 
      }else{
        
        myYear = new Date(Date.now()).getUTCFullYear()
        myFinalMonth = myMonth 
      }



      selectedDatesFromInitial =
      myYear + "-" + myFinalMonth + "-01"
        // new Date(Date.now()).getFullYear() +"-" +new Date(Date.now()).getMonth() +"-01";

      const firstDay = new Date(
        new Date(Date.now()).getUTCFullYear() +
          "-" +
          (new Date(Date.now()).getUTCMonth() + 1) +
          "-01"
      );
      //selectedDatesTo = new Date(firstDay.setDate(firstDay.getDate()-1)).toISOString();
      // selectedDatesTo = new Date(
      //   new Date(firstDay.setUTCDate(firstDay.getUTCDate())).setHours(0, 0, 0)
      // ).toISOString();
      selectedDatesTo = new Date(
        firstDay.setUTCDate(firstDay.getUTCDate() - 1)
      ).toISOString();

      //alert('working index ===1....' + selectedDatesTo + "vs..." + firstDay)

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 4) {
      //selectedDatesFromInitial = new Date().getFullYear() + "-01-01";
      selectedDatesFromInitial = new Date().getUTCFullYear() + "-01-01";

      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 5) {
      //selectedDatesFromInitial = new Date().getFullYear() + "-01-01";

      //const previousYearFrom = new Date(new Date().getFullYear() + "-01-01");
      //const previousYearTo = new Date(new Date().getFullYear() + "-12-31");
      const previousYearFrom = new Date(new Date().getUTCFullYear() + "-01-01");
      const previousYearTo = new Date(new Date().getUTCFullYear() + "-12-31");
      

      selectedDatesFromInitial = new Date(
        new Date(
          previousYearFrom.setDate(previousYearFrom.getUTCDate() - 365)
        ).setHours(0, 0, 0)
      ).toISOString();

      selectedDatesTo = new Date(
        new Date(
          //previousYearTo.setDate(previousYearTo.getUTCDate() - 363)
          previousYearTo.setDate(previousYearTo.getUTCDate() - 366)
        ).setHours(0, 0, 0)
      ).toISOString();

      // selectedDatesFromInitial = new Date(
      //   new Date(
      //     previousYearFrom.setDate(previousYearFrom.getDate() - 365)
      //   ).setHours(0, 0, 0)
      // ).toISOString();

      // selectedDatesTo = new Date(
        //   new Date(
          //     previousYearTo.setDate(previousYearTo.getDate() - 363)
          //   ).setHours(0, 0, 0)
          // ).toISOString();
          
          //selectedDatesTo = new Date(Date.now()).toISOString();


          
          setTabInitialDate(selectedDatesFromInitial);
          setTabFinalDate(selectedDatesTo);
    } else if (index === 6) {
      
    }


  };



  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        backgroundImage:
          "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      {matches ? "" : <Toolbar />}

      {/* Start of tab code......--- */}

      {/* </MyTabs> */}

      {/* End of Tab code----------- */}

      <Container maxWidth="lg" sx={{ mt: 4, mb: 3 }}>
        <MyTabs 
        changeTabsHandler={changeTabsHandler}
        dateSelectorHandler={dateSelectorHandler} >
          <Typography
            align="center"
            variant="body2"
            color="text.secondary"
            sx={{ margin: "auto" }}
          >
            Information from: -
            {new Date(tabInitialDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
            - to -
            {new Date(tabFinalDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>

          <Grid container spacing={3}>
            {/* PAYMENTS OUT ALL */}
            {userInfo.permission === 6 &&
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 325,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
                >
                {/* <PaymentsIn /> */}
                {/* <PaymentsIn
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                /> */}

                <PaymentsOut
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                  />
              </Paper>
            </Grid>
             }

            
            {/* PAYMENTS OUT SUPPLIERS */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 325,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                

                <PaymentsOut
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                  myTitle="Payments Suppliers"
                />
              </Paper>
            </Grid>


            {/* Graphs Purchase by Supplier  Graph */}

            {!showSalesGraph ? (
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    //height: 270,
                    height: "auto",
                    paddingBottom: 0,
                    minWidth: 230,
                  }}
                >
                  <PurchaseByCostSummaryGraph
                    tabInitialDate={tabInitialDate}
                    tabFinalDate={tabFinalDate}
                    reportType={{ type: "BySupplier", typeId: 1 }}
                  />
                </Paper>
              </Grid>
            ) : (
              ""
            )}

            {/* Graphs Purchase by General Cost  Graph */}

            {!showSalesGraph ? (
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    //height: 270,
                    height: "auto",
                    paddingBottom: 0,
                    minWidth: 230,
                  }}
                >
                  <PurchaseByCostSummaryGraph
                    tabInitialDate={tabInitialDate}
                    tabFinalDate={tabFinalDate}
                    reportType={{ type: "ByCost", typeId: 2 }}
                  />
                </Paper>
              </Grid>
            ) : (
              ""
            )}

            {/* Graphs Purchase by FIX Cost NON OPERATIONAL  Graph */}

            {!showSalesGraph ? (
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    //height: 270,
                    height: "auto",
                    paddingBottom: 0,
                    minWidth: 230,
                  }}
                >
                  {/* <PurchaseByCostSummaryGraph
                    tabInitialDate={tabInitialDate}
                    tabFinalDate={tabFinalDate}
                    reportType={{type:"ByCost", typeId:2} }
                  /> */}

                  {userInfo.permission === 6 &&
                  <SalaryReport
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                  myTitle="NON OPERATIONAL"
                  />
                }
    
                </Paper>
              </Grid>
            ) : (
              ""
            )}

            {/* Graphs related N.2 */}

            {/* <Grid item xs={12}>
              {!isLoading ? (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}> */}
            {/* <DashboardGraph
                    salesByDateList={salesByDateList}
                    salesByDateListTarget={salesByDateListTarget}
                    salesCancelledByDateList={salesCancelledByDateList}
                    //changeDates={changeDates}

                    //changeDatesHandler={changeDatesHandler}
                    // reportVariables={reportVariables}
                    // salesByConsultantList={salesByConsultantList}
                  /> */}
            {/* </Paper>
              ) : (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15} />
                </Box>
              )}
            </Grid> */}

            {/* Recent Activity */}

            <Grid item xs={12}>
              <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}>

              </Paper>
            </Grid>
          </Grid>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </MyTabs>
      </Container>
    </Box>
  );
}
