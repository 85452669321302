import { configureStore } from "@reduxjs/toolkit";

import commentsSlice from './comments-slice'
import orderSlice from './order-slice'
import quoteSlice from './quote-slice'
import usersSlice from './users-slice'
import userSlice from './user-slice'
import notificationsSlice from './notifications-slice'
import paymentSlice from './payment-slice'
import paymentPlanSlice from './paymentPlan-slice'
import purchasePaymentSlice from'./purchasePayment-slice'
import supplierSlice from './supplier-slice'
import kpisSlice from './kpis-slice'
import invoicingSlice from './invoicing-slice'
import wagesPaymentSlice from './wagesPayment-slice'
import comunicationSlice from './comunication-slice'



const store = configureStore({
    reducer:{ 
        comments: commentsSlice.reducer, 
        orders: orderSlice.reducer, 
        quote:quoteSlice.reducer, 
        user:userSlice.reducer, 
        notifications:notificationsSlice.reducer,
        userDetails:usersSlice.reducer,
        paymentIn:paymentSlice.reducer,
        paymentPlan:paymentPlanSlice.reducer,
        purchasePaymentOut:purchasePaymentSlice.reducer,
        supplier:supplierSlice.reducer,
        kpis:kpisSlice.reducer,
        invoicing:invoicingSlice.reducer,
        wagesPayment:wagesPaymentSlice.reducer,
        comunication:comunicationSlice.reducer,
      }
})

export default store;