import React,{Fragment} from 'react'

import theme from "../../UI/Theme";

import useMediaQuery from "@material-ui/core/useMediaQuery";

//MUI IMPORTS --------------------------------------------
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TodayIcon from "@mui/icons-material/Today";
import UndoIcon from "@mui/icons-material/Undo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DateRangeIcon from '@mui/icons-material/DateRange';



const MainDashboardTabs = (props)=>{


    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

    const {handleChange, a11yProps, value} = props

    return (
      <Fragment>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          orientation={matches3 ? "vertical" : "horizontal"}
          sx={{ width: "95vw", marginTop: 0 }}
        >
          <Tab
            icon={
              <TodayIcon
                sx={{
                  fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="This Week..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(0)}
          />
          <Tab
            icon={
              <UndoIcon
                sx={{
                  fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Previous Week..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(1)}
          />
          <Tab
            icon={
              <CalendarTodayIcon
                sx={{
                  fontSize: matches3 ? "1.8rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="This Month..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(2)}
          />
          <Tab
            icon={
              <UndoIcon
                sx={{
                  fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Previous Month..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(3)}
          />
          <Tab
            icon={
              <EventNoteIcon
                sx={{
                  fontSize: matches3 ? "1.7rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="This year..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(4)}
          />
          <Tab
            icon={
              <UndoIcon
                sx={{
                  fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Previous year..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(5)}
          />

          <Tab
            icon={
              <DateRangeIcon
                sx={{
                  fontSize: matches3 ? "1.7rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Select Dates..."
            sx={{
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(6)}
          />
        </Tabs>
      </Fragment>
    );
    
}

export default MainDashboardTabs;
