import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import DashboardGraph from "../reportComponents/DashboardGraph";
import { variables } from "../../Variables";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI Imports-------------------------------
import Box from "@mui/material/Box";
import { yearPickerClasses } from "@mui/lab";

const SalesHistoricGraph = (props) => {
  const [salesByDateList, setSalesByDateList] = useState([]);
  const [salesByMonthHistoricList, setSalesByMonthHistoricList] = useState([]);
  const [salesCancelledByDateList, setSalesCancelledByDateList] = useState([]);
  const [salesByDateListTarget, setSalesByDateListTarget] = useState([]);
  const [salesByYearListAll, setSalesByYearListAll] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [myBarChartData, setMyBarChartData] = useState(false);

  //const [graphType,setGraphType] = useState({})

  const { tabInitialDate, tabFinalDate, selectedYearFrom, graphType } = props;

  const historicYears = [
    { id: 1, myYear: "2014" },
    { id: 2, myYear: "2015" },
    { id: 3, myYear: "2016" },
    { id: 4, myYear: "2017" },
    { id: 5, myYear: "2018" },
    { id: 5, myYear: "2019" },
    { id: 5, myYear: "2020" },
    { id: 5, myYear: "2021" },
    { id: 6, myYear: "2022" },

  ];

  const myMonths = [
    { id: 1, monthName: "Jan" },
    { id: 2, monthName: "Febr" },
    { id: 3, monthName: "Mar" },
    { id: 4, monthName: "Apr" },
    { id: 5, monthName: "May" },
    { id: 6, monthName: "Jun" },
    { id: 7, monthName: "Jul" },
    { id: 8, monthName: "Aug" },
    { id: 9, monthName: "Sep" },
    { id: 10, monthName: "Oct" },
    { id: 11, monthName: "Nov" },
    { id: 12, monthName: "Dec" },
  ];

  let currentSalesByYearListAll = [];
  //let newTestFilter = [];
  let BarChartData = [];

  //Get Sales Consolidated by Month Historic from 2014 -------------------------------------------
  const getSalesByMonthHistoric = (selectedYearFrom) => {
    // console.log(
    //   "getSalesByMonth Historical consolidated report ............... !!!!"
    // );
    setIsLoading1(true);
    //const selectedYearTo = new Date(Date.now()).toISOString();
    const selectedYearTo = "2022";

    axios
      .get(
        variables.API_URL +
          "sales/consolidatedsalesbymonth/" +
          selectedYearFrom +
          "/" +
          selectedYearTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "This is the Return from Backend on HistoricSalesByMonth....",
        //   res.data.salesByMonthHistoric
        // );
        setSalesByMonthHistoricList(res.data.salesByMonthHistoric);

        if (res.data.salesByMonthHistoric.length === 0) {
          //   setTotalSalesByDate(0);
          //   setTotalPagesByDate(0);
        } else {
          //const totalSales = res.data.salesByMonthHistoric.slice(-1);
          //setTotalSalesByDate(totalSales[0].cumulative_sum);
          //setTotalPagesByDate(totalSales[0].cumulative_sum_pages);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        //setNotification("Something went wrong, please try again");
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };
  //END of Get Sales Consolidated by Month Historic from 2014 -------------------------------------------

  //Get List of Sales by Consultant selected--------------
  const getSalesByDate = (selectedDatesFrom, selectedDatesTo) => {
    // console.log(
    //   "getSalesByDate ............... in the SalesVsTarget component called !!!!"
    // );
    setIsLoading1(true);
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    axios
      .get(
        variables.API_URL +
          "sales/salesbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setSalesByDateList(res.data.salesByDate);

        if (res.data.salesByDate.length === 0) {
          //   setTotalSalesByDate(0);
          //   setTotalPagesByDate(0);
        } else {
          const totalSales = res.data.salesByDate.slice(-1);
          //setTotalSalesByDate(totalSales[0].cumulative_sum);
          //setTotalPagesByDate(totalSales[0].cumulative_sum_pages);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        //setNotification("Something went wrong, please try again");
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };

  //Get List of Cancelled Orders by Consultant Selected--------------
  const getSalesCancelledByDate = (selectedDatesFrom, selectedDatesTo) => {
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    // console.log(
    //   "getSalesCancelledByDate ............... in the SalesVsTarget component called !!!!"
    // );
    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          "sales/salescancelledbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setSalesCancelledByDateList(res.data.salesCancelledByDate);

        if (res.data.salesCancelledByDate.length === 0) {
          //setTotalSalesCancelledByDate(0);
          //setTotalPagesCancelledByDate(0);
        } else {
          const totalSalesCancelledByDate =
            res.data.salesCancelledByDate.slice(-1);
          //   setTotalSalesCancelledByDate(
          //     totalSalesCancelledByDate[0].cumulative_sum
          //   );
          //   setTotalPagesCancelledByDate(
          //     totalSalesCancelledByDate[0].cumulative_sum_pages
          //   );
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        // setNotification(
        //   "Something went wrong when Loading Cancellations, please try again"
        // );
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };

  //Calculate Target Figure for the Dates selected (only Working Days)--------------
  const getTargetInfo = (selectedDatesFrom, selectedDatesTo) => {
    // console.log(
    //   "getTargetInfo ............... in the SalesVsTarget component called !!!!"
    // );

    const startDate = new Date(selectedDatesFrom);
    const endDate = new Date(selectedDatesTo);

    //endDate.setDate(endDate.getDate() + 1);

    const targetByDate = [];
    //const myGraphType = {"graph": "Historic Sales by month 5 Years", "graphId":2}

    //let i = 0;
    const targetDaily = 3000;
    let targetAcc = 0;

    //while (i < 7 && startDate < endDate) {
    while (startDate < endDate) {
      if (!(startDate.getDay() === 6 || startDate.getDay() === 0)) {
        targetAcc = targetAcc + targetDaily;
        //daysOfWeek.push(startDate.getDay());
        targetByDate.push({
          date: new Date(startDate).toISOString(),
          target: targetDaily,
          targetAcc,
        });
      }

      startDate.setDate(startDate.getDate() + 1);
      //i++;
    }

    setSalesByDateListTarget(targetByDate);
    //setGraphType(myGraphType);
  };

  //END of Calculate Target Figure for the Dates selected (only Working Days)--------------

  // PREPARING THE INFORMATION FOR THE GRAPH FOR SALES BY MONTH HISTORIC----------------------
  const getSalesByMonthHistoricData = () => {
    myMonths.map((month) => {
      // currentSalesByYearListAll.push({
      //   month: month.monthName,
      //   monthId: month.id,
      // });

      if (salesByMonthHistoricList.length > 0) {
        const salesByMonthHistoricListFilter = salesByMonthHistoricList.filter(
          (item) => item.MONTH_ID === month.id
        );

        salesByMonthHistoricListFilter.map((subItem) => {
          //console.log("subItems...",subItem.MONTH_ID)

          currentSalesByYearListAll[subItem.MONTH_ID - 1] = {
            //...currentSalesByYearListAll[subItem.MONTH_ID-1],
            monthId: salesByMonthHistoricListFilter[0].MONTH_ID,
            month: month.monthName,
            y1: salesByMonthHistoricListFilter[0].AMOUNT,
            y2: salesByMonthHistoricListFilter[1].AMOUNT,
            y3: salesByMonthHistoricListFilter[2].AMOUNT,
            y4: salesByMonthHistoricListFilter[3].AMOUNT,
            y5: salesByMonthHistoricListFilter[4].AMOUNT,
            y6: salesByMonthHistoricListFilter[5].AMOUNT,
            y7: salesByMonthHistoricListFilter[6].AMOUNT,
            y8: salesByMonthHistoricListFilter[7].AMOUNT,
            y9: salesByMonthHistoricListFilter[8].AMOUNT,

          };
        });

        //console.log("Filtered", testFilter);

        setSalesByYearListAll(currentSalesByYearListAll);
        //console.log("the sales for the Year...", currentSalesByYearListAll);
      }
    });
    
    //Finding the current month----------------------------------------
    const currentMonth = new Date().getMonth()+1
   

    const SalesByMonth = salesByMonthHistoricList.filter(
      (item) => item.MONTH_ID === currentMonth
    );

    // console.log(
    //   "The historical sales for September for the last 5 years...",
    //   SalesByMonth
    // );

    if (SalesByMonth.length > 0) {
      SalesByMonth.map((item) => {
        BarChartData.push({
          //Name: consultant.NAME,
          Name: item.YEAR,
          Sales: item.AMOUNT,
          NetSales: item.AMOUNT.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          }),
        });
      });

      setMyBarChartData(BarChartData)


    }


  };

  //-------------------------------------
  useEffect(() => {
    //  alert("inside UseEffect in sales Vs Target")
    if (tabInitialDate && tabFinalDate) {
      getSalesByDate(tabInitialDate, tabFinalDate);
      getSalesCancelledByDate(tabInitialDate, tabFinalDate);
      getTargetInfo(tabInitialDate, tabFinalDate);

      getSalesByMonthHistoric(selectedYearFrom);
    }

    return () => {};
  }, [tabInitialDate, tabFinalDate]);
  //-------------------------------------

  useEffect(() => {
    getSalesByMonthHistoricData();
  }, [salesByMonthHistoricList]);

  return (
    <Fragment>
      {!isLoading1 && !isLoading2 ? (
        <DashboardGraph
          salesByDateList={salesByDateList}
          salesByDateListTarget={salesByDateListTarget}
          salesCancelledByDateList={salesCancelledByDateList}
          //salesByYearListAll={salesByYearListAll}
          salesByYearListAll={salesByYearListAll}
          myBoxHeight={600}
          graphType={graphType}
          BarChartData = {myBarChartData}
        />
      ) : (
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15} />
        </Box>
      )}
    </Fragment>
  );
};

export default SalesHistoricGraph;
