import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { updateQuote } from "../../store/quote-slice";
import {updateUser} from "../../store/users-slice";
import { v4 as uuidv4 } from "uuid";

import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@mui/material/Avatar";




//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));



const UserStep_3 = () => {
  const currentUserInfo = useSelector((state) => state.userDetails.userInfo);
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  // console.log("current User state from step 3", currentUserInfo);
  // console.log(
  //   "current new user state from step 3 one dipper",
  //   //currentQuoteInfo.companyType.COTYPE
  // );

  let userInfo1 = [];

  if (currentUserInfo) {
    userInfo1 = [
      { label: "Title : ", value: currentUserInfo.userTitle.TITLE },
      { label: "Team: ", value: currentUserInfo.userTeam.TEAM },

      {
        label: "Name: ",
        value:
        currentUserInfo.firstName +
          " " +
          currentUserInfo.lastName,
      },
      {
        label: "Email: ",
        value: currentUserInfo.userEmail,
      },
      { label: "Mobile: ", value: currentUserInfo.userMobile },
      { label: "U.T.R: ", value: currentUserInfo.userUTR },
      {
        label: "N.I: ",
        value: currentUserInfo.userNI,
      },
    ];
  }

  //Generate the unique QuoteKey when rendering the page
  useEffect(() => {
    const value = uuidv4();
    dispatch(
      updateUser({
        userKey: value,
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
          <Avatar
          alt="user pic"
          src={currentUserInfo.userPicUrl}
          sx={{ width: "8vw", height: "8vw", cursor: "pointer", margin:1}}
        //   onClick={(event) => {
        //     event.preventDefault();
        //     document.getElementById("userPicUrl").click();
        //   }}
        />

        <TableContainer
          component={Paper}
          sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>User Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: 500 }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    style={{ color: "#4E1721", fontWeight: 500 }}
                    align="center"
                  >
                    {info.value}
                  </TableCell>
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
  );
};

export default UserStep_3;
