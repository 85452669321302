
import { MyTable, MyTableRow, StyledTableCell } from "../UI/styledComponents/StyledComponents";

import theme from "../UI/Theme";

//MUI IMPORTS-----------------------------------------------------------------
import TableBody from "@mui/material/TableBody";
//import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import { current } from "@reduxjs/toolkit";


const PaymentPlanInfo = (props)=>{
    
    
    let paymentInfo1 = [];
    
    const matches = useMediaQuery(theme.breakpoints.down("lg"));

    const {currentPaymentPlanInfo} = props;

    console.log("the props aquiiiii....",currentPaymentPlanInfo)
  
     //const currentPaymentPlanInfo1 = [];
  
    if (currentPaymentPlanInfo?.payPlanInfo.selectedOrder.length > 0) {
    //console.log("Order number...",currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    paymentInfo1 = [
      { label: "Order : ", value: currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_REFERENCE },
      { label: "Company: ", value: currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].COMPANY_NAME },
    //   { label: "Total Paid: ", value: "£ " + parseFloat(currentPaymentPlanInfo.amountPaid).toLocaleString("en-US", {
    //     minimumFractionDigits: 2,
    //   }) },
    //   { label: "Payment Ref: ", value: currentPaymentInfo.paymentReference },
    //   { label: "Payment Type: ", value: currentPaymentPlanInfo.paymentType.CLIENTPAYMENTTYPE_REF, id:currentPaymentPlanInfo.paymentType.CLIENTPAYMENTTYPE_ID,
    //   icon:
    //   [
    //   <AccountBalanceOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <ReceiptLongOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <CreditCardOffOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <ContactlessOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <AlternateEmailOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   ], 
    // },
    { label: "... ", value: "..." },
    { label: "Payment Plan Set Date: ", value: new Date(currentPaymentPlanInfo.payPlanInfo.paymentPlanSetDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }),
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"500"
     },
     { label: "Payment Plan Signed Date: ", value: new Date(currentPaymentPlanInfo.paymentPlanSignedDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }),
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"500"
     },
     { label: "N. Of Payments: ", value: currentPaymentPlanInfo.payPlanInfo.PaymentPlanRepaymentNumber },
     { label: "... ", value: "..." },
    //   { label: "New Payment Date: ", value: new Date(currentPaymentInfo.paymentDateUpdated).toLocaleString("default", {
    //     day: "numeric",
    //     month: "short",
    //     year:"numeric",
    //   }),
    //   myFont: theme.palette.common.ludisMain,
    //   myFontWeight:"900"
    //  },

    ];
  }



    return(
        <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >

        <TableContainer
          component={Paper}
          //sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
          sx={{ margin:1, width: matches ? "45vw": "40vw"  }}

        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Payment Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: info.myFontWeight }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    //style={{ color: "#4E1721", fontWeight: 500 }}
                    style={{color: info.myFont, fontWeight: info.myFontWeight}}
                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
    )
}

export default PaymentPlanInfo;