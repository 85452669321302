import {Fragment} from 'react'
 
//MUI IMPORTS
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography, Box } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import TextField from '@mui/material/TextField';
import useMediaQuery from "@material-ui/core/useMediaQuery";

//loader Imports--------------
import { SyncLoader } from "react-spinners";


//MY IMPORTS----------------------------------------------------------------------
import TableComponent from './ArtTable';
import { MyTextField } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

const DesignerList = (props)=>{

    const {selectedName, handleNameChange, designersList, filteredOrders, isLoading, reportTitle} = props

    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

    //console.log("the data here....",designersList.activeUsersByTitleId?.length)
    //console.log("the data here....",designersList.activeUsersByTitleId)
    //console.log("the data here for filtered orders....",filteredOrders)
    //console.log("my props.......",props)



    return (

        designersList.activeUsersByTitleId?.length > 0 && (
        <Fragment   >
          {/* <div className="Art"> */}
          {/* <Box
            // sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}
            //sx={{ display: "flex", flexDirection: "column", overflow: "auto", width: '100%' }}

          > */}
          {/* <header className="Art-header"> */}
          {/* <Typography variant="h4" gutterBottom>
              Designers...
            </Typography> */}
          {/* <Box
          sx={{ 
            display: "flex", 
            flexDirection: "column", 
            overflow: "auto", 
            alignItems: "center",
            backgroundColor: "#ccc",
            //width:"",
            //margin:"auto"
         }}
          //   container spacing={2} justifyContent="center"
          > */}
            <Box
              //   item xs={12} md={12}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "row",
                //overflow: "auto",
                //width: "100%",
                margin:"auto",
                
                
              }}
            >
              {/* <FormControl fullWidth> */}
              <FormControl sx={{ width: matches3 ? "60vw" : "300px", margin:"auto", marginTop: 5,  }}>
                {/* <InputLabel id="name-select-label" sx={{ marginTop: 1 }}> */}
                {/* <InputLabel
                    htmlFor="custom-input"
                    sx={{  
                      marginTop: 1,
                      color: "#ccc", // Change label color
                      fontSize: "1.2rem", // Change label font size
                      // Add more custom styles here
                    }}
                  >
                  Select Designer
                </InputLabel> */}
                <MyTextField
                  id="name-select-label"
                  select
                  label="Select Designer"
                  defaultValue="EUR"
                  helperText=""
                  value={selectedName}
                  onChange={handleNameChange}
                >
                     <MenuItem value="">...</MenuItem>
                    {designersList.activeUsersByTitleId?.map(
                    (designer, index) => (
                      <MenuItem key={index} value={designer}>
                        {designer.NAME}
                      </MenuItem>
                    )
                  )}

                </MyTextField>

                {/* <Select
                  labelId="name-select-label"
                  id="name-select"
                  value={selectedName}
                  onChange={handleNameChange}
                >
                  <MenuItem value="">All</MenuItem>
                  {designersList.activeUsersByTitleId?.map(
                    (designer, index) => (
                      <MenuItem key={index} value={designer}>
                        {designer.NAME}
                      </MenuItem>
                    )
                  )}
                </Select> */}
              </FormControl>
            </Box>

            {!isLoading ? (
              <Box
                //   item xs={12} md={12}
                sx={{
                  marginTop: "10px",
                  //display: "flex",
                  //flexDirection: "column",
                  //justifyContent: "center",
                  overflow: "auto",
                  height: "70Vh",
                  width: matches3 ? "60vw" : "72vw",
                  //backgroundColor:"#ccc"
                  
                }}
              >
                {/* <Typography variant="h6" gutterBottom>
                  Results
                </Typography> */}

                {filteredOrders?.length > 0 ? (
                  <TableComponent 
                  data={filteredOrders} isLoading={isLoading} />
                ) : (
                  <Box textAlign="center" sx={{ marginTop: 5 }}>
                    <ReorderIcon
                      sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
                    />
                  </Box>
                )}

                {/* {filteredOrders?.length > 0 && (
                  <ul>
                    {filteredOrders?.map((order, index) => (
                      <li key={index}>
                        {order.CONFIRMATION_REFERENCE +" -- "+ order.COMPANY_NAME}
                      </li>
                    ))}
                  </ul>
                )} */}
              </Box>
            ) : (
              <Box textAlign="center" sx={{ marginTop: 5 }}>
                <SyncLoader color="#7A1125" size={15} />
              </Box>
            )}
          {/* </Box> */}
          {/* </header> */}
          {/* </Box> */}
          {/* </div> */}
        </Fragment>
      )
    );
}

export default DesignerList