import { Fragment, useState, useEffect, memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Title from "../Title";
import axios from "axios";
//import { variables } from "../Variables";
import theme from "../UI/Theme";
import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";

//MUI IMPORTS-------------------------------------------
//import Link from "@mui/material/Link";
//import Typography from "@mui/material/Typography";
//import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider, Grid, Button, Typography } from "@mui/material";
//import Grid from "@mui/material/Grid";
//import Button from "@mui/material/Button";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
//import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import useMediaQuery from "@material-ui/core/useMediaQuery";

// function preventDefault(event) {
//   event.preventDefault();
// }
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

//export default function Receivables(props) {
const Receivables = (props)=> {
  const currentReceivablesInfo = useSelector((state) => state.kpis.receivablesInfo);

  let totalByType = 0;

  //alert("Receivable Component Called !!")

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //console.log("the props in Receivables", props);

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        //direction="column"
        //justifyContent="space-around"
        //alignItems="center"
        sx={{
          //p: 2,
          //height: 265,
          height: 325,
          bgcolor: "#fafafa",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          margin: "auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#4E1721",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.icon}

          <Title>{props.title}</Title>
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: 0,
          }}
        >
          <Typography variant="h6" sx={{ color: "#4E1721" }}>
            {/* {props.totalOutstandingData.totalOutstanding? */}
            {currentReceivablesInfo.allOutsData?
            // `£ ${props.totalOutstandingData.totalOutstanding?.toLocaleString("en-US", {
              `£ ${currentReceivablesInfo.allOutsData.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`
              : "£0.00"}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ margin: 0 }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
        {/* <Divider  sx={{width:'80%'}} /> */}

        {/* Option to show Unallocated payments on Payments in -------------------------------- */}

        {props.title === "Outstanding" && (
          <>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box sx={{display:'flex', flexDirection:'column',alignItems: "center", justifyContent: "space-evenly", }}>
                <Typography
                  color="text.secondary.main"
                  component="p"
                  sx={{ fontSize: "0.8rem" }}
                >
                  {props.totalPaymentsUnallocatedByDate
                    ? "( No Allocated: £ " +
                      props.totalPaymentsUnallocatedByDate.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      ) +
                      " )"
                    : "..."}
                </Typography>

                <TableContainer component={Paper}>
                  <MyTable size="small">
                    <TableBody>

                        <MyTableRow key={Math.random()}>
                            <TableCell
                              align="right"
                              style={{ fontWeight: 500, fontSize:12 }}
                            >
                              1st.50%
                            </TableCell>

                            <TableCell
                              style={{ color: "#4E1721", fontWeight: 500, fontSize:12 }}
                              align="left"
                            >
                              £{" "}
                              {/* {props.totalOutstandingData.totalOutstanding_first50?.toLocaleString("en-US", { */}
                              {currentReceivablesInfo.allOutsData_first50?.toLocaleString("en-US", {

                                minimumFractionDigits: 2, maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </MyTableRow>

                          <MyTableRow key={Math.random()}>
                            <TableCell
                              align="right"
                              style={{ fontWeight: 500, fontSize:12 }}
                            >
                              2nd.50%
                            </TableCell>

                            <TableCell
                              style={{ color: "#4E1721", fontWeight: 500, fontSize:12 }}
                              align="left"
                            >
                              £{" "}
                              {/* {props.totalOutstandingData.totalOutstanding_second50?.toLocaleString("en-US", { */}
                              {currentReceivablesInfo.allOutsData_second50?.toLocaleString("en-US", {

                                minimumFractionDigits: 2, maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </MyTableRow>


                      {/* {props.paymentsByTypeByDateList.map((type) => {
                        const Unaloc =
                          props.paymentsUnallocatedByTypeByDateList.filter(
                            (item) =>
                              item.PAYMENTTYPE_ID === type.PAYMENTTYPE_ID
                          );
                        if (Unaloc.length > 0) {
                          //console.log("Unaloc", Unaloc[0].subTotal);
                          totalByType = type.subTotal + Unaloc[0].subTotal;
                        } else {
                          totalByType = type.subTotal;
                        }
                        return totalByType ? (
                          <MyTableRow key={Math.random()}>
                            <TableCell
                              align="right"
                              style={{ fontWeight: 500 }}
                            >
                              {type.CLIENTPAYMENTTYPE_REF}
                            </TableCell>

                            <TableCell
                              style={{ color: "#4E1721", fontWeight: 500 }}
                              align="left"
                            >
                              £{" "}
                              {totalByType.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                          </MyTableRow>
                        ) : (
                          "..."
                        );
                      })} */}
                    </TableBody>
                  </MyTable>
                </TableContainer>
              </Box>
            </Collapse>
          </>
        )}

        {/* Option to show Payments out Details on Payments DIR, FIX, Suppliers --------------------------------*/}

        {props.title === "Payments Out" && (
          <>
            {/* <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore> */}

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <TableContainer component={Paper}>
                <MyTable size="small">
                  <TableBody>
                    {/* <Typography
                color="text.secondary.main"
                component="p"
                sx={{ fontSize: "0.8rem" }}
              >
                {props.totalPaymentsSuppByDate
                  ? "SUPP: £ " +
                    props.totalPaymentsSuppByDate.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })
                  : "..."}
              </Typography> */}

                    {props.totalPaymentsSuppByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500 }}>
                          SUPP :
                        </TableCell>

                        <TableCell
                          style={{ color: "#4E1721", fontWeight: 500 }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsSuppByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      "..."
                    )}

                    {/* <Typography
                      color="text.secondary.main"
                      component="p"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {props.totalPaymentsDIRByDate
                        ? "DIR: £ " +
                          props.totalPaymentsDIRByDate.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })
                        : "..."}
                    </Typography> */}

                    {props.totalPaymentsDIRByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500 }}>DIR :</TableCell>

                        <TableCell
                          style={{ color: "#4E1721", fontWeight: 500 }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsDIRByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      "..."
                    )}

                    {/* <Typography
                      color="text.secondary.main"
                      component="p"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {props.totalPaymentsFIXByDate
                        ? "FIX: £ " +
                          props.totalPaymentsFIXByDate.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })
                        : "..."}
                    </Typography> */}

                    {props.totalPaymentsFIXByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500 }}>FIX :</TableCell>

                        <TableCell
                          style={{ color: "#4E1721", fontWeight: 500 }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      "..."
                    )}
                  </TableBody>
                </MyTable>
              </TableContainer>
            </Collapse>
          </>
        )}

        {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
          on 15 March, 2019
        </Typography> */}
        <Divider sx={{ width: "80%" }} />

        <Typography
          color="text.secondary.main"
          component="p"
          sx={{ fontSize: "0.8rem" }}
        >
          {/* {props.changeDates ? "This Year..." : "This Week..."} */}
          {"updated on :"+ new Date(currentReceivablesInfo.lastUpdate_receivables).toLocaleDateString()}
        </Typography>
        <Typography
          color="text.secondary.main"
          component="p"
          sx={{ fontSize: "0.8rem" }}
        >
          {/* {props.changeDates ? "This Year..." : "This Week..."} */}
          {"at :"+ new Date(currentReceivablesInfo.lastUpdate_receivables).toLocaleTimeString()}
        </Typography>
      </Grid>

      {/* <Link color="primary" href="#" onClick={preventDefault}>
            View balance
          </Link> */}
      <Button
        onClick={(event) => props.refreshReceivablesInfo(event)}
        sx={{ color: "#7A1125", textTransform: "capitalize" }}
      >
        <AutorenewIcon
          sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
        />
        {/* {props.changeDates ? "View This Week" : "View All Year"} */}
        {"Update Info"}

      </Button>
    </Fragment>
  );
}

export default  memo(Receivables);

