
//------------------MUI IMPORTS -----------------------------------------------
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
//import Button from "@mui/material/Button";
import { PropagateLoader } from "react-spinners";
import { SyncLoader } from "react-spinners";

import Title from "../Title";
import { MyPaper } from "./styledComponents/StyledComponents";
import theme from './Theme';

const DashboardContainerReport = (props) => {
  const {
    matches,
    matches2,
    matches3,
    notification,
    ListFiltered,
    //handleListItemChange,
    //handleGetReport_2,
    //reportVariables,
    IsLoading,
    box1,
    box2,
    box3,
    box4,
    reportTitle,
    reportId,
    boxHeight,
    boxHeight_container,
    boxHeightMatch,
    boxSize,
    myWidth,
    myMargin,
  } = props;


  return (
    <Box
      id="luis"
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === "light"
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        //backgroundImage:"url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",    
        flexGrow: 1,
        height: boxHeight_container ||  "",
        overflow: "auto",
        margin: myMargin || 0,
        //padding:0
        //backgroundColor:"#000",
        
        
      }}
    >
      {/* <Toolbar /> */}
      {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
      <Container maxWidth="xlg" sx={{ mt: myMargin||4, mb: 10, width: matches2? "95vw" : myWidth||"70vw",   }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                alignContent: "center",
                justifyContent:"space-between",
                flexDirection: "column",

                height: matches3 || matches ? boxHeightMatch || 400 : boxHeight&& boxHeight ||240,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: matches2? "center" : "flex-start",
                    
                    
                  }}
                >
                  <GradingOutlinedIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitle}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />

                
              </Grid>
              {/* Payment report id = 2 */}
              {ListFiltered.length > 0 || reportId===2 ? (
                <Grid
                  //container
                  //spacing={1}
                  sx={{
                    display: "flex",
                    //justifyContent: matches? "center" :"space-between",
                    justifyContent:"center",
                    alignContent: "center",
                    flexDirection: matches ? "column" : "row",
                    paddingTop: 2,
                    //margin: "auto",
                    // marginTop: matches ? 0 : 0,
                    
                  }}
                >

                  {box1}
                  

                 

                  {/* <Button
                    onClick={() => handleGetReport_2({ reportVariables })}
                    variant="outlined"
                    sx={{
                      margin: matches ? "0.2em" : "1em",
                      color: "#7a1125",
                      borderColor: "#7a1125",
                      height: "3.2rem",
                      "&:hover": {
                        borderColor: "#270c10",
                        color: "#fce9ec",
                        backgroundColor: "#7a1125",
                      },
                    }}
                  >
                    GO
                  </Button> */}
                </Grid>
              ) : (
                <PropagateLoader color="#7A1125" size="15" margin="2" />
              )}
            </Paper>
          </Grid>

          {/* <Grid item xs={12} md={4} lg={3}> */}
          <Grid item xs={12}  md={4} lg={boxSize? boxSize:9}>
            {/* <MyPaper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: matches ? 400 : boxHeight&& boxHeight ||240,
              }}
            > */}
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                color: theme.palette.common.ludisMain2,
                //height: matches ? 400 : boxHeight&& boxHeight ||240,
                height: matches3 || matches ? boxHeightMatch || 400 : boxHeight&& boxHeight ||240,
              }}
            >


              {box2}


            </Paper>
            {/* </MyPaper> */}
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{ p: 2, display: "flex", flexDirection: "column" }}
            >
              {box3}
            </Paper>
          </Grid>
          <Grid item xs={12} id="luis2">
            <Paper
              sx={{ p: 2, mb: 10, display: "flex", flexDirection: "column", backgroundColor:"#fff", marginBottom:20 }}
            >
              {IsLoading && (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15}  />
                </Box>
              )}
              
              {box4}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardContainerReport;
