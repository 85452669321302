import axios from "axios";
import { useState, useEffect, useContext } from "react";

import DashboardContainer from "../UI/DashboardContainer";
import UserDetails from "../adminComponents/UserDetails";
import { variables } from "../../Variables";
//import MyTabs from "../UI/MyTabs";
import PoSteps from "./PoSteps";
import PoStep_1 from "./PoStep_1";
import UserStep_2 from "../adminComponents/UserStep_2";
import UserStep_3 from "../adminComponents/UserStep_3";
import UserStep_4 from "../adminComponents/UserStep_4";

import PurchaseInvoicesReport from "../reportComponents/purchaseReports/PurchaseInvoicesReport";

import { SocketContext } from "../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";

//MUI IMPORTS--------------------------------------
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";

import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import GroupIcon from "@mui/icons-material/Group";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReceiptIcon from "@mui/icons-material/Receipt";

import theme from "../UI/Theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PurchaseInvoices = () => {
  //New Code here......
  const [suppliersList, setSuppliersList] = useState([]);

  const [projectList, setProjectList] = useState([]);

  //end New Code Here...

  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );
  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );

  const [value, setValue] = useState(0);

  //const [projectList, setProjectList] = useState([]);
  const [usersCommissionList, setUsersCommissionList] = useState([]);
  const [usersTeamList, setUsersTeamList] = useState([]);

  const [purchasePaymentsTypeList, setPurchasePaymentsTypeList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //fetching Markets from DB-------------------------------------------
  const getSuppliersList = () => {
    axios
      .get(variables.API_URL + "purchase/supplierslist", {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching products...",res.data.productListInfo)
        setSuppliersList(res.data.suppliersList);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetching products from DB-------------------------------------------
  const getPurchasePaymentsTypeList = () => {
    //console.log("Get All payments Type list .... from Income Component")

    axios
      .get(variables.API_URL + "purchase/purchasepaymentstypelist", {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching payments Type...",res.data.paymentTypeListInfo)
        setPurchasePaymentsTypeList(res.data.purchasePaymentsTypeListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // //fetching projects from DB-------------------------------------------
  // const getProjects = () => {
  //   axios
  //     .get(
  //       variables.API_URL + "projects",
  //       { withCredentials: true })

  //     .then((res) => {
  //       console.log("fetching projects...",res.data.projectListInfo)
  //       setProjectList(res.data.projectListInfo);
  //       //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getSuppliersList();
    getPurchasePaymentsTypeList();
    //getProjects();
    //getUsersTitleList();
    //getProjects();
    //getProducts();
    //getUsersTeamList();
    //getUsersCommissionList();
    // getCompanyTypeList();
  }, []);

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      console.log("receiving a message object", data);
      console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      console.log("receiving a notification object", data);
      console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  //Steps for creating a new user-------------------------------------
  // const steps = [
  //   {
  //     label: "PO Details",
  //     info: <PoStep_1  suppliersList={suppliersList} projectList={projectList} />,
  //     icon: <SettingsIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
  //     iconCompleted: <SettingsIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
  //     id: 0
  //   },
  //   {
  //     label: "User Details",
  //     info: <UserStep_2  usersTeamList={usersTeamList} usersCommissionList={usersCommissionList} />,
  //     icon: <GroupAddIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
  //     iconCompleted: <GroupAddIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
  //     id: 1
  //   },
  //   {
  //     label: "User Summary",
  //     info:<UserStep_3 />,
  //     icon: <ScreenSearchDesktopIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
  //     iconCompleted: <ScreenSearchDesktopIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
  //     id: 2
  //   },
  //   {
  //     label: "Confirmation",
  //     info: <UserStep_4 socket={socket} userInfo={userInfo} />,
  //     icon:  <MarkEmailReadIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
  //     iconCompleted:  <MarkEmailReadIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
  //     id: 3
  //   },
  // ];

  return (
    <>
      {/* <DashboardContainer 
    reportTitle="Purchase Invoices Information"
    myMarginTop={5}
    icon={<ReceiptIcon
      sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
    />}
    matches2={matches2}
    myWidth="80vw" //container width
    
    > */}

      {/* tabcode */}

      {/* <Box sx={{ width: "100%", display:"flex", flexDirection:"column", margin:0 }}> */}

      <Box
        component="main"
        sx={{
          // backgroundColor: (theme) =>
          //   theme.palette.mode === 'light'
          //     ? theme.palette.grey[100]
          //     : theme.palette.grey[900],
          //width: "100%",
          
          backgroundImage:
            "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          
          
        }}
      >
        {matches3 ? "" : <Toolbar />}

        {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 3, }}> */}
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: matches3? "center" : "flex-start",
              alignItems: "center",
              height: matches3 ? "20vh" : "auto",

            }}
          >
            {/* <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            
            > */}

            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="secondary"
              indicatorColor="secondary"
              // centered
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              orientation={matches3 ? "vertical" : "horizontal"}
              //sx={{display:'flex',  flexDirection:'column', justifyContent:'center' }}
            >
              <Tab
                icon={<PlaylistAddCheckCircleIcon />}
                iconPosition="start"
                label="P. Invoices List"
                {...a11yProps(0)}
              />
              <Tab
                icon={<PostAddIcon />}
                iconPosition="start"
                label="Create New Invoice"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <UserDetails /> */}
            <PurchaseInvoicesReport
              suppliersList={suppliersList}
              purchasePaymentsTypeList={purchasePaymentsTypeList}
              socket = {socket}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DashboardContainer
            //reportTitle="Create a New User"
            //myMarginTop= {0}
            // icon={<GroupAddIcon
            //   sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
            // />}
            >
              {/* <PoSteps steps={steps} /> */}
            </DashboardContainer>
          </TabPanel>
        {/* </Container> */}
        </Box>
      {/* </Box> */}

      {/* tabcode end */}
      {/* </DashboardContainer> */}
    </>
  );
};

export default PurchaseInvoices;
