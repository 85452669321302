import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import theme from "../UI/Theme";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI IMPORTS----------------------------
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const PaymentInfoOrderSummary = (props) => {
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);
  const currentPaymentInfo = useSelector(
    (state) => state.paymentIn.paymentInfo
  );

  const { isLoading1_allPayOnOrder } = props;

  //console.log("Selected Order from the summary payments...", selectedOrder);

  return (
    <Fragment>

      {!isLoading1_allPayOnOrder ? 

      selectedOrder.length > 0 ? (
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: theme.palette.common.ludisMain, marginTop: 2 }}
          >
            {"£ " +
              (currentPaymentInfo?.selectedOrderTotalPaid).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                }
              )}
          </Typography>

          <Divider sx={{ width: "80%" }} />

          <Typography
            color="text.secondary.main"
            component="p"
            sx={{ fontSize: "0.8rem", marginTop: 2 }}
          >
            {"Order: £ " +
              selectedOrder[0].TOTAL_AMOUNT.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
          </Typography>

          <Typography
            color="text.secondary"
            component="p"
            sx={{ fontSize: "0.7rem", marginTop: 1 }}
          >
            {"Bal: £ " +
              (
                selectedOrder[0].TOTAL_AMOUNT -
                currentPaymentInfo?.selectedOrderTotalPaid
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
          </Typography>
        </Grid>
      ) : (
        ""
      )

      : 
      <Box textAlign="center">
      <SyncLoader color="#7A1125" size={15}  />
    </Box>}


    </Fragment>
  );
};

export default PaymentInfoOrderSummary;
