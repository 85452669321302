import { styled } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
//import theme from "../../UI/Theme";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
//import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListItemText from "@mui/material/ListItemText";


//Styled TextField----------------

// const matches2 = useMediaQuery(theme.breakpoints.down("md"));
// const matches3 = useMediaQuery(theme.breakpoints.down("sm"));
// const matches = useMediaQuery(theme.breakpoints.down("lg"));



export const MyTextField = styled(TextField)(({ theme }) => ({
    ...theme.mixins.toolbar,
  
    "& .MuiInputBase-root": {
      color: "#270c10",
      fontSize: "0.8em",
    },
  
    "& .MuiInputLabel-root": {
      color: "#7a1125",
      fontSize: "0.9em",
    },
  }));


  //Variant from TEXT FIELD -------------------------------------------------
  export const MyTextField2 = styled(TextField)(({ theme }) => ({
    ...theme.mixins.toolbar,

  
    "& .MuiInputBase-root": {
      color: "#270c10",
      //color: "#ccc",
      fontSize: "0.8em",
      //height:"10px",
      margin:"0"

      
      
    },
  
    "& .MuiInputLabel-root": {
      color: "#7a1125",
      //color: "#ccc",
      fontSize: "0.9em",
      margin:"0"
    },
  }));  


  //Styled paper----------------
export const MyPaper = styled(Paper)(({ theme }) => ({
  color: "#7a1125",

  //color: "#270c10",

  height: 240,
  [theme.breakpoints.down("lg")]: {
    height: 400,
  },
  [theme.breakpoints.down("md")]: {
    height: 240,
  },
}));


//Styled Table -------------------------------------------
export const MyTable = styled(Table)(({ theme }) => ({

  //width:"90vw",


  "& .MuiTableHead-root": {
    // backgroundColor: "#7a1125",
    //backgroundColor:"#4E1721",
    //backgroundColor:"#000",
    
    


    "& 	.MuiTableCell-root": {
      width:"auto",
      color: "#F5F5F5",
      fontSize: "0.875rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem",
      },
    },
  },

  "& 	.MuiTableBody-root": {
    "& 	.MuiTableCell-root": {
      color: "#7A1125",
      fontSize: "0.895rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem",
      },
      // [theme.breakpoints.down("xl")]: {
      //   fontSize: "0.825rem",
      // },
    },
  },


}));
//Styled Table End ---------------------------------------

//Styled Table without Background -------------------------------------------
export const MyTable2 = styled(Table)(({ theme }) => ({

  // "& .MuiTableHead-root": {
  //   // backgroundColor: "#7a1125",
  //   //backgroundColor: "#4E1721",
  //   //width:50,
  //   //padding:0,
    

  //   "& 	.MuiTableCell-root": {
  //     //color: "#F5F5F5",
  //     color: theme.palette.common.ludisMain2,
  //     fontSize: "0.875rem",
  //     [theme.breakpoints.down("sm")]: {
  //       fontSize: "0.75rem",
  //     },
  //   },
  // },

  "& .MuiTableHead-root": {
    // backgroundColor: "#7a1125",
    //backgroundColor:"#4E1721",
    //backgroundColor:"#000",
    


    "& 	.MuiTableCell-root": {
      width:"auto",
      //color: "#F5F5F5",
      color: theme.palette.common.ludisMain2,
      fontSize: "0.875rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem",
      },
    },
  },

  "& 	.MuiTableBody-root": {
    "& 	.MuiTableCell-root": {
      padding:0,
      color: "#7A1125",
      fontSize: "0.875rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem",
      },
    },
  },

  // "& .MuiTableRow-root":{
  //   backgroundColor:"#ccc"

  // },


}));
//Styled Table End ---------------------------------------


//Styled Row ----------------------------------------

export const MyTableRow = styled(TableRow)(({ theme }) => ({
  
  "&:nth-of-type(odd)": {
    backgroundColor: "#F5F5F5",
    
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//Styled Row End ------------------------------------


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 10,
  width:"auto",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
  
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.7rem",
    
  },
}));


export const MyTableCell = styled(TableCell)(({ theme }) => ({
  //backgroundColor:"#ccc",
  
  margin:0,
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    paddingTop:10,
    paddingBottom:10

  },
  

  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "0.7rem",
    width:"auto",
    
  },
}));

//list Item Styling ----------------------------------------------
export const MyListItemText = styled(ListItemText)(({ theme }) => ({
  
  color:"#4E1721",
  fontSize:"1rem",

  "& .MuiListItemText-primary": {
    
      [theme.breakpoints.down("md")]: {
        fontSize:"0.85rem"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize:"0.75rem"
      },
    // width:"auto",
    // color: "#F5F5F5",
    // fontSize: "0.875rem",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "0.6rem",
    // },
  },
  
  // margin:0,
  // [theme.breakpoints.down("sm")]: {
  //   padding: 0,
  //   paddingTop:10,
  //   paddingBottom:10

  // },
  

  // [`&.${tableCellClasses.head}`]: {
  //   // backgroundColor: theme.palette.common.black,
  //   backgroundColor: "#4E1721",

  //   color: theme.palette.common.white,
  // },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: "0.7rem",
  //   width:"auto",
    
  // },
}));
