import React,{Fragment} from 'react'


//MY IMPORTS---------------------------------------------
import theme from "../Theme";
import DesignerList from "../../artworkComponents/DesignerList";

//MUI IMPORTS --------------------------------------------
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TodayIcon from "@mui/icons-material/Today";
import UndoIcon from "@mui/icons-material/Undo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DateRangeIcon from '@mui/icons-material/DateRange';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';



const ArtworkTrackerTabs = (props)=>{


    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

    const {handleChange, a11yProps, value,designersList,selectedName,filteredOrders,handleNameChange,isLoading,reportTitle } = props

    return (
      <Fragment>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            height: matches3 ? "20vh" : "auto",
            marginTop: matches3 ? 0 : 3,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            // centered
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            orientation={matches3 ? "vertical" : "horizontal"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: 0,
              width: matches3 ? "95vw" : "auto",
            }}
            // value={value}
            // onChange={handleChange}
            // aria-label="basic tabs example"
            // textColor="secondary"
            // indicatorColor="secondary"
            // scrollButtons
            // allowScrollButtonsMobile
            // variant="scrollable"
            // orientation={matches3 ? "vertical" : "horizontal"}
            // sx={{ width: "95vw", marginTop: 0 }}
          >
            <Tab
              icon={
                <ImageNotSupportedIcon
                  sx={{
                    fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                  }}
                />
              }
              iconPosition="start"
              label="No Artwork..."
              sx={{
                fontSize: matches3
                  ? "0.70rem"
                  : matches2
                  ? "0.75rem"
                  : "0.70rem",
                minHeight: "10vh",
              }}
              {...a11yProps(0)}
            />
            <Tab
              icon={
                <MarkEmailUnreadIcon
                  sx={{
                    fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                  }}
                />
              }
              iconPosition="start"
              label="Art Received..."
              sx={{
                fontSize: matches3
                  ? "0.70rem"
                  : matches2
                  ? "0.75rem"
                  : "0.70rem",
                minHeight: "10vh",
              }}
              {...a11yProps(1)}
            >
              {/* <DesignerList
                designersList={designersList}
                selectedName={selectedName}
                filteredOrders={filteredOrders}
                handleNameChange={handleNameChange}
                isLoading={isLoading}
                reportTitle={reportTitle}
              /> */}
            </Tab>
            <Tab
              icon={
                <FormatShapesIcon
                  sx={{
                    fontSize: matches3 ? "1.8rem" : matches2 ? "1" : "1.50rem",
                  }}
                />
              }
              iconPosition="start"
              label="Proof..."
              sx={{
                fontSize: matches3
                  ? "0.70rem"
                  : matches2
                  ? "0.75rem"
                  : "0.70rem",
                minHeight: "10vh",
              }}
              {...a11yProps(2)}
            />
            <Tab
              icon={
                <ThumbUpOffAltIcon
                  sx={{
                    fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                  }}
                />
              }
              iconPosition="start"
              label="Approved..."
              sx={{
                fontSize: matches3
                  ? "0.70rem"
                  : matches2
                  ? "0.75rem"
                  : "0.70rem",
                minHeight: "10vh",
              }}
              {...a11yProps(3)}
            />
            <Tab
              icon={
                <EventNoteIcon
                  sx={{
                    fontSize: matches3 ? "1.7rem" : matches2 ? "1" : "1.50rem",
                  }}
                />
              }
              iconPosition="start"
              label="T.B.Cancelled..."
              sx={{
                fontSize: matches3
                  ? "0.70rem"
                  : matches2
                  ? "0.75rem"
                  : "0.70rem",
                minHeight: "10vh",
              }}
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
      </Fragment>
    );
    
}

export default ArtworkTrackerTabs;
