import {Fragment} from 'react'
import NonopList from "./NonopList"

const PurchaseInvoicesNonop =()=>{

    const reportTitle="NON-Operational Invoices"
    const wagesType = 3 // FIX NON-Operationa wages / FIX Invoices


    return(
        <Fragment>
            <NonopList reportTitle={reportTitle} wagesType={wagesType}  />
        </Fragment>
    )
}

export default PurchaseInvoicesNonop