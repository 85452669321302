import axios from "axios";
import { useEffect, useState, Fragment } from "react";

//loader Imports--------------
//import { SyncLoader } from "react-spinners";
//import { PropagateLoader } from "react-spinners";

import html2canvas from "html2canvas";
//import { jsPDF } from "jspdf";

//------MUI IMPORTS------------------------------------------
import Box from "@mui/material/Box";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

import ReceiptIcon from "@mui/icons-material/Receipt";
import ComputerIcon from "@mui/icons-material/Computer";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GradingIcon from "@mui/icons-material/Grading";

import theme from "../UI/Theme";

import DashboardContainerReport from "../UI/DashboardContainerReport";
import PaymentsReportByDate from "./PaymentsReportByDate";
import { variables } from "../../Variables";
import { MyTextField } from "../UI/styledComponents/StyledComponents";
import PaymentsIn from "../paymentsComponent/PaymentsIn";
import Title from "../Title";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

let reportVariables = {
  //consultantSelected_id: "",
  selectedDatesFrom: "",
  selectedDatesTo: "",
};

export default function PaymentsReport() {
  const [consultantListFiltered, setConsultantListFiltered] = useState([]);

  const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  const [selectedDateTo, setSelectedCDateTo] = useState(null);

  const [paymentsByDateList, setPaymentsByDateList] = useState([]);
  const [totalPaymentsByDate, setTotalPaymentsByDate] = useState(null);
  //const [showPaymentsList, setShowPaymentsList] = useState(false)

  const [notification, setNotification] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [updatedPayment, setUpdatedPayment] = useState(null);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  //const matches2 = useMediaQuery(theme.breakpoints.down("md"));


  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //to be deleted....
  const [locale, setLocale] = useState("de");
  //to be deleted end....

  //Function to manage the state to check if new Payment has been recorded---------------------------------
  const isUpdatedPayment = (payId)=>{
    setUpdatedPayment(payId);
  }

  // //to show payments list as per Bank Statements or Recorde Date-----------------------------------
  const showBankHandler = (event) => {
    event.preventDefault();
    setShowBank((prev) => !showBank);
  };

  const resetReportVariables = () => {
    reportVariables = {
      ...reportVariables,
      // consultantSelected_id: "",
      selectedDatesFrom: "",
      selectedDatesTo: "",
    };
  };

  //let  box2Content = "..."

  //test
  // const updateBox2 = ()=>{
  //   box2Content =
  //   showBank &&
  //   <PaymentsIn
  //   // tabInitialDate={selectedDateFrom}
  //   // tabFinalDate={selectedDateTo}
  //   tabInitialDate={new Date(selectedDateFrom).toISOString()}
  //   tabFinalDate={new Date(selectedDateTo).toISOString()}
  //   />;
  // }

  //End Test

  //get all consultants--------------------
  //"http://localhost:5000/consultants",
  // const getConsultants = () => {
  //   axios
  //     .get(
  //       variables.API_URL + "consultants",
  //       { withCredentials: true })
  //     .then((res) => {
  //       setConsultantList(res.data.consultantInfo);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //Sales By Consultant for the periode of dates ----------------
  //"http://localhost:5000/salesbyconsultant/" +
  //const getSalesByConsultant = (
  const getAllPaymentsByDate = (selectedDatesFrom, selectedDatesTo) => {
    const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
    const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();

    // let paymentsUrl = "payments/paymentsbydate/";
    // showBank
    //   ? (paymentsUrl = "payments/paymentsbydate/")
    //   : (paymentsUrl = "payments/paymentsbydateonbank/");

    // console.log(
    //   "dates From ....",
    //   selectedDatesFrom_2,
    //   "...Dates To...",
    //   selectedDatesTo_2
    // );

    //console.log("showbank... insid paymentsreport...", showBank);
    //console.log("showbank... insid paymentsreport...",typeof(showBank))

    setIsLoading1(true);
    axios
      .get(
        variables.API_URL +
          "payments/paymentsbydate/" +
          // paymentsUrl +
          selectedDatesFrom_2 +
          "/" +
          selectedDatesTo_2 +
          "/" +
          showBank,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log(res.data);
        setPaymentsByDateList(res.data.paymentsByDate);

        if (res.data.paymentsByDate.length === 0) {
          setTotalPaymentsByDate(0);
          //setTotalPagesByConsultant(0);
        } else {
          const totalPaid = res.data.paymentsByDate.slice(-1);
          setTotalPaymentsByDate(totalPaid[0].cumulative_sum);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification("Something went wrong, please try again");
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  };

  useEffect(() => {
    //getConsultants();
    resetReportVariables();
    //test
    //updateBox2()
    //end test
  }, []);

  // useEffect(() => {
  //   if (consultantList.length > 0) {
  //     const myconsultantListFiltered = consultantList.map((option) => {
  //       const firstLetter = option.NAME[0].toUpperCase();
  //       return {
  //         firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //         ...option,
  //       };
  //     });

  //     setConsultantListFiltered(myconsultantListFiltered);
  //   }
  // }, [consultantList]);

  // const handleConsultantChange = (event, value) => {
  //   //console.log("value from the function handleconsultant...", value);

  //   setSalesByConsultantList([]);
  //   setTotalSalesByConsultant(null);
  //   setTotalPagesByConsultant(0);
  //   setConsultantSelected(value);

  //   if (value) {
  //     reportVariables = {
  //       ...reportVariables,
  //       consultantSelected_id: value.CONSULTANT_ID,
  //     };
  //   } else {
  //     reportVariables = {
  //       ...reportVariables,
  //       consultantSelected_id: null,
  //     };
  //   }
  //   console.log("reportVariables after consultant change", reportVariables);
  // };

  const handleDatesChangesFromTo = (date, id) => {
    //console.log("value from the DatePicker", date);
    setPaymentsByDateList([]);
    setTotalPaymentsByDate(null);
    // setTotalPagesByConsultant(0);

    const dateIsValid = date !== null && date !== "Invalid Date";

    if (id === 0) {
      setSelectedCDateFrom(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: date,
        };
      }
    } else if (id === 1) {
      setSelectedCDateTo(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: date,
        };
      }
    } else {
      //console.log("error, date not usable only 2 options available");
      return;
    }

    // console.log(
    //   "reportVariables after date change from the second picker....",
    //   reportVariables
    // );
  };

  const handleGetReport_2 = ({ reportVariables }) => {
    if (
      // consultantSelected &&
      selectedDateFrom !== null &&
      selectedDateTo !== null &&
      selectedDateFrom !== "Invalid Date" &&
      selectedDateTo !== "Invalid Date"
    ) {
      // console.log(
      //   "datefrom",
      //   reportVariables.selectedDatesFrom,
      //   "---date to...",
      //   reportVariables.selectedDatesTo,
      //   "--object...",
      //   reportVariables
      // );
      // getSalesByConsultant(
      //   reportVariables.consultantSelected_id,
      //   reportVariables.selectedDatesFrom,
      //   reportVariables.selectedDatesTo
      // );
      //getAllPaymentsByDate(reportVariables.selectedDatesFrom, reportVariables.selectedDatesTo )
      getAllPaymentsByDate(selectedDateFrom, selectedDateTo);

      //test
      //updateBox2(selectedDateFrom, selectedDateTo)
      //EndTest

      setNotification(null);
      //console.log("click here... alles ok")
    } else {
      //console.log("missing information for the report");
      // setSalesByConsultantList([]);
      // setTotalSalesByConsultant(null);
      // setTotalPagesByConsultant(0);

      setNotification("Please select valid data");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  // const totalPagesByConsultantHandler = (pages) => {
  //   setTotalPagesByConsultant(pages);
  // };

  //console.log("list of payments by dates ...", paymentsByDateList);

  const box1Content = (
    <Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}
        >
          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="From"
            value={selectedDateFrom}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches ? "0.2em" : "1em" }}
              />
            )}
          />

          <Box
            sx={{
              margin: matches ? "0.2em" : "1em",
              textAlign: "center",
            }}
          >
            {" "}
            to{" "}
          </Box>

          <DatePicker
            label="To"
            value={selectedDateTo}
            onChange={(date) => handleDatesChangesFromTo(date, 1)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches ? "0.2em" : "1em" }}
              />
            )}
          />

          <Button
            onClick={() => handleGetReport_2({ reportVariables })}
            variant="outlined"
            sx={{
              margin: matches ? "0.2em" : "1em",
              color: "#7a1125",
              borderColor: "#7a1125",
              height: "3.2rem",
              "&:hover": {
                borderColor: "#270c10",
                color: "#fce9ec",
                backgroundColor: "#7a1125",
              },
            }}
          >
            GO
          </Button>
        </Box>

        {/* Button to change from Bank statements to recorded date for payment list---------------------- */}
        <Box
          sx={{
            display: "flex",
            //flexDirection: matches ? "column" : "row",
            flexDirection: "column",
            //alignItems: "flex-start",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {showBank ? (
              <ReceiptIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            ) : (
              <ComputerIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            )}

            <Typography
              sx={{
                p: 1,
                //backgroundColor: "#e7dddd",
                color: theme.palette.common.ludisMain2,
                fontSize: matches ? 16 : 22,
              }}
            >
              {showBank ? "Bank Date" : "Recorded Date"}
            </Typography>
          </Box>

          <Button
            onClick={(event) => {
              event.preventDefault();
              showBankHandler(event);
            }}
            sx={{
              color: "#7A1125",
              textTransform: "capitalize",
              marginBottom: 2,
            }}
          >
            {showBank ? (
              <>
                <ComputerIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Recorded Date
                </Typography>
              </>
            ) : (
              <>
                
                <ReceiptIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Bank Date
                </Typography>
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );

  //Box to show Payments list for the dates selected-----------------------------------------
  const box3Content = (
    <PaymentsReportByDate
      paymentsByDateList={paymentsByDateList}
      isLoading1={isLoading1}
      showBank={showBank}
      matches={matches}
      matches2={matches2}
      matches3={matches3}
      isUpdatedPayment={isUpdatedPayment} 
      updatePayment={updatedPayment} 

    />
  );

  //Box to Show Summary report on payments for the dates selected-----------------------------
  const box2Content =
    paymentsByDateList.length > 0 ? (
      <PaymentsIn
        // tabInitialDate={selectedDateFrom}
        // tabFinalDate={selectedDateTo}
        tabInitialDate={new Date(selectedDateFrom).toISOString()}
        tabFinalDate={new Date(selectedDateTo).toISOString()}
        showBank={showBank}
        updatePayment={updatedPayment} 
      />
    ) : (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Payments In</Title>
        <Divider sx={{ width: "10vw" }} />
        <GradingIcon
          sx={{
            color: theme.palette.common.ludisMain,
            fontSize: 50,
            marginTop: 5,
          }}
        />
      </Box>
    );

  useEffect(() => {
    if (selectedDateFrom !== null && selectedDateTo !== null) {
      handleGetReport_2({ reportVariables });
    }
  }, [showBank, updatedPayment ]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <DashboardContainerReport
        box1={box1Content}
        //box1={<h1>Box 1 info here....</h1>}
        box2={box2Content}
        box3={box3Content}
        box4={<h6>...</h6>}
        matches={matches}
        matches2={matches2}
        notification={notification}
        ListFiltered={consultantListFiltered}
        handleListItemChange=""
        handleGetReport_2={handleGetReport_2}
        reportVariables={reportVariables}
        IsLoading={false}
        reportTitle="Payments Report ..."
        reportId={2} //Id for payment report so do not load consultant list
        boxHeight={300}
        //boxHeight_container = "50vw"
        boxHeight_container = "95vh"
        boxSize={3}
      />

      {/* <Box
        id="luis"
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",

                  height: matches ? 400 : 240,
                }}
              >
                <Grid>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <GradingOutlinedIcon
                      baseClassName="fas"
                      className="fa-plus-circle"
                      sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                    />
                    <Title>Sales Report....</Title>
                  </Box>
                  {notification && (
                    <Alert severity="error">
                      <h4>{notification}</h4>
                    </Alert>
                  )}

                  <Divider />
                  

                  
                </Grid>
                {consultantListFiltered.length > 0 ? (
                  <Grid
                    //container
                    //spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      flexDirection: matches ? "column" : "row",

                      margin: "auto",
                    }}
                  >
                    <Autocomplete
                      id="grouped-demo"
                      options={consultantListFiltered.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.NAME}
                      sx={
                        matches
                          ? { width: "auto", margin: "0.2em" }
                          : { width: 250, margin: "1em" }
                      }
                      onChange={(event, value) =>
                        handleConsultantChange(event, value)
                      }
                      renderInput={(params) => (
                        <MyTextField {...params} label="Consultant" />
                      )}
                    />

                    <DatePicker
                      mask={maskMap[locale]}
                      format="YYY/MM/dd"
                      label="From"
                      value={selectedDateFrom}
                      onChange={(date) => handleDatesChangesFromTo(date, 0)}
                      renderInput={(params) => (
                        <MyTextField
                          {...params}
                          sx={{ margin: matches ? "0.2em" : "1em" }}
                        />
                      )}
                    />

                    <Box
                      sx={{
                        margin: matches ? "0.2em" : "1em",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      to{" "}
                    </Box>

                    <DatePicker
                      label="To"
                      value={selectedDateTo}
                      onChange={(date) => handleDatesChangesFromTo(date, 1)}
                      renderInput={(params) => (
                        <MyTextField
                          {...params}
                          sx={{ margin: matches ? "0.2em" : "1em" }}
                        />
                      )}
                    />

                    <Button
                      onClick={() => handleGetReport_2({ reportVariables })}
                      variant="outlined"
                      sx={{
                        margin: matches ? "0.2em" : "1em",
                        color: "#7a1125",
                        borderColor: "#7a1125",
                        height: "3.2rem",
                        "&:hover": {
                          borderColor: "#270c10",
                          color: "#fce9ec",
                          backgroundColor: "#7a1125",
                        },
                      }}
                    >
                      GO
                    </Button>
                  </Grid>
                ) : (
                  <PropagateLoader color='#7A1125' size='15' margin='2' />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <MyPaper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SummaryReport
                  summaryInfo={summaryInfo}
                  totalSalesByConsultant={totalSalesByConsultant}
                  totalPagesByConsultant={totalPagesByConsultant}
                />
              </MyPaper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              
                
                <ReportSalesGraph
                  reportVariables={reportVariables}
                  salesByConsultantList={salesByConsultantList}
                  totalPagesByConsultantHandler={(pages) =>
                    totalPagesByConsultantHandler(pages)
                  }
                />
                
                
                
              </Paper>
            </Grid>
            <Grid item xs={12} id="luis2">
              <Paper
                sx={{ p: 2, mb: 10, display: "flex", flexDirection: "column" }}
              >
                {IsLoading && (
                  <Box textAlign="center">
                    <SyncLoader color="#7A1125" size='8' />
                  </Box>
                )}

                <ReportSalesList
                  reportVariables={reportVariables}
                  salesByConsultantList={salesByConsultantList}
                  totalPagesByConsultantHandler={(pages) =>
                    totalPagesByConsultantHandler(pages)
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </LocalizationProvider>
  );
}
