import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { updateQuote } from "../../store/quote-slice";
import {updateUser} from "../../store/users-slice";
import { v4 as uuidv4 } from "uuid";


import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkOffIcon from '@mui/icons-material/WorkOff';




//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));



const AddWagesPaymentStep_2 = () => {
  //const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  // const currentPurchasePaymentInfo = useSelector(
  //   (state) => state.purchasePaymentOut.purchasePaymentOutInfo
  // );

  // const currentSupplierSelected = useSelector(
  //   (state) => state.supplier.supplierInfo
  // );

  const currentWagesPaymentInfo = useSelector(
    (state) => state.wagesPayment.wagesPaymentInfo
  );

  console.log("this is the currentpurchase payment info in step 2.....",currentWagesPaymentInfo)

  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //console.log("current Payment state from step 3", currentPaymentInfo.paymentType);
//   console.log(
//     "current new Payment state from step 3 one dipper",
//     //currentQuoteInfo.companyType.COTYPE
//   );

  let paymentInfo1 = [];

  //if (Object.keys(currentPurchasePaymentInfo).length !== 0) {
    if (currentWagesPaymentInfo.selectedWagesInvoice.length !== 0) {

    //console.log("Order number...",currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    paymentInfo1 = [
      { label: "Consultant. : ", value: currentWagesPaymentInfo.selectedWagesInvoice[0].Firstname +  " " + currentWagesPaymentInfo.selectedWagesInvoice[0].Lastname.charAt(0) },
      { label: "Wages Invoice N.: ", value: currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_REF },
      { label: "Total Paid: ", value: "£ " + parseFloat(currentWagesPaymentInfo.wagesInvoiceAmountPaid).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      }) },
      { label: "Payment Date: ", value: new Date(currentWagesPaymentInfo.wagesPaymentDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }) },
      { label: "Payment Ref: ", value: currentWagesPaymentInfo.wagesPaymentReference },
      { label: "Comments: ", value: currentWagesPaymentInfo.wagesComments },
      { label: "Payment Type: ", value: currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_REPORT, id:currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID,
      icon:
      [
      <BusinessCenterIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <WorkOffIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <TapAndPlayIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <ReceiptLongOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <MenuBookIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <CreditScoreIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <CreditCardOffOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      // <AlternateEmailOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      ], 
    }

    ];
  }

  //Generate the unique QuoteKey when rendering the page
//   useEffect(() => {
//     const value = uuidv4();
//     dispatch(
//       updateUser({
//         userKey: value,
//       })
//     );
//   }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
          {/* <Avatar
          alt="user pic"
          src={currentUserInfo.userPicUrl}
          sx={{ width: "8vw", height: "8vw", cursor: "pointer", margin:1}}
        //   onClick={(event) => {
        //     event.preventDefault();
        //     document.getElementById("userPicUrl").click();
        //   }}
        /> */}

        <TableContainer
          component={Paper}
          //sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
          sx={{ margin:1, width: matches ? "45vw": "40vw"  }}

        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Wages Invoice Payment Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: 500 }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    style={{ color: "#4E1721", fontWeight: 500 }}
                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
  );
};

export default AddWagesPaymentStep_2;
