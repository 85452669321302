import { Fragment } from 'react';

// This is a React Router v6 app
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './Home';
import Department from './Department';
import Main from './Main';

//Design related imports
import './App.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  

  //when matching No real route
  const NotFound = () => {
    return <>You have landed on a page that doesn't exist</>;
  }


  return (
    
    <Fragment>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/department' element={<Department />} />
        <Route path='/main/*' element={<Main />}  />
        <Route path="*" element={<NotFound />} />


        {/* Review this line as it gives an error but it is exactly the same as the prveios one? */}
        {/* <Route Path='/credit' element={<CreditControl />} /> */}


      </Routes>

    </Fragment>
    

  );
}

export default App;
