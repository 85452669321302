import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";

//--------REDUX IMPORTS----------------------------------------------------------
import { useDispatch } from "react-redux";
import { logUser} from "./store/user-slice";

// This is a React Router v6 app

//------------------MUI IMPORTS------------------------------------------------//

import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";

//------------------COMPONENTS IMPORTS-----------------------------------------//
import CreditControl from "./components/CreditControl";
import CreditControlMenu from "./components/CreditControlMenu";
import MainContent from "./components/MainContent";
import MainDashboard from "./components/MainDashboard";
import Sales from "./components/Sales";
import Admin from "./components/adminComponents/Admin";
import theme from "./components/UI/Theme";
import Reports from "./components/Reports";
import ReportsMenu from "./components/ReportsMenu";
import SalesReport from "./components/reportComponents/SalesReport";
import PaymentsReport from "./components/reportComponents/PaymentsReport";
import Users from "./components/adminComponents/Users";

import Orders from "./components/adminComponents/Orders";
import Quote from "./components/salesComponents/Quote";
import Purchase from "./components/Purchase";
import PurchaseMenu from "./components/PurchaseMenu";
import PurchaseOrder from "./components/purchaseComponents/PurchaseOrder";
import PurchaseInvoices from "./components/purchaseComponents/PurchaseInvoices";
import PurchaseInvoicesNonop from "./components/purchaseComponents/PurchaseInvoicesNonop";


import ProductionMenu from "./components/ProductionMenu";
import ArtTracker from "./components/artworkComponents/ArtTracker";


import Salary from "./components/Salary";
import SalaryMenu from "./components/SalaryMenu";
import SalaryListMenu from "./components/purchaseComponents/SalaryListMenu";
//import SalaryList from "./components/purchaseComponents/SalaryList";


import Income from "./components/paymentsComponent/Income";
import Ccorders from "./components/paymentsComponent/Ccorders";


import { variables } from './Variables';

//Temporary components for the links
const Services = () => {
  return <h1>Service Component here,,,,</h1>;
};

const CustomSoftware = () => {
  return <h1>Custom Software Component here,,,,</h1>;
};

const Mobileapps = () => {
  return <h1>Mobile Apps Component here,,,,</h1>;
};

const Websites = () => {
  return <h1>websites Component here,,,,</h1>;
};

const About = () => {
  return <h1>About Component here,,,,</h1>;
};

const Contact = () => {
  return <h1>Contact Component here,,,,</h1>;
};
//Temporary components for the links

axios.defaults.withCredentials = true;

export default function Home() {
  const dispatch = useDispatch();
  //const userInfo = useSelector((state) => state.user);

  const [userName, setUserName] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPicURL, setUserPicURL] = useState("");
  const [userId, setUserId] = useState("");
  const [userPermission, setUserPermission] = useState("");
  const [userConsultantId, setUserConsultantId] = useState("");




  const navigate = useNavigate();

  //linkmanagement states------------
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(0);
  //linkmanagement states------------

  const checkToken = () => {
    axios
    // .get("http://localhost:5000/employee", { withCredentials: true })
    .get(variables.API_URL+"employee", { withCredentials: true })

      .then((res) => {
        //console.log("Checking the Token in employees", res.data.user)
        setUserName(res.data.user.NAME)
        setUserEmail(res.data.user.EMAIL)
        setUserPicURL(res.data.user.USER_PIC)
        setUserId(res.data.user.StaffID)
        setUserFirstName(res.data.user.Firstname)
        setUserLastName(res.data.user.Lastname)
        setUserPermission(res.data.user.ADMIN_PERMISSION)
        setUserConsultantId(res.data.user.CONSULTANT_ID)
        // setUserPermission(res.data.user.Permission);
        



      })
      .catch((error) => {
        console.log(error);
        navigate("/", { userInfo: {} });
      });
  };

  const logoutHandler = () => {
    axios
    // .get("http://localhost:5000/auth/logout", { withCredentials: true })
    .get(variables.API_URL+"auth/logout", { withCredentials: true })

      .then((res) => {
        navigate("/", { userInfo: {} });
      });
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (userName) {
      dispatch(logUser({
        username: userName,
        userEmail: userEmail,
        userPicURL: userPicURL,
        userId: userId,
        userFirstName: userFirstName,
        userLastName: userLastName,
        userPermission:userPermission,
        userConsultantId:userConsultantId,


      }));
    }
  }, [userName, userEmail, userPicURL, userId, userFirstName, userLastName, userPermission, userConsultantId ]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        {/* {userName ? <h6>Hi {userName}</h6> : "..."} */}

        <Routes>
          <Route
            path=""
            element={
              <MainDashboard
                onLogout={logoutHandler}
                value={value}
                setValue={setValue}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            }
          >
            <Route path="/creditcontrol" element={<CreditControlMenu />} />
            <Route path="/creditcontrolgeneral" element={<CreditControl />} />
            <Route path="/income" element={<Income />} />
            <Route path="/ccorders" element={<Ccorders />} />


            <Route path="/sales" element={<Sales />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/quote" element={<Quote />} />
            

            <Route path="/purchase" element={<PurchaseMenu />} />
            <Route path="/purchasegeneral" element={<Purchase />} />
            <Route path="/purchaseorder" element={<PurchaseOrder />} />
            <Route path="/purchaseinvoices" element={<PurchaseInvoices />} />
            <Route path="/purchaseinvoicesnonop" element={<PurchaseInvoicesNonop />} />



            <Route path="/production" element={<ProductionMenu />} />
            <Route path="/artworktracker" element={<ArtTracker />} />
            

            <Route path="/Salary" element={<SalaryMenu />} />
            <Route path="/Salarygeneral" element={<Salary />} />
            <Route path="/salarylistmenu" element={<SalaryListMenu />} />
            {/* <Route path="/salaryList" element={<SalaryList />} /> */}




            <Route path="/admin" element={<Admin />} />
            <Route path="/users" element={<Users />} />

            <Route
              // path="/dash-main-content"
              path="/"

              element={
                <MainContent
                  salesByConsultantList={[]}
                  salesByConsultantByDate={[]}
                />
              }
            />

            <Route path="/reports" element={<ReportsMenu />} />
            <Route path="/reportsgeneral" element={<Reports />} />
            <Route path="/salesreport" element={<SalesReport />} />
            <Route path="/paymentsreport" element={<PaymentsReport />} />
            
            


            <Route path="/services/" element={<Services />} />
            <Route path="/mobileapps" element={<Mobileapps />} />
            <Route path="/customsoftware" element={<CustomSoftware />} />
            <Route path="/websites" element={<Websites />} />

            <Route
              exact
              path="/revolution"
              element={() => <div>Revolution</div>}
            />
            <Route exact path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route exact path="/estimate" element={() => <div>Estimate</div>} />
          </Route>
        </Routes>

        {/* <button className="btn btn-primary" onClick={logoutHandler}>
          log out
        </button> */}
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
