import { Fragment, useState, useEffect } from "react";
import Title from "./Title";
import axios from "axios";
import { variables } from "../Variables";
import theme from "./UI/Theme";
import PaymentsOutInfo from "./purchaseComponents/PaymentsOutInfo";
import PaymentsOutInfoSalary from "./purchaseComponents/PaymentsOutInfoSalary";
import PaymentsInInfo from "./paymentsComponent/PaymentsInInfo";

//MUI IMPORTS-------------------------------------------
//import Link from "@mui/material/Link";
//import Typography from "@mui/material/Typography";
//import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider, Grid, Button, Typography } from "@mui/material";
//import Grid from "@mui/material/Grid";
//import Button from "@mui/material/Button";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
//import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { MyTable, MyTableRow } from "./UI/styledComponents/StyledComponents";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ComputerIcon from "@mui/icons-material/Computer";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import Popover from "@mui/material/Popover";
//import useMediaQuery from "@material-ui/core/useMediaQuery";

// function preventDefault(event) {
//   event.preventDefault();
// }
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Deposits(props) {
  let totalByType = 0;
  let totalDisplay = 0;
  let subTotalDisplay = 0;


  const [expanded, setExpanded] = useState(false);
  
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  const {showBank, showBankHandler, MainContentReport}=props

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (props.title === "Salary Paid") {
    totalDisplay = props.totalPaymentsFIXByDate_Ops + props.totalPaymentsDIRByDate;
  } else if(props.title ==="Payments Out") {
    totalDisplay = props.totalPaymentsByDate
    subTotalDisplay = props.totalPaymentsByDate - props.totalPaymentsFIXByDate_NonOps;
  }else{
    totalDisplay = props.totalPaymentsByDate
  };


  //console.log("the props in deposits", props);

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        //direction="column"
        //justifyContent="space-around"
        //alignItems="center"
        sx={{
          //p: 2,
          //height: 265,
          height: 325,
          bgcolor: "#fafafa",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          margin: "auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#4E1721",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.icon}

          <Title>{props.title}</Title>
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: 0,
          }}
        >
          {/* {props.title === "Salary Paid"
            ? (totalDisplay =
                props.totalPaymentsFIXByDate_Ops + props.totalPaymentsDIRByDate)
            : (totalDisplay = props.totalPaymentsByDate)} */}

          <Typography variant="h6" sx={{ color: "#4E1721" }}>
            {/* {props.totalPaymentsByDate */}
            {props.totalPaymentsByDate
              ? `£ ${totalDisplay.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`
              : "£0.00"}
          </Typography>


          {props.title === "Payments Out" && 
          <Typography 
            variant="h6" 
            color="text.secondary" 
            component="p"
            sx={{ fontSize: "0.8rem" }}
          >
            {subTotalDisplay
              ? `OPS: £ ${subTotalDisplay.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`
              : "£0.00"}
          </Typography>
          }

          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ margin: 0 }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
        {/* <Divider  sx={{width:'80%'}} /> */}

        {/* Option to show Unallocated payments on Payments in -------------------------------- */}

        {props.title === "Payments In" && (
          <>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography
                  color="text.secondary.main"
                  component="p"
                  sx={{ fontSize: "0.8rem" }}
                >
                  {props.totalPaymentsUnallocatedByDate
                    ? "( No Allocated: £ " +
                      props.totalPaymentsUnallocatedByDate.toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      ) +
                      " )"
                    : " "}
                </Typography>

                <TableContainer component={Paper}>
                  <MyTable size="small">
                    <TableBody>
                      {props.paymentsByTypeByDateList.map((type) => {
                        const Unaloc =
                          props.paymentsUnallocatedByTypeByDateList.filter(
                            (item) =>
                              item.PAYMENTTYPE_ID === type.PAYMENTTYPE_ID
                          );
                        if (Unaloc.length > 0) {
                          //console.log("Unaloc", Unaloc[0].subTotal);
                          totalByType = type.subTotal + Unaloc[0].subTotal;
                        } else {
                          totalByType = type.subTotal;
                        }
                        return totalByType ? (
                          <MyTableRow key={Math.random()}>
                            <TableCell
                              align="right"
                              style={{ fontWeight: 500, fontSize: 12 }}
                            >
                              {type.CLIENTPAYMENTTYPE_REF}
                            </TableCell>

                            <TableCell
                              style={{
                                color: "#4E1721",
                                fontWeight: 500,
                                fontSize: 12,
                              }}
                              align="left"
                            >
                              £{" "}
                              {totalByType.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                          </MyTableRow>
                        ) : (
                          " "
                        );
                      })}
                    </TableBody>
                  </MyTable>
                </TableContainer>
              </Box>
            </Collapse>
          </>
        )}

        {/* Option to show Payments out Details on Payments DIR, FIX, Suppliers --------------------------------*/}

        {props.title === "Payments Out" && (
          <>
            {/* <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore> */}

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <TableContainer component={Paper}>
                <MyTable size="small">
                  <TableBody>
                    {/* <Typography
                color="text.secondary.main"
                component="p"
                sx={{ fontSize: "0.8rem" }}
              >
                {props.totalPaymentsSuppByDate
                  ? "SUPP: £ " +
                    props.totalPaymentsSuppByDate.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })
                  : "..."}
              </Typography> */}

                    {props.totalPaymentsSuppByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          SUPP :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsSuppByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}

                    {props.totalPaymentsDIRByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          DIR :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsDIRByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}

                    {/* <Typography
                      color="text.secondary.main"
                      component="p"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {props.totalPaymentsFIXByDate
                        ? "FIX: £ " +
                          props.totalPaymentsFIXByDate.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })
                        : "..."}
                    </Typography> */}

                    {/* {props.totalPaymentsFIXByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          FIX :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      "..."
                    )} */}

                    {props.totalPaymentsFIXByDate_Ops ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          FIX :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate_Ops.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}

                    {props.totalPaymentsFIXByDate_NonOps ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          NON OPS :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate_NonOps.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}
                  </TableBody>
                </MyTable>
              </TableContainer>
            </Collapse>
          </>
        )}

        {/* Option to show Payments out Details on Payments DIR, FIX, Suppliers --------------------------------*/}

        {props.title === "Salary Paid" && (
          <>
            {/* <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore> */}

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <TableContainer component={Paper}>
                <MyTable size="small">
                  <TableBody>
                    {props.totalPaymentsDIRByDate ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          DIR :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsDIRByDate.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}

                    {props.totalPaymentsFIXByDate_Ops ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          FIX :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate_Ops.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )}

                    {/* {props.totalPaymentsFIXByDate_NonOps ? (
                      <MyTableRow key={Math.random()}>
                        <TableCell style={{ fontWeight: 500, fontSize: 12 }}>
                          NON OPS :
                        </TableCell>

                        <TableCell
                          style={{
                            color: "#4E1721",
                            fontWeight: 500,
                            fontSize: 12,
                          }}
                          align="center"
                        >
                          £{" "}
                          {props.totalPaymentsFIXByDate_NonOps.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </MyTableRow>
                    ) : (
                      " "
                    )} */}
                  </TableBody>
                </MyTable>
              </TableContainer>
            </Collapse>
          </>
        )}

        {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
          on 15 March, 2019
        </Typography> */}
        <Divider sx={{ width: "80%" }} />

        <Typography
          color="text.secondary.main"
          component="p"
          sx={{ fontSize: "0.8rem" }}
        >
          {/* {props.changeDates ? "This Year..." : "This Week..."} */}
        </Typography>
      </Grid>

      {/* <Link color="primary" href="#" onClick={preventDefault}>
            View balance
          </Link> */}
      <Box
      sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
      }}
      >
      <Button
        onClick={() => props.showDetailsHandler()}
        sx={{ color: "#7A1125", textTransform: "capitalize", fontSize: "0.7rem" }}
        >
        <FormatListBulletedIcon
          sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />
        {props.showDetails ? "Hide" : "more..."}
      </Button>

      {props.title==="Payments In" && MainContentReport &&

      <Button
        onClick={(event) => showBankHandler(event)}
        sx={{ color: "#7A1125", textTransform: "capitalize", fontSize: "0.7rem" }}
      >
        {showBank ? (
              <ReceiptIcon
                sx={{ fontSize: 15, color: "#4E1721", marginRight: 2 }}
              />
            ) : (
              <ComputerIcon
                sx={{ fontSize: 15, color: "#4E1721", marginRight: 2 }}
              />
            )}
        {showBank ? "See Record Date" : "See Bank Date"}
      </Button>
      }
      </Box>    

      {/* Modal for case: Show payments Out to Suppliers ---------------------- */}
      <Dialog
        open={props.showDetails}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => props.showDetailsHandler()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={false}
        PaperProps={{ sx: { width: matches3?"95vw":"70vw" } }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "row",
              alignItems: "center",
              justifyContent:matches3?"center":"flex-start"
              
            }}
          >
            {props.icon}

            <Title>{props.title} Information</Title>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ width: matches3?"85vw":"70vw", height: "60vh" }}>
          {/* DISPLAY INFORMATION FOR PAYMENTS OUT */}
          {props.paymentsByDateList?.length > 0 && (props.title==="Payments Suppliers" || props.title==="Payments Out") && (
            <Fragment>
              <DialogContentText sx={{ margin: 2 }}>
                {/* On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME} */}
              </DialogContentText>

              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize: matches3?"0.8rem":"1rem" }}
              >
                {props.title + "  " + "between:  "}
                  
              </Typography>


              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize:matches3?"0.8rem":"1rem" }}
              >
                {/* {props.title + "  " + "between :  "} */}
                {new Date(props.selectedDatesFromInitial).toLocaleString(
                  "default",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )}{" "}
                {"  And  "}
                {new Date(props.selectedDatesTo).toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>

              <PaymentsOutInfo
                currentPaymentsOutInfo={props.paymentsByDateList}
              />
            </Fragment>
          )}

          {/* DISPLAY INFORMATION FOR  DIR and FIX PAYMENTS  */}
          {(props.DIRPaymentsByDateList?.length > 0 || props.FIXPaymentsByDateList?.length > 0) && (props.title==="Salary Paid" || props.title==="Payments Out") && (
            <Fragment>
              <DialogContentText sx={{ margin: 2 }}>
                {/* On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME} */}
              </DialogContentText>

              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize: matches3?"0.8rem":"1rem" }}
              >
                {props.title + "  " + "between:  "}
                  
              </Typography>


              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize:matches3?"0.8rem":"1rem" }}
              >
                {/* {props.title + "  " + "between :  "} */}
                {new Date(props.selectedDatesFromInitial).toLocaleString(
                  "default",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )}{" "}
                {"  And  "}
                {new Date(props.selectedDatesTo).toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>

              {props.DIRPaymentsByDateList.length >0 &&
              <PaymentsOutInfoSalary
              // currentPaymentsOutInfo={props.paymentsByDateList}
              currentPaymentsOutInfo={props.DIRPaymentsByDateList}
              subTitle="DIR"
              />
              }

              {props.FIXPaymentsByDateList.length >0 &&
              <Box sx={{marginTop:5 }}>

              <PaymentsOutInfoSalary
              // currentPaymentsOutInfo={props.paymentsByDateList}
              currentPaymentsOutInfo={props.FIXPaymentsByDateList}
              subTitle="FIX"
              />
              </Box>
              }

            </Fragment>
          )}



          {/* DISPLAY INFORMATION FOR PAYMENTS IN */}
          {props.paymentsInByDateList?.length > 0 && (
            <Fragment>
              <DialogContentText sx={{ margin: 2 }}>
                {/* On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME} */}
              </DialogContentText>

              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize: matches3?"0.8rem":"1rem" }}
              >
                {props.title + "  " + "between:  "}
                  
              </Typography>

              <Typography
                align="center"
                variant="body2"
                //color="text.secondary"
                sx={{ marginTop: 2, marginBottom: 2, fontSize: matches3?"0.8rem":"1rem" }}
              >
                {/* {props.title + "  " + "between:  "} */}
                {new Date(props.selectedDatesFromInitial).toLocaleString(
                  "default",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )}{" "}
                {"  And  "}
                {new Date(props.selectedDatesTo).toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Typography>

              <PaymentsInInfo
                currentPaymentsInInfo={props.paymentsInByDateList}
              />
            </Fragment>
          )}

          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
}
