import { Fragment } from "react";

const CreditControlAgeReport = () => {
  return (
    <Fragment>
      <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/creditcontrol/CCReport-General.png_Of5uBHP1wz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1664354472475" />

      
    </Fragment>
  );
};

export default CreditControlAgeReport;
