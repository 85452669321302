import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { updateQuote } from "../../store/quote-slice";
//import {updateUser} from "../../store/users-slice";
import { v4 as uuidv4 } from "uuid";

import { MyTable, MyTableRow } from "../../UI/styledComponents/StyledComponents";
import theme from "../../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';

import { Divider } from "@mui/material";
import TextField from '@mui/material/TextField';




//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));



const CreateEmailStep_2 = () => {
  const currentComunicationEmailInfo = useSelector((state) => state.comunication.comunicationCreateEmailInfo);
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //console.log("this is the comunication info...",currentComunicationEmailInfo.selectedOrder[0].CLUB_NAME + currentComunicationEmailInfo.selectedOrder[0].PROJECTEDITION + currentComunicationEmailInfo.selectedOrder[0].YEAR)


  let comunicationInfo1 = [];

  if (currentComunicationEmailInfo) {
    //console.log("Order number...",currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    comunicationInfo1 = [
      { label: "Email : ", value: currentComunicationEmailInfo.comunicationEmail },
      { label: "CC: ", value: currentComunicationEmailInfo.comunicationEmailCC },
      { label: "Subject: ", value: currentComunicationEmailInfo.comunicationEmailSubject },
      { label: "Email Date: ", value: new Date(currentComunicationEmailInfo.comunicationEmailDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }) },
    //   { label: "Message: ", value: <pre> {currentComunicationEmailInfo.ComunicationMessage} </pre> },
    
    //   { label: "Payment Type: ", value: currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_REF, id:currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_ID,
    //   icon:
    //   [
    //   <AccountBalanceOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <ReceiptLongOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <CreditCardOffOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <ContactlessOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   <AlternateEmailOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
    //   ], 
    // }

    ];
  }



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >


        <TableContainer
          component={Paper}
          //sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
          sx={{ margin:1, width: matches ? "45vw": "40vw"  }}

        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Comunication Email Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comunicationInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: 500 }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    style={{ color: "#4E1721", fontWeight: 500 }}
                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>

        <Divider />

      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          alignItems: "center",
          //alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >

      <TextField
          id="filled-multiline-static"
          label="Check your email... "
          multiline
          rows={10}
          defaultValue= {currentComunicationEmailInfo.comunicationMessage}
          variant="outlined"
          sx={{ width:matches?"45vw": "75vw"}}
          disabled={true}
          //onChange={(event) =>handleInputChangeMessage(event.target.value, event.target.name)}
        />

      </Box>


    </>
  );
};

export default CreateEmailStep_2;
