import { Fragment, useState, useEffect } from "react";

//----------REDUX IMPORTS --------------------------------
import { useSelector } from "react-redux";

import axios from "axios";
import theme from "../UI/Theme";
import { variables } from "../../Variables";
import PaymentsOut from "../purchaseComponents/PaymentsOut";
import Deposits from "../Deposits";
import SalaryChart from "../graphComponents/SalaryChart";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI IMPORTS-------------------------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import Box from "@mui/material/Box";

const SalaryReport = (props) => {

  const userInfo = useSelector((state) => state.user);


  const [showSalesGraph, setShowSalesGraph] = useState(false);

  const [paymentsByDateList, setPaymentsByDateList] = useState([]);
  const [totalPaymentsSuppByDate, setTotalPaymentsSuppByDate] = useState([]);

  const [totalPaymentsDIRByDate, setTotalPaymentsDIRByDate] = useState([]);
  const [DIRPaymentsByDateList, setDIRPaymentsByDateList] = useState([]);
  
  const [DIRPaymentsByDateByConsultantList, setDIRPaymentsByDateByConsultantList] = useState([]); // NEW CODE SEP 2022


  const [totalPaymentsFIXByDate, setTotalPaymentsFIXByDate] = useState([]);
  const [FIXPaymentsByDateList, setFIXPaymentsByDateList] = useState([]);

  const [mySalaryData_FixOps, setMySalaryData_FixOps]=useState([])
  const [mySalaryData_FixNonOps, setMySalaryData_FixNonOps]=useState([])

  const [changeDates, setChangeDates] = useState(null);

  const [IsLoading1, setIsLoading1] = useState(false);
  const [IsLoading2, setIsLoading2] = useState(false);
  const [IsLoading3, setIsLoading3] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  // let netSales = 0;
  // let BarChartData = [];

  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  //const selectedDatesFromInitial = new Date(prevMonday).toISOString();

  const selectedDatesFromInitial = props.tabInitialDate;
  const selectedDatesTo = props.tabFinalDate;
  const myTitle = props.myTitle;
  const updateSalary = props.updateSalary;
  //const tabInitialDate = props.tabInitialDate;
  //const tabFinalDate = props.tabFinalDate;

  //Get All (Standard) Payments by Date (Suppliers)------------------------------------
  const getPaymentsOutByDate = (selectedDatesFromInitial, selectedDatesTo) => {
    // console.log(
    //   "getPaymentsOutByDate............... in the Payments OUT component called !!!!"
    // );
    setIsLoading1(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "purchase/paymentsoutbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",
        //   res.data.paymentsOutByDate
        // );
        setPaymentsByDateList(res.data.paymentsOutByDate);

        if (res.data.paymentsOutByDate.length === 0) {
          setTotalPaymentsSuppByDate(0);
        } else {
          const totalStandardPayments = res.data.paymentsOutByDate.slice(-1);
          setTotalPaymentsSuppByDate(totalStandardPayments[0].cumulative_sum);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (DIR) Salary Payments by Date------------------------------------
  const getDIRSalariesPayByDate = (
    selectedDatesFromInitial,
    selectedDatesTo,
    consultantId,
  ) => {
    //console.log("getDIRSalariesPayByDate............... in the Payments OUT component called !!!!")
    setIsLoading2(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesFrom = new Date(Date.now()-1800).toISOString();

    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "salaries/dirsalariespaidbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
          "/" + 
          //false
          "0"
          ,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",res.data.salariesPayByDate
        //   //res.data.DIRSalariesPayByDate
        // );
        setDIRPaymentsByDateList(res.data.salariesPayByDate);
        // if (myTitle === "Salary Paid") {
        //   updateSalary(res.data.DIRSalariesPayByDate);
        // }
        if (res.data.salariesPayByDate.length === 0) {
          setTotalPaymentsDIRByDate(0);
        } else {
          const totalDIRPayments = res.data.salariesPayByDate.slice(-1);
          setTotalPaymentsDIRByDate(totalDIRPayments[0].cumulative_sum);
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (FIX) Salary Payments by Date OPERATIONAL FIX ONLY------------------------------------
  const getFIXSalariesPayByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {
    //console.log("getFIXSalariesPayByDate............... in the Payments OUT component called !!!!")
    setIsLoading3(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);

    axios
      .get(
        variables.API_URL +
          "salaries/fixsalariespaidbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
          "/" + 
          "0"
          //false
          ,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by Date list...",
        //   res.data.FIXSalariesPayByDate
        // );
        //setFIXPaymentsByDateList(res.data.FIXSalariesPayByDate);
        setFIXPaymentsByDateList(res.data.salariesPayByDate);


          //if (res.data.FIXSalariesPayByDate.length === 0) {
          if (res.data.salariesPayByDate.length === 0) {

          setTotalPaymentsFIXByDate(0);
        } else {
          //const totalFIXPayments = res.data.FIXSalariesPayByDate.slice(-1);
          const totalFIXPayments = res.data.salariesPayByDate.slice(-1);
          //console.log("here is the FIX Total....", totalFIXPayments[0].cumulative_sum)
          setTotalPaymentsFIXByDate(totalFIXPayments[0].cumulative_sum);
        }
        setIsLoading3(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeDatesHandler = () => {
    setChangeDates(!changeDates);
  };

  const getFixOps=()=>{

    if(FIXPaymentsByDateList?.length >0){

      setMySalaryData_FixOps(FIXPaymentsByDateList.filter((data)=>data.TYPECOST_ID===1))
      setMySalaryData_FixNonOps(FIXPaymentsByDateList.filter((data)=>data.TYPECOST_ID===2))


    }

  }

  useEffect(() => {
    setChangeDates(false);
  }, []);


  useEffect(() => {
    getFixOps(FIXPaymentsByDateList);
    //console.log("es el Unoooooooooooooooooo",mySalaryData_FixOps)

  }, [FIXPaymentsByDateList]);


  useEffect(() => {
    //console.log("first time switch...", changeDates);

    if (selectedDatesFromInitial) {
      //console.log("inside the use Effect....", selectedDatesFromInitial);

      // if (!changeDates) {

      if (totalPaymentsSuppByDate.length === 0) {
      }

      getPaymentsOutByDate(selectedDatesFromInitial, selectedDatesTo);

      //getDIRSalariesPayByDate(selectedDatesFromInitial, selectedDatesTo, consultantId,);
      getDIRSalariesPayByDate(selectedDatesFromInitial, selectedDatesTo);
      getFIXSalariesPayByDate(selectedDatesFromInitial, selectedDatesTo);

      //getPaymentsUnallocatedByDate(selectedDatesFromInitial);
      //console.log("is true...!!!");
      // } else {
      //   //console.log("is false...!!!");
      //   const myDateFrom= new Date().getFullYear() +"-01-01";
      //   getPaymentsOutByDate(myDateFrom, selectedDatesTo);
      //   getDIRSalariesPayByDate(myDateFrom, selectedDatesTo);
      //   getFIXSalariesPayByDate(myDateFrom, selectedDatesTo);
      //   //getPaymentsOutByDate("2022-01-01");
      //   //getPaymentsUnallocatedByDate("2022-01-01");
      // }
    }

    //return () => {};
    //  }, [changeDates]);
  }, []);


  // //Separating FIX Cost into Operational and NON operational for Totals--------------------------------------

  // useEffect(()=>{

  //   if(FIXPaymentsByDateList.length > 0){

  //     const FIXPaymentsByDateListFiltered = FIXPaymentsByDateList.filter((item)=>item.TYPECOST_ID === 1)

  //     const FIXPaymentsByDateListFiltered_total = FIXPaymentsByDateListFiltered.reduce(
  //       (prevValue, currentValue) => prevValue + currentValue.TOTALPAID_FIX,0
  //     );

  //     console.log("AFTER REDUCE METHOD just before......",FIXPaymentsByDateListFiltered)
  //     console.log("AFTER REDUCE METHOD...",FIXPaymentsByDateListFiltered_total)

  //   }


  // },[FIXPaymentsByDateList])

  // //End Separating FIX Cost into Operational and NON operationa for Totals-----------------------------------

  return (
    <Fragment>
      <Grid container spacing={3}>

        {/* Recent Deposits */}
        {myTitle !== "NON OPERATIONAL" &&
        <Grid item xs={12} md={6} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 325,
              paddingBottom: 0,
              minWidth: 230,
              //backgroundColor:"#000"
            }}
          >
            {/* <PaymentsIn /> */}
            {/* <PaymentsIn
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                /> */}

           

            <PaymentsOut
              tabInitialDate={selectedDatesFromInitial}
              tabFinalDate={selectedDatesTo}
              myTitle={"Salary Paid"}
              //updateSalary={updateSalary}
            />

            

            {/* <Fragment>
              {!IsLoading1 && !IsLoading2 && !IsLoading3 ? (
                <Deposits
                  title={myTitle}
                  totalPaymentsByDate={
                    myTitle === "Salary Paid"
                      ? totalPaymentsDIRByDate + totalPaymentsFIXByDate
                      : totalPaymentsSuppByDate +
                        totalPaymentsDIRByDate +
                        totalPaymentsFIXByDate
                  }
                  totalPaymentsSuppByDate={totalPaymentsSuppByDate}
                  totalPaymentsDIRByDate={totalPaymentsDIRByDate}
                  totalPaymentsFIXByDate={totalPaymentsFIXByDate}
                  totalPaymentsUnallocatedByDate={0}
                  changeDates={changeDates}
                  changeDatesHandler={changeDatesHandler}
                  icon={
                    <CreditCardOffIcon
                      sx={{
                        fontSize: 20,
                        color: "#4E1721",
                        marginRight: 2,
                        marginTop: -1,
                      }}
                    />
                  }
                />
              ) : (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15} />
                </Box>
              )}
            </Fragment> */}
          </Paper>
        </Grid>

      }

        {/* Recent Receivables */}
        {/* <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  height: 325,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              > */}
        {/* <Outstanding /> */}

        {/* <Receivables
                title="Outstanding"
                totalOutstandingData={{totalOutstanding:allOutsData,totalOutstanding_first50:allOutsData_first50,totalOutstanding_second50:allOutsData_second50, }}
                icon={
                  <PhoneInTalkIcon
                    sx={{
                      fontSize: 20,
                      color: "#4E1721",
                      marginRight: 2,
                      marginTop: -1,
                    }}
                  />
                }
                 /> */}
        {/* </Paper>
            </Grid> */}

        {/* Graphs Purchase by Supplier  Graph */}



        {myTitle !== "NON OPERATIONAL" &&

        !showSalesGraph ? (
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                //height: 270,
                height: "auto",
                paddingBottom: 0,
                minWidth: 230,
                //backgroundColor:"#000"
              }}
            >
              <SalaryChart
               // salaryDataDIR={DIRPaymentsByDateList}
                salaryData={DIRPaymentsByDateList}
                //salaryDataFIX={salaryDataFIX}
                //salaryDataFIXNOP={salaryDataFIXNOP}
                //tabInitialDate={tabInitialDate}
                //tabFinalDate={tabFinalDate}
                reportType={{ type: "ByDIR", typeId: 1, typeSubId: 0, typeSubIdName: "" }}
              />
            </Paper>
          </Grid>
        ) : (
          ""
        )
        
        }

        {/* Graphs Salary by Consultant FIX OPERATIONAL ONLY BAR CHART */}

        {myTitle !== "NON OPERATIONAL" &&

        !showSalesGraph ? (
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                //height: 270,
                height: "auto",
                paddingBottom: 0,
                minWidth: 230,
                //backgroundColor:"#000"
              }}
            >
              <SalaryChart
                //salaryDataDIR={salaryDataDIR}
                //salaryData={FIXPaymentsByDateList}
                salaryData={mySalaryData_FixOps}
                //salaryDataFIX={FIXPaymentsByDateList}
                //salaryDataFIXNOP={salaryDataFIXNOP}
                //tabInitialDate={tabInitialDate}
                //tabFinalDate={tabFinalDate}
                reportType={{ type: "ByFIX", typeId: 22, typeSubId: 1, typeSubIdName: "  Operational" }}
              />
            </Paper>
          </Grid>
        ) : (
          ""
        )
        }


        {/* Graphs Salary by Consultant FIX NON OPERATIONAL ONLY BAR CHART */}

        {

        userInfo.permission === 6 &&
        
        myTitle === "NON OPERATIONAL" &&
        

        !showSalesGraph ? (
          <Grid item xs={12} md={12} lg={12}>
            {/* <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                //height: 270,
                height: "auto",
                paddingBottom: 0,
                minWidth: 230,
              }}
            > */}
              <SalaryChart
                //salaryDataDIR={salaryDataDIR}
                //salaryData={FIXPaymentsByDateList}
                salaryData={mySalaryData_FixNonOps}
                //salaryDataFIX={FIXPaymentsByDateList}
                //salaryDataFIXNOP={salaryDataFIXNOP}
                //tabInitialDate={tabInitialDate}
                //tabFinalDate={tabFinalDate}
                reportType={{ type: "ByFIX", typeId: 22, typeSubId:2, typeSubIdName: "  NON Operational" }}
              />
            {/* </Paper> */}
          </Grid>
        ) : (
          ""
        )

      }







        {/* Graphs related N.2 */}

        {/* <Grid item xs={12}>
              {!isLoading ? (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}> */}
        {/* <DashboardGraph
                    salesByDateList={salesByDateList}
                    salesByDateListTarget={salesByDateListTarget}
                    salesCancelledByDateList={salesCancelledByDateList}
                    //changeDates={changeDates}

                    //changeDatesHandler={changeDatesHandler}
                    // reportVariables={reportVariables}
                    // salesByConsultantList={salesByConsultantList}
                  /> */}
        {/* </Paper>
              ) : (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15} />
                </Box>
              )}
            </Grid> */}

        {/* Recent Activity */}

        <Grid item xs={12}>
          <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}>
            {/* <TableContainer component={Paper}>
                  <MyTable size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>REf</TableCell>
                        <TableCell
                        // align="right"
                        >
                          Total Price
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          Total Outs
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {allOrdersWithPaymentsFull.length > 0
                        ? allOrdersWithPaymentsFull?.map((item) => {
                            if (item.totalOuts > 0.5) {
                              return ( */}
            {/*// <MyTableRow key={Math.random()}>
                                //   <TableCell
                                //     // align="right"
                                //     style={{ fontWeight: 500 }}
                                //   >
                                //     {item.order.CONFIRMATION_REFERENCE}
                                //   </TableCell>

                                //   <TableCell
                                //     style={{
                                //       color: "#4E1721",
                                //       fontWeight: 500,
                                //     }}
                                //     // align="left"
                                //   >
                                //     {/* £{" "} 
                                //     {item.order.TOTAL_AMOUNT.toLocaleString(
                                //       "en-US",
                                //       {
                                //         minimumFractionDigits: 2,
                                //       }
                                //     )}
                                //   </TableCell>

                                //   <TableCell
                                //     style={{
                                //       color: "#4E1721",
                                //       fontWeight: 500,
                                //     }}
                                //     // align="left"
                                //   >
                                //     {/* £{" "} 
                                //     {item.totalOuts.toLocaleString("en-US", {
                                //       minimumFractionDigits: 2,
                                //     })}
                                //   </TableCell>
                                // </MyTableRow>
                //               );
                //             }
                //           })
                //         : "array empty..."}
                //     </TableBody>
                //   </MyTable>
                // </TableContainer>

                {/* 
      <Grid item xs={12}>
    <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}> */}
            {/* <LiveFeeds /> */}
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SalaryReport;
