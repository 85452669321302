import {Fragment, useState} from 'react'
import theme from "./Theme";

//------MUI IMPORTS------------------------------------------
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from '@mui/icons-material/DateRange';

import { MyTextField } from "./styledComponents/StyledComponents";

const localeMap = {
    en: enLocale,
    fr: frLocale,
    de: deLocale,
  };
  
  const maskMap = {
    fr: "__/__/____",
    en: "__/__/____",
    de: "__.__.____",
  };
  
  let reportVariables = {
    //consultantSelected_id: "",
    selectedDatesFrom: "",
    selectedDatesTo: "",
  };



const DateSelector = (props)=>{

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  const [locale, setLocale] = useState("de");

  const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  const [selectedDateTo, setSelectedCDateTo] = useState(null);
  const [notification, setNotification] = useState(null);

  const {dateSelectorHandler}=props


  const handleDatesChangesFromTo = (date, id) => {

    const dateIsValid = date !== null && date !== "Invalid Date";

    if (id === 0) {
      setSelectedCDateFrom(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: date,
        };
      }
    } else if (id === 1) {
      setSelectedCDateTo(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: date,
        };
      }
    } else {
      //console.log("error, date not usable only 2 options available");
      return;
    }

   
  };


  const handleGetReport_2 = ({ reportVariables }) => {
    if (
      // consultantSelected &&
      selectedDateFrom !== null &&
      selectedDateTo !== null &&
      selectedDateFrom !== "Invalid Date" &&
      selectedDateTo !== "Invalid Date"
    ) {
     
      //getAllPaymentsByDate(selectedDateFrom, selectedDateTo);
      //console.log("Go clicked...")
      dateSelectorHandler(selectedDateFrom, selectedDateTo)

      setNotification(null);
      
    } else {

      //console.log("Go clicked No working...")
    

      setNotification("Please select valid data");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };





    return (
      <Fragment>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: matches3?"auto":"1rem"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: matches3 ? "column" : "row",
                alignItems: matches3 ?"center":""
              }}
            >
              <Box
                sx={{
                  width: matches3?"85vw":"auto",
                  height:matches3?"7vh":"6vh",
                  display: "flex",
                  justifyContent: matches3?"space-between":"center",
                  //flexDirection: matches3 ? "column" : "row",
                }}
              >

              <DatePicker
                components={{ OpenPickerIcon: DateRangeIcon }}
                mask={maskMap[locale]}
                format="YYY/MM/dd"
                label="From"
                value={selectedDateFrom}
                onChange={(date) => handleDatesChangesFromTo(date, 0)}
                renderInput={(params) => (
                  <MyTextField
                  size="small"
                  {...params}
                  sx={{
                    margin: matches3 ? "0.5em" : "1em",
                    fontSize: "0.7rem",
                  }}
                  />
                  )}
                  />

              <Box
                sx={{
                  margin: matches3 ? "0.5em" : "1em",
                  textAlign: "center",
                }}
                >
                to
              </Box>

              <DatePicker
                components={{ OpenPickerIcon: DateRangeIcon }}
                label="To"
                value={selectedDateTo}
                onChange={(date) => handleDatesChangesFromTo(date, 1)}
                renderInput={(params) => (
                  <MyTextField
                  size="small"
                  {...params}
                  sx={{
                    margin: matches3 ? "0.5em" : "1em",
                    fontSize: "0.7rem",
                  }}
                  />
                  )}
                  />

              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: matches3?"83vw":"auto",
                  flexDirection: "column",
                  alignTiems: matches3?"stretch":"center"
                }}
              >
                <Button
                  onClick={() => handleGetReport_2({ reportVariables })}
                  variant="outlined"
                  sx={{
                    margin: matches3 ? "0" : "1em",
                    marginBottom: matches3? "1em":"auto",
                    //width: matches3? "62vw":"auto",
                    color: "#7a1125",
                    borderColor: "#7a1125",
                    height: "1.65rem",
                    "&:hover": {
                      borderColor: "#270c10",
                      color: "#fce9ec",
                      backgroundColor: "#7a1125",
                    },
                  }}
                >
                  GO
                </Button>
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </Fragment>
    );
}

export default DateSelector