import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateQuote } from "../../store/quote-slice";

//-------------------IMPORTS MUI---------------------------------------
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";
//import TextField from "@mui/material/TextField";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";

const QuoteStep_1 = (props) => {
  const dispatch = useDispatch();
  //const quoteinfo = useSelector((state) => state.quote);

  const AutoCompleteProjects = useRef();
  const AutoCompleteProducts = useRef();


  
  const { projectList, productList } = props;

  
  //console.log("quote info from state...", quoteinfo);

  // console.log("projectlist from the component quoteStep_1...", projectList);
  // console.log("Productlist from the component quoteStep_1...", productList);

  const [projectListFiltered, setProjectListFiltered] = useState([]);
  const [productListFiltered, setProductListFiltered] = useState([]);
  
  const [enteredInput, setEnteredInput] = useState(null);
  const [enteredInputName, setEnteredInputName] = useState(null);



  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //for the project list
  useEffect(() => {
    if (projectList.length > 0) {
      const myProjectListFiltered = projectList.map((option) => {
        const firstLetter = option.CLUB_NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setProjectListFiltered(myProjectListFiltered);
    }
  }, [projectList]);

  //for product list
  useEffect(() => {
    if (productList.length > 0) {
      const myProductListFiltered = productList.map((option) => {
        const firstLetter = option.product_name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setProductListFiltered(myProductListFiltered);
    }
  }, [productList]);

  const handleQuoteChange = (event, value,name) => {
    
    console.log("value from the function handleProjectsChange...", value);
    
    console.log("name from the function handleProjectsChange...", name );
    

    if (value) {
      dispatch(
        updateQuote({
          [name]: value,
        })
      );
    }
  };


//test to be deleted
const handleInputChange = (value,name) => {
    
  console.log("value from the function handlpriceChange...", value);
  console.log("name from the function handlpriceChange...", name );
  
  setEnteredInput(value);
  setEnteredInputName(name);
  

  
};


useEffect(() => {
  const identifier = setTimeout(() => {
    console.log("Checking input...");
    

    console.log("price info and key", enteredInput, enteredInputName)

    if (enteredInput) {
      dispatch(
        updateQuote({
          [enteredInputName]: enteredInput,
        })
      );
    }else{

      dispatch(
        updateQuote({
          [enteredInputName]: null,
        })
      );

    }


  }, 500);

  return () => {
    // console.log("CleanUp");
    clearTimeout(identifier);
  };
}, [enteredInput, enteredInputName]);




//end test to be deleted


  // const handleProductChange = (event, value) => {
  //   console.log("value from the function handleProductsChange...", value);
  // };

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <Autocomplete
          
          ref={AutoCompleteProjects}
          name="project"
          margin="auto"
          // id="grouped-demo"
          id="project"
          options={projectListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.CLUB_NAME + " " + option.YEAR}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
           onChange={(event, value ) => handleQuoteChange(event, value, AutoCompleteProjects.current.getAttribute("name"))}
          

           renderInput={(params) => <MyTextField {...params} label="Projects" name='project' onSelect={(event)=>console.log(event.target.name)} />}
          

          renderOption={(props, option) => {
            //display value in Popper elements
            
            return (
              <li
                {...props}
                key={option.PROJECT_ID}
              >{`${option.CLUB_NAME}  ${option.YEAR} `}</li>
            );
          }}
        />

        <Divider />

        <Autocomplete
          ref={AutoCompleteProducts}
          name="product"
          margin="auto"
          id="grouped-demo"
          options={productListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.product_name}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event, value, name ) => handleQuoteChange(event, value,  AutoCompleteProducts.current.getAttribute("name"))}
          renderInput={(params) => <MyTextField {...params} label="Products"  />}
        />

        <Divider />

        <MyTextField
          name='price'
          id="outlined-number"
          label="£ Net Price"
          type="number"
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
          onChange={(event)=>handleInputChange(event.target.value, event.target.name)}
          


        />
      </Box>
    </>
  );
};

export default QuoteStep_1;
