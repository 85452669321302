import { Fragment } from "react";

//MUI IMPORTS --------------------------------------
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/styles";

import PaginationItem from '@mui/material/PaginationItem';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { minHeight } from "@mui/system";

//Styled Pagination----------------

const ColumnPagination = styled(Pagination)(({ theme }) => ({
  //  fontSize:"1.8em",

  "& 	.MuiPagination-ul": {
    display: "flex",
    flexDirection: "column",
  },
}));

const MySmallPagination = styled(Pagination)(({ theme }) => ({
    width: "auto",
    margin:"auto",
    marginTop:2,


  "& 	.MuiPagination-ul": {
    display: "flex",
    flexDirection: "row",
  },


  "&  .MuiPaginationItem-sizeSmall":{
    minWidth:"1.3rem",
    height:"1.3rem"
    //minHeight:"1rem"

 }



}));

// const MySmallPaginationItem = styled(PaginationItem)(({ theme }) => ({
//   // width: "auto",
//   // margin:"auto",
//   // marginTop:2,

// "&  .MuiPaginationItem-root":{
//   //backgroundColor:"#000",
//   //fontSize:"10rem"
// },  


// "& 	.MuiPaginationItem-icon": {
//   fontSize:"0.8rem",
//   margin:0,
//   width:"auto"
//   //backgroundColor:"#000"
//   //color:"#000"
//   // display: "flex",
//   // flexDirection: "row",
// },

// "&  .MuiPaginationItem-sizeSmall":{
//    width:"10rem"
// },



// }));

const MyPagination = (props) => {
  const { postsPerPage, totalPosts, paginate, page, matches2 } = props;

  // const pageNmubers=[];
  // for(let i=1; i <= Math.ceil(totalPosts/postsPerPage); i++ ){
  //     pageNmubers.push(i);
  // }

  const pageNumbers = Math.ceil(totalPosts / postsPerPage);

  // const handleChange = ()=>{

  //     console.log("this is my page...")

  // }

  return (
    <Fragment>
      <Stack spacing={2}>
        {matches2 ? (
          <MySmallPagination
          //count={pageNumbers}
          count={pageNumbers}
          page={page}
          onChange={(event, number) => paginate(event, number)}
          color="primary"
          size="small"
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: KeyboardArrowLeftIcon, next: KeyboardArrowRightIcon }}
              {...item}
              sx={{ fontSize:"0.6rem", margin:0}}
            />
          )}
          
        />
          //Version of Vertical Pagination working--------------------------------------------------------------------
          // <ColumnPaginationTest
          //   //count={pageNumbers}
          //   count={pageNumbers}
          //   page={page}
          //   onChange={(event, number) => paginate(event, number)}
          //   color="primary"
          //   size="small"
          //   renderItem={(item) => (
            //     <PaginationItem
            //       components={{ previous: KeyboardArrowUpIcon, next: KeyboardArrowDownIcon }}
            //       {...item}
            //       sx={{ fontSize:"0.6rem", margin:0}}
          //     />
          //   )}
          
          // />
          //END of Version of Vertical Pagination working--------------------------------------------------------------------

        ) : (
          <Pagination
            //count={pageNumbers}
            count={pageNumbers}
            page={page}
            onChange={(event, number) => paginate(event, number)}
            color="primary"
            size="small"
          />
        )}
      </Stack>
    </Fragment>
  );
};

export default MyPagination;
