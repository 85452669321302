import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";



//Table Pagination Code External Function ----------------------------------------------------------
export function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    //const matches = useMediaQuery(theme.breakpoints.down("lg"));
    //const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));
  
    const handleFirstPageButtonClick = (event) => {
      event.preventDefault()
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      event.preventDefault()
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      event.preventDefault()
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      event.preventDefault()
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          
        >
          {theme.direction === 'rtl' ? 
          <LastPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
          <FirstPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? 
          <KeyboardArrowRight sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
          <KeyboardArrowLeft sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? 
          <KeyboardArrowLeft sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
          <KeyboardArrowRight sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? 
          <FirstPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
          <LastPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    
  };
  //END Table Pagination Code External Function ------------------------------------------------------