import React, { useState, useEffect, Fragment } from "react";
//import {io} from 'socket.io-client';
//import {mySocket} from '../utils/socket-client';

import { SocketContext } from "../../utils/socket-client";

import AdminForm from "./AdminForm";
import ShowDateTime from "../UI/ShowDateTime";

//-----REDUX IMPORTS--------------------------------------
import { useSelector, useDispatch } from "react-redux";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";

import DashboardContainer from "../UI/DashboardContainer";
import theme from "../UI/Theme";

//-------MUI IMPOORTS ------------------------------------------
import Button from "@mui/material/Button";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import SpeedIcon from '@mui/icons-material/Speed';

export default function SalesReports() {
  const userInfo = useSelector((state) => state.user);
  const messagesListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );

  const dispatch = useDispatch();

  const mySocket = React.useContext(SocketContext);

  const [socket, setSocket] = useState(null);
  const [messagesList, setMessagesList] = useState(messagesListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );

  const [user, setUser] = useState(userInfo.username);
  const [activeUsers, setActiveUsers] = useState([]);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log("the current user is...quiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", userInfo.username);
  // console.log("the current user is...quiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", userInfo.userPicURL);

  // console.log("the current socket is...", socket);

  useEffect(() => {
    //setSocket(io('http://localhost:5002'));
    setSocket(mySocket);
    //console.log("connecting...");
  }, [mySocket]);

  useEffect(() => {
    socket?.emit("newUser", user);
  }, [socket, user]);

  //getting the list of users online....
  useEffect(() => {
    socket?.on("getActiveUsers", (data) => {
      //console.log("returned data from socket", data.onlineUsers);

      setActiveUsers(data.onlineUsers);
    });
  }, [socket]);

  //console.log("all active users...", activeUsers);

  //Listening to messages--------------------------------
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      // console.log("receiving a message object", data);
      // console.log("receiving a message", data.text);
    });
  }, [socket]);

  //Listening to Notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      // console.log("receiving a notification object", data);
      // console.log("receiving a notification", data.type);
      // console.log("receiving a notification", data.name);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //handle messages
  const handleNotificationText = (text) => {
    //console.log("text sending....");
    socket.emit("sendText", {
      senderName: userInfo.username,
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      text: text,
    });
  };

  //handle notifications
  const handleNotification = (info) => {
    //console.log("handleNotification starting...");

    socket.emit("sendNotification", {
      senderName: userInfo.username,
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
    });
  };

  //console.log("notification text here...", notificationsList);

  return (
    <DashboardContainer
      reportTitle="Admin Module"
      myMarginTop={10}
      icon={
        <SpeedIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
      }
      myWidth={matches3 ? "77.7vw" : "80vw"}
      matches={matches}
      matches2={matches2}
      matches3={matches3}
      myHeight="auto"
    >
      <Typography>Admin Module</Typography>
      <Typography>
        <ShowDateTime/>
      </Typography>


      <Grid item xs={12} md={6} lg={3}>
        <Box
          sx={{
            //p: 2,
            display: "flex",
            flexDirection: "column",
            //height: 270,
            //height: 325,
            height: 335,
            paddingBottom: 0,
            minWidth: "10vw",
            backgroundImage:
              "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludis-Logo-2020_85kTiekBI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650977432909)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Grid>

      {userInfo.permission === 6 && (
        <Fragment>
          <h3>Admin Module Messages... {messagesList}</h3>
          <Button
            onClick={() =>
              handleNotificationText(
                "This is the new Text send by Luis H instead of new text here...by lh"
              )
            }
          >
            Send Message...
          </Button>

          <Button
            onClick={() =>
              handleNotification({
                type: 1,
                name: "Quote",
                notificationDate: new Date().toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }),
                notificationTime: new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }),
              })
            }
          >
            Send quote Notification...
          </Button>
          <Button
            onClick={() =>
              handleNotification({
                type: 1,
                name: "Order",
                notificationDate: new Date().toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }),
                notificationTime: new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }),
              })
            }
          >
            Send Order Notification...
          </Button>
          <Button
            onClick={() =>
              handleNotification({
                type: 1,
                name: "Payment",
                notificationDate: new Date().toLocaleString("default", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }),
                notificationTime: new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }),
              })
            }
          >
            Send Payment Notification...
          </Button>
          {/* <AdminForm/> */}
        </Fragment>
      )}
    </DashboardContainer>
  );
}

new Date().toLocaleString("default", { day: "numeric", month: "short" });
