import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import { paymentPlanActions } from "../../store/paymentPlan-slice";

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";

//My IMPORTS----------------
import theme from "../UI/Theme";
import Title from "../Title";
import { variables } from "../../Variables";

//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const AddPlanSignedStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const currentUserInfo = useSelector((state) => state.user);
  //const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);

  const [newPayPlanSignedStatus, setNewPayPlanSignedStatus] = useState({});

  const { 
    socket, 
    userInfo, 
    showAddPaymentModalHandler, 
    isNewPaymentPlan, 
    isLoading1_PayplanOnOrderHandler, 
    isLoading1_PayPlanOnOrder } = props;

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

    //Mongo info----------------------------------------------------------------------
    const url= variables.API_URL_MONGO + "notifications"

    const createPost = (newPost)=>{    
      axios.post(url, newPost)
      .then((res)=>{
          if(res.status === 201){
              
              //getPost()
          }
      })
   }



  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");

    socket.emit("sendNotification", {
      //senderName: userInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails: {
        "ref": info.notificationDetails.ref, 
        "confoId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_ID,
        "company":info.notificationDetails.company,
        "clientId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CLIENT_ID,
    
      }
    })



    //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: userInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails: {
        "ref": info.notificationDetails.ref, 
        "confoId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_ID,
        "company":info.notificationDetails.company,
        "clientId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CLIENT_ID,
    },
      // receiverName: "All",
    }
    createPost(noty)




  };


  //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  const resetPaymentPlanInfo = ()=>{
    dispatch(paymentPlanActions.resetPaymentPlan({paymentPlanSignedDate: new Date().toISOString(),}))
    // dispatch(
    //   paymentInActions.resetPaymentIn({

    //     //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
    //     paymentDate: new Date().toISOString(),


    //   })
    // );

   }
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

   


  //Function to create (post) the New Payment in the DB ----------
  const addPayPlanSigned =  () => {

    setIsLoading(true)
    isLoading1_PayplanOnOrderHandler(true)

    //alert("the status loading on payplan starting - III..." +  isLoading1_PayPlanOnOrder)


    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
        CONFIRMATION_ID: currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_ID,
        PAYPLAN_DATE_SIGNED: currentPaymentPlanInfo.paymentPlanSignedDate,
        M_USER:currentUserInfo.userId,
        TIME: new Date(),

    };

    //console.log("this is the data for the request on new pay plan signed....",data)

    //Create (post) a New Payment ------------------------------
    axios
      .post(variables.API_URL + "payments/newpayplansigned", data, config)

      .then((response) => {
        setNewPayPlanSignedStatus({
          message: " Payment Plan Signed Recorded Successfully",
          status: true,
        });

        //Submit the notification to the socket - notificationType = 2 for Booking New Invoice.......
        submitNotification({
          //type: 1,
          type: {generalType:1, notificationType:2},
          name: "P. Plan Signed",
          notificationDate: new Date().toLocaleString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }),
          notificationTime: new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: '2-digit',
          }),
          notificationDetails: {
              "ref":currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_REFERENCE , 
              "confoId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_ID,
              "company":currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].COMPANY_NAME},
              "clientId": currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CLIENT_ID,
        });

        //send the paymentID to the parent component to refresh the list of payments including new one-----
        isNewPaymentPlan(Math.random()) 

        isLoading1_PayplanOnOrderHandler(false)

        //Reset Payment In Information----------
        resetPaymentPlanInfo()



        

        setTimeout(() => {
          //setNotification(null);
          const showAddPaymentModalHandler_local = (event)=>showAddPaymentModalHandler(event,3);
          showAddPaymentModalHandler_local()
          //console.log("This is the end message before closing.....")
      }, 1500);

        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewPayPlanSignedStatus({
          message: "  Something went Wrong ! Payment Plan Signed Information NOT saved",
          status: false,
        });

        isLoading1_PayplanOnOrderHandler(false)

       
      });

      setIsLoading(false)
      //isLoading1_PayplanOnOrderHandler(false)


  };
  // end of create the function to create (post) the New User and save It in the DB ----------



  //Step I Creating New User as consultant
  useEffect(() => {
    addPayPlanSigned();
  }, []);


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 


      Object.keys(newPayPlanSignedStatus).length !== 0 ?


        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >




      {
        newPayPlanSignedStatus.status ? (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            <Title sx={{ margin: "auto" }}>
              PAYMENT PLAN SIGNED RECORDED SUCCESSFULLY !
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              PAYMENT PLAN INFORMATION NOT SAVED, CONTACT YOUR ADMIN TEAM !
            </Title>
          </Box>
        )}




          <ListItem>
            {newPayPlanSignedStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}


            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
              <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Payment Status:"
              secondary={
                newPayPlanSignedStatus.message ? newPayPlanSignedStatus.message : "No Info..."
              }
            />
          </ListItem>
        </Box>

        :

        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>




      }
    </Fragment>
  );
};

export default AddPlanSignedStep_3;












// import {Fragment} from 'react';

// const AddPplanSignedStep_3 = ()=>{

//     return(
//         <Fragment>
//             Add P. Plan Signed here....
//         </Fragment>
//     )
// }

// export default AddPplanSignedStep_3;

