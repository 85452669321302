// import { createTheme } from '@material-ui/core/styles';
import { createTheme } from "@mui/material/styles";

const arcBlue = "#0B72B9";
const arcOrange = "#FFBA60";
const okGreen = "#82b74b";
const ludisRed = "#4E1721"; //dark red ludis
const ludisRed2 = "#7A1125"; //light red ludis
const ludisPink1 = "#FCF1F4"; //light pink ludis
//GraphColors
const lineGreen = "#155C2E"; //Green Graph Line
const lineOrange = "#B06B0C"; //Orange Graph Line
const linePurple = "#4A0872"; //Purple Graph Line



export default createTheme({
  palette: {
    common: {
      arcBlue: `${arcBlue}`,
      arcOrange: `${arcOrange}`,
      ludisMain: `${ludisRed}`,
      ludisMain2: `${ludisRed2}`,
      ludisLight1: `${ludisPink1}`,
      okGreen:`${okGreen}`,
      GraphLine1:`${lineGreen }`,
      GraphLine2:`${lineOrange }`,
      GraphLine3:`${linePurple }`,


    },
    primary: {
      main: `${arcBlue}`,
    },
    secondary: {
      // main:`${arcOrange}`
      main: `${ludisRed}`,
    },
  },
  typography: {
    h4: {
      fontWeight: 300,
    },
    h6: {
      fontWeight: 500,
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
    headerInfo: {
      ontFamily: "Roboto",
      fontSize: "1rem",
      textTransform: "none",
      color: `${ludisRed}`,
    },
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
  },
  li:{
    fontSize: "10rem",
  },
  smallScreen:{
    fontSize:"0.5rem",
  },
});
