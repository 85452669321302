import { useState } from "react";
import Login from "./Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//loader Imports--------------
import { HashLoader } from "react-spinners";

import Modal from "react-bootstrap/Modal";

import Typed from "react-typed";

//Design related imports
import "./App.css";
import "./style.css";
//BOOTSTRAP IMPORTS-----------------------------
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import logo1 from "./images_ludis/Logo image.svg";
import Carousel from 'react-bootstrap/Carousel'
// import logo from "./images_ludis/Services-Brochures.png";
import logoS4b from "./images/s4b-logo-single.png";
import placeholder1 from "./images_ludis/HOWLEYHALL__2020_COVER.jpg";
import placeholder5 from "./images_ludis/WPNSA_2020_COVER.jpg";
import placeholder6 from "./images_ludis/WORCESTERSHIRE_2017_COVER.jpg";
import placeholder4 from "./images_ludis/WimbledonCommon_2021_COVER.jpg";
import placeholder2 from "./images_ludis/TRURO_2020_COVER.jpg";
import placeholder3 from "./images_ludis/ROYALCROMER_2020_COVER.jpg";
// import screenshot from "./images_ludis/LanglandBay2020_COVER.jpg";

import { variables } from "./Variables";

axios.defaults.withCredentials = true;

export default function Home() {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState("");

  const navigate = useNavigate();

  const goToLogin = (event) => {
    isloggedin();
  };

  //funcion to check if you are logged in already
  const isloggedin = () => {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    };

    //axios.get(variables.API_URL, {withCredentials: true})
    // .get("http://localhost:5000/checkingisloggedin", config)
    //.get("https://ludisgroup-erp-back.herokuapp.com/checkingisloggedin", config)
    axios
      .get(variables.API_URL + "checkingisloggedin", config)
      .then((res) => {
        setUser(res.data);
        //console.log("here is the response data...", res);
        //console.log("here is the response user name...", res.data.user);
        //forward it to the home page
        navigate("/main", { userInfo: { user } });
      })
      .catch((error) => {
        setUser("");
      })
      .then(function () {
        setLogin(!login);
      });
  };

  return (
    <>
      <header className="home-header">
        <div className="container">
          <nav className="row pt-3 ">
            <div className="col-sm-2 col-6 text-center mb-5">
              <a href="https://www.ludisgroup.co.uk/">
                <img
                  src={logo1}
                  alt="logo"
                  className="col-sm-6 col-6 text-center mb-0"
                />
                <br />
                Ludis Publishing Group
              </a>
            </div>
            <div className="col-sm-2 col-6 text-center mb-5">
              <a href="#modules">
                <i className="bi bi-calendar2-week"></i>
                <br />
                Publications
              </a>
            </div>
            <div className="col-sm-2 col-6 text-center mb-5">
              <a href="#presentation">
                <i className="bi bi-activity"></i>
                <br />
                Products
              </a>
            </div>
            <div className="col-sm-2 col-6 text-center mb-5">
              <a href="#mobile">
                <i className="bi bi-phone"></i>
                <br />
                News
              </a>
            </div>
            <div className="col-sm-2 col-6 text-center mb-5">
              <a href="#contact">
                <i className="bi bi-journal-arrow-up"></i>
                <br />
                Contact
              </a>
            </div>

            <div className="col-sm-2 col-6 text-center mb-5">
              {/* <a href="#" onClick={goToLogin}> */}
              <button className="login-button" href="#" onClick={goToLogin}>
                {login ? (
                  <>
                    <i className="bi bi-arrow-bar-left" onClick={goToLogin}></i>{" "}
                    <br />
                    Back
                  </>
                ) : (
                  <>
                    <i
                      className="bi bi-box-arrow-in-right"
                      onClick={goToLogin}
                    ></i>{" "}
                    <br />
                    Login
                  </>
                )}
              </button>
            </div>
          </nav>

          <div>
            {login ? (
              user ? (
                <div className="spinner">
                  <HashLoader />
                </div>
              ) : (
                <>
                  <div className="modal-container">
                    <Modal
                      show={login}
                      size="lg"
                      onHide={() => setLogin(false)}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header
                        closeButton
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={logo1}
                          alt="logo"
                          className="col-md-2 col-2 text-center mb-0"
                        />
                        <Modal.Title
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            margin: "auto",
                          }}
                        >
                          <p className="headerLogin">
                            Welcome to Ludis Members Area
                          </p>
                        </Modal.Title>
                      </Modal.Header>

                      <Login />

                      <Modal.Footer>
                        <div className="form-footer">
                          <img src={logoS4b} alt="logo" className="s4b-logo" />
                          <p className="mt-3 mb-3 text-muted">
                            Power by S4B-Consulting &copy; 2021
                          </p>
                        </div>
                        <Button variant="secondary" size="sm">
                          Forgot your Password?
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )
            ) : (
              <div className="main-info">
                <div className="row">
                  <div className="col-md-6 offset-md-3 text-center pt-5 mt-5 pb-5 mb-5">
                    {/* <img src={logo} className="img-fluid" alt="logo" /> */}
                    <img
                      src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Services-Brochures_R0a68ccn8.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648723850222"
                      className="img-fluid"
                      alt="logo"
                    />
                  </div>
                </div>

                <h1>High Quality Sports Publications</h1>
                <Typed
                  className="typed-text"
                  strings={[
                    "Marketing Collateral",
                    "Brochures",
                    "Programmes",
                    "Yearbooks",
                    "Magazines",
                    "Special event publications",
                  ]}
                  typeSpeed={40}
                  backSpeed={60}
                  loop
                />
                <a href="#modules" className="btn-main-offer">
                  Our Products
                </a>
              </div>
            )}
          </div>
        </div>
      </header>

      <main className="main-samples" id="modules">
        <div className="container-sm">
          <div className="row pt-5 pb-5">
            <div className="col-md-4 p-2">
              {/* <img src={placeholder1} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/HOWLEYHALL__2020_COVER_O4I0zPZkZd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729510073" alt="placeholder" className="img-fluid" />

            </div>
            <div className="col-md-4 p-2">
              {/* <img src={placeholder2} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/ROYALCROMER_2020_COVER_YAJMxf_oy.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729526827" alt="placeholder" className="img-fluid" />

            </div>
            <div className="col-md-4 p-2">
              {/* <img src={placeholder3} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/TRURO_2020_COVER_XjZefnMJ4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729575707" alt="placeholder" className="img-fluid" />
            </div>
            <hr style={{ fontSize: "50px", color: "#ffffff" }} />
            <hr style={{ fontSize: "50px", color: "#ffffff" }} />

            <div className="col-md-4 p-2">
              {/* <img src={placeholder4} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/WimbledonCommon_2021_COVER_stzH3t0cO.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729578258" alt="placeholder" className="img-fluid" />
            </div>

            <div className="col-md-4 p-2">
              {/* <img src={placeholder5} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/WPNSA_2020_COVER_DCdQb7eWK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729896658" alt="placeholder" className="img-fluid" />
            </div>

            <div className="col-md-4 p-2">
              {/* <img src={placeholder6} alt="placeholder" className="img-fluid" /> */}
              <img src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/WORCESTERSHIRE_2017_COVER_EsiizaaMT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648729862728" alt="placeholder" className="img-fluid" />
            </div>
          </div>
        </div>
      </main>

      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center">
              <i
                className="bi bi-command"
                style={{ fontSize: "50px", color: "#5b4bf0" }}
              ></i>
              <br />
              Works on Mac
            </div>
            <div className="col-md-3 text-center">
              <i
                className="bi bi-windows"
                style={{ color: "#6454ed", fontSize: "50px" }}
              ></i>
              <br />
              Works on Windows
            </div>
            <div className="col-md-3 text-center">
              <i
                className="bi bi-bootstrap"
                style={{ fontSize: "50px", color: "#7564e6" }}
              ></i>
              <br />
              Scalable Design
            </div>
            <div className="col-md-3 text-center">
              <i
                className="bi bi-window"
                style={{ fontSize: "50px", color: "#8877df" }}
              ></i>
              <br />
              Responsive
            </div>
          </div>
        </div>
      </section>
      <section id="presentation">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-12">
            {/* carousel start */}
            <Carousel>
              <Carousel.Item>
                <img
                  // className="d-block w-80"
                  className="carouselPics"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/services_houd6g2kJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648729090793"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>Sports Publications</h3>
                  <p>
                    Official Brochures, by Ludis Group.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  className="carouselPics"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/magazine-sample_Um4rRrUqR.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648729089090"
                  alt="Second slide"
                />

                <Carousel.Caption>
                  <h3>Magazines</h3>
                  <p>
                  Official Programs, by Ludis Group.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  className="carouselPics"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/otherproducts-sample_GbfyNGaqU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648729089983"
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h3>Business Branding Stationery</h3>
                  <p>
                  Taylor made design, by Ludis Group.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  // className="d-block w-100"
                  className="carouselPics"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/cards-sample_UP0ucUzpj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1648729089003"
                  alt="4th slide"
                />

                <Carousel.Caption>
                  <h3>Business Cards</h3>
                  <p>
                  Full Designed, by Ludis Group.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>

            </Carousel>
            {/* carousel end */}

            {/* <img src={screenshot} className="img-fluid" alt="imac" /> */}
          </div>
        </div>
      </section>
      <section id="mobile">
        <div className="container">
          <div className="row vh-100 d-flex align-items-center">
            <div className="col-md-4">
              <h2>Latest News...</h2>
              <br />
              <p>
                Latest news in the sports and golf industry and internal news
                too!
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer className="text-center p-5" id="contact">
        <h2>Contact us</h2>

        <div className="container">
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              <div id="emailHelp" className="form-text">
                We'll never share your email with anyone else.
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Message</label>
              <textarea className="form-control"></textarea>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </footer>
    </>
  );
}
