import {Fragment, useState} from 'react'


import SalaryList from './SalaryList'
import MyTabsGen from "../UI/MyTabsGeneral";

const SalaryListMenu = ()=>{

    const [wagesType, setWagesType]=useState(1)
    const [reportTitle, setReportTitle]=useState("")
    const [reportTitleShort, setReportTitleShort]=useState("")


    const changeTabsHandler = (index) => {
       
        if (index === 0) {
            //console.log("you have clicked...DIR")
    
          setWagesType(1);
          setReportTitle("Salary Report - DIR ...")
          setReportTitleShort("ByDIR")
          
        } else if (index === 1) {
            //console.log("you have clicked...FIX")
            setWagesType(2);
            setReportTitle("Salary Report - FIX ...")
            setReportTitleShort("ByFIX")
          
        }   else { } 
      };

    return(
        <Fragment>
            <MyTabsGen changeTabsHandler={changeTabsHandler} >

                <SalaryList reportTitle={reportTitle} reportTitleShort={reportTitleShort} wagesType={wagesType} />

            </MyTabsGen>
        </Fragment> 
    )
}

export default SalaryListMenu