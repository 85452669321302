import { useState, useEffect } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "../Chart";

//import Typography from "@mui/material/Typography";
//import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";

import "core-js/actual/array/group-by";

export default function Graph(props) {
  // function Copyright(props) {
  //   return (
  //     <Typography
  //       variant="body2"
  //       color="text.secondary"
  //       align="center"
  //       {...props}
  //     >
  //       {"Copyright © "}
  //       <Link color="inherit" href="https://s4b-consulting.de" target="blank">
  //         S4B Consulting
  //       </Link>{" "}
  //       {new Date().getFullYear()}
  //       {"."}
  //     </Typography>
  //   );
  // }

  const { salesByConsultantList } = props;
  const [salesGraphData, setSalesGraphData] = useState([]);
  const [changeGraphData, setChangeGraphData] = useState(null);

  // console.log(
  //   "starting fulllist sales by consultant by date",
  //   salesByConsultantList, "switch is...", changeGraphData
  // );

  //test to be deleted 

  useEffect(()=>{

    setChangeGraphData(false)

  },[])
  

    

  //test to be deleted end

  // let graphDate = new Date(item.DATE).toLocaleString("default", {
  //   day: "numeric",
  //   month: "short",
  // })



  const changeGraphDataHandler = () => {
    setChangeGraphData(!changeGraphData);
  };

  const monthlyGraphHandler = async () => {
    if (salesByConsultantList) {
      //console.log(new Date(salesByConsultantList[1].DATE).getMonth()+1)

      let salesByConsultantByDate_Monthly = [];
      await salesByConsultantList.reduce(
        (res, order) => {
          if (!res[new Date(order.DATE).getMonth()]) {
            res[new Date(order.DATE).getMonth()] = {
              DATE: new Date(order.DATE).getMonth(),
              amountNet: order.NET_AMOUNT,
            };
            salesByConsultantByDate_Monthly.push(
              res[new Date(order.DATE).getMonth()]
            );
          } else {
            res[new Date(order.DATE).getMonth()].amountNet =
              res[new Date(order.DATE).getMonth()].amountNet + order.NET_AMOUNT;
          }

          return res;
        },
        [{}]
      );

      // console.log(
      //   "SECOND REDUCER EXCERCISE from Button...............",
      //   salesByConsultantByDate_Monthly
      // );
      setSalesGraphData(salesByConsultantByDate_Monthly);
    }
  };

  // console.log("From Graph.js .... to group by...", salesByConsultantList);

  // const groupByCategory = salesByConsultantList.groupBy((item) => {
  //   return item.DATE;
  // });
  // console.log(groupByCategory);

  const dailyGraphHandler = async () => {
    let salesByConsultantByDate = [];
    await salesByConsultantList.reduce(
      (res, order) => {
        if (!res[order.DATE]) {
          res[order.DATE] = { DATE: order.DATE, amountNet: order.NET_AMOUNT };
          salesByConsultantByDate.push(res[order.DATE]);
        } else {
          res[order.DATE].amountNet =
            res[order.DATE].amountNet + order.NET_AMOUNT;
        }

        return res;
      },
      [{}]
    );

    if(salesByConsultantList.length > 0){
      //dailyGraphHandler();
    }

    setSalesGraphData(salesByConsultantByDate);

    //console.log("SECOND REDUCER EXCERCISE...............", salesByConsultantByDate); // sumAmount: {editor: 57, admin: 56}
    // console.log(
    //   "SALES GRAPH DATA FROM GRAPH COMPONENT...BEFORE PASSING IT OVER TO CHART COMPONENT...............",
    //   salesGraphData
    // ); // sumAmount: {editor: 57, admin: 56}
  };




  useEffect(() => {

    //console.log("first time switch...", changeGraphData);

    if (salesByConsultantList.length>0){

      
      if (changeGraphData) {
        monthlyGraphHandler();
        //console.log("is true...!!!");
      } else {
        //console.log("is false...!!!");
        dailyGraphHandler();
      }
    
    }
      
    //return () => {};

  }, [changeGraphData]);




  return (
    <Box
      //component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        //height: "100vh",
        //overflow: "auto",
        height: salesByConsultantList.length > 0 ? 400 : 0,
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                //height: 240,
                height: salesByConsultantList.length > 0 ? 240 : 0,
              }}
            >
             
              <Chart
              salesByConsultantList={salesByConsultantList}
              //salesByConsultantByDate={salesByConsultantByDate}
              salesByConsultantByDate={salesGraphData}
              salesGraphData={salesGraphData}
              changeGraphData={changeGraphData}
              />
              
            
            </Paper>
          </Grid>
          <Button
            onClick={() => changeGraphDataHandler()}
            sx={{ color: "#7A1125", textTransform: "capitalize" }}
          >
            <DateRangeTwoToneIcon
              sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
            />
            {changeGraphData ? "View Daily" : "View Monthly"}
          </Button>
          {/* <Button
            onClick={()=>dailyGraphHandler()}
            sx={{ color: "#7A1125", textTransform: "capitalize" }}
          >
            <DateRangeTwoToneIcon
              sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
            />
            daily
          </Button> */}
        </Grid>

        <Divider />

        {/* <Button>By Month</Button> */}
        {/* <Copyright sx={{ pt: 4 }} /> */}
      </Container>
    </Box>
  );
}
