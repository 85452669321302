import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
//import { paymentInActions } from "../../store/payment-slice";
import { purchasePaymentOutActions } from "../../store/purchasePayment-slice";

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";

//My IMPORTS----------------
import theme from "../UI/Theme";
import Title from "../Title";
import { variables } from "../../Variables";

//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const AddPurchasePaymentStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const currentUserInfo = useSelector((state) => state.user);
  const currentPurchasePaymentInfo = useSelector((state) => state.purchasePaymentOut.purchasePaymentOutInfo);
  const currentSupplierSelected = useSelector((state) => state.supplier.supplierInfo);

  const [newPurchasePaymentStatus, setNewPurchasePaymentStatus] = useState({});

  const { socket, showPurchasePaymentModalHandler, isUpdatedPurchasePayment } = props;

  const matches = useMediaQuery(theme.breakpoints.down("sm"));


  //Mongo info----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"

  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
        if(res.status === 201){
            
            //getPost()
        }
    })
 }




  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");

//     socket.emit("sendNotification", {
//       senderName: currentUserInfo.username,
//       senderPic: currentUserInfo.userPicURL,
//       receiverName: "All",
//       type: info.type,
//       name: info.name,
//       notificationDate: info.notificationDate,
//     });
//   };

    socket.emit("sendNotification", {
    //senderName: currentUserInfo.username,
    senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
    senderPic: currentUserInfo.userPicURL,
    receiverName: "All",
    type: info.type,
    name: info.name,
    notificationDate: info.notificationDate,
    notificationTime: info.notificationTime,
    notificationDetails_Purchase: {
        "invoice": info.notificationDetails_Purchase.invoice, 
        "invoiceId": currentPurchasePaymentInfo?.selectedPurchaseInvoice[0].PURCHASEINVOICE_ID,
        "supplier":info.notificationDetails_Purchase.supplier,
        "supplierId":currentSupplierSelected.selectedSupplier.SUPPLIER_ID,
        "purchasePaid":info.notificationDetails_Purchase.purchasePaid,
        "isSupplier": info.notificationDetails_Purchase.isSupplier,
    }
  })

      //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: currentUserInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      //notificationDetails_Purchase: {
      notificationDetails:{  
        "invoice": info.notificationDetails_Purchase.invoice, 
        "invoiceId": currentPurchasePaymentInfo?.selectedPurchaseInvoice[0].PURCHASEINVOICE_ID,
        "supplier":info.notificationDetails_Purchase.supplier,
        "supplierId":currentSupplierSelected.selectedSupplier.SUPPLIER_ID,
        "purchasePaid":info.notificationDetails_Purchase.purchasePaid,
        "isSupplier": info.notificationDetails_Purchase.isSupplier,
    },
      // receiverName: "All",
    }
    createPost(noty)




};


  //Reset Purchase Payment In Slice to initial values function to be used after payment is recorded -----
  const resetPurchasePaymentInfo = () => {
    dispatch(
      purchasePaymentOutActions.resetPurchasePaymentOut({
        purchasePaymentDate: new Date().toISOString(),
      })
    );

  };
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

   



  //Function to create (post) the New Payment in the DB ----------
  const createNewPurchasePayment = () => {

    setIsLoading(true)


    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
        PURCHASEINVOICE_ID: currentPurchasePaymentInfo?.selectedPurchaseInvoice[0].PURCHASEINVOICE_ID,
        PAYDATE: currentPurchasePaymentInfo.purchasePaymentDate,
        TOTALPAID: currentPurchasePaymentInfo.purchaseInvoiceAmountPaid,
        PAYREF:currentPurchasePaymentInfo.purchasePaymentReference,
        SUPPLIER_ID:currentSupplierSelected.selectedSupplier.SUPPLIER_ID,
        PAYTYPE_ID:currentPurchasePaymentInfo.purchasePaymentType.PAYTYPE_ID,
        FIRMA_ID:4,
        VAT_FACTOR:"1.2",
        M_USER:currentUserInfo.userId,
        TIME: new Date(),

    };

    //console.log("this is the data for the request....",data)

    //Create (post) a New Payment ------------------------------
    axios
      .post(variables.API_URL + "purchase/newpurchasepayment", data, config)

      .then((response) => {
        setNewPurchasePaymentStatus({
          message: " Purchase Payment saved Successfully",
          status: true,
        });

        //Submit the notification to the socket.......
        // submitNotification({
        //   type: 1,
        //   name: "Payment",
        //   notificationDate: new Date().toLocaleString("default", {
        //     day: "numeric",
        //     month: "short",
        //     year: "numeric",
        //   }),
        // });

        //Submit the notification to the socket  - notificationType = 4 for New Purchase Supplier Payment..............
        submitNotification({
            //type: 1,
            type: {generalType:1, notificationType:4},
            name: "Purchase Payment",
            notificationDate: new Date().toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            notificationTime: new Date().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: '2-digit',
            }),
            notificationDetails_Purchase: {
                "invoice": currentPurchasePaymentInfo?.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER , 
                "invoiceId": currentPurchasePaymentInfo?.selectedPurchaseInvoice[0].PURCHASEINVOICE_ID,
                "supplier": currentSupplierSelected.selectedSupplier.SUPPLIER_NAME,
                "supplierId":currentSupplierSelected.selectedSupplier.SUPPLIER_ID,
                "purchasePaid" : currentPurchasePaymentInfo.purchaseInvoiceAmountPaid,
                "isSupplier" : true,
            }
                //"purchasePayment" : currentPurchasePaymentInfo.purchaseInvoiceAmountPaid,

          });

        //send the paymentID to the parent component to refresh the list of payments including new one-----
        isUpdatedPurchasePayment(Math.random())



        //Reset Payment In Information----------
        resetPurchasePaymentInfo()

        

        setTimeout(() => {
          //setNotification(null);
          const showPurchasePaymentModalHandler_local = (event)=>showPurchasePaymentModalHandler(event,1);
          showPurchasePaymentModalHandler_local()
          //showAddPaymentModalHandler(1);
          //console.log("This is the end message before closing.....")
      }, 2000);

        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewPurchasePaymentStatus({
          message: "  Something went Wrong ! Purchase Payment NOT saved",
          status: false,
        });

      });

      setIsLoading(false)


  };
  // end of create the function to create (post) the New User and save It in the DB ----------



  //Step I Creating New User as consultant
  useEffect(() => {
    createNewPurchasePayment();
  }, []);


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 

      Object.keys(newPurchasePaymentStatus).length !== 0 ?



        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >




{
        newPurchasePaymentStatus.status ? (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            <Title sx={{ margin: "auto" }}>
              PURCHASE PAYMENT SUCCESSFULLY SAVED !
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              PURCHASE PAYMENT NOT SAVED, CONTACT YOUR ADMIN TEAM !
            </Title>
          </Box>
        )}




          <ListItem>
            {newPurchasePaymentStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}


            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
              <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Payment Status:"
              secondary={
                newPurchasePaymentStatus.message ? newPurchasePaymentStatus.message : "No Info..."
              }
            />
          </ListItem>
        </Box>

        : 
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      }
    </Fragment>
  );
};

export default AddPurchasePaymentStep_3;
