import React, { useState, useEffect, Fragment } from "react";
import { Outlet } from "react-router-dom";

//----------REDUX IMPORTS --------------------------------
import { useSelector } from "react-redux";

//----------MUI IMPORTS ----------------------------------
import { styled } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
//import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Avatar from "@mui/material/Avatar";
//import ListItemButton from "@mui/material/ListItemButton";

//new Icons for Ludis ...
import DashboardIcon from "@mui/icons-material/Dashboard";
import LaptopIcon from "@mui/icons-material/Laptop";
import CallIcon from "@mui/icons-material/Call";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import SellIcon from "@mui/icons-material/Sell";
import GroupsIcon from "@mui/icons-material/Groups";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";

import MainDashboardOptions from "./MainDashboardOptions";
import MainDashboardOptions_Management from "./MainDashboardOptions_Management";
import MainDashboardOptions_Management_Ops from "./MainDashboardOptions_Management_Ops";

import theme from "./UI/Theme";
import logo from "../images_ludis/Logo image.svg";
import DisplayNotifications from "./UI/DisplayNotifications";

const drawerWidth = 240;

//const useStyles = makeStyles((theme) => ({}));

//Styled Toolbar----------------
const MyToolbar = styled(Toolbar)(({ theme }) => ({
  ...theme.mixins.toolbar,

  border: `1px solid ${theme.palette.divider}`,

  height: "5.5em",
  [theme.breakpoints.down("md")]: {
    height: "4em",
  },
  [theme.breakpoints.down("xs")]: {
    height: "3em",
  },
}));

//Styled Toolbar Div----------------
const ToolDiv = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  marginBottom: "3em",
  [theme.breakpoints.down("md")]: {
    marginBottom: "2em",
  },
  [theme.breakpoints.down("xs")]: {
    marginBottom: "1.25em",
  },
}));

//Styled Logout Button-----------
const MyLogo = styled(Box)(({ theme }) => ({
  height: "5.5em",
  [theme.breakpoints.down("md")]: {
    height: "4em",
  },
  [theme.breakpoints.down("sm")]: {
    height: "3em",
  },
}));

//Styled Logo -----------
const LogoutButton = styled(Button)(({ theme }) => ({
  ...theme.typography.estimate,
  borderRadius: "50px",
  marginLeft: "50px",
  marginRight: "25px",
  height: "45px",
  backgroundColor: theme.palette.common.ludisMain,
  "&:hover": {
    opacity: 0.7,
    backgroundColor: theme.palette.common.ludisMain2,
  },
}));

//const AppBar = styled(MuiAppBar, {
const MyAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: theme.palette.common.ludisMain2,
  ...theme.typography.estimate,
  height: "90px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "70px",
    marginLeft: 0,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "60px",
    maringLeft: 0,
  },
  zIndex: theme.zIndex.modal + 1,

  "& .MuiIconButton-root": {
    color: "#fff",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },

    height: "3rem",
    width: "3rem",
    [theme.breakpoints.down("md")]: {
      height: "2.5rem",
      width: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "2rem",
      width: "2rem",
    },
  },

  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#fff",
    boxSizing: "border-box",

    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

//Styled SwipeableDrawer--------------------------------

const MySwipeableDrawer = styled(SwipeableDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    // backgroundColor: theme.palette.common.ludisMain2,
  },
}));

//ListItemButton Styled -----------------------------------
// const MyListItemButton = styled(ListItemButton, {
//   shouldForwardProp: (prop) => prop !== "selected",
// })(({ theme, selected }) => ({
//   textDecoration: "none",

//   "& .MuiListItemText-root": {
//     ...theme.typography.estimate,
//     // color: "#fff",
//     color: theme.palette.common.ludisMain2,
//     opacity: 0.7,
//     fontSize: "1.2rem",
//     //padding: "0 24px 10px 16px",

//     "&:hover": {
//       backgroundColor: "transparent",
//       // color: "#c7c0bf",
//       color: theme.palette.common.ludisMain2,
//     },
//     ...(selected && {
//       opacity: 1,
//       // color: "#fff",
//       color: theme.palette.common.ludisMain,
//     }),
//   },
// }));

// const mdTheme = createTheme();

//Component Starting here--------------------------------------------------------------------------------------------------------------------

function DashboardContent(props) {
  const userInfo = useSelector((state) => state.user);
  const messagesList = useSelector((state) => state.notifications.messages);
  const notificationsList = useSelector(
    (state) => state.notifications.notificationsType
  );

  const [showNotifications, setShowNotifications] = useState(false);

  // console.log("notifications lis...", notificationsList);
  // console.log("notifications messages...", messagesList);
  // console.log("User info aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii...", userInfo);

  //const classes = useStyles();

  //console.log("redux state for messageList...", messagesList);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenMenu(true);
  };

  const closeSmallDrawer=(event)=>{
     event.preventDefault()
     //console.log("I am closing it.......................")
     setOpenDrawer(false)
  }

  const ludisRoutes = [
    {
      name: "Dashboard",
      // link: "/main/dash-main-content",
      link: "/main",
      icon: <DashboardIcon />,
      activeIndex: 0,
    },
    {
      //   name: "Admin",
      name: "Services",
      link: "/main",
      icon: <LaptopIcon />,
      activeIndex: 1,
      ariaOwns: anchorEl ? "simple-menu" : undefined,
      ariaPopup: anchorEl ? true : undefined,
      mouseOver: (event) => handleClick(event),
    },
    {
      name: "Credit Control",
      link: "/main/creditcontrol",
      icon: <CallIcon />,
      activeIndex: 2,
    },
    { name: "sales", link: "/main/sales", icon: <SellIcon />, activeIndex: 3 },
    {
      name: "Production",
      link: "/main",
      icon: <ArchitectureIcon />,
      activeIndex: 4,
    },
    {
      name: "Purchase",
      link: "/main",
      icon: <ShoppingCartIcon />,
      activeIndex: 5,
    },
    {
      name: "Reports",
      link: "/main/reports",
      icon: <BarChartIcon />,
      activeIndex: 6,
      options: [
        {
          name: "Sales",
          link: "/main/customsoftware",
          activeIndex: 6,
          selectedIndex: 61,
        },
        {
          name: "Payments",
          link: "/main/customsoftware",
          activeIndex: 6,
          selectedIndex: 62,
        },
        {
          name: "Purchase",
          link: "/main/customsoftware",
          activeIndex: 6,
          selectedIndex: 63,
        },
      ],
    },
    { name: "Salary", link: "/main", icon: <GroupsIcon />, activeIndex: 7 },
  ];

  // const routes = [
  //   { name: "main", link: "/main", activeIndex: 0 },
  //   {
  //     name: "Services",
  //     link: "/main/services",
  //     activeIndex: 1,
  //     ariaOwns: anchorEl ? "simple-menu" : undefined,
  //     ariaPopup: anchorEl ? true : undefined,
  //     mouseOver: (event) => handleClick(event),
  //   },
  //   { name: "The Revolution", link: "/main/revolution", activeIndex: 2 },
  //   { name: "About Us", link: "/main/about", activeIndex: 3 },
  //   { name: "Contact Us", link: "/main/contact", activeIndex: 4 },
  // ];

  const menuOptions = [
    // { name: "Services", link: "/main/services", activeIndex: 1, selectedIndex: 0 },
    {
      name: "Custom Software Development",
      link: "/main/customsoftware",
      activeIndex: 1,
      selectedIndex: 1,
    },
    {
      name: "Mobile App Development",
      link: "/main/mobileapps",
      activeIndex: 1,
      selectedIndex: 2,
    },
    {
      name: "Website Development",
      link: "/main/websites",
      activeIndex: 1,
      selectedIndex: 3,
    },
  ];

  const path = typeof window !== "undefined" ? window.location.pathname : null;

  const activeIndex = () => {
    // const found = routes.find(({ link }) => link === path);
    const found = ludisRoutes.find(({ link }) => link === path);

    const menuFound = menuOptions.find(({ link }) => link === path);

    if (menuFound) {
      props.setValue(1);
      props.setSelectedIndex(menuFound.selectedIndex);
    } else if (found === undefined) {
      props.setValue(false);
    } else {
      props.setValue(found.activeIndex);
    }
  };

  useEffect(() => {
    activeIndex();
  }, [path]);

  //Drawer code for small screens------------------------------------------
  const drawerSmall = (
    <Fragment>
      <MySwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        //setOpenDrawer={()=>setOpenDrawer(false)}
      >
        {/* <div className={classes.toolbarMargin} /> */}
        <ToolDiv />

        {/* <MainDashboardOptions
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
        /> */}

        <List>
        {userInfo.permission === 6 && 
        <MainDashboardOptions
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          closeSmallDrawer={closeSmallDrawer}
          matches={matches}
          
        />
        }
        
        {userInfo.permission === 5  && <MainDashboardOptions_Management_Ops
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          matches={matches}
        />}

         {userInfo.permission < 5  && <MainDashboardOptions_Management
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          matches={matches}
        />}

      </List>

      </MySwipeableDrawer>
    </Fragment>
  );
  //Drawer code for small screens------------------------------------------

  //Drawer code for large screens------------------------------------------
  const drawerLarge = (
    <Drawer variant="permanent" open={open}>
      <MyToolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </MyToolbar>

      <List>
        {/* Full permission---------------------------  */}
        {userInfo.permission === 6 && 
        <MainDashboardOptions
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          matches={matches}
        />
        }
        {/* Admin and Management permission---------------------------  */}
          {userInfo.permission === 5  && <MainDashboardOptions_Management_Ops
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          matches={matches}
        />}

        {/* others permission---------------------------  */}
          {userInfo.permission < 5  && <MainDashboardOptions_Management
          value={props.value}
          setValue={props.setValue}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          matches={matches}
        />}

      </List>

      <Divider />
    </Drawer>
  );
  //Drawer code for large screens------------------------------------------

  //Function to show / hide notifications -------------------------
  const HandleShowNotifications = () => {
    setShowNotifications((prev) => !prev);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          backgroundImage:
            "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        }}
      >
        <CssBaseline />

        <MyAppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            {matches ? (
              <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                disableRipple
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                // color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                {open ? "" : <MenuIcon />}
              </IconButton>
            )}

            <MyLogo component="img" src={logo} alt="logo pic" />

            <Typography
              variant="h4"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Ludis Dashboard
            </Typography>
            {showNotifications && <DisplayNotifications />}
            <IconButton color="inherit" onClick={HandleShowNotifications}>
              {notificationsList.length > 0 ? (
                <Badge badgeContent={notificationsList.length} color="primary">
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>

            {/* {showNotifications && 
            <DisplayNotifications/>
            } */}

            <IconButton color="inherit">
              {messagesList.length > 0 ? (
                <Badge badgeContent={messagesList.length} color="primary">
                  <MailIcon />
                </Badge>
              ) : (
                <MailIcon />
              )}
            </IconButton>

            <LogoutButton variant="contained" onClick={props.onLogout}>
              Logout
            </LogoutButton>

            <Box sx={{display:'flex', flexDirection: matches ? 'row': 'column', justifyContent:'center', alignItems:'center', marginTop: matches? 0.5 :3}}>
              <Avatar alt="user pic" src={userInfo.userPicURL} />
              {matches? " " : (userInfo.firstName).charAt(0) + (userInfo.firstName.toLowerCase()).slice(1, userInfo.username.lenght) + " " + (userInfo.lastName).charAt(0)  }
              {/* {matches? " " : (userInfo.username).charAt(0) + (userInfo.username.toLowerCase()).slice(1, userInfo.username.lenght)  } */}

            </Box>
          </Toolbar>
        </MyAppBar>

        {matches ? drawerSmall : drawerLarge}

        <Box
          component="main"
          sx={{
            // backgroundColor: (theme) =>
            //   theme.palette.mode === "light"
            //     ? theme.palette.grey[100]
            //     : theme.palette.grey[900],
            backgroundImage:
              "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
            flexGrow: 1,
            height: "100vh",
            //overflow: "auto",
            overflow: "hidden",
          }}
        >
          <Toolbar />

          <Outlet />
        </Box>
      </Box>
    </Fragment>
  );
}

export default DashboardContent;
