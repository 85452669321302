import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
    name:'comments',
    initialState:{ commentsAreVisible: false},
    reducers:{
        toggle(state){
            state.commentsAreVisible = !state.commentsAreVisible;
        }
    },
});

export const commentsActions = commentsSlice.actions;

export default commentsSlice;