
//------------------MUI IMPORTS -----------------------------------------------
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PaymentIcon from '@mui/icons-material/Payment';
import BrushIcon from '@mui/icons-material/Brush';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
//import Button from "@mui/material/Button";



import { PropagateLoader } from "react-spinners";
import { SyncLoader } from "react-spinners";

import Title from "../Title";
//import { MyPaper } from "./styledComponents/StyledComponents";

const DashboardContainerReportList = (props) => {
  const {
    matches,
    notification,
    ListFiltered,
    //handleListItemChange,
    //handleGetReport_2,
    //reportVariables,
    IsLoading,
    //isLoading_orderList,
    box1,
    box2,
    box2A,
    box2B,
    box2C,
    box3,
    box3A,
    box4,
    reportTitle,
    reportTitleOptions,
    reportTitleOptions_2A,
    reportTitleOptions_2B,
    reportTitleOptions_2C,
    boxSize,
    boxSize2,

  } = props;

  //console.log("this is the props from the Dashboard container.....", isLoading_orderList)

  return (
    <Box
      id="luis"
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === "light"
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        backgroundImage:"url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
      <Container maxWidth="xlg" sx={{ mt: 4, mb: 4, width: matches? "95vw" : "70vw" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}  md={6} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
                alignItems:"center",

                height: matches ? 200 : 180,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <GradingOutlinedIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitle}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />
              </Grid>
              {ListFiltered.length > 0 ? (
                <Grid
                  //container
                  //spacing={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flexDirection: matches ? "column" : "row",
                    paddingTop: 2,
                  }}
                >
                  {box1}

                </Grid>
              ) : (
                <PropagateLoader color="#7A1125" size="15" margin="2" />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}  md={6} lg={boxSize2? boxSize2:9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems:"center",

                height: matches ? 200 : 180,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ManageAccountsIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitleOptions}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />
              </Grid>

              <Grid>{box2}</Grid>
              

            </Paper>
          </Grid>


          <Grid item xs={12} md={6} lg={boxSize2? boxSize2:9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems:"center",

                height: matches ? 200 : 180,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <PaymentIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitleOptions_2A}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />
              </Grid>

              <Grid>{box2A}</Grid>
              

            </Paper>
          </Grid>



          <Grid item xs={12} md={6} lg={boxSize2? boxSize2:9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems:"center",

                height: matches ? 200 : 180,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ScreenSearchDesktopIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitleOptions_2B}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />
              </Grid>

              <Grid>{box2B}</Grid>
              

            </Paper>
          </Grid>


        {/* Invoicing status------------------------------------------------------ */}
          <Grid item xs={12} md={6} lg={boxSize2? boxSize2:9}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems:"center",

                height: matches ? 200 : 180,
              }}
            >
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ReceiptLongIcon
                    //baseClassName="fas"
                    baseclassname="fas"
                    className="fa-plus-circle"
                    sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                  />
                  <Title>{reportTitleOptions_2C}</Title>
                </Box>
                {notification && (
                  <Alert severity="error">
                    <h4>{notification}</h4>
                  </Alert>
                )}

                <Divider />
              </Grid>

              <Grid>{box2C}</Grid>
              

            </Paper>
          </Grid>





          <Grid item xs={12} >
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {box3}
            </Paper>
          </Grid>

          {/* {box3A && 
          <Grid item xs={12} >
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {box3A}
            </Paper>
          </Grid> 
          } */}

          <Grid item xs={12} id="luis2">
            <Paper
              sx={{ p: 2, mb: 10, display: "flex", flexDirection: "column" }}
            >
              {IsLoading && (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15} />
                </Box>
              )}

              {box4}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DashboardContainerReportList;
