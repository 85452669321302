import { Fragment } from "react";

import theme from "./Theme";

//MUI IMPORTS-------------------------------------------
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// import AvatarTestPic from "../../images/avatar-test-pic.jpg";

import { useSelector } from "react-redux";

const DisplayNotifications = () => {
  const notificationsList = useSelector(
    (state) => state.notifications.notificationsType
  );
  const messagesList = useSelector((state) => state.notifications.messages);

  console.log(
    "this is the notification list from display component...",
    notificationsList
  );
  console.log(
    "this is the messages list from display component...",
    messagesList
  );

  //const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));


  //Sorting notifications by time from the latest----------------------------------
  const notificationsListSorted = notificationsList.slice(0).sort((a, b) => {
    const dateTimeA = new Date(`${a.notificationDate} ${a.notificationTime}`);
    const dateTimeB = new Date(`${b.notificationDate} ${b.notificationTime}`);
    return dateTimeB - dateTimeA ;
  });






  return (
    <Box
      component="span"
      sx={{
        color: "#4E1721",
        borderRadius: 1.5,

        //marginTop: '6rem',
        marginTop: matches ? "4.2rem" : "6rem",
        marginRight: 2,

        position: "absolute",
        top: "0px",
        right: 0,
        width: "95%",
        height: "auto",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "flex-end",
        justifyContent: "center",
        overflow: "auto",
        maxHeight: "50vh",
        maxWidth: 370,
        //backgroundColor:"#000"
      }}
    >
      <List
        sx={{
          width: "100%",
          height: "auto",
          maxWidth: 360,
          bgcolor: "background.paper",
          backgroundColor: "#e7dddd",
          //maxHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          //marginTop: 26,
          marginTop: 1,
        }}
      >
        {/* {notificationsList.map((notification) => { */}
        {notificationsListSorted.map((notification) => {
          
          return (
            <Fragment>
              <ListItem
                alignItems="flex-start"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <ListItemAvatar>
                  {/* <Avatar alt="Remy Sharp" src={AvatarTestPic} /> */}
                  {/* <Avatar alt="user pic" src='https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/WhatsApp_Image_2021-01-10_at_20.00.07_Ub7bsKGLXl5.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1631192701589&tr=w-1104%2Ch-736%2Cfo-auto' /> */}
                  <Avatar alt="user pic" src={notification.senderPic} />
                </ListItemAvatar>
                <ListItemText
                  // primary={`New ${notification.name} Submitted !`}
                  primary={`New ${notification.name}`}
                  secondary={
                    <Fragment>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {notification.notificationDetails && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Ref:  ${notification.notificationDetails.ref}`}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Client:  ${notification.notificationDetails.company}`}
                            </Typography>
                          </>
                        )}

                        {notification.notificationDetails_Purchase
                          ?.isSupplier && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Inv.:  ${notification.notificationDetails_Purchase.invoice}`}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Supplier:  ${notification.notificationDetails_Purchase.supplier}`}
                            </Typography>

                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Paid:  ${notification.notificationDetails_Purchase.purchasePaid}`}
                            </Typography>
                          </>
                        )}

                        {notification.notificationDetails_Purchase?.isWages && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`W.Inv.:  ${notification.notificationDetails_Purchase.wagesInvoice}`}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Consultant:  ${notification.notificationDetails_Purchase.consultant}`}
                            </Typography>

                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {/* {`Paid:  ${notification.notificationDetails_Purchase.purchasePaid}`} */}
                              {
                                "Amount Paid: " +
                                  notification.notificationDetails_Purchase
                                    .wagesPaid
                                // parseFloat( notification.notificationDetails_Purchase.wagesPaid).toLocaleString("en-US", {
                                //   minimumFractionDigits: 2,
                                // })
                              }
                            </Typography>
                          </>
                        )}

                        {notification.notificationDetails_Payment && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Order.:  ${notification.notificationDetails_Payment.confo}`}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Client:  ${notification.notificationDetails_Payment.client}`}
                            </Typography>

                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {/* {`Amount Paid:  ${notification.notificationDetails_Payment.amountPaid}`} */}
                              {"Amount Paid: £ " +
                                parseFloat(
                                  notification.notificationDetails_Payment
                                    .amountPaid
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                })}
                            </Typography>
                          </>
                        )}

                        {notification.notificationDetails_Comunication && (
                          <>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Order.:  ${notification.notificationDetails_Comunication.confo}`}
                            </Typography>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {`Client:  ${notification.notificationDetails_Comunication.client}`}
                            </Typography>

                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.secondary"
                            >
                              {/* {`Amount Paid:  ${notification.notificationDetails_Payment.amountPaid}`} */}
                              {`Type: ${notification.notificationDetails_Comunication.Type}`}
                            </Typography>
                          </>
                        )}

                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {/* {`By:  ${notification.senderName.userName}`} */}
                          {`By:  ${
                            notification.senderName.userName.charAt(0).toUpperCase() +
                            notification.senderName.userName.toLowerCase().slice(1)
                          }`}
                        </Typography>
                        {`  on:  ${notification.notificationDate}`}
                        {`  at:  ${notification.notificationTime || "..."}`}
                      </Box>
                    </Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />
            </Fragment>
          );
        })}

        {/* <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
        primary="Summer BBQ"
        secondary={
            <React.Fragment>
            <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body2"
            color="text.primary"
            >
            to Scott, Alex, Jennifer
            </Typography>
            {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
        }
        />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
        primary="Oui Oui"
        secondary={
            <React.Fragment>
            <Typography
            sx={{ display: "inline" }}
            component="span"
                variant="body2"
                color="text.primary"
                >
                Sandra Adams
                </Typography>
                {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
            }
            />
        </ListItem> */}
      </List>
    </Box>
  );
};

export default DisplayNotifications;
