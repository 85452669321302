import { createSlice } from "@reduxjs/toolkit";

export const wagesPaymentSlice = createSlice({
  name: "wagesPayment",
  initialState: {
    wagesPaymentInfo: {
      selectedWagesInvoice: [],
      //selectedSupplier: {},
      selectedWagesInvoiceTotalPaid:0,
      wagesInvoiceAmountPaid: 0,
      wagesPaymentDate:"",
      wagesPaymentReference: "",
      wagesPaymentFirma:"4",
      wagesComments:"", 
      wagesPaymentType: 
      {
          firstLetter: "O",
          PAYTYPE_ID: 1,
          PAYTYPE: "OPERATIONAL",
          PAYTYPE_REF: "OPS",
      },
        
        //paymentDateUpdated:"",
        //paymentDate: new Date(),
    },
},
  reducers: {
    updateWagesPayment: (state, action) => {
      const { payload } = action;

      state.wagesPaymentInfo = { ...state.wagesPaymentInfo, ...payload };

      //console.log("full payload from quote reducer new state...",payload)
    },

    resetWagesPayment: (state, action) => {

      const { payload } = action;

      console.log("inside the slice for reset wages....",payload)

      state.wagesPaymentInfo = {
        selectedWagesInvoice: [],
        selectedWagesInvoiceTotalPaid:0,
        wagesInvoiceAmountPaid: 0,
        wagesPaymentDate: payload.wagesPaymentDate,
        wagesPaymentReference: "",
        wagesPaymentFirma:"4",
        wagesComments:"",
        wagesPaymentType: 
        {
            firstLetter: "O",
            COSTTYPE_ID: 1,
            COSTTYPE: "OPERATIONAL",
            COSTTYPE_REPORT: "OPS",
        },
        
        
        //selectedSupplier: {},
        //purchasePaymentDate: payload.paymentDate,
        

      };
    },
  },
});

export const wagesPaymentActions = wagesPaymentSlice.actions;

export default wagesPaymentSlice;
