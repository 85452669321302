import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import Title from "./Title";

import theme from "./UI/Theme";

//MUI IMPORTS---------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";


//Toottip Customized-----------
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log("payload...", payload[0].payload)
    return (
      <div className="custom-tooltip">
        {/* <p
          style={{ color: `${theme.palette.common.ludisMain}` }}
          className="label"
        >{`${label} : £ ${payload[0].payload.amount}`}
        </p> */}
        <p
          style={{ color: `${theme.palette.primary.main}` }}
          className="label"
        >{`${label} : £ ${payload[0].payload.amount}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};


// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

// const data = [
//   createData("00:00", 0),
//   createData("03:00", 300),
//   createData("06:00", 600),
//   createData("09:00", 800),
//   createData("12:00", 1500),
//   createData("15:00", 2000),
//   createData("18:00", 2400),
//   createData("21:00", 2400),
//   createData("24:00", undefined),
// ];

const months = [ "Jan", "Febr", "Mar", "Apr", "May", "Jun", 
           "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export default function Chart(props) {
  const theme = useTheme();
  let graphData = null;
  const {
    //salesByConsultantList,
    salesByConsultantByDate,
    salesGraphData,
    changeGraphData,
  } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //console.log("from the chart last onw...", salesByConsultantList);
  //console.log("dates in Chart component example....",new Date(salesByConsultantByDate[3].DATE).getDate())

  if (salesByConsultantByDate.length > 0) {
    //console.log("from the chart after if....", salesByConsultantByDate);
    //console.log("from the chart after if....", salesGraphData);

    graphData = salesByConsultantByDate.map((item, i) => {
      //let x = i;

      let graphDate = ''

      if (changeGraphData) {
        graphDate = months[item.DATE];
      } else {
        graphDate = new Date(item.DATE).toLocaleString("default", {
          day: "numeric",
          month: "short",
        });
      }

      let x = graphDate;
      let y = item.amountNet;
      return createData(x, y);
    });
    //console.log("the graph data ... from chart component...", graphData);
  }

  return (
    <>
      {/* {salesByConsultantList.length > 0 ? ( */}

      {salesGraphData.length > 0 ? (
        <Fragment>
          {changeGraphData ? (
            <Title>Sales By Month</Title>
          ) : (
            <Title>Sales By Day</Title>
          )}

          <ResponsiveContainer>
            <LineChart
              //data={data}
              data={graphData}
              margin={{
                top: 16,
                //right: 16,
                right: matches3?0:16,
                bottom: 0,
                //left: 24,
                left: matches3?0:24,

              }}
            >
              <XAxis
                dataKey="time"
                stroke={theme.palette.text.secondary}
                //style={theme.typography.body2}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                //style={theme.typography.body2}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
              >
                <Label
                  angle={270}
                  position="left"
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                  }}
                >
                  {/* Sales ($) */}
                  {matches3? "": "Sales ($)"}
                </Label>
              </YAxis>


              <Tooltip
                cursor={{ fill: "#e7dddd" }}
                content={<CustomTooltip />}
                wrapperStyle={{
                  width: "auto",
                  backgroundColor: "#e7dddd",
                  borderRadius: 3,
                  fontSize: "0.8rem",
                  padding: 5,
                }}
              />

              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />



              <Line
                //isAnimationActive={false}
                type="monotone"
                dataKey="amount"
                stroke={theme.palette.primary.main}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Fragment>
      ) : (
        "loading"
      )}
    </>
  );
}
