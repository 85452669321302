import { Fragment } from "react";
import Virtualize from "../adminComponents/VirtualList";

import { PropagateLoader } from "react-spinners";

//------MUI IMPORTS------------------------------------------
import Box from "@mui/material/Box";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Divider, Grid, Button, Typography } from "@mui/material";

const OrdersList = (props) => {
  const { 
    activeOrdersList, 
    isLoading_orderList, 
    refreshOrderList,
    currentorderSelectedRefFinder,
   } = props;

  //console.log("this is the props from the ORDERSLIST Component.....", isLoading_orderList)


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: 0,
      }}
    >
      {!isLoading_orderList ? 
      <Virtualize 
      listFiltered={activeOrdersList} 
      currentorderSelectedRefFinder = {currentorderSelectedRefFinder}
      /> 
      : 
      <PropagateLoader color="#7A1125" size="15" margin="2" />
    }
      <Button
        onClick={(event) => refreshOrderList(event)}
        sx={{ color: "#7A1125", textTransform: "capitalize", marginTop: 2 }}
      >
        <AutorenewIcon
          sx={{ fontSize: 15, color: "#4E1721", marginRight: 2 }}
        />
        {/* {props.changeDates ? "View This Week" : "View All Year"} */}
        {"Add All Orders"}
      </Button>
    </Box>
  );
};

export default OrdersList;
