import {createSlice} from '@reduxjs/toolkit';

export const paymentPlanSlice = createSlice({
    name:"paymentPlan",
    initialState:{

        payPlanInfo:{    
            selectedOrder:[],
            paymentPlanSetDate:"",
            PaymentPlanRepaymentNumber:"",
            PaymentPlanId:null,
        },
        
        paymentPlanSignedDate:"",
        paymentPlanSignedId:null,
        
    },
    reducers:{

        updatePayPlanInfo: (state, action)=>{
            const {payload} = action; 
            state.payPlanInfo = {...state.payPlanInfo, ...payload };
        },
        updatePayPlanSigned: (state, action)=>{
            const {payload} = action 
            state.paymentPlanSignedDate = payload.paymentPlanSignedDate
            state.paymentPlanSignedId = payload.paymentPlanSignedId
        },
        resetPaymentPlan:(state, action)=>{

            const {payload} = action;

            state.payPlanInfo={
                selectedOrder:[],
                paymentPlanSetDate:"",
                PaymentPlanRepaymentNumber:0,
                PaymentPlanId:null,
            };

            state.paymentPlanSignedDate= payload.paymentPlanSignedDate;
            state.paymentPlanSignedId = null;
            

        }


    }
})

export const paymentPlanActions = paymentPlanSlice.actions;

export default paymentPlanSlice;