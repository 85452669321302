import { useState } from "react";
import theme from "../Theme";

import DesignerList from "../../artworkComponents/DesignerList";

//MUI IMPORTS --------------------------------------------
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
//import GroupAddIcon from "@mui/icons-material/GroupAdd";
//import GroupIcon from "@mui/icons-material/Group";
import Box from "@mui/material/Box";
import TodayIcon from "@mui/icons-material/Today";
import UndoIcon from "@mui/icons-material/Undo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';


const ArtworkTrackerTabs = (props) => {
  //value for the tabs--------------------
  const [value, setValue] = useState(0);

  const { changeTabsHandler, children, index, ...other } = props;
  const { designersList, selectedName, filteredOrders, handleNameChange, isLoading, reportTitle  } = props;

  
  //console.log("this is the filteredOrder passed...",filteredOrders)

  //const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const {selectedName, handleNameChange, designersList, filteredOrders, isLoading, reportTitle, value} = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        
      >
        {value === index && (
          <Box sx={{ p: matches3? 0: 3, width: matches3? "60vw" : "75vw", height:"auto", overflow:"auto"  }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //handle change for TABS---------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTabsHandler(newValue);
  };

  return (
    // {/* <Box sx={{ borderBottom: 1, borderColor: "divider", display:'flex', flexDirection:'row', justifyContent:'center', height: matches3 ? '17vh' : 'auto',  }}> */}
    // <Box sx={{ width: matches3?"100%":"95%", margin:"auto" , marginTop: matches3? 0 : 5, width:matches3?"90vw":"auto"  }}>
    
    
    <Box
      sx={{
        //width: "100%",
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: matches3 ? 0 : 1,
        width: matches3 ? "75vw" : "80vw",
        // backgroundColor:"#ccc"
        
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          height: matches3 ? "20vh" : "auto",
          marginTop: matches3 ? 0 : 3,
          width: matches3 ? "70vw" : "auto",
        //   backgroundColor: "#ccc",
             
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          // centered
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          orientation={matches3 ? "vertical" : "horizontal"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 0,
            width: matches3 ? "95vw" : "auto",
          }}
          //sx={{ width:"95vw", marginTop:0 }}
        >
          {/* <Tab
            icon={<CurrencyExchangeIcon sx={{fontSize: matches3?"1.5rem":matches2?"1":"1.8rem"}} />}
            iconPosition="start"
            label="DIR"
            // sx={{fontSize: matches3?"0.6rem":"0.875rem", minHeight:"5vh"}}
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.80rem", minHeight:"10vh"}}
            {...a11yProps(0)}
          />
          <Tab
            icon={<GpsFixedIcon sx={{fontSize: matches3?"1.8rem":matches2?"1":"1.8rem"}} />}
            iconPosition="start"
            label="FIX"
            // sx={{fontSize: matches3?"0.6rem":"0.875rem",  minHeight:"5vh"}}
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.80rem", minHeight:"10vh"}}
            {...a11yProps(1)}
          /> */}
          <Tab
            icon={<ImageNotSupportedIcon sx={{ fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem", }}/>}
            iconPosition="start"
            label={matches2? "No Art" : "No Artwork..."}
            sx={{
            //   fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.55rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(0)}
          />

          <Tab
            icon={ <MarkEmailUnreadIcon sx={{ fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",}} />}
            iconPosition="start"
            label={matches2 ? "Art.Rec" : "Art Received..."}
            sx={{
            //   fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.55rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(1)}
          />
          {/* <DesignerList
              designersList={designersList}
              selectedName={selectedName}
              filteredOrders={filteredOrders}
              handleNameChange={handleNameChange}
              isLoading={isLoading}
              reportTitle={reportTitle}
            /> */}

          <Tab
            icon={
              <FormatShapesIcon
                sx={{
                  fontSize: matches3 ? "1.8rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Proof..."
            sx={{
            //   fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.55rem" : "0.70rem",  
              minHeight: "10vh",
            }}
            {...a11yProps(2)}
          />
          <Tab
            icon={
              <ThumbUpOffAltIcon
                sx={{
                  fontSize: matches3 ? "1.70rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label="Approved..."
            sx={{
            //   fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.55rem" : "0.70rem",  
              minHeight: "10vh",
            }}
            {...a11yProps(3)}
          />
          <Tab
            icon={
              <EventNoteIcon
                sx={{
                  fontSize: matches3 ? "1.7rem" : matches2 ? "1" : "1.50rem",
                }}
              />
            }
            iconPosition="start"
            label= {matches2 ? " T.B.C" : "T.B.Cancelled..."}
            sx={{
            //   fontSize: matches3 ? "0.70rem" : matches2 ? "0.75rem" : "0.70rem",
              fontSize: matches3 ? "0.70rem" : matches2 ? "0.55rem" : "0.70rem",
              minHeight: "10vh",
            }}
            {...a11yProps(4)}
          />
        </Tabs>
      </Box>

      
      
      <TabPanel 
      value={value} index={0}
      >
        {props.children}
        {/* <DesignerList
          designersList={designersList}
          selectedName={selectedName}
          filteredOrders={filteredOrders}
          handleNameChange={handleNameChange}
          isLoading={isLoading}
          reportTitle={reportTitle}
        /> */}
        {/* <UserDetails /> */}
      </TabPanel>
      <TabPanel 
      value={value} 
      index={1}
      
      >
        {props.children}
        
        <DesignerList
          designersList={designersList}
          selectedName={selectedName}
          filteredOrders={filteredOrders}
          handleNameChange={handleNameChange}
          isLoading={isLoading}
          reportTitle={reportTitle}
        />
        
      </TabPanel>
    </Box>
    
  );
}; 

export default ArtworkTrackerTabs;
