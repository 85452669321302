import { useState, useRef, Fragment, useEffect } from "react";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";

import GeneralGraph from "./GeneralGraph";

import Grid from "@mui/material/Grid";

const DashboardGraph = (props) => {
  const { salesByDateList, salesCancelledByDateList, salesByDateListTarget, salesByYearListAll, myBoxHeight, graphType, BarChartData } =
    props;

  //test to be deleted
  //const [showGraph, setShowGraph] = useState(false);
  //END test to be deleted

  const [checked, setChecked] = useState(true);

  const containerRef = useRef(null);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  
  // useEffect(()=>{
  //   //setChecked(false);
  //   if(checked === null){
  //     setChecked(true)
  //   }
  

  // },[])

  // useEffect(() => {
  //   if (salesByDateListTarget.length > 0) {
  //     setChecked(true)
  //   } 
  // }, []);
//}, [salesByDateListTarget]);


  //alert("breake....")
  //console.log("Checking here-----------------------------------------!!!!",salesByYearListAll)
  //console.log("Checking here-----------------------------------------!!!!",changeDates)
  // console.log('checkingggggggggggggggggggggggggggggggggggg...............',changeDates)
  //console.log('checkingggggggggggggggggggggggggggggggggggg...............',props)

  // console.log(
  //   "salesByYearListAll props here.........................!!!!!",
  //   salesByYearListAll
  // );

  return (
    <Fragment>
      <Box ref={containerRef} sx={{display:"flex", flexDirection:"column", alignContent:"center", justifyContent:"center"}}>
        <FormControlLabel
          sx={{ margin: 0, justifyContent:"center" }}
          control={<Switch checked={checked} onChange={handleChange} />}
          label={checked? "Hide Graph" : "Show Graph"}
        />
      </Box>

     {/* { checked === null ? "is null" : "no nulll"}  */}

    

      <Slide
        // sx={{ height: salesByConsultantList.length>0 ? "auto" : 0 }}
        sx={{ height: checked ? "auto" : 0, overflow: "hidden" }}
        direction="right"
        in={checked}
        //in={salesByConsultantList.length>0}

        container={containerRef.current}
      >
        <Grid>
          {/* {salesByDateListTarget?.length > 0 && (  */}
          {/* {salesByDateListTarget.length > 0 ? (   */}
          {/* {checked ? ( */}
            <GeneralGraph
              salesByDateList={salesByDateList}
              salesByDateListTarget={salesByDateListTarget}
              salesCancelledByDateList={salesCancelledByDateList}
              salesByYearListAll={salesByYearListAll}
              myBoxHeight = {myBoxHeight}
              graphType = {graphType}
              BarChartData = {BarChartData}
              //changeDates={changeDates}

              // salesByDateList={salesByDateList}
              // salesByDateListTarget={salesByDateListTarget}
              // changeDates={changeDates}

              //changeDatesHandler={changeDatesHandler}
            />
           
          {/* : (
            
            "...loading"
          )} */}
        </Grid>
      </Slide>
    </Fragment>
  );
};

export default DashboardGraph;
