import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

//REDUX IMPORTS-------------------------------------------
import { useSelector, useDispatch } from "react-redux";
import { comunicationInfoActions } from "../../../store/comunication-slice";

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";



//My IMPORTS----------------
import theme from "../../UI/Theme";
import Title from "../../Title";
import { variables } from "../../../Variables";
//  import sendEmailGeneral from "./SendEmail_General";


//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const CreateEmailStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const currentUserInfo = useSelector((state) => state.user);
  const currentComunicationInfo = useSelector((state) => state.comunication.comunicationCreateEmailInfo);

  

  const [newComunicationStatus, setNewComunicationStatus] = useState({});
  const [newComunicationSavedStatus, setNewComunicationSavedStatus] = useState({});

  //const { socket, userInfo, showAddPaymentModalHandler, isNewPayment } = props;
  const { socket, showComunicationModalHandler, isNewPayment } = props;

  // preparing data for the email--------------------------------------------------
  const emailData = {
    email:currentComunicationInfo.comunicationEmail,
    emailCC:currentComunicationInfo.comunicationEmailCC,
    emailSubject:currentComunicationInfo.comunicationEmailSubject,
    emailDate:currentComunicationInfo.comunicationEmailDate,
    emailMessage:currentComunicationInfo.comunicationMessage,
    invoiceRef:currentComunicationInfo.selectedOrder[0].CONFIRMATION_REFERENCE,
    projectRef:currentComunicationInfo.selectedOrder[0].CLUB_NAME +  
    " " + currentComunicationInfo.selectedOrder[0].YEAR
  }
  // END preparing data for the email----------------------------------------------



  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  //Mongo info----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"

  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
        if(res.status === 201){
            
            //getPost()
        }
    })
 }

  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");


    socket.emit("sendNotification", {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: currentUserInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails_Comunication: {
        "confo": currentComunicationInfo.selectedOrder[0].CONFIRMATION_REFERENCE, 
        "confoId": currentComunicationInfo.selectedOrder[0].CONFIRMATION_ID, 
        "client": currentComunicationInfo.selectedOrder[0].COMPANY_NAME,
        "clientId": currentComunicationInfo.selectedOrder[0].CLIENT_ID,
        "Type":"C.C Email (General)",
    }
    });




    //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      senderPic: currentUserInfo.userPicURL,
      notificationDetails: {
        "confo": currentComunicationInfo.selectedOrder[0].CONFIRMATION_REFERENCE, 
        "confoId": currentComunicationInfo.selectedOrder[0].CONFIRMATION_ID, 
        "client": currentComunicationInfo.selectedOrder[0].COMPANY_NAME,
        "clientId": currentComunicationInfo.selectedOrder[0].CLIENT_ID,
        "Type":"C.C Email (General)",
    },
      // receiverName: "All",
    }
    createPost(noty)



  };




    //Function to create a new Comunication Email in the DB ----------
    const saveNewComunication = () => {

      const config = {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
        },
      };
      const comunicationData = {
          COMMUNICATIONTYPE_ID:"10",
          CONFIRMATION_ID: currentComunicationInfo.selectedOrder[0].CONFIRMATION_ID,
          COMMUNICATION_DATE:new Date(),
          M_USER:currentUserInfo.userId,
          TIME: new Date(),
      };
      //console.log("this is the data for the request....",data)
  
      //Create (post) a New Comunication to Record it in DB-------------------
       axios
         .post(
           variables.API_URL + "comunication/recordemailgeneral",
           comunicationData,
           config
         )
  
         .then((response) => {
            setNewComunicationSavedStatus({
             message: " New Comunication Email saved in DB Successfully",
             status: true,
           });
         });
  
    };
    // end of create a new Comunication Email in the DB  ----------


  //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  // const resetComunicationInfo = ()=>{
  //   dispatch(
  //       comunicationInfoActions.resetComunicationCreateEmail({
  //       //paymentDate: new Date().toISOString(),
  //     })
  //   );

  //  }
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----



  //Create the email to be sent--------------------------------------------------------------------------------

  const sendEmailGeneralFinal = async (emailData) => {
    setIsLoading(true)
    //e.preventDefault();

    const config = {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
        },
      };

             //await axios
             //.post("http://localhost:8090/sendPdf", {
             axios
             .post(variables.API_URL + "comunication/sendemailgeneral", emailData, config)
             .then((response) => {
               //console.log("here is the axios response....",response);
               //console.log("LuisHresponse...", response);
               //console.log("this is the status...", response.status);
               setNewComunicationStatus({
                 message: " Email sent Successfully",
                 status: true,
               });
               //if email was sent ok and response from backend is 250 OK then save in DB------
               if(response.status === 250){
                saveNewComunication()
               }

               //Submit the notification to the socket -NotificationType = 1 for payment.......
               submitNotification({
                 // type:7
                 type: { generalType: 1, notificationType: 7 },
                 name: "Comunication",
                 notificationDate: new Date().toLocaleString("default", {
                   day: "numeric",
                   month: "short",
                   year: "numeric",
                 }),
                 notificationTime: new Date().toLocaleTimeString("en-US", {
                   hour: "2-digit",
                   minute: "2-digit",
                   second: "2-digit",
                 }),
                 notificationDetails_Comunication: {
                   confo:
                     currentComunicationInfo.selectedOrder[0]
                       .CONFIRMATION_REFERENCE,
                   confoId:
                     currentComunicationInfo.selectedOrder[0].CONFIRMATION_ID,
                   client:
                     currentComunicationInfo.selectedOrder[0].COMPANY_NAME,
                   clientId: currentComunicationInfo.selectedOrder[0].CLIENT_ID,
                   Type: "C.C Email (General)",
                 },
               });



               //send the paymentID to the parent component to refresh the list of payments including new one-----
               //isNewPaymentPlan(Math.random());

               //isLoading1_PayplanOnOrderHandler(false);

               //Reset Payment In Information----------
               //resetPaymentPlanInfo();

               setTimeout(() => {
                 //setNotification(null);
                 const showComunicationModalHandler_local = (event) =>
                 showComunicationModalHandler(event, 1);
                 showComunicationModalHandler_local();
                 //console.log("This is the end message before closing.....")
               }, 1500);



             }).catch(function (error) {
              console.log(error);
              setNewComunicationStatus({
                message: "  Something went Wrong ! Email NOT Sent",
                status: false,
              });
            });
             
              //console.log('notification submited...and is loading...',isLoading)
          
              //resetComunicationInfo()
              setIsLoading(false)
  };



  

  //END Create the email to be sent----------------------------------------------------------------------------
 
   







  //Step I Creating New User as consultant
  useEffect(() => {
    //createNewPayment(); // to be replace for create new comunication records in DB in clientcomunicationdetails and clientcomunication
    //sendEmailGeneral()
    if(emailData){

      sendEmailGeneralFinal(emailData)
      
    }

}, []);


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="3" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : Object.keys(newComunicationStatus).length !== 0 ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {newComunicationStatus.status ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: matches ? 0 : "2rem",
                marginTop: matches ? 0 : "2rem",
              }}
            >
              <CheckCircleOutlineIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
              <Title sx={{ margin: "auto" }}>EMAIL SUCCESSFULLY SENT !</Title>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: matches ? 0 : "2rem",
                marginTop: matches ? 0 : "2rem",
              }}
            >
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
              <Title sx={{ margin: "auto" }}>EMAIL NOT SENT, CONTACT YOUR ADMIN TEAM !</Title>
            </Box>
          )}

          <ListItem>
            {newComunicationStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}

            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Comunication Status:"
              secondary={
                newComunicationStatus.message
                  ? newComunicationStatus.message
                  : "No Info..."
              }
            />
          </ListItem>
        </Box>
      ) : (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      )}
    </Fragment>
  );
};

export default CreateEmailStep_3;
