import {Fragment} from 'react'

import Title from "../Title";
import theme from "../UI/Theme";
import ReportSalesList from "../reportComponents/ReportSalesList"

//====MUI IMPORTS------------------------------------------------------------------

import { Divider, Grid, Button, Typography, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Draggable from "react-draggable";


function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const MyDialog = (props)=>{

    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

    const {
      salesByDateList, 
      salesCancelledByDateList, 
      showDetails, 
      showDetailsHandler, 
      title, 
      tabInitialDate,
      tabFinalDate,
      totalPagesByConsultantHandler,
      totalPagesCancelledByConsultantHandler,

    } = props

    return(

        <Fragment>

    

    {/* Modal for case: Show payments Out to Suppliers ---------------------- */}
    <Dialog
    // open={props.showDetails}
    open={showDetails}
    //open={open}
    //onClose={handleClose}
    //onClose={(event) => props.showDetailsHandler()}
    onClose={(event) => showDetailsHandler()}
    PaperComponent={PaperComponent}
    aria-labelledby="draggable-dialog-title"
    maxWidth={false}
    PaperProps={{ sx: { width: matches3?"95vw":"70vw" } }}
  >
    <DialogTitle>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "row",
          alignItems: "center",
          justifyContent:matches3?"center":"flex-start"
          
        }}
      >
        {props.icon}

        <Title>{props.title} Information</Title>
      </Box>
    </DialogTitle>
    <DialogContent sx={{ width: matches3?"85vw":"70vw", height: "60vh" }}>
      {/* DISPLAY INFORMATION FOR PAYMENTS OUT */}
      {props.salesByDateList?.length > 0 && (
        <Fragment>
          <DialogContentText sx={{ margin: 2 }}>
            {/* On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
              Client: {currentOrderSelected[0].COMPANY_NAME} */}
          </DialogContentText>

          <Typography
            align="center"
            variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, marginBottom: 2, fontSize: matches3?"0.8rem":"1rem" }}
          >
            {title + "  " + "between:  "}
              
          </Typography>


          <Typography
            align="center"
            variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, marginBottom: 2, fontSize:matches3?"0.8rem":"1rem" }}
          >
            {/* {props.title + "  " + "between :  "} */}
            {new Date(tabInitialDate).toLocaleString(
              "default",
              {
                day: "numeric",
                month: "short",
                year: "numeric",
              }
            )}{" "}
            {"  And  "}
            {new Date(tabFinalDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>

                 <Fragment>
                    <Box sx={{ marginBottom:5}}>

                      <ReportSalesList
                        reportVariables={{"consultantSelected_id":0}}
                        salesByConsultantList={salesByDateList}
                        totalPagesByConsultantHandler={(pages) =>totalPagesByConsultantHandler(pages)}
                        reportName="Orders"
                        reportType="Main"
                        />
  
                      </Box>

                    <Divider />

                    <Box sx={{marginTop:5}}>


                      <ReportSalesList
                        reportVariables={{"consultantSelected_id":0}}
                        salesByConsultantList={salesCancelledByDateList}
                        totalPagesByConsultantHandler={(pages) =>totalPagesCancelledByConsultantHandler(pages)}
                        reportName="Cancelled Orders"
                        reportType="Main"
                        />
                        
                      </Box>
                  </Fragment>

         
        </Fragment>
      )}

      

      {/* <AddPplanSignedSteps steps={steps_signed} /> */}
    </DialogContent>
    <DialogActions></DialogActions>
  </Dialog>

  </Fragment>

  )

    
}

export default MyDialog