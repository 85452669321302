import React, {useState, useEffect, Fragment} from 'react'

import MyDatePicker from '../UI/DatePicker'

//MUI Imports--------------
import Box from "@mui/material/Box";

//OTHER IMPORTS------------------------------------------
import { 
  format, 
  compareAsc, 
  addDays,
  formatDistance, 
  subDays, 
  parse, 
  add, 
  previousMonday, 
  subMonths, 
  startOfMonth, 
  endOfMonth, 
  startOfYear, 
  endOfYear,
  subYears,
  formatISO9075,
 } from "date-fns";


const ShowDateTime = ()=>{
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDate2, setCurrentDate2] = useState(new Date());

  //calculate the previous Monday date------------------------------------------------------------
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setUTCDate(
      prevMonday.getUTCDate() - ((prevMonday.getUTCDay() + 6) % 7)
    )
  );

  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  //let formatDistanceTest = formatDistance(subDays(prevMonday,0), new Date(), { addSuffix: false });
  //let formatDistanceTest2 = format(new Date(prevMonday), "dd/MM/yyyy")
  //let formatDistanceTest3 = format(new Date(prevMonday), "yyyy-MM-dd");
  //let formatDistanceTest4 = subDays(new Date(prevMonday), 10)
  //let formatDistanceTest5 = previousMonday(new Date())
  //let selectedDatesFromInitial = new Date(new Date(prevMonday.setUTCDate(prevMonday.getUTCDate() - 7)).setUTCHours(23, 59, 59)).toISOString();
  //).toGMTString();
  //let selectedDatesTo = new Date(new Date(prevMonday.setUTCDate(prevMonday.getUTCDate() + 7)).setUTCHours(22, 59, 59)).toISOString();
  //).toGMTString();
  //let initial = format(new Date(selectedDatesFromInitial), "yyyy-MM-dd")
  //let final = format(new Date(selectedDatesTo), "yyyy-MM-dd");

  // let selectedDatesFromInitial = new Date(Date.now()).getUTCFullYear() + "-" + (new Date(Date.now()).getUTCMonth() + 1) + "-01";
  // let selectedDatesTo = new Date(Date.now()).toISOString();

  // let initial = format(new Date(selectedDatesFromInitial), "yyyy-MM-dd")
  // let final = format(new Date(selectedDatesTo), "yyyy-MM-dd");

  // let myMonth = subMonths(new Date(), 1)
  // let myMonthF = format(new Date(myMonth), "yyyy-MM-dd");

  // let startMyMonth = startOfMonth(new Date(myMonth))
  // let startMyMonthF = format(new Date(startMyMonth),"yyyy-MM-dd")

  // let endMyMonth = endOfMonth(new Date(myMonth))
  // let endMyMonthF = format(new Date(endMyMonth),"yyyy-MM-dd")


  // let previousYear = subYears(new Date(), 1)
  // let startMyYear = startOfYear(new Date(previousYear))
  // let endMyYear = endOfYear(new Date(previousYear))
  
  // let startMyYearF = format(new Date(startMyYear),"yyyy-MM-dd")
  // let endMyYearF = format(new Date(endMyYear),"yyyy-MM-dd")

  

  




  // console.log("my new date ini...",selectedDatesFromInitial)
  // console.log("my new date to...",selectedDatesTo)


  //formatISO9075(new Date(2019, 8, 18, 19, 0, 52)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
      setCurrentDate2(prevMonday)
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center"
      }}
    >

      <h3>{format(currentDate,"dd-MMMM")}</h3>
      <h4>{formatISO9075(currentDate,{ representation: 'time' })}</h4>

     
    </Box>
  );
}

export default ShowDateTime;
