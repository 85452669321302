import { Fragment } from "react";
import DashboardContainer from "./UI/DashboardContainer";


import theme from "./UI/Theme";

//MUI IMPORTS----------------------------------------------------------
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DesktopMacIcon from '@mui/icons-material/DesktopMac';

const ProductionMenu = () => {
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Fragment>
      <DashboardContainer
        reportTitle="Production Module"
        myMarginTop={10}
        icon={
          <DesktopMacIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
        }
        myWidth={matches3?"77.7vw":"80vw"}
        matches={matches}
        matches2={matches2}
        matches3={matches3}
        myHeight="auto"
      >

        <Typography>Production Module Content... </Typography>

        <Grid item xs={12} md={6} lg={3}>
              <Box
                sx={{
                  //p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  //height: 325,
                  height: 335,
                  paddingBottom: 0,
                  minWidth: "10vw",
                  backgroundImage: "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludis-Logo-2020_85kTiekBI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650977432909)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
                >

               
              </Box>
            </Grid>
      </DashboardContainer>
    </Fragment>
  );
};

export default ProductionMenu;
