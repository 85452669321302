import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "paymentIn",
  initialState: {
    paymentInfo: {
      selectedOrder: [],
      selectedOrderTotalPaid:0,
      amountPaid: 0,
      paymentType: 
        {
          firstLetter: "B",
          CLIENTPAYMENTTYPE_ID: 1,
          CLIENTPAYMENTTYPE: "BACS TRANSFER",
          CLIENTPAYMENTTYPE_REF: "Bacs",
        },
      
      //paymentDate: new Date(),
      paymentDate:"",
      paymentReference: "",
      paymentDateUpdated:"",
    },
  },
  reducers: {
    updatePaymentIn: (state, action) => {
      const { payload } = action;

      state.paymentInfo = { ...state.paymentInfo, ...payload };

      //console.log("full payload from quote reducer new state...",payload)
    },

    resetPaymentIn: (state, action) => {

      const { payload } = action;

      //console.log("inside the slice for reset payments in....",payload)

      state.paymentInfo = {
        selectedOrder: [],
        selectedOrderTotalPaid:0,
        amountPaid: 0,
        paymentType: 
          {
            CLIENTPAYMENTTYPE: "GO CARDLESS",
            CLIENTPAYMENTTYPE_ID: 6,
            CLIENTPAYMENTTYPE_REF: "G.C",
            firstLetter: "G",
          },
        // paymentType:  // changed on 16.Feb.24 -- code for BACS type as default
        //   {
        //     CLIENTPAYMENTTYPE: "BACS TRANSFER",
        //     CLIENTPAYMENTTYPE_ID: 1,
        //     CLIENTPAYMENTTYPE_REF: "Bacs",
        //     firstLetter: "B",
        //   },
        
          //paymentDate: new Date(),
          //paymentDate: payload.paymentDate, // changed on 16.Feb.24 -- code for todays date as default
          paymentDate: payload.paymentDate,
          paymentReference: "",
          paymentDateUpdated:payload.paymentDateUpdated,
          selectedPayment_id: "",
      };
    },
  },
});

export const paymentInActions = paymentSlice.actions;

export default paymentSlice;
