import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateQuote } from "../../store/quote-slice";
import { v4 as uuidv4 } from "uuid";

import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";




//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));



const QuoteStep_3 = () => {
  const currentQuoteInfo = useSelector((state) => state.quote.quoteInfo);
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  console.log("current quote state from step 3", currentQuoteInfo);
  console.log(
    "current quote state from step 3 one dipper",
    currentQuoteInfo.companyType.COTYPE
  );

  let quoteInfo1 = [];

  if (currentQuoteInfo) {
    quoteInfo1 = [
      { label: "Company: ", value: currentQuoteInfo.companyName },
      { label: "CompanyType: ", value: currentQuoteInfo.companyType.COTYPE },

      {
        label: "Contact: ",
        value:
          currentQuoteInfo.clientFirstName +
          " " +
          currentQuoteInfo.clientLastName,
      },
      {
        label: "Project: ",
        value:
          currentQuoteInfo.project.CLUB_NAME +
          " " +
          currentQuoteInfo.project.YEAR,
      },
      { label: "Product: ", value: currentQuoteInfo.product.product_name },
      { label: "Quoted Price: ", value: "£   "+currentQuoteInfo.price },
      {
        label: "Company Market: ",
        value: currentQuoteInfo.companyMarket.COMARKET_NAME,
      },
    ];
  }

  //Generate the unique QuoteKey when rendering the page
  useEffect(() => {
    const value = uuidv4();
    dispatch(
      updateQuote({
        quoteKey: value,
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ width: matches ? "80vw" : "40vw" }}
        >
          <MyTable size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Quote Information</StyledTableCell>
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quoteInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  <TableCell style={{ fontWeight: 500 }}>
                    {info.label}
                  </TableCell>
                  <TableCell
                    style={{ color: "#4E1721", fontWeight: 500 }}
                    align="center"
                  >
                    {info.value}
                  </TableCell>
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
  );
};

export default QuoteStep_3;
