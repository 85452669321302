import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import DashboardContainer from "../UI/DashboardContainer";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import BarChart from "../BarChart";

import { MyListItemText } from "../UI/styledComponents/StyledComponents"



//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TimelineIcon from '@mui/icons-material/Timeline';

//loader Imports--------------
import { SyncLoader } from "react-spinners";

export default function SalesByConsultantSummaryGraph(props) {
  const [salesByConsultantsList, setSalesByConsultantsList] = useState([]);
  const [cancellationsByConsultantsList, setCancellationsByConsultantsList] =
    useState([]);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  const { tabInitialDate, tabFinalDate } = props;

  const [showSalesGraph, setShowSalesGraph] = useState(true);

  const showSalesGraphHandler = (event) => {
    event.preventDefault();
    setShowSalesGraph((prev) => !showSalesGraph);
  };

  //alert("my props inside..."+tabInitialDate+"and"+ tabFinalDate)
  //console.log("my props inside...",props)

  let netSales = 0;
  let BarChartData = [];


  
  // let prevMonday = new Date();
  // prevMonday = new Date(
  //   prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  // );
  // // sets the date object to last Monday, if the current day is Monday,
  // // set it to the current date
  // prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  // //const selectedDatesFromInitial = new Date(prevMonday).toISOString();
  // const selectedDatesFromInitial = new Date(prevMonday).toISOString();

  const selectedDatesFromInitial = props.tabInitialDate;
  //const selectedDatesFromInitial = new Date(tabInitialDate);
  //const selectedDatesTo = props.tabFinalDate;

  //const selectedDatesToInitial = new Date(Date.now()).toISOString();

  // console.log("Date from ....", selectedDatesFromInitial);
  // console.log("Date To...", new Date(Date.now()).toISOString());

  //fetching the info from db......

  //Get All sales by consultant------------------------------------
  //"http://localhost:5000/sales/salesbyconsultantsummary/" +
  const getSalesByConsultantSummary = (tabInitialDate, tabFinalDate) => {
    // const selectedDatesFrom = "2022-01-01";
    //const selectedDatesFrom = selectedDatesFromInitial;
    const selectedDatesFrom = tabInitialDate;

    //const selectedDatesFrom = "2022-03-28";
    //const selectedDatesTo = "2022-02-28";

    //const selectedDatesTo = new Date(Date.now()).toISOString();
    const selectedDatesTo = tabFinalDate;

    // console.log(
    //   "getSalesByConsultantSummary ............... in the salesbyconsultantsumary for GRAPH component called !!!!"
    // );
    setIsLoading1(true);

    axios
      .get(
        variables.API_URL +
          "sales/salesbyconsultantsummary/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "All sales by consultant summary...aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii",
        //   res.data.SalesByConsultantSummary

        // );
        setSalesByConsultantsList(res.data.SalesByConsultantSummary);
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All Cancelations by Consultant ------------------------------------
  //"http://localhost:5000/sales/cancellationsbyconsultantsummary/" +
  const getCancellationsByConsultantSummary = (
    tabInitialDate,
    tabFinalDate
  ) => {
    //const selectedDatesFrom = selectedDatesFromInitial;
    const selectedDatesFrom = tabInitialDate;
    //const selectedDatesFrom = "2022-03-28";
    //const selectedDatesTo = "2022-02-28";
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    const selectedDatesTo = tabFinalDate;

    // console.log("this is the selected date from....", selectedDatesFrom);
    // console.log("this is the selected date to....", selectedDatesTo);

    //console.log("getCancellationsByConsultantSummary ............... in the salesbyconsultantsumary for GRAPH component called !!!!")
    setIsLoading2(true);

    axios
      .get(
        variables.API_URL +
          "sales/cancellationsbyconsultantsummary/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all cancellations by consultant summary...",
        //   res.data.cancellationsByConsultantSummary
        // );
        setCancellationsByConsultantsList(
          res.data.cancellationsByConsultantSummary
        );

        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //end fetching the info from db....

  useEffect(() => {
    if (selectedDatesFromInitial) {
      getSalesByConsultantSummary(tabInitialDate, tabFinalDate);
      getCancellationsByConsultantSummary(tabInitialDate, tabFinalDate);
    }
  }, []);

  return (
    <Fragment>
      <Button
        onClick={(event) => {
          event.preventDefault();
          showSalesGraphHandler(event);
        }}
        sx={{ color: "#7A1125", textTransform: "capitalize" }}
      >
        <TimelineIcon sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }} />

        {/* {props.changeDates ? "View This Week" : "View All Year"} */}

        {showSalesGraph ? "Hide Graph" : "Show Graph"}
      </Button>

      {showSalesGraph ? (
        !isLoading1 && !isLoading2 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "center",
            }}
          >

            <Grid item xs={12} md={12} lg={8}>
              {/* <DashboardContainer
              reportTitle="Reporting Module"
              myMarginTop={5}
              icon={
                <QueryStatsIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
              }
              myWidth={10}
              > */}
              {/* <Box> */}
              {salesByConsultantsList.length === 0? 
              <EqualizerIcon sx={{ fontSize: 20, color: "#4E1721", marginRight: 2, display:"flex"}} />
              :
              <BarChart BarChartData={BarChartData} />
              }
              {/* </Box> */}
              {/* </DashboardContainer> */}
            </Grid>


            {/* <DashboardContainer
          reportTitle="Reporting Module"
          myMarginTop={10}
          icon={
            <QueryStatsIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
          }
          myWidth={12}
        > */}
            {/* <h6>Date From: ...{selectedDatesFromInitial}</h6> */}

            {/* <h6>Date To: ...{new Date(Date.now()).toISOString()}</h6> */}

            {/* <Grid item xs={12} md={1} lg={1}></Grid> */}

            <Grid item xs={12} md={12} lg={4}>
              <Box
                sx={{
                  //p: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",

                  //margin: 2,
                  // width: matches ? "100%" : 400,
                }}
              >
                {/* <DashboardContainer
          reportTitle="Reporting Module"
          myMarginTop={5}
          icon={
            <QueryStatsIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
          }
          myWidth={10}
          > */}

                {/* <h6>Date From: ...{tabInitialDate}</h6>
          <h6>Date To: ...{tabFinalDate}</h6> */}

                {/* <Grid item xs={12} md={12} lg={12}> */}
                <List key={`${Math.random()}`}>
                  {salesByConsultantsList.map((consultant) => {
                    const canx = cancellationsByConsultantsList.filter(
                      (item) => item.CONSULTANT_ID === consultant.CONSULTANT_ID
                    );
                    if (canx.length > 0) {
                       //console.log("CANX", canx[0].subTotal);
                      netSales = consultant.subTotal - canx[0].subTotal;
                      //netSales = consultant.subTotal
                      if( consultant.CONSULTANT_ID===6){
                        console.log("my Net Sales for Ethan subtotal 1...",consultant.subTotal)
                        console.log("my Net Sales for Ethan subtotal canx...",canx[0].subTotal)
                        console.log("my Net Sales for Ethan...",netSales)

                      }
                    } else {
                      netSales = consultant.subTotal;
                    }

                    BarChartData.push({
                      //Name: consultant.NAME,
                      Name: (matches3?
                      consultant.Firstname.charAt(0).toUpperCase():
                        consultant.Firstname.charAt(0).toUpperCase() + consultant.Firstname.toLowerCase().slice(1)) +
                        " " +
                        consultant.Lastname.charAt(0),
                      NetSales: netSales,
                      Sales: netSales.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      }),
                    });

                    return (
                      <Fragment key={`${Math.random()}`}>
                        <ListItem
                          key={`${Math.random()}`}
                          sx={{
                            width: matches ? "100%" : 300,
                            //color: "#4E1721",
                            //fontSize:"20rem"
                          }}
                        >
                          <ListItemAvatar key={`${Math.random()}`}>
                            <Avatar sx={{ bgcolor: "#e7dddd" }}>
                              <AccountCircleIcon
                                key={`${Math.random()}`}
                                sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <MyListItemText
                            key={`${Math.random()}`}
                            primary={
                              //consultant.NAME +
                              consultant.Firstname.charAt(0).toUpperCase() + consultant.Firstname.toLowerCase().slice(1) +
                              " " +
                              consultant.Lastname.charAt(0) +
                              " :  £ " +
                              netSales.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })
                            }
                            

                            //secondary={newClientStatus ? newClientStatus : "No Info..."}
                          />
                        </ListItem>
                        <Divider sx={{ width: "80%" }} />
                      </Fragment>
                    );
                  })}
                </List>
                {/* </Grid> */}
                {/* </DashboardContainer> */}
              </Box>
            </Grid>
            {/* </DashboardContainer> */}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      
    </Fragment>
  );
}
