import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

//Redux IMPORTS------------------------
import { Provider } from "react-redux";
import store from "./store/index";

//Context API Imports--------------------
import{SocketContext, mySocket} from './utils/socket-client';


ReactDOM.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
    <SocketContext.Provider value={mySocket}>

    <Provider store={store}>
      <App />
    </Provider>
    </SocketContext.Provider>
    {/* </React.StrictMode> */}
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
