import { createSlice } from "@reduxjs/toolkit";

export const kpisSlice = createSlice({
  name: "kpis",
  initialState: {
    receivablesInfo: {

        allOrdersWithPaymentsFull: [],
        allOutsData:null,
        allOutsData_first50:null,
        allOutsData_second50:null,
        lastUpdate_receivables:null,

      
    },
    // supplierAccount:{

    //   selectedSupplierTotalPurchases:0,
    //   selectedSupplierTotalPaid:0,

    // },
  },
  reducers: {
    updateReceivablesInfo: (state, action) => {
      const { payload } = action;

      //state.supplierInfo = { ...state.supplier, ...payload };
      state.receivablesInfo = { ...state.receivablesInfo, ...payload };


      //console.log("full payload from quote reducer new state...",payload)
    },

    // updateSupplierAccount: (state, action) => {
    //   const { payload } = action;

    //   state.supplierAccount = { ...state.supplierAccount, ...payload };

    //   //console.log("full payload from quote reducer new state...",payload)
    // },

    resetReceivablesInfo: (state, action) => {

      const { payload } = action;

      //console.log("inside the slice for reset payments in....",payload)

      state.receivablesInfo = {

        allOrdersWithPaymentsFull: [],
        allOutsData:null,
        allOutsData_first50:null,
        allOutsData_second50:null,
        lastUpdate_receivables:null,

      }

    //   state.supplierAccount={

    //     selectedSupplierTotalPurchases:0,
    //     selectedSupplierTotalPaid:0,
  
    //   };

      
    },
  },
});

export const kpisActions = kpisSlice.actions;

export default kpisSlice;
