import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

//loader Imports--------------
//import { SyncLoader } from "react-spinners";
import { PropagateLoader } from "react-spinners";

//---------------MUI IMPORTS -----------------------------
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
//import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
//import FolderIcon from "@mui/icons-material/Folder";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from '@mui/icons-material/Done';
import Alert from "@mui/material/Alert";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//My IMPORTS----------------
import Title from "../Title";
import theme from "../UI/Theme";
import {variables} from '../../Variables';

// const Demo = styled("div")(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
// }));

const QuoteStep_4 = (props) => {
  const currentQuoteInfo = useSelector((state) => state.quote.quoteInfo);

  const { socket, userInfo } = props;

  console.log("company Name", currentQuoteInfo.companyName);
  console.log("company Name", currentQuoteInfo.product.PRODUCT_ID);
  console.log("company Name", currentQuoteInfo.product.PUBSIZE_ID);


  const [lastClientId, setLastClientId] = useState("");
  const [lastPreconfoId, setLastPreconfoId] = useState("");

  const [saveStatus, setSaveStatus] = useState({});
  const [newClientStatus, setNewClientStatus] = useState({});
  const [preOrderStatus, setPreOrderStatus] = useState({});
  const [emailStatus, setEmailStatus] = useState({});

  //const [okImage, setOkImage] = useState("");
  //const [QuoteRef, setQuoteRef] = useState("");

  const [IsLoading, setIsLoading] = useState(false);

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // const failImage = `<img src='https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/critical_RLQRVnFd-.png?updatedAt=1633510133720' roundedCircle />`;
  // const MyOkImage = `<img src="https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/ok_YMgi1R5Ar.png?updatedAt=1633510133585" roundedCircle />`;
  //const failImage = "";
  //const MyOkImage = "";

  //handle notifications
  const submitNotification = (info) => {
    console.log("handleNotification starting from quote step 4...");

    socket.emit("sendNotification", {
      senderName: userInfo.username,
      senderPic:userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
    });
  };

  //Create the function to create (post) the NewClient and save in the DB ----------
  const postQuote_client = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
      COMPANY_NAME: currentQuoteInfo.companyName,
      TYPECO: currentQuoteInfo.companyType.COTYPE_ID,
      COMARKET_ID: currentQuoteInfo.companyMarket.COMARKET_ID,
      MAIN_CONTACT: currentQuoteInfo.clientFirstName,
      EMAIL: currentQuoteInfo.clientEmail,
      TIME: new Date(),
    };

    //Create (post) a New client ---------------
    //"http://localhost:5000/clients/newclient",
    axios
      .post(
        variables.API_URL + "clients/newclient", 
        data, config)

      .then((response) => {
        setNewClientStatus({
          message: " New Client Created Successfully",
          status: true,
        });
        //setOkImage(true);
        setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewClientStatus({
          message: "  Something went Wrong ! New Client NOT saved",
          status: false,
        });

        setSaveStatus({
          message: "  There were a problem, Quote NOT Saved ",
          status: false,
        });
        //setOkImage(false);
        setEmailStatus({
          message: "Quote Email NOT sent, Please contact your Admin Team !",
          status: false,
        });
      });
  };
  // end of create the function to create (post) the NewClient and save in the DB ----------

  //create the function to Create (post) a New Preconfirmation after taken the New client Id from previous post  -----------------

  const postNewPreconfo = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
      PROJECT_ID: currentQuoteInfo.project.PROJECT_ID,
      DATE: new Date(),
      CLIENT_ID: lastClientId,
      NET_AMOUNT: currentQuoteInfo.price,
      PRODUCT_ID: currentQuoteInfo.product.PRODUCT_ID,
      PUBSIZE_ID: currentQuoteInfo.product.PUBSIZE_ID,
      TIME: new Date(),
    };

    //Create a New Preorder-------------------------------
    //"http://localhost:5000/orders/newpreorder", 
    axios
      .post(
        variables.API_URL + "orders/newpreorder",
        data, config)

      .then((response) => {
        console.log(response);
        console.log("preconfo all good here...", response.status);

        console.log(response.data);
        console.log(response.status);

        setPreOrderStatus({
          message: "Pre-Order Created Successfully",
          status: true,
        });

        setLastPreconfoId(response.data.newPreConfirmation.insertId);
        console.log(
          "thelatest Preconfirmation id before posting the confo",
          response.data.newPreConfirmation.insertId
        );
      })
      .catch(function (error) {
        console.log(error);
        setPreOrderStatus({
          message:
            "Something went wrong ! Pre-Order NOT saved... Please contact Admin Team",
          status: false,
        });
      });
  };
  // end of the function to post the Preconfo taken the client Id from previous pos to save in the DB ----------

  //Function to send the email--------------------------------

  const sendQuoteEmail = () => {
    setIsLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
      companyName: currentQuoteInfo.companyName,
      companyType: currentQuoteInfo.companyType.COTYPE,
      companyMarket: currentQuoteInfo.companyMarket.COMARKET_NAME,
      companyEmail: currentQuoteInfo.clientEmail,
      clientFirstName: currentQuoteInfo.clientFirstName,
      clientLastName: currentQuoteInfo.clientLastName,
      product: currentQuoteInfo.product.product_name,
      project:
        currentQuoteInfo.project.CLUB_NAME + currentQuoteInfo.project.YEAR,
      priceOffer: currentQuoteInfo.price,
      phone: "+49176 5697 2222",
      message:
        "We are looking forward to Welcoming you on Board soon ! please click here to confirm your quote",
      quoteKey: currentQuoteInfo.quoteKey,
    };


//Send Email with Quote ------------------------
//"http://localhost:5000/send-email",
    axios
      .post(
        variables.API_URL + "send-email", 
        data, config)
      .then(function (response) {
        console.log(response);
        if (response.status === 250) {
          console.log("all good here...", response.status);
          setEmailStatus({
            message: "Quote Email Sent Successfully to the client !",
            status: true,
          });

          submitNotification({
            type: 1,
            name: "Quote",
            notificationDate: new Date().toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
          });
        } else {
          console.log("error", response.status);
          // setEmailStatus("Your Quote Email has been sent Successfully ! ");
          setEmailStatus({
            message: "Quote Email NOT sent, Please contact your Admin Team ! ",
            status: false,
          });
        }

        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setEmailStatus({
          message:
            "Something went wrong ... Quote Email NOT sent, Please contact your Admin Team !",
          status: false,
        });
      });
  };
  //end of Function to send the email ------------------------

  // Function to create (post) a new quote---------------------------------------------
  const postQuote = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
      CLIENT_ID: lastClientId,
      PRECONFIRMATION_ID: lastPreconfoId,
      QUOTECODE: currentQuoteInfo.quoteKey,
      QUOTESTATUS_ID: 1,
      TIME: new Date(),
    };

    //Create (post) a New Quote----------------------
    //"http://localhost:5000/clients/newquote",
    axios
      .post(
        variables.API_URL + "clients/newquote", 
        data, config)
      .then(function (response) {
        setSaveStatus({
          message: "Quote Saved Successfully",
          status: true,
        });

        sendQuoteEmail();
      })
      .catch(function (error) {
        console.log(error);
        setSaveStatus({
          message:
            "Something went wrong ... Quote NOT Saved ! please contact Admin Team",
          status: false,
        });
      });
  };
  // end of the function to create (post) the New Quote  -----------------------------

  //Step I Creating New Client
  useEffect(() => {
    postQuote_client();
  }, []);

  //Step II Create New Pre-Confirmation
  useEffect(() => {
    if (lastClientId) {
      postNewPreconfo();
    }
  }, [lastClientId]);

  //Step III  Create New Quote
  useEffect(() => {
    if (lastPreconfoId) {
      postQuote();
    }
  }, [lastPreconfoId]);

  return IsLoading ? (
    <Box
      // textAlign="center"
      sx={{
        p: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      {/* <SyncLoader color="#7A1125" size="8" /> */}
      <PropagateLoader color="#7A1125" size="8" />
      <Alert severity="info">Your Quote is on its way ! — Please wait...</Alert>
    </Box>
  ) : (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        {saveStatus.status &&
        newClientStatus.status &&
        preOrderStatus.status &&
        emailStatus.status ? (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            <Title sx={{ margin: "auto" }}>
              QUOTE SUCCESSFULLY SENT TO THE CLIENT !
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              QUOTE NOT SENT, CONTACT YOUR ADMIN TEAM !
            </Title>
          </Box>
        )}

        <List >
          <ListItem>
            {newClientStatus.status ? (
              <DoneIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}

            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="New Client Status"
              secondary={
                newClientStatus.message ? newClientStatus.message : "No Info..."
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            {preOrderStatus.status ? (
              <DoneIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}

            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <StickyNote2OutlinedIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Pre-Order Status"
              secondary={
                preOrderStatus.message ? preOrderStatus.message : "No Info..."
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            {saveStatus.status ? (
              <DoneIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}

            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{
                fontSize: 30,
                color: "#4E1721",
                mr: 1,
                backgroundColor: saveStatus.status ? "" : "#e7dddd",
              }}
              primary="Quote Status"
              secondary={saveStatus.message ? saveStatus.message : "No Info..."}
            />
          </ListItem>
          <Divider />
          <ListItem>
            {emailStatus.status ? (
              <DoneIcon
                sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
              />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
                <ForwardToInboxIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{
                fontSize: 30,
                color: "#4E1721",
                mr: 1,
                backgroundColor: saveStatus.status ? "" : "#e7dddd",
              }}
              primary="Email Status"
              secondary={
                emailStatus.message ? emailStatus.message : "No Info..."
              }
            />
          </ListItem>
        </List>
      </Box>

      {/* {saveStatus && saveStatus} */}
      {/* {newClientStatus && newClientStatus} */}
      {/* {preOrderStatus && preOrderStatus} */}
      {/* {emailStatus && emailStatus} */}

      {/* {emailStatus && saveStatus ? 
        <div className="quoteCreated">
          <h3>{saveStatus}</h3>
          <h3>{emailStatus}</h3>

          {okImage ? 
            <div>
              <img
                src="https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/ok_YMgi1R5Ar.png?updatedAt=1633510133585"
                roundedCircle
                />
            </div>
           : 
            <div>
              <img
                src="https://ik.imagekit.io/bwcdq46tkc8/s4b-consulting/critical_RLQRVnFd-.png?updatedAt=1633510133720"
                roundedCircle
                />
            </div>}
          

        </div>

        : "No email status and No save Status information.... "


        }
      */}
    </>
  );
};

export default QuoteStep_4;
