import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    messages: [],
    notificationsType:[],
  },
  reducers: {
    updateMessagesList: (state, action) => {
      const { payload } = action;
      //const currentMessagesList = state.messages
      state.messages = payload.messagesList;
    },
    updateNotificationsTypeList: (state, action) => {
      const { payload } = action;
      //console.log("notification slice... ", payload)
      //const currentMessagesList = state.messages
      //state.notificationsType = payload.notificationsType;
      state.notificationsType = payload.notificationsList;

    },
  },
});

export const { updateMessagesList, updateNotificationsTypeList } = notificationsSlice.actions;

export default notificationsSlice;
