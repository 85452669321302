import { Fragment, useState } from "react";

import { MyTable, MyTableRow, StyledTableCell} from "../UI/styledComponents/StyledComponents";
import Title from "../Title";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {TableCell, TableHead}  from "@mui/material";
//import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@mui/material/Button";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";



const OrderData = (props) => {
  const { selectedOrder } = props;
  const [showOrderDetails, setShowOrderDetails] = useState(true);
  
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  //console.log("selected Order from orderDAta component.....", selectedOrder);

  let orderInfo1 = [];
  let orderInfo2 = [];
  let orderInfo3 = [];


  const showOrderDetailsHandler = (event) => {
    event.preventDefault();
    setShowOrderDetails((prev) => !showOrderDetails);
  };

  if (selectedOrder.length > 0) {
    //Order Left Side Invoice Data Client Data ----------------------------------------------------------- 
    orderInfo1 = [
      {
        label: "Company: ",
        value: !matches3? selectedOrder[0].COMPANY_NAME.substring(0, 50) : selectedOrder[0].COMPANY_NAME.substring(0, 20) ,
      },
      {
        label: "Contact: ",
        value: !matches3? selectedOrder[0].MAIN_CONTACT.substring(0, 50): selectedOrder[0].MAIN_CONTACT.substring(0, 20),
      },
      {
        label: "Address: ",
        value: !matches3? (
          selectedOrder[0].ADDRESS1 +
          " " +
          selectedOrder[0].ADDRESS2
        ).substring(0, 50): (
          selectedOrder[0].ADDRESS1 +
          " " +
          selectedOrder[0].ADDRESS2
        ).substring(0, 20),
      },
      { label: "Town: ", value: !matches3? selectedOrder[0].TOWN.substring(0, 50): selectedOrder[0].TOWN.substring(0, 20) },
      {
        label: "County: ",
        value: !matches3? selectedOrder[0].ClientCounty.substring(0, 50): selectedOrder[0].ClientCounty.substring(0, 20),
      },
      {
        label: "Post C.: ",
        value: !matches3? selectedOrder[0].ClientPost.substring(0, 50): selectedOrder[0].ClientPost.substring(0, 20),
      },
    ];

    //Order Right Side Invoice Data ---------------------------------------------------------------- 
    orderInfo2 = [
      {
        label: "Date: ",
        value: new Date(selectedOrder[0].DATE).toLocaleString("default", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
      },
      { label: "Tel: ", value: selectedOrder[0].TEL },
      {
        label: "Email: ",
        value: selectedOrder[0].ClientEmail,
      },
      {
        label: selectedOrder[0].EMAIL_2 ? "Art Email: " : "...",
        value: selectedOrder[0].EMAIL_2.substring(0, 50),
      },
      { label: "Designer: ", value: selectedOrder[0].STYLEDESIGNER_ID },
      { label: "Sales Consultant: ", value: selectedOrder[0].ConsultantName },
    ];


    //Order Details ----------------------------------------------------------------
    orderInfo3 = [
      {
        label: selectedOrder[0].product_name + " --- " + selectedOrder[0].pubsize,
        value: " £ " + selectedOrder[0].NET_AMOUNT.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        value2: " £ " + selectedOrder[0].VAT_AMOUNT.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })
        ,
      },
      { label: "...  " + selectedOrder[0].PUBLIST, 
        value: "..." ,
        value2: "..." ,
      },
      {
        label: "...  " + selectedOrder[0].DESIGNDETAILS,
        value: "...",
        value2: "...",
      },
      {
        label: "...  " + selectedOrder[0].EXCLUSIVITYDETAILS ,
        value: "...",
        value2: "...",
      },
      {
        label: "...  " + selectedOrder[0].PAYMENTDETAILS ,
        value: "...",
        value2: "...",
      },
      
    ];



  }

  // const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return selectedOrder.length > 0 ? (
    <Fragment>
      <Box
      sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
      }}
      >

      <Title>
        {selectedOrder[0].CLUB_NAME} {selectedOrder[0].YEAR}
      </Title>
      <Title>
        {selectedOrder[0].CLUB_CODE + " - "+ selectedOrder[0].CONFIRMATION_REFERENCE}
      </Title>

      </Box>

      <Divider />

      {/* <p>Company: {selectedOrder[0].COMPANY_NAME}</p>
      <p>Contact: {selectedOrder[0].MAIN_CONTACT}</p>
      <p>
        Address: {selectedOrder[0].ADDRESS1} {selectedOrder[0].ADDRESS2}{" "}
      </p>
      <p>Town: {selectedOrder[0].TOWN}</p>
      <p>County: {selectedOrder[0].COUNTY}</p>
      <p>PostCode: {selectedOrder[0].POST_CODE}</p> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: !matches2 ? "row" : "column",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 2,
        }}
      >
        {/* <TableContainer component={Paper} sx={{ width: !matches2? "30vw": "80vw", marginTop:2 }}> */}
        <TableContainer component={Paper} sx={{ width: matches3? "85vw":  matches2? "80vw" : matches?"40vw":"30vw", marginTop:2 }}>

          <MyTable size="small">
            <TableBody>
              {orderInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches3 && <TableCell>{info.label}</TableCell>}
                  <TableCell  align={matches3?"center" : matches2?"right" : "left"} >
                    {info.value}
                  </TableCell>
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
        
        {/* <TableContainer component={Paper} sx={{ width: !matches2? "30vw": "80vw", marginTop:2 }}> */}
        <TableContainer component={Paper} sx={{ width: matches3? "85vw":  matches2? "80vw" : matches?"40vw":"30vw", marginTop:2 }}>

          <MyTable size="small">
            <TableBody >
              {orderInfo2.map((info) => (
                <MyTableRow key={info.value}  >
                  {/* {!matches && <StyledTableCell >{info.label}</StyledTableCell>} */}
                  {!matches3 && <TableCell >{info.label}</TableCell>}
                  <TableCell  align={matches3?"center" : matches2?"right" : "left"} >
                    {info.value || "..."}
                    </TableCell>
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>

      {/* NewCodeSep22  */}
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showOrderDetailsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />

          {/* {props.changeDates ? "View This Week" : "View All Year"} */}

          {showOrderDetails ? "Hide Details" : "Show Details"}
        </Button>
      </Box>

      {showOrderDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: 2,
          }}
        >
          {/* <TableContainer component={Paper} sx={{ width: "63vw" }}> */}
          <TableContainer component={Paper} sx={{ width: !matches? "63vw": "80vw"  }}>
           
            <MyTable size="small">
            <TableHead>
          <MyTableRow>
          {!matches3 && 
            <StyledTableCell>Description</StyledTableCell>}
            <StyledTableCell align="right">Net</StyledTableCell>
            <StyledTableCell align="right">VAT</StyledTableCell>
            
          </MyTableRow>
        </TableHead>
              <TableBody>
                {orderInfo3.map((info) => (
                  <MyTableRow key={info.value + Math.random()}>
                    {!matches3 && 
                    <TableCell>{info.label}</TableCell>}
                    <TableCell align="right">{info.value}</TableCell>
                    <TableCell align="right">{info.value2}</TableCell>
                  </MyTableRow>
                ))}
              </TableBody>
            </MyTable>
          </TableContainer>
          
        </Box>
      )}

      {/* EndNewCodeSep22 */}
    </Fragment>
  ) : (
    "..."
  );
};

export default OrderData;
