import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import DashboardContainerReportList from "../UI/DashboardContainerReportList";
import OrderData from "./OrderData";

//import {commentsActions} from  "../../store/comments-slice";
import { useDispatch } from "react-redux";

import axios from "axios";

import { orderActions } from "../../store/order-slice";

import { variables } from "../../Variables";

//import theme from "../UI/Theme";
import Virtualize from "./VirtualList";

//------MUI IMPORTS------------------------------------------
import Box from '@mui/material/Box';
//import useMediaQuery from "@material-ui/core/useMediaQuery";

// import Button from "@mui/material/Button";
// import Autocomplete from "@mui/material/Autocomplete";
// import { createFilterOptions } from '@mui/material/Autocomplete';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Box from "@mui/material/Box";

const Orders = () => {
  const dispatch = useDispatch();

  //const showComments = useSelector(state =>state.comments.commnetsAreVisible);

  const showOrder = useSelector((state) => state.orders.showOrder);
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  //console.log("showOrder...?",showOrder)

  // const showCommentsHandler = ()=> {
  //     dispatch( commentsActions.toggle())
  // }

  const handleOrderSelectedChange = () => {};

  const [consultantList, setConsultantList] = useState([]);
  //const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //fetching orders from DB From the last 5 years (2017)------------------------------------------- 
  //"http://localhost:5000/orders",
  const getAllOrders = () => {
    const selectedDatesFrom = "2017-01-01"
    axios
      //get(variables.API_URL + "orders/allorders", { withCredentials: true }
      .get(variables.API_URL + "orders/allorders/" + selectedDatesFrom, { withCredentials: true })
      .then((res) => {
        setConsultantList(res.data.orderInfo);
        dispatch(orderActions.addFullOrdersList(res.data.orderInfo));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllOrders();

    return () => {
      dispatch(
        orderActions.selectOrder({
          selectedOrderRef: [],
        })
      );
    };
  }, []);

  //console.log("this is the consultant list....", consultantList);

  //const box1Content = <Virtualize listFiltered={consultantList}  />

  const box1Content = <Virtualize listFiltered={consultantList} />;
  //const box1Content = 'box1'
  const box2Content = "Options here...";
  const box3Content = showOrder ? (
    <OrderData selectedOrder={selectedOrder} />
  ) : (
    ""
  );
  const box4Content = "Box3...";

  return (
        

        <DashboardContainerReportList
          box1={box1Content}
          box2={box2Content}
          box3={box3Content}
          box4={box4Content}
          matches=""
          notification=""
          ListFiltered={consultantList}
          handleListItemChange={handleOrderSelectedChange}
          handleGetReport_2=""
          reportVariables=""
          IsLoading=""
          reportTitle="Orders ..."
          reportTitleOptions="Actions ..."
        />

    
  );
};

export default Orders;

