import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";
import { paymentPlanActions } from "../../store/paymentPlan-slice";

import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";


// MUI IMPORTS---------------------------------------------------

import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';


import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";



//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: "#4E1721",
  
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));





function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};




const ChangePayDateStep_1 = (props) => {
  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  const dispatch = useDispatch();
  //const selectedOrder = useSelector((state) => state.orders.selectedOrder);


  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());

  //const { paymentTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const locale = "de";


  //--------------------------------------------------------datemanager---------
  const handleDatesChangesFromTo = (date) => {
    const dateIsValid = date !== null && date !== "Invalid Date";


    setSelectedPaymentDate(date);

    if (dateIsValid) {
      //Add the Selected Order to the Payments In information
      dispatch(
        paymentInActions.updatePaymentIn({
            paymentDateUpdated: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
            //paymentReference: currentPaymentInfo.paymentReference + "-x"
            //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        })
      );

    } else {
      console.log("error, date not usable only 2 options available");
      return;
    }
  };
  //-------------------------------------------------------END datemanager------



  let paymentInfo1 = [];

//   if (currentPaymentInfo) {
  if(currentPaymentInfo?.selectedOrder.length > 0 ){
    //console.log("Order number...",currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    paymentInfo1 = [
      { label: "Order : ", value: currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE },
      { label: "Company: ", value: currentPaymentInfo.selectedOrder[0].COMPANY_NAME },
      { label: "Total Paid: ", value: "£ " + parseFloat(currentPaymentInfo.amountPaid).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      }) },
      { label: "Payment Ref: ", value: currentPaymentInfo.paymentReference },
      { label: "Payment Type: ", value: currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_REF, id:currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_ID,
      icon:
      [
      <AccountBalanceOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <ReceiptLongOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <CreditCardOffOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <ContactlessOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      <AlternateEmailOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft:2 }} />,
      ], 
    },
    { label: "... ", value: "..." },
    { label: "Payment Date: ", value: new Date(currentPaymentInfo.paymentDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }),
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"900"
     },

    ];
  }



  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >



        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          

          {/* <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="New Bank Date"
            value={selectedPaymentDate}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params} 
                //sx={{ margin: matches ? "0.2em" : "1em" }}
                sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 400, margin: "1em" }
                  }
              />
            )}
          /> */}


          {/* //Table with Summary before change-------------- */}
          <TableContainer
          component={Paper}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Payment Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ fontWeight: info.myFontWeight }}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    style={{ color: info.myFont , fontWeight: info.myFontWeight }}
                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
          {/* //End Table with Summary before change---------- */}

          <Divider sx={{width: matches? "30vw" : "10vw", marginTop:3, marginBottom:3}}/>

          {/* To choose the new Payment Date on Bank Statement */}
          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="New Bank Date"
            value={selectedPaymentDate}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params} 
                //sx={{ margin: matches ? "0.2em" : "1em" }}
                sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 400, margin: "1em" }
                  }
              />
            )}
          />
          {/* END To choose the new Payment Date on Bank Statement */}







        </Box>

<Divider />

</Box>

       
      </Fragment>
    </LocalizationProvider>
  );
};

export default ChangePayDateStep_1;
