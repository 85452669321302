import { createSlice } from "@reduxjs/toolkit";

export const purchasePaymentSlice = createSlice({
  name: "purchasePaymentOut",
  initialState: {
    purchasePaymentOutInfo: {
      selectedPurchaseInvoice: [],
      //selectedSupplier: {},
      selectedPurchaseInvoiceTotalPaid:0,
      purchaseInvoiceAmountPaid: 0,
      purchasePaymentType: 
        {
          firstLetter: "B",
          PAYTYPE_ID: 1,
          PAYTYPE: "BACS TRANSFER",
          PAYTYPE_REF: "Bacs",
        },
      
      //paymentDate: new Date(),
      purchasePaymentDate:"",
      purchasePaymentReference: "",
      purchasePaymentFirma:"4",
      purchasePaymentVatRate:"1.200",

      //paymentDateUpdated:"",
    },
  },
  reducers: {
    updatePurchasePaymentOut: (state, action) => {
      const { payload } = action;

      state.purchasePaymentOutInfo = { ...state.purchasePaymentOutInfo, ...payload };

      //console.log("full payload from quote reducer new state...",payload)
    },

    resetPurchasePaymentOut: (state, action) => {

      const { payload } = action;

      console.log("inside the slice for reset payments in....",payload)

      state.purchasePaymentOutInfo = {
        selectedPurchaseInvoice: [],
        //selectedSupplier: {},
        selectedPurchaseInvoiceTotalPaid:0,
        purchaseInvoiceAmountPaid: 0,
        purchasePaymentType: 
          {
            firstLetter: "B",
            PAYTYPE_ID: 1,
            PAYTYPE: "BACS TRANSFER",
            PAYTYPE_REF: "Bacs",
          },
      
        //purchasePaymentDate: payload.paymentDate,
        purchasePaymentDate: payload.purchasePaymentDate,

        purchasePaymentReference: "",
        purchasePaymentFirma:"4",
        purchasePaymentVatRate:"1.200",


      };
    },
  },
});

export const purchasePaymentOutActions = purchasePaymentSlice.actions;

export default purchasePaymentSlice;
