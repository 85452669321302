import { Fragment } from "react";

import { useSelector } from "react-redux";

import theme from "../UI/Theme";

//MUI IMPORTS----------------------------
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const PaymentStatusOnOrder = (props) => {
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);
  const currentPaymentInfo = useSelector(
    (state) => state.paymentIn.paymentInfo
  );

  const { isLoading1_allPayOnOrder } = props;

  return (
    <Fragment>
      {selectedOrder?.length > 0 ? (
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {selectedOrder[0].TOTAL_AMOUNT -
            currentPaymentInfo?.selectedOrderTotalPaid ===
          0 ? 
            <Box sx={{display:"Flex", flexDirection:"row", alignItems:"center"}}>
              <CheckCircleOutlineIcon
                sx={{
                  color: theme.palette.common.okGreen,
                  fontSize: 50,
                  marginTop: 2,
                }}
              />

              <Typography
                variant="h6"
                sx={{ color: theme.palette.common.okGreen, marginTop: 2 }}
              >
                PAID
              </Typography>
            </Box>
           : 
            "..."
          }

          <Divider sx={{ width: "80%" }} />

          <Typography
            color="text.secondary.main"
            component="p"
            sx={{ fontSize: "0.8rem", marginTop: 2 }}
          >
            {"T. Paid: £ " +
              (currentPaymentInfo?.selectedOrderTotalPaid).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: 2,
                }
              )}
          </Typography>

          {/* <Typography
            color="text.secondary"
            component="p"
            sx={{ fontSize: "0.8rem", marginTop: 1 }}
          >
            {"Balance £ " +
              (
                selectedOrder[0].TOTAL_AMOUNT -
                currentPaymentInfo?.selectedOrderTotalPaid
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
              })}
          </Typography> */}
        </Grid>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default PaymentStatusOnOrder;
