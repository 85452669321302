import axios from "axios";
import { variables } from "../../Variables";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const UserStep_4 = () => {

    const currentUserInfo = useSelector((state) => state.user);

    const newUserInfo = useSelector((state) => state.userDetails.userInfo);

    // console.log("New user info here..............................!!! aquiiii", newUserInfo)
    // console.log("Current user info here..............................!!! aquiiii", currentUserInfo)





  //Create the function to create (post) the NewUser and save It in the DB ----------
  const createNewUser = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
        firstName: newUserInfo.firstName,
        status: "2",
        titleId:newUserInfo.userTitle.TITLE_ID,
        wBasic:newUserInfo.wBasic,
        commissionTypeId:newUserInfo.userCommissionType.COMMISSIONTYPE_ID,
        teamId:newUserInfo.userTeam.TEAM_ID,
        userEmail:newUserInfo.userEmail,
        userMobile:newUserInfo.userMobile,
        userPicUrl:newUserInfo.userPicUrl,
        userPhoneBill:newUserInfo.userPhoneBill,
        userUTR:newUserInfo.userUTR,
        userNI:newUserInfo.userNI,
        lastName:newUserInfo.lastName,
        userPassword:newUserInfo.userPassword,
        M_USER:currentUserInfo.userId,
        TIME: new Date(),

    //   COMPANY_NAME: currentQuoteInfo.companyName,
    //   TYPECO: currentQuoteInfo.companyType.COTYPE_ID,
    //   COMARKET_ID: currentQuoteInfo.companyMarket.COMARKET_ID,
    //   MAIN_CONTACT: currentQuoteInfo.clientFirstName,
    //   EMAIL: currentQuoteInfo.clientEmail,
    };

    //Create (post) a New User ---------------
    //"http://localhost:5000/clients/newclient",
    axios
      .post(variables.API_URL + "users/createnewuser", data, config)

      .then((response) => {
        // setNewClientStatus({
        //   message: " New Client Created Successfully",
        //   status: true,
        // });
        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        // setNewClientStatus({
        //   message: "  Something went Wrong ! New Client NOT saved",
        //   status: false,
        // });

        // setSaveStatus({
        //   message: "  There were a problem, Quote NOT Saved ",
        //   status: false,
        // });
        // //setOkImage(false);
        // setEmailStatus({
        //   message: "Quote Email NOT sent, Please contact your Admin Team !",
        //   status: false,
        // });
      });
  };
  // end of create the function to create (post) the New User and save It in the DB ----------


  //Step I Creating New User as consultant
  useEffect(() => {
    createNewUser();
  }, []);


  return <h6>User Step 4 Final....New !</h6>;
};

export default UserStep_4;
