import { Fragment, useState } from "react";
import Title from "../Title";


//MUI IMPORTS--------------------------------------------------
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";


//TOBE DELETED---------------------------------------------------------------------
//import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
//import Button from "@mui/material/Button";
//import TimelineIcon from '@mui/icons-material/Timeline';
//import MyDialog from "../UI/Dialog"
// function preventDefault(event) {
//   event.preventDefault();
// }

export default function SummaryReport(props) {
  //console.log("from summary report component...yessssssssssssssssssssssss", props);

  //const[showDetails, setShowDetails]=useState(null)

  const {
    totalSalesByConsultant,
    totalSalesCancelledByConsultant, 
    totalPagesByConsultant,
    totalPagesCancelledByConsultant,
    link
    //salesByDateList, 
    //salesCancelledByDateList, 
    //tabInitialDate, 
    //tabFinalDate,

  } = props

  



  // const showDetailsHandler = ()=>{
  //   setShowDetails((prev)=> !showDetails)
  // }

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          p: 2,
          // display: "flex",
          // flexDirection: "column",
          // justifyContent:"space-between",
          //height: 265,
          height: props.boxSize,
          bgcolor: "#fafafa",
          //bgcolor: "#000",
          borderRadius: 1,
          margin: "auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#4E1721",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop:1
          }}
        >
          <LocalOfferIcon
            sx={{
              fontSize: 20,
              color: "#4E1721",
              marginRight: 2,
              marginTop: -1,
            }}
          />
          <Title>{props.summaryInfo.title}</Title>
        </Typography>

        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography variant="h6">
            {totalPagesByConsultant
              ? `£ ${(
                  totalSalesByConsultant -
                  totalSalesCancelledByConsultant
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`
              : "£0.00"}
          </Typography>

          <Divider sx={{ width: "80%" }} />

          <Typography
            color="text.secondary.main"
            component="p"
            sx={{ fontSize: "0.8rem", marginTop:props.boxMargin }}
          >
            {totalSalesByConsultant
              ? "Sales: £ " +
                totalSalesByConsultant.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : "..."}
          </Typography>

          <Typography
            color="text.secondary"
            component="p"
            sx={{ fontSize: "0.8rem" }}
          >
            {totalSalesCancelledByConsultant
              ? "Cancelled £ " +
                totalSalesCancelledByConsultant.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })
              : "..."}
          </Typography>
        </Grid>

        {/* <Divider  sx={{width:'80%'}} /> */}

        <Grid item xs={2}>
          <Typography
            // variant="h6"
            sx={{ fontSize: "1.0rem" , marginTop:props.boxMargin }}
          >
            {totalPagesByConsultant
              ? `£ ${(
                  (totalSalesByConsultant -
                    totalSalesCancelledByConsultant) /
                  (totalPagesByConsultant -
                    totalPagesCancelledByConsultant)
                ).toFixed(2)} / page` 
              : "£0.00 per page"}
          </Typography>
        </Grid>
        {/* <Divider  sx={{width:'80%'}} /> */}
        <Grid item xs>
          <Typography sx={{ flex: 1 }}>
            {totalPagesByConsultant -
              totalPagesCancelledByConsultant}{" "}
            pages
          </Typography>
        </Grid>
        {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
        </div> */}

        {/* <Divider  sx={{width:'80%'}} /> */}
      </Grid>

      {link && (
        // <Button
        //   //onClick={() => props.changeDatesHandler()}
        //   //onClick={(event)=> props.showGraphHandler(event)}
        //   onClick={(event)=> {
        //     event.preventDefault()
        //     //props.showGraphHandler(event)
        //   }}

        //   sx={{ color: "#7A1125", textTransform: "capitalize" }}
        // >
        //   <TimelineIcon
        //     sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
        //   />
          
        //   {/* {props.changeDates ? "View This Week" : "View All Year"} */}
          
        //   {props.showGraph ? "current" : "compare"}
          
        // </Button>

        <Fragment>


        {/* <Button
        // onClick={() => props.showDetailsHandler()}
        onClick={() => showDetailsHandler()}
        sx={{ color: "#7A1125", textTransform: "capitalize" }}
        >
        <DateRangeTwoToneIcon
          sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />
        </Button> */}
        
        {/* {showDetails ? "Hide Details" : "View Details"} */}
        {/* {props.showDetails ? "Hide Details" : "View Details"} */}


        {/* <MyDialog 
        salesByDateList={salesByDateList}
        salesCancelledByDateList={salesCancelledByDateList}
        showDetails={showDetails}
        showDetailsHandler={showDetailsHandler}
        title="Sales"
        tabInitialDate={tabInitialDate}
        tabFinalDate = {tabFinalDate}
        totalPagesByConsultantHandler={totalPagesByConsultantHandler}
        /> */}

      </Fragment>



      )}
    </Fragment>
  );
}
