import { useState } from "react";
import theme from "../UI/Theme";

//MY IMPORTS---------------------------------------------
import DateSelector from "./DateSelector"
import MainDashboardTabs from "./tabsContent/MainDashboardTabs"
import ArtworkTrackerTabs from "./tabsContent/ArtworkTrackerTabsTOBEDELETED"

//MUI IMPORTS --------------------------------------------
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
//import GroupAddIcon from "@mui/icons-material/GroupAdd";
//import GroupIcon from "@mui/icons-material/Group";
import Box from "@mui/material/Box";
import TodayIcon from "@mui/icons-material/Today";
import UndoIcon from "@mui/icons-material/Undo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DateRangeIcon from '@mui/icons-material/DateRange';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MyTabs = (props) => {
  //value for the tabs--------------------
  const [value, setValue] = useState(0);

  const { changeTabsHandler, dateSelectorHandler, tabContent } = props;

  //const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //handle change for TABS---------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTabsHandler(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: matches3 ? 0 : 1,
        width: matches3 ? "90vw" : "auto",
        
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          height: matches3 ? "20vh" : "auto",
          marginTop: matches3 ? 0 : 3,
        }}
        
      >
        {tabContent === "MainDashboardTaps" && (
          <MainDashboardTabs
            handleChange={handleChange}
            a11yProps={a11yProps}
            value={value}

          />
        )}

        {tabContent === "ArtworkTrackerTaps" && (
          <ArtworkTrackerTabs
            handleChange={handleChange}
            a11yProps={a11yProps}
            value={value}
            TabPanel={TabPanel}
          />
        )}

        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          // centered
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          orientation={ matches3?"vertical" : "horizontal"}
          //sx={{display:'flex',  flexDirection:'column', justifyContent:'center', width:"95vw", marginTop:0 }}
          sx={{ width:"95vw", marginTop:0 }}
        >
          <Tab
            icon={<TodayIcon sx={{fontSize: matches3?"1.70rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="This Week..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(0)}
          />
          <Tab
            icon={<UndoIcon sx={{fontSize: matches3?"1.70rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="Previous Week..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(1)}
          />
          <Tab
            icon={<CalendarTodayIcon sx={{fontSize: matches3?"1.8rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="This Month..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(2)}
          />
          <Tab
            icon={<UndoIcon sx={{fontSize: matches3?"1.70rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="Previous Month..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(3)}
          />
          <Tab
            icon={<EventNoteIcon sx={{fontSize: matches3?"1.7rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="This year..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(4)}
          />
          <Tab
            icon={<UndoIcon sx={{fontSize: matches3?"1.70rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="Previous year..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(5)}
          />

          <Tab
            icon={<DateRangeIcon sx={{fontSize: matches3?"1.7rem":matches2?"1":"1.50rem"}} />}
            iconPosition="start"
            label="Select Dates..."
            sx={{fontSize: matches3?"0.70rem":matches2?"0.75rem":"0.70rem", minHeight:"10vh"}}
            {...a11yProps(6)}

            />
            
        </Tabs> */}
      </Box>

      <TabPanel value={value} index={0}>
        {props.children}
        {/* <UserDetails /> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {props.children}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {props.children}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {props.children}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {props.children}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {props.children}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {value === 6 && (
          <DateSelector dateSelectorHandler={dateSelectorHandler} />
        )}
        {props.children}
      </TabPanel>
    </Box>
  );
};

export default MyTabs;
