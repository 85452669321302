import {Fragment} from "react";
import theme from './UI/Theme';
import Title from "./Title";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import { LineChart, Line } from "recharts";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];


//Toottip Customized-----------
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        //console.log("payload...", payload[0].payload)
        

        

      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : £ ${(payload[0].payload.Sales).toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }
  
    return null;
  };



//export default class Example extends PureComponent {

const MyBarChart = (props) => {

    

    const {BarChartData, myTitle, myXSmall, mySmall, myMedium, myLarge, myXLarge} = props
    const myLabel = props.myLabel;
    const myBarColor = props.myBarColor;

    //console.log("my lable", myLabel)

    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));
    const matches4 = useMediaQuery(theme.breakpoints.down("xs"));



    


  return (
    // <BarChart width={600} height={300} data={data}>
    //<BarChart width={600} height={300} data={BarChartData} >
    <Fragment>
    <Title>{myTitle}</Title>
    {/* <BarChart width={matches3? 280: matches2? 480 : matches? 460 : 600}  height={300} data={BarChartData}  > */}
    <BarChart width={matches4? myXSmall||20:matches3? mySmall||280: matches2? myMedium||480 : matches? myLarge||460 : myXLarge||600}  height={300} data={BarChartData}  >
    {/* <BarChart width={matches? myXLarge||600:matches2? myLarge||460: matches3? myMedium||480 : matches4? mySmall||280 : myXSmall||20}  height={300} data={BarChartData}  > */}


    
    <XAxis dataKey="Name" stroke="#8884d8" style={{fontSize: matches3? '0.5rem':'0.7rem', fill:theme.palette.common.ludisMain2, fontWeight:500}}   />
    {!matches3 &&
    <YAxis style={{fontSize:matches2? '0.5rem':'0.7rem', fill: myBarColor || theme.palette.common.ludisMain}}  />
    }
    {/* <Tooltip wrapperStyle={{ width: "auto", backgroundColor: '#e7dddd' }} /> */}
    <Tooltip 
    cursor={{fill: '#e7dddd'}} 
    content={<CustomTooltip  />} 
    wrapperStyle={{ width: 'auto', backgroundColor: '#e7dddd', borderRadius: 3, fontSize:'0.9rem', color: myBarColor || theme.palette.common.ludisMain, fontWeight:500 }} />
    {!matches3&&
    <Legend  width={100} wrapperStyle={{ top: 30, right: 20, backgroundColor: '#e7dddd', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px', fontSize:'0.7rem' }} />
    }
    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"  />
    <Bar dataKey={myLabel || "NetSales"} fill={myBarColor || theme.palette.common.ludisMain} barSize={30}    />
  </BarChart>
  </Fragment>
  );
};

export default MyBarChart;
