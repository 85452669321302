import { useSelector, useDispatch } from "react-redux";
//import { updateQuote } from "../../store/quote-slice";


import { MyTable, MyTableRow } from "../UI/styledComponents/StyledComponents";
import theme from "../UI/Theme";

//----------IMPORTS FROM MUI _______________________________________________
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';




//to be added to the styledComponent file......
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "#4E1721",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));





const InvoicingStep_2 = (props) => {
  const currentInvoiceInfo = useSelector((state) => state.invoicing);
  const dispatch = useDispatch();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //console.log("current Payment Plan state from step 2", currentPaymentPlanInfo);

  const {invoiceNumber} = props


  let invoiceInfo1 = [];

  //if (currentInvoiceInfo) {
  if(Object.keys(currentInvoiceInfo).length !== 0) {
    
    //console.log("Order number...",currentPaymentPlanInfo.payPlanInfo.selectedOrder[0].CONFIRMATION_REFERENCE)
    invoiceInfo1 = [
      { label: "Order : ", value: currentInvoiceInfo.invoicingInfo.selectedOrder[0].CLUB_CODE + " - " + currentInvoiceInfo.invoicingInfo.selectedOrder[0].CONFIRMATION_REFERENCE },
      { label: "Inv : ", value: "Inv." + currentInvoiceInfo.invoicingInfo.selectedOrder[0].CONFIRMATION_REFERENCE + " - " + invoiceNumber },
      { label: "Net A. : ", value: "£ " + (currentInvoiceInfo.invoicingInfo.selectedOrder[0].NET_AMOUNT/2).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      }) },
      { label: "Company: ", value: currentInvoiceInfo.invoicingInfo.selectedOrder[0].COMPANY_NAME },
    //   { label: "Total Paid: ", value: "£ " + parseFloat(currentPaymentInfo.amountPaid).toLocaleString("en-US", {
    //     minimumFractionDigits: 2,
    //   }) },
      { label: "Order Taken Date: ", value: new Date(currentInvoiceInfo.invoicingInfo.selectedOrder[0].DATE).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }) ,
      myFont:theme.palette.common.ludisMain2,
      myFontWeight:"400"
    },
      { label: "Invoice Booking Date: ", value: new Date(currentInvoiceInfo.invoicingInfo.invoiceBookDate).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year:"numeric",
      }) , 
      myFont: theme.palette.common.ludisMain,
      myFontWeight:"900"
    },
   

    ];
  }


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "column" : "row",
          //alignItems: "center",
          alignItems:  matches ? "center" : "flex-start",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
         

        <TableContainer
          component={Paper}
          sx={{ width: matches ? "30vw" : "40vw", margin:1, width: matches ? "45vw": "40vw"  }}
        >
          <MyTable size="small" >
            <TableHead>
              <TableRow>
                {!matches ? <StyledTableCell>Payment Information</StyledTableCell> : ""}
                <StyledTableCell align="center">Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceInfo1.map((info) => (
                <MyTableRow key={info.value}>
                  {!matches ? 
                  <TableCell style={{ color: info.myFont, fontWeight: info.myFontWeight}}>
                    {info.label}
                  </TableCell>
                      : ""}  
                  <TableCell
                    //style={{ color: "#4E1721", fontWeight: 500 }}
                    style={{ color: info.myFont, fontWeight: info.myFontWeight }}

                    align="center"
                  >
                    {info.value}


                    {info.id && info.icon[info.id-1]}

                  </TableCell>
                  
                  
                </MyTableRow>
              ))}
            </TableBody>
          </MyTable>
        </TableContainer>
      </Box>
    </>
  );
};

export default InvoicingStep_2;
