import { createSlice } from "@reduxjs/toolkit";

export const usersSlice = createSlice({
  name: 'userDetails',
  initialState:{
    userInfo: {
      firstName:"",
      lastName:"",
      userEmail:"",
      wBasic:null,
      userMobile:"",
      userPhoneBill:null,
      userTitle:{},
      userCommissionType:{},
      userTeam:{},
      userPicUrl:"",
      userPassword:"",
      userUTR:"",
      userNI:"",
      userKey:""

    },
    UserStatus:{
      status:false,
    }
  },
  reducers:{

    updateUser: (state, action)=>{

      const {payload} = action 

      //const newState = payload;
      
     
      state.userInfo = { ...state.userInfo, ...payload };



      console.log("full payload from Users reducer new state...",payload)
      
     

    },


  },
})

export const {updateUser} = usersSlice.actions;


export default usersSlice;
