import { useState, useEffect, Fragment } from "react"

import { useDispatch, useSelector } from "react-redux"
//import { paymentInActions } from "../../store/payment-slice"
import { wagesPaymentActions } from '../../store/wagesPayment-slice'

import axios from "axios";
import DashboardContainer from "../UI/DashboardContainer"
import { variables } from "../../Variables"
import theme from "../UI/Theme"
import BarChart from "../BarChart"
import {TablePaginationActions} from "../UI/Pagination";

import Title from "../Title"
import { MyTable, MyTableRow, MyTable2, MyTableCell, StyledTableCell } from "../UI/styledComponents/StyledComponents"
import ChangePayDateSteps from "../paymentsComponent/ChangePayDateSteps"
import ChangePayDateStep_1 from "../paymentsComponent/ChangePayDateStep_1"
import ChangePayDateStep_2 from "../paymentsComponent/ChangePayDateStep_2"
import ChangePayDateStep_3 from "../paymentsComponent/ChangePayDateStep_3"

import AddPaymentSteps from "../paymentsComponent/AddPaymentSteps"
//import AddPurchasePaymentStep_1 from "../purchaseComponents/AddPurchasePaymentStep_1"
import AddWagesPaymentStep_1 from "../purchaseComponents/AddWagesPaymentStep_1"
import AddWagesPaymentStep_2 from "../purchaseComponents/AddWagesPaymentStep_2"
import AddWagesPaymentStep_3 from "../purchaseComponents/AddWagesPaymentStep_3"


import AddPurchasePaymentStep_2 from "../purchaseComponents/AddPurchasePaymentStep_2"
import AddPurchasePaymentStep_3 from "../purchaseComponents/AddPurchasePaymentStep_3"

//loader Imports--------------
import { SyncLoader } from "react-spinners"

import Draggable from "react-draggable"

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import { Divider } from "@mui/material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined"
import Paper from "@mui/material/Paper"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
//import TableCell from "@mui/material/TableCell";
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { styled } from "@mui/material/styles"

import Popover from "@mui/material/Popover"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined"
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined"
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined"
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined"
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined"
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined"
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import IconButton from "@mui/material/IconButton"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import Collapse from "@mui/material/Collapse"


import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // backgroundColor: theme.palette.common.black,
//     backgroundColor: theme.palette.common.ludisMain,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


export const DisplayExtraInfo = (props)=>{

  const {open1, item, matches, matches2, matches3, wagesType} = props;

  return (
    <TableRow key={Math.random()}>
  {/* Extra information collapsable  */}
  {/* <TableRow key={item.CLIENTPAYMENT_ID}> */}
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                              >
                              <Collapse in={open1[wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID]} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    >
                                    DIR Details....
                                  </Typography>

                                  <MyTable2 size="small" aria-label="payments" sx={{marginY:3}} >

                                    {/* <TableHead >
                                      <TableRow sx={{backgroundColor: "#fff"}}>
                                        <TableCell >Basic</TableCell>
                                        <TableCell>Com. Orders</TableCell>
                                        <TableCell>Com. Payments</TableCell>
                                        <TableCell>Bonuses</TableCell>
                                        <TableCell>Deductions</TableCell>

                                        <TableCell>Com. Cancel</TableCell>

                                      </TableRow>
                                    </TableHead> */}

                                    <TableBody>
                                      <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                      <TableCell >Basic</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_RETAINER).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>
                                        </TableRow>

                                        <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                        <TableCell>Com. Orders</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_COMMISSIONORDERS).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>
                                        </TableRow>

                                        <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                        <TableCell>Com. Payments</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_COMMISSIONPAYMENTS).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>
                                        </TableRow>

                                        <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                        <TableCell>Bonuses</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_BONUSES).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>
                                        </TableRow>
                                        
                                        <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                        <TableCell>Deductions</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_DEDUCTIONS).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>
                                        </TableRow>
                                        
                                        <TableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                                        <TableCell>Com. Cancel</TableCell>
                                        <TableCell>
                                            £{" "}
                                            {parseFloat(item.wOrder.INVOICE_COMMISSIONCANCEL).toLocaleString(
                                              "en-US",
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </TableCell>

                                      </TableRow>
                                    </TableBody>
                                  </MyTable2>
                                </Box>
                              </Collapse>
                            </TableCell>
                          {/* End Extra information collapsable */}
                          </TableRow>
                          
  )
                          
                          
                        }
                          
                          
const SalaryReportByDate = (props)=> {
  const dispatch = useDispatch();

  const currentPurchaseInvoceSelected = useSelector(
    (state) => state.purchasePaymentOut.purchasePaymentOutInfo
  );

  const currentSupplierSelected = useSelector(
    (state) => state.supplier.supplierInfo
  );

  const currentWagesInvoceSelected = useSelector(
    (state) => state.wagesPayment.wagesPaymentInfo
  );

  const userInfo = useSelector((state) => state.user);
  
  //const [paymentsOnOrder, setPaymentsOnOrder] = useState([]);

  //const [isLoading1, setIsLoading1] = useState(false);

  //const [isLoading2, setIsLoading2] = useState(false);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  //const matches2 = useMediaQuery(theme.breakpoints.down("md"));

  const [showWagesList, setShowWagesList] = useState(true);
  const [totalPayments, setTotalPayments] = useState(null);

  //variables for the popover (hoover)
  const [paymentRef, setPaymentRef] = useState("");

  //const [showPaymentDateChange, setShowPaymentDateChange] = useState(false);
  const [showWagesPayment, setShowWagesPayment] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);


  //isLoading1_allPayOnOrder is to pass to the parent component Income.js info about loading to share with sibling componente paymentinfoOrderSummary.js
  const {
    //paymentsByDateList,
    salariesByDateList,
    isLoading1,
    showBank,
    matches,
    matches2,
    matches3,
    isUpdatedPayment,
    showAddPaymentModalHandler,
    costTypeList,
    
    //purchasePaymentsTypeList,
    socket,
    wagesType,
    //isUpdatedPurchasePayment,
  
} = props;

//console.log("this is the salariesByDateList with payments included  ", salariesByDateList)

  let repDate = "";

  //Functions for Table Pagination--------------------------------------
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - salariesByDateList.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      
    };
    //END Functions for TAble Pagination----------------------------------

  const showPaymentsHandler = (event) => {
    event.preventDefault();
    setShowWagesList((prev) => !showWagesList);
  };

//   const resetPaymentInfo = () => {
//     dispatch(
//       paymentInActions.resetPaymentIn({
//         paymentDate: new Date().toISOString(),
//       })
//     );
//   };

  const resetWagesPayment = () => {
    dispatch(
        wagesPaymentActions.resetWagesPayment({
          wagesPaymentDate: new Date().toISOString(),
      })
    );
  };





    //Update Wages Paymnet details into the slice---------------------
    const updateWagesPaymentInfo = (wagesPaymentInfo) => {
        //console.log("Purchase payment info insde the update function....", purchasePaymentInfo);
        //console.log("Purchase payment info insde the update function....", purchasePaymentInfo.pOrder.PURCHASEINVOICE_ID);
    
    
        //if (purchasePaymentInfo) {
          if(Object.keys(wagesPaymentInfo).length !== 0) {
          //Add the Selected Order to the Payments In information
          dispatch(
            wagesPaymentActions.updateWagesPayment({
              //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(), //---here so far....
              selectedWagesInvoice: [
                {
                  // "confirmationId":paymentInfo.CONFIRMATION_ID
                  //DIRINVOICE_ID: wagesType=== 1? wagesPaymentInfo.wOrder.DIRINVOICE_ID: wagesPaymentInfo.wOrder.FIXINVOICE_ID,
                  //DIRINVOICE_REF: wagesType=== 1? wagesPaymentInfo.wOrder.DIRINVOICE_REF: wagesPaymentInfo.wOrder.FIXINVOICE_REF,
                  INVOICE_ID: wagesType=== 1? wagesPaymentInfo.wOrder.DIRINVOICE_ID: wagesPaymentInfo.wOrder.FIXINVOICE_ID,
                  INVOICE_REF: wagesType=== 1? wagesPaymentInfo.wOrder.DIRINVOICE_REF: wagesPaymentInfo.wOrder.FIXINVOICE_REF,

                  CONSULTANT_ID: wagesPaymentInfo.wOrder.CONSULTANT_ID,
                  Firstname: wagesPaymentInfo.wOrder.Firstname,
                  Lastname: wagesPaymentInfo.wOrder.Lastname

                },
              ],
              
            })
          );
        } else {
          //resetPurchasePaymentInfo();
          resetWagesPayment()
          
        }
      };





  //Show the modal with form to submit new payment in-----------
  const showModalHandler = (event, action, item) => {
    //resetPaymentInfo();
    resetWagesPayment()
    if (event) {
      event.preventDefault();
      //console.log("Order Id here....", item);
    }
    if (action === 1) {
      setShowWagesPayment((prev) => !showWagesPayment);
      //updatePaymentInfo(item);
      updateWagesPaymentInfo(item || {});
    } else if (action === 2) {
      //setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      //setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      //setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //let totalPaidOnOrder = 0;

  //   let confoId = "";
  //   if (selectedOrder.length > 0) {
  //     confoId = selectedOrder[0].CONFIRMATION_ID;
  //   }

  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
    />,
  ];

  //POP OVER CODE --------------------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);

  const [open1, setOpen1] = useState(false);

  const handlePopoverOpen = (event, reference) => {
    //console.log(reference)
    setAnchorEl(event.currentTarget);
    setPaymentRef(reference);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPaymentRef("");
  };

  const handleCollapse = (event, item) => {
    event.preventDefault();
    // console.log("el eventoooooo....", event.currentTarget);
    // console.log("el eventoooooote....", item.DIRINVOICE_ID);

    setOpen1((prev) => ({ ...prev, [ wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID]: !prev[ wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID] }))

    //test to be deleted------
    //paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };


  //Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC ------------
  // const paymentListFiltered = (payType)=>{
    
  //   // if(payType===5){
  //     //for CARD payments--------
  //     const paymentsByDateListFiltered_current = salariesByDateList.filter((item)=>item.CLIENTPAYMENTTYPE_ID === payType)
  //     console.log("here is the filtered list for card paymetns....",paymentsByDateListFiltered_current)
  //     //console.log("here is the original list for all paymetns....",paymentsByDateList)

  //     //setPaymentsByDAteListFiltered(paymentsByDateListFiltered_current)
  //   // }
  // }
  //END Filter payments list by Payment Type i.e Card payments / BACS / Cheques / GC --------





  const open = Boolean(anchorEl);

  //Steps for Add a Signed Payment Plan to the order-------------------------------------
//   const steps_changeDate = [
//     {
//       label: "Payment Details",
//       info: (
//         <ChangePayDateStep_1
//         // usersTitleList={usersTitleList}
//         //paymentTypeList={paymentTypeList}
//         />
//       ),
//       icon: (
//         <DriveFileRenameOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />
//       ),
//       iconCompleted: (
//         <DriveFileRenameOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
//       ),
//       id: 0,
//     },
//     {
//       label: "Summary",
//       info: <ChangePayDateStep_2 />,
//       icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
//       iconCompleted: (
//         <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
//       ),
//       id: 1,
//     },
//     {
//       label: "Confirmation",
//       info: (
//         <ChangePayDateStep_3
//           //socket={socket}
//           //userInfo={userInfo}
//           showModalHandler={showModalHandler}
//           isUpdatedPayment={isUpdatedPayment}
//           //updatePayment={updatedPayment}
//           //newPaymentPlan={newPaymentPlan}
//           isLoading1={isLoading1}
//           //isLoading1_PayplanOnOrderHandler={isLoading1_PayplanOnOrderHandler}
//         />
//       ),
//       //info: "Payment comfirmation component...",
//       icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
//       iconCompleted: (
//         <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
//       ),
//       id: 2,
//     },
//   ];



    //Steps for Add a Payment to the DIR Inv. selected-------------------------------------
    const steps_AddWagesPayment = [

        {
          label: "Wages Payment Details",
          info: (
            // <AddPurchasePaymentStep_1
            //   //usersTitleList={usersTitleList}
            //   purchasePaymentsTypeList={purchasePaymentsTypeList}
            // />
            <AddWagesPaymentStep_1
              //usersTitleList={usersTitleList}
              costTypeList={costTypeList}
              
            />
          ),
          icon: <PaymentOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
          iconCompleted: (
            <PaymentOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
          ),
          id: 0,
        },
        {
          label: "Summary",
          //info: <AddPurchasePaymentStep_2 />,
          info: <AddWagesPaymentStep_2 />,
          //info: "Payment Summary component...",
          icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
          iconCompleted: (
            <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
          ),
          id: 1,
        },
        {
          label: "Confirmation",
          info: ( 
            // <AddPurchasePaymentStep_3
            //   socket={socket}
            //   //userInfo={userInfo}
            //   //showAddPaymentModalHandler={showAddPaymentModalHandler}
            //   //showPurchasePaymentModalHandler={showPurchasePaymentModalHandler}
            //   showPurchasePaymentModalHandler={showModalHandler}
              
            //   //isNewPurchasePayment={isNewPurchasePayment}
            //   isUpdatedPurchasePayment = {isUpdatedPurchasePayment}
            // />
            <AddWagesPaymentStep_3
              socket={socket}
              showWagesPaymentModalHandler={showModalHandler}
              isUpdatedPayment = {isUpdatedPayment}
              wagesType={wagesType}
            />


          ),
          //info: "Payment comfirmation component...",
          icon: <StorageOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
          iconCompleted: (
            <StorageOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
          ),
          id: 2,
        },
    
      ];









  //   const getPaymentsOnOrder = (confoId) => {

  //     setIsLoading1(true);
  //     isLoading1_allPayOnOrderHandler(true); //this is the same info of isloading1 passed to the parent component to share with Siblings....

  //     axios
  //       .get(variables.API_URL + "payments/paymentsonorder/" + confoId, {
  //         withCredentials: true,
  //       })
  //       .then((res) => {
  //         //console.log("payments on order here....", res.data.paymentsOnOrder);
  //         setPaymentsOnOrder(res.data.paymentsOnOrder);
  //         setIsLoading1(false);
  //         isLoading1_allPayOnOrderHandler(false); //this is the same info of isloading1 passed to the parent component to share with Siblings....
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //   useEffect(() => {
  //     if (confoId) {
  //       //getPaymentsOnOrder(confoId);
  //     }
  //   }, [confoId, newPayment]);

  //Code to update Order selected slice with total paid on Order--------
  useEffect(() => {
    //console.log("inside use effect to update total paid...", paymentsOnOrder)

    if (salariesByDateList?.length > 0) {
      setTotalPayments(
        salariesByDateList[salariesByDateList.length - 1].cumulative_sum
      );
    } else {
      setTotalPayments(0);
    }

    // dispatch(
    //   paymentInActions.updatePaymentIn({
    //     selectedOrderTotalPaid: totalPaidOnOrder,
    //   })
    // );
  }, [salariesByDateList]);

  return (
    //paymentsByDateList.length > 0 ?
    <Fragment>
      <Box
        sx={{
          display: "flex",
          //flexDirection: matches ? "column" : "row",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault();
            showPaymentsHandler(event);
          }}
          sx={{
            color: "#7A1125",
            textTransform: "capitalize",
            marginBottom: 2,
          }}
        >
          <CreditScoreOutlinedIcon
            sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />



          {
          wagesType === 3? 
          showWagesList ? "Hide Invoices" : "Show Invoices" :
          showWagesList ? "Hide Wages" : "Show Wages"
          }
        </Button>
      </Box>

      {showWagesList ? (
        !isLoading1 ? (
          <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
              //backgroundColor:"#000",
              // height: "100vh",
              // overflow: "auto",
              
            }}
          >
            <Title>{wagesType === 1? "DIR Invoices..." : wagesType === 2?  "FIX Invoices..." : "NON-OPS Invoices..."}</Title>

            <Divider sx={{ width: "60vw" }} />

            {salariesByDateList?.length > 0 ? (
              <TableContainer
                component={Paper}
                // sx={{ width: matches2 ? "80vw" : "60vw", marginTop: 2 }}
                sx={{ width: matches3?"70vw":matches2 ? "60vw" : "60vw", marginTop: 2, padding:0  }}
              >
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <TableRow key={Math.random()}>
                      <StyledTableCell align="left">...</StyledTableCell>
                      <StyledTableCell align="left">{wagesType===1? "DIR" : "FIX"}</StyledTableCell>
                      {matches3?"":<StyledTableCell>Date From</StyledTableCell>}
                      <StyledTableCell>Date To</StyledTableCell>
                      <StyledTableCell align="left">Consultant</StyledTableCell>

                      <StyledTableCell align="left">
                        Amount&nbsp;(£)
                      </StyledTableCell>
                      {matches3?"":
                      <Fragment>
                      <StyledTableCell align="right">Sales</StyledTableCell>
                      <StyledTableCell align="right">Index</StyledTableCell>
                      <StyledTableCell align="right">Paid(£)</StyledTableCell>
                      <StyledTableCell align="right">Status</StyledTableCell>
                      </Fragment>
                      }

                      {userInfo.permission === 6 &&<StyledTableCell align="right">Action</StyledTableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {
                    (rowsPerPage > 0 ? salariesByDateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):
                    salariesByDateList).map((item) => {
                    //   showBank
                    //     ? (repDate = item.DATEONSTATEMENT)
                    //     : (repDate = item.PAYMENTDATE);
                    //console.log("my item in the map...",item.wOrder.TITLE_ID)
                      return (
                        //item.wOrder.TITLE_ID !==14 && // ONLY OPERATIONAL FIX INFORMATION DISPLAYED
                        <Fragment>
                          <MyTableRow key={wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID}>
                            <MyTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                              >
                                {open1[wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID] ? (
                                  // <KeyboardArrowUpIcon />
                                  <KeyboardArrowUpIcon sx={{fontSize:matches3?"0.6rem":"1rem", padding:0}} />
                                ) : (
                                  // <KeyboardArrowDownIcon />
                                  <KeyboardArrowDownIcon sx={{fontSize:matches3?"0.6rem":"1rem",padding:0}} />
                                )}
                              </IconButton>
                            </MyTableCell>

                            <MyTableCell>
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0 }}>
                                {wagesType === 1?item.wOrder.DIRINVOICE_REF : item.wOrder.FIXINVOICE_REF}
                              </Typography>
                            </MyTableCell>
                            {matches3?"":<MyTableCell>
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0 }}>
                                {new Date(wagesType === 1?item.wOrder.DIRFROM_DATE : item.wOrder.FIXFROM_DATE ).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  //year: "numeric",
                                })}
                              </Typography>
                            </MyTableCell>}
                            <MyTableCell>
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0 }}>
                                {new Date(wagesType === 1?item.wOrder.DIRTO_DATE : item.wOrder.FIXTO_DATE).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  //year: "numeric",
                                })}
                              </Typography>
                            </MyTableCell>

                            <MyTableCell align="left">
                              {/* <Typography sx={{ fontSize: matches ? 11 : 14 }}> */}
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : "1rem", padding:0 }}>
                                  {/* {item.wOrder.Firstname + "  " + item.wOrder.Lastname.charAt(0)} */}
                                  {item.wOrder.Firstname.charAt(0).toUpperCase() +item.wOrder.Firstname.toLowerCase().slice(1) + " " + item.wOrder.Lastname.charAt(0) }
                              </Typography> 
                            </MyTableCell>

                            <MyTableCell>
                              £{" "}
                              {parseFloat(item.wOrder.TOTALINVOICE_PAID).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </MyTableCell>

                            {matches3?"":
                            <Fragment>
                              <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                  {/* {item.NAME} */}
                                  ...
                              </Typography> 
                            </TableCell>
                            <TableCell>
                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                  {/* {item.NAME} */}
                                  ...
                              </Typography> 
                            </TableCell>

                            <MyTableCell>
                              {/* £{" "} */}
                              {parseFloat(item.totalPaid).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </MyTableCell>

                            <TableCell>

                              <Typography sx={{ fontSize: matches ? 11 : 14 }}>
                                {/* {(parseFloat(item.pOrder.PURCHASEINVOICE_VAT) + parseFloat(item.pOrder.PURCHASEINVOICE_NETPRICE) - parseFloat(item.totalPaid)) === parseFloat(0) && */}
                                {(Math.round(((item.wOrder.TOTALINVOICE_PAID)  - (item.totalPaid))*10)/10) === 0 &&

                                <CheckCircleOutlineIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.okGreen,
                                  marginLeft: 1,
                                }}
                                />}

                               {Math.round(item.wOrder.TOTALINVOICE_PAID - item.totalPaid) < 0 && 
                                <ReportGmailerrorredIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.ludisRed2,
                                  marginLeft: 1,
                                }}
                                />}

                               {Math.round(item.wOrder.TOTALINVOICE_PAID - item.totalPaid) > 0 &&  parseFloat(item.totalPaid) > 0 &&
                                <RunningWithErrorsIcon
                                sx={{
                                  fontSize: 25,
                                  color: theme.palette.common.arcOrange,
                                  marginLeft: 1,
                                }}
                                />}

                              </Typography>
                            </TableCell>
                            </Fragment>
                            }


                            {/* <StyledTableCell align="right">
                              {matches ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                              {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                            </StyledTableCell> */}

                            {userInfo.permission === 6 &&<StyledTableCell align="right">
                              <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  showModalHandler(event, 1, item);
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 1,
                                  }}
                                />
                              </Button>
                            </StyledTableCell>}
                          </MyTableRow>

                          {open1[ wagesType ===1? item.wOrder.DIRINVOICE_ID : item.wOrder.FIXINVOICE_ID] && <DisplayExtraInfo open1={open1}  item={item} matches = {matches} wagesType={wagesType}/>}

                          
                        </Fragment>
                      );
                    })}
                  </TableBody>

                  {/* Footer--------------------------------------------------------------- */}
                <TableFooter sx={{backgroundColor:theme.palette.common.ludisMain, color:theme.palette.common.ludisLight1}}>
                    <TableRow key={Math.random()}>
                      <TablePagination
                        //component="div"
                        
                        rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                        colSpan={matches3?6:11}
                        count={salariesByDateList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native:true,
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        //extras------------------------------------------------
                        labelRowsPerPage={<span>{matches3?"":"rows"} </span>}
                        //labelDisplayedRows={({page})=>{return `Page: ${page}`}}
                        labelDisplayedRows={ matches3? ({page})=>{return `Page: ${page}`} :
                        (from=page) => `${from.from}-${from.to === -1 ? from.count : from.to} of ${from.count}`
                      }
                      //sx={{ color:theme.palette.common.ludisLight1 }}
                      sx={{ ".MuiTablePagination-toolbar":{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"baseline",
                              justifyContent:"center",
                              alignContent:"center",
                              
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              pt:1,
                              //margin:"auto"
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              width: "auto",
                              ml: matches3?"0":"0",
                              pl: matches3?"0":"1"
                              
                              
                            },
                            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":{
                              //display:"flex",
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              //margin:"auto"
                              //backgroundColor:"#000"
                            } ,
                            ".MuiTablePagination-selectIcon":{
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.8rem" : matches?"0.9rem": "1rem",
                              //backgroundColor:"#000"
                              
                            },
                            ".MuiTablePagination-menuItem":{
                              color:theme.palette.common.ludisMain,
                              fontSize:"1rem",
                              
                              //backgroundColor:"#000"
                            }
                            
                          }}
                          />
                    </TableRow>
                  </TableFooter>
                  
                  {/* END Footer----------------------------------------------------------- */}


                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <SyncLoader color="#7A1125" size={15} />
          </Box>
        )
      ) : (
        ""
      )}

      {/* <Popover
        id="mouse-over-popover"
        //id={item.CLIENTPAYMENT_ID}
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      > */}
      {/* <Typography sx={{ p: 1 }}>{item.CHEQUEREF}</Typography> */}
      {/* <Typography
          sx={{
            p: 1,
            backgroundColor: "#e7dddd",
            color: theme.palette.common.ludisMain,
            fontSize: 20,
          }}
        >
          {paymentRef}
        </Typography> */}
      {/* </Popover> */}

      {/* Modal for case: Show payment plan signed Details ---------------------- */}
      <Dialog
        open={showWagesPayment}
        //open={open}
        //onClose={handleClose}
        onClose={(event) => showModalHandler(event, 1)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>Submit Wages Payment</DialogTitle>
        

        <DialogContent>
          {currentWagesInvoceSelected.selectedWagesInvoice.length > 0 && (
            <>
              <DialogContentText sx={{ margin: 2 }}>
              {/* DIR Invoice...{currentPurchaseInvoceSelected.selectedPurchaseInvoice[0].PURCHASEINVOICE_NUMBER} */}
              W.Invoice:
              {(wagesType === 1? " DIR - " : " FIX - " )  +
              currentWagesInvoceSelected.selectedWagesInvoice[0].INVOICE_REF
              } 
              
              </DialogContentText>
              <DialogContentText sx={{ margin: 2 }}>
              
              Consultant: {currentWagesInvoceSelected.selectedWagesInvoice[0].Firstname + " " + currentWagesInvoceSelected.selectedWagesInvoice[0].Lastname.charAt(0)}
              </DialogContentText>
            </>
            )}
          {/* <AddPplanSignedSteps steps={steps_signed} /> */}
               {/* Add a new Purchase Payment ... */}
          {/* <ChangePayDateSteps
            steps={steps_AddPurchasePayment}
            showModalHandler={showModalHandler}
          /> */}

          <AddPaymentSteps steps={steps_AddWagesPayment} />

        </DialogContent>
          
        
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
  //   : ("..." );
}

export default SalaryReportByDate
