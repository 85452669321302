import { useState, useEffect, Fragment } from "react";

import theme from "../UI/Theme";

//MUI IMPORTS---------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import GeneralChartMultiple from "../graphComponents/GeneralChartMultiple";
import GeneralChart from "../graphComponents/GeneralChart";
import BarChart from "../BarChart";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";

//import Typography from "@mui/material/Typography";
//import Link from "@mui/material/Link";
//import "core-js/actual/array/group-by";



let salesByDateList = [];


export default function GeneralGraph(props) {
  const {
    salesByDateList,
    salesByDateListTarget,
    salesCancelledByDateList,
    salesByYearListAll,
    myBoxHeight,
    graphType,
    BarChartData,
  } = props;

  const [salesGraphData1, setSalesGraphData1] = useState([]);
  const [salesGraphData1Cancel, setSalesGraphData1Cancel] = useState([]);
  const [salesGraphData1Final, setSalesGraphData1Final] = useState([]);
  const [salesGraphData_target, setSalesGraphData_target] = useState([]);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  // const [salesGraphData_historic1, setSalesGraphData_historic1] = useState([]);
  // const [salesGraphData_historic2, setSalesGraphData_historic2] = useState([]);
  // const [salesGraphData_historic3, setSalesGraphData_historic3] = useState([]);

  //const [changeGraphData, setChangeGraphData] = useState(null);

  //alert('brake...')

  // console.log(
  //   "see all props here......................... aquiiiiiiiiiiiiiiii!!!!!",
  //   BarChartData
  // );

  //test to be deleted

  // console.log(
  //   "FINALcheckingggggggggggggggggggggggggggggggggggg...............",
  //   salesByYearListAll
  // );
  //console.log('checkingggggggggggggggggggggggggggggggggggg...............',props)

  //changing data from daily to monthly------------------
  // const changeGraphDataHandler = () => {
  //   setChangeGraphData(!changeGraphData);
  // };

  const monthlyGraphHandler = async () => {
    //let acc = null;
    if (salesByDateList) {
      //console.log(new Date(salesByConsultantList[1].DATE).getMonth()+1)

      let salesByConsultantByDate_Monthly = [];
      await salesByDateList.reduce(
        (res, order) => {
          if (!res[new Date(order.DATE).getMonth()]) {
            res[new Date(order.DATE).getMonth()] = {
              DATE: new Date(order.DATE).getMonth(),
              amountNet: order.NET_AMOUNT,
              //acc: acc + order.NET_AMOUNT,
            };
            salesByConsultantByDate_Monthly.push(
              res[new Date(order.DATE).getMonth()]
            );
          } else {
            res[new Date(order.DATE).getMonth()].amountNet =
              res[new Date(order.DATE).getMonth()].amountNet + order.NET_AMOUNT;

            //new---
            // res[new Date(order.DATE).getMonth()].acc =
            // res[new Date(order.DATE).getMonth()].acc + order.NET_AMOUNT;
          }

          //console.log("the ressssssssss inside the reduce.............",res)

          return res;
        },
        [{}]
      );

      // console.log(
      //   "SECOND REDUCER EXCERCISE from Button...............",
      //   salesByConsultantByDate_Monthly
      // );

      //disable temporarily until the code is updated with acc from dailygraphhandler-------------------------!!!
      //setSalesGraphData1(salesByConsultantByDate_Monthly);
    }
  };

  const dailyGraphHandler = async () => {
    let acc = null;
    let salesByConsultantByDate = [];
    await salesByDateList.reduce(
      (res, order) => {
        if (!res[order.DATE]) {
          res[order.DATE] = {
            DATE: order.DATE,
            //DATE: new Date(order.DATE).toLocaleString("en-GB", {timeZone: 'CET'}),
            amountNet: order.NET_AMOUNT,
            acc: order.cumulative_sum,
          };
          salesByConsultantByDate.push(res[order.DATE]);
        } else {
          res[order.DATE].amountNet =
            res[order.DATE].amountNet + order.NET_AMOUNT;

          //new to add acc
          res[order.DATE].acc = order.cumulative_sum;
          //new end
        }

        //console.log("the ressssssssss inside the reduce order.............", order.cumulative_sum)
        //console.log("the ressssssssss inside the reduce order only res.............", res)
        //console.log("array after push with sales...",salesByConsultantByDate)

        return res;
      },
      [{}]
    );

    // if( salesByDateList.length > 0){
    //   //dailyGraphHandler();
    // }

    setSalesGraphData1(salesByConsultantByDate);

    // console.log(
    //   "SALES GRAPH DATA FROM GRAPH COMPONENT...BEFORE PASSING IT OVER TO CHART COMPONENT...............",
    //   salesGraphData,"and the origen...",salesByConsultantByDate
    // ); // sumAmount: {editor: 57, admin: 56}
  };


  //Getting Cancelations grouped by date--------------------------------------------------------------------------
  const dailyGraphHandlerCancel = async () => {
    let acc = null;
    let salesByConsultantByDateCancel = [];
    await salesCancelledByDateList.reduce(
      (res, order) => {
        if (!res[order.CANCELLATION_DATE]) {
          res[order.CANCELLATION_DATE] = {
            CANCELLATION_DATE: order.CANCELLATION_DATE,
            //CANCELLATION_DATE: new Date(order.CANCELLATION_DATE).toLocaleString("en-GB", {timeZone: 'CET'}),
            amountNet: order.NET_AMOUNT,
            acc: order.cumulative_sum,
          };
          salesByConsultantByDateCancel.push(res[order.CANCELLATION_DATE]);
          //salesByConsultantByDateCancel.push(res[new Date(order.CANCELLATION_DATE.toLocaleString("en-US", {timeZone: 'CET'}))]);
          //console.log("cancelation Date before the push...",order.CANCELLATION_DATE)
          //console.log("cancelation Date before the push...",typeof(order.CANCELLATION_DATE))
          //console.log("cancelation DateTime before the push...",order.TIME)
          //console.log("cancelation Date before the push... without",new Date(order.CANCELLATION_DATE))
          //console.log("cancelation fixing date to timezone Newyork...",new Date(order.CANCELLATION_DATE).toLocaleString("en-US", {timeZone: "America/New_York"}))
          //console.log("cancelation fixing date to timezone MST...",new Date(order.CANCELLATION_DATE).toLocaleString("en-US", {timeZone: "MST"}))
          //console.log("cancelation fixing date to timezone CET...",new Date(order.CANCELLATION_DATE).toLocaleString("en-US", {timeZone: "CET"}))


        } else {
          res[order.CANCELLATION_DATE].amountNet =
            res[order.CANCELLATION_DATE].amountNet + order.NET_AMOUNT;

          //new to add acc
          res[order.CANCELLATION_DATE].acc = order.cumulative_sum;
          //new end
        }

        //console.log("the ressssssssss inside the reduce order cancel.............", order.cumulative_sum)
        //console.log("the ressssssssss inside the reduce order only res cancel.............", res)
        //console.log("original array with cancelations...",salesCancelledByDateList)
        //console.log("array after push with cancelations...",salesByConsultantByDateCancel)
        

        return res;
      },
      [{}]
    );

    // if( salesByDateList.length > 0){
    //   //dailyGraphHandler();
    // }

    setSalesGraphData1Cancel(salesByConsultantByDateCancel);

    

   
  };
  //END of Getting Cancellations grouped by daye------------------------------------------------------------------


  //Get Final array for sales with cancelations by date------------------------------------------------------------

  const dailyGraphHandlerFinal = async () => {

    let accFinal = null;
    let salesByConsultantByDateFinal = [];

    

    if(salesGraphData1.length>0){
      
      salesGraphData1.map( (sale)=>
      {
        //console.log("sale ....",sale.DATE)
        const myNewArray=salesGraphData1Cancel.filter((can)=>sale.DATE === can.CANCELLATION_DATE )
        //console.log("matching...", myNewArray)
        if(myNewArray.length > 0){
            accFinal = accFinal + sale.amountNet - myNewArray[0].amountNet
            salesByConsultantByDateFinal.push(
            {
            //DATE: new Date(sale.DATE).toLocaleString("en-GB", {timeZone: 'CET'}),
            DATE: sale.DATE,
            amountNet: sale.amountNet - myNewArray[0].amountNet,
            acc: accFinal
            }
             )

          //console.log("matching...", myNewArray[0].amountNet)
        }else{

            accFinal = accFinal + sale.amountNet

            salesByConsultantByDateFinal.push(
            {
            //DATE: new Date(sale.DATE).toLocaleString("en-GB", {timeZone: 'CET'}),
            DATE: sale.DATE,
            amountNet: sale.amountNet ,
            acc: accFinal,
            }
             )

        }

      
        


      }
      
      )


      //console.log("Final Array.....", salesByConsultantByDateFinal)


    }


    setSalesGraphData1Final(salesByConsultantByDateFinal);

    




  }



  //END Get Final array for sales with cancelations by date--------------------------------------------------------






  //Get object for accumulated sales including accumulated target-------------------------------------------
  const getsalesByDateList_target = () => {
    let salesByDateListTargetJoined = [];
    let salesAcc = 0;

    if (salesByDateListTarget?.length > 0) {
      salesByDateListTarget.map((target) => {
        //if (salesGraphData1.length > 0) {
        if (salesGraphData1Final.length > 0) {

          const targetDate =
            new Date(target.date).getFullYear() +
            "-" +
            (new Date(target.date).getMonth() + 1) +
            "-" +
            new Date(target.date).getDate();

          //alert(targetDate)
          // console.log("internal from the function.... the targetDate", salesGraphData)

          //const canx = salesGraphData1.filter(
          const canx = salesGraphData1Final.filter(

            (item) =>
              new Date(item.DATE).getFullYear() +
                "-" +
                (new Date(item.DATE).getMonth() + 1) +
                "-" +
                new Date(item.DATE).getDate() ===
              targetDate
          );
          //alert(canx.length)
          if (canx.length > 0) {
            //console.log("CANX for TARGET....", canx[0].acc);
            salesAcc = canx[0].acc;

            //netSales = consultant.subTotal - canx[0].subTotal;
            //netSales = consultant.subTotal
          } else {
            //netSales = consultant.subTotal;
          }

          salesByDateListTargetJoined.push({
            DATE: target.date,
            targetSalesAcc: target.targetAcc,
            salesAcc: salesAcc,
          });

          // BarChartData.push({
          //   Name: consultant.NAME,
          //   NetSales: netSales,
          //   Sales: netSales.toLocaleString("en-US", {
          //     minimumFractionDigits: 2,
          //   }),
          // });
        }
      });
    }

    //console.log("the last array with all info for SALESBYDATELISTTARTGETJOINED ...........................",salesByDateListTargetJoined)
    setSalesGraphData_target(salesByDateListTargetJoined);
  };

  // //Get object for Sales by month for one year-------------------------------------------
  // const getsalesByMonthList_historic = () => {
  //   let salesByYearListAllJoined1 = [];
  //   let salesByYearListAllJoined2 = [];
  //   let salesByYearListAllJoined3 = [];

  //   let salesAcc = 0;

  //   if (salesByYearListAll?.length > 0) {
  //     salesByYearListAll.map((item) => {
  //       //const targetDate = new Date(target.date).getFullYear()+"-"+(new Date(target.date).getMonth()+1)+"-"+new Date(target.date).getDate()

  //       //alert(targetDate)
  //       // console.log("internal from the function.... the targetDate", salesGraphData)

  //       // const canx = salesGraphData1.filter(
  //       //   (item) => new Date(item.DATE).getFullYear()+"-"+(new Date(item.DATE).getMonth()+1)+"-"+new Date(item.DATE).getDate() === targetDate
  //       // );
  //       //alert(canx.length)
  //       // if (canx.length > 0) {

  //       //   //console.log("CANX for TARGET....", canx[0].acc);
  //       //   salesAcc = canx[0].acc;

  //       //   //netSales = consultant.subTotal - canx[0].subTotal;
  //       //   //netSales = consultant.subTotal
  //       // } else {
  //       //   //netSales = consultant.subTotal;

  //       // }

  //       if (item.length > 0) {
  //         item.map((subItem) => {
  //           //console.log("subItem..... in the loop....", subItem);
  //           if (subItem.YEAR === 2014) {
  //             salesByYearListAllJoined1.push({
  //               DATE: subItem.MONTH_ID,
  //               targetSalesAcc1: subItem.AMOUNT,
  //               //targetSalesAcc2: item[1].AMOUNT,
  //               //salesAcc:salesAcc
  //             });
  //           } else if (subItem.YEAR === 2015) {
  //             salesByYearListAllJoined1.push({
  //               targetSalesAcc2: subItem.AMOUNT,
  //             });
  //           } else if (subItem.YEAR === 2016) {
  //             salesByYearListAllJoined1.push({
  //               targetSalesAcc3: subItem.AMOUNT,
  //             });
  //           }
  //         });
  //       }

  //       // BarChartData.push({
  //       //   Name: consultant.NAME,
  //       //   NetSales: netSales,
  //       //   Sales: netSales.toLocaleString("en-US", {
  //       //     minimumFractionDigits: 2,
  //       //   }),
  //       // });
  //     });
  //   }

  //   // console.log(
  //   //   "the last array with all info 1 ...........................",
  //   //   salesByYearListAllJoined1
  //   // );
  //   // console.log(
  //   //   "the last array with all info 2 ...........................",
  //   //   salesByYearListAllJoined2
  //   // );
  //   // console.log(
  //   //   "the last array with all info 3 ...........................",
  //   //   salesByYearListAllJoined3
  //   // );

  //   setSalesGraphData_historic1(salesByYearListAllJoined1);
  //   //setSalesGraphData_historic2(salesByYearListAllJoined2);
  //   //setSalesGraphData_historic3(salesByYearListAllJoined3);
  // };

  // useEffect(()=>{

  //   setChangeGraphData(false)

  // },[])

  useEffect(() => {
    //console.log("first time switch...", changeGraphData);

    //if ( salesByDateList.length>0){
    if (salesByDateListTarget?.length > 0) {
      // if (!changeGraphData) {
      //   monthlyGraphHandler();
      //   //console.log("is true...!!!");
      // } else {
      //   //console.log("is false...!!!");
      dailyGraphHandler();
      dailyGraphHandlerCancel();

      // }
    }

    //return () => {};
  }, [salesByDateListTarget]);
  // }, [changeGraphData]);

  //getting the new array of accumulated sales including target-------------------
  useEffect(() => {
    // console.log("the ressssssssss inside the reduce order.............", salesGraphData)
    // console.log("the ressssssssss inside the reduce order.............", changeDates)

    //if(salesGraphData1){
    if (salesByDateListTarget) {
      getsalesByDateList_target();
    }

    //},[salesByDateListTarget])
    //},[salesGraphData1])
  }, [salesGraphData1,salesGraphData1Final]);

  //getting the new array of SALES BY MONTH FOR EVERY YEAR------------------
  useEffect(() => {
    // console.log("the ressssssssss inside the reduce order.............", salesGraphData)
    // console.log("the ressssssssss inside the reduce order.............", changeDates)

    //if(salesGraphData1){
    // if (salesByYearListAll?.length > 0) {
    //   getsalesByMonthList_historic();
    // }

    //},[salesByDateListTarget])
    //},[salesGraphData1])
  }, [salesByYearListAll]);



  useEffect(()=>{
    dailyGraphHandlerFinal()
  },[salesGraphData1, salesGraphData1Cancel])

  //console.log("the last thing salesGraphData_historic ....................................", salesByYearListAll)
  //console.log("the last thing salesGraphData1 ....................................", salesGraphData1)
  //console.log("the last thing changeGraphData ....................................", changeGraphData)
  // console.log("the last thing changeDates ....................................", changeDates)

  return (
    <Fragment>

    <Box
      //component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        //overflow: "auto",
        height: "auto",
        //height: salesByDateList.length > 0 ? myBoxHeight || 400 : 0,
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, height:"auto"  }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems:matches3?"center":"",
                height: "50vh",
                //height: salesByDateList.length > 0 ? myBoxHeight-150 || 240 : 0,
                //backgroundColor:"#000"
              }}
            >
              {salesGraphData_target?.length > 0 && graphType.graphId === 1 && (
                <GeneralChart
                  salesByDateList={salesGraphData_target}
                  //salesGraphData={salesGraphData1}
                  salesGraphData={salesGraphData1Final}

                  // salesHistoric1={salesGraphData_historic1}
                  // salesHistoric2={salesGraphData_historic2}
                  // salesHistoric3={salesGraphData_historic3}

                  //changeGraphData={changeGraphData}
                  //changeDates={changeDates}

                  //  salesByDateList={ salesGraphData}
                  //salesByConsultantByDate={salesByConsultantByDate}
                  //salesByConsultantByDate={salesGraphData}
                />
                
              )}


              {/* Checking if Graph is Historic with multiple lines----------------------------- */}
              {salesByYearListAll?.length > 0 && graphType.graphId === 2 && (
                
                
                <GeneralChartMultiple
                  salesByDateList={salesGraphData_target}
                  salesGraphData={salesByYearListAll}
                />
                  
              )}

              {/* Checking if Graph is Historic with multiple lines----------------------------- */}
              {salesByYearListAll?.length > 0 && graphType.graphId === 3 && (
                
                <>
                {/* <GeneralChartMultiple
                  salesByDateList={salesGraphData_target}
                  salesGraphData={salesByYearListAll}
                /> */}
                
                <BarChart 
                BarChartData={BarChartData} 
                myLabel={"Sales"} 
                myTitle={graphType.graph}
                myXSmall={20}
                mySmall={250}
                myMedium={420}
                myLarge={495}
                myXLarge={690} 
                />

                </>
                  
              )}



            </Paper>
          </Grid>
          <Button
            // onClick={() => changeGraphDataHandler()}
            sx={{ color: "#7A1125", textTransform: "capitalize" }}
          >
            <DateRangeTwoToneIcon
              sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
            />
            {/* {changeGraphData ? "View Daily" : "View Monthly"} */}
          </Button>
        </Grid>

        <Divider />
      </Container>
    </Box>

    

    </Fragment>
  );
}
