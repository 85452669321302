//import SalesByConsultantSummaryGraph from "./graphComponents/SalesByConsultantSummaryGraph";
import {Fragment} from 'react';
import SalesHistoricGraph from "./graphComponents/SalesHistoricGraph";
import Box from "@mui/material/Box";

import theme from "./UI/Theme";

//MUI IMPORTS--------------------------------------------
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Reports() {
  const tabInitialDate = "2022-01-01";
  const tabFinalDate = "2022-12-31";

 
  const matches1 = useMediaQuery(theme.breakpoints.down("md"));
  const matches2 = useMediaQuery(theme.breakpoints.down("sm"));


  //Finding the current month as per today's date
  const myCurrentMonth = new Date(Date.now()).getFullYear() +  "-" + (new Date(Date.now()).getMonth()+1) + "-01";
  const currentMonth = new Date(myCurrentMonth).toLocaleString(
    "default",
    {
      //day: "numeric",
      month: "long",
      //year: "numeric",
    }
  )
  //End of Finding the current month as per today's date.

  return (
    <Fragment>
    <Box
      //component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        backgroundImage:
          "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        height: "80vh",
        overflow: "auto",
        marginTop:5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={10}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: matches2?"90vw":matches1?"85vw":"65vw" }}>
          <SalesHistoricGraph
            tabInitialDate={tabInitialDate}
            tabFinalDate={tabFinalDate}
            selectedYearFrom="2014"
            graphType={{"graph": "Historic Sales by month 5 Years", "graphId":2}}
          />
          
        </Paper>
      </Grid>

      <Grid item xs={10} sx={{marginTop:2}}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: matches2?"90vw":matches1?"85vw":"65vw" }}>
          <SalesHistoricGraph
            tabInitialDate={tabInitialDate}
            tabFinalDate={tabFinalDate}
            selectedYearFrom="2014"
            graphType={{"graph": "Historic Sales by month - " + currentMonth, "graphId":3}}
          />
          
        </Paper>
      </Grid>
    </Box>

    </Fragment>
  );
}
