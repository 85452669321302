import React from 'react';
import {io} from 'socket.io-client';


//local version --------------------------------------------------------
//const socketServerUrl = 'http://localhost:5002';

//live version Heroku----------------------------------------------------------------
 //const socketServerUrl = 'https://ludisgroup-erp-back-socket.herokuapp.com';

 //live version render----------------------------------------------------------------
 const socketServerUrl = 'https://ludisgroup-erp-back-socket.onrender.com';


export const mySocket = io(socketServerUrl) ;

export const SocketContext = React.createContext();