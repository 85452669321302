import { Fragment, useRef, useState } from "react";
import {
  // Document,
  // Page,
  // Text,
  // View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { PulseLoader } from "react-spinners";

import { MyTable, MyTableRow, MyTable2, MyTableCell, StyledTableCell } from "../UI/styledComponents/StyledComponents"
import {TablePaginationActions} from "../UI/Pagination";

//-------REDUX IMPORTS----------------------
import { useDispatch, useSelector } from "react-redux"

//-------MUI IMPORTS------------------------
//import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { styled } from "@mui/styles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Collapse from "@mui/material/Collapse"

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

//------MY IMPORTS---------------------------
import Title from "../Title";
import theme from "../UI/Theme";
import PdfReportDocument from "./PdfReportDocument";
//import { userInfo } from "os";

// Generate Order Data
// function createData(id, date, name, shipTo, paymentMethod, amount) {
//   return { id, date, name, shipTo, paymentMethod, amount };
// }

const styles = StyleSheet.create({
  downloading: { textDecoration:'none' },
  // section: { color: 'white', textAlign: 'center', margin: 30 }
});

export const DisplayExtraInfo = (props)=>{

  const {open1, item, matches, matches2, matches3, reportName} = props;

  

  return (
    <TableRow key={Math.random()}>
  {/* Extra information collapsable  */}
  {/* <TableRow key={item.CLIENTPAYMENT_ID}> */}
                            <MyTableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                              >
                              <Collapse in={open1[item.CONFIRMATION_ID + reportName.charAt(0)]} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0, }}
                                    >
                                     Order Details....
                                  </Typography>

                                  <MyTable2 size="small" aria-label="payments" >
                                    <TableHead >
                                      <TableRow key={Math.random()} sx={{backgroundColor: "#fff"}}>
                                        <TableCell >Order Date</TableCell>
                                        <TableCell>Consultant</TableCell>
                                        <TableCell align="center">Company</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key={item.CONFIRMATION_REFERENCE + Math.random()}>
                                        {/* <MyTableCell component="th" scope="row"> */}
                                        <MyTableCell>
                                          {new Date(
                                            item.DATE
                                            ).toLocaleString("default", {
                                              day: "numeric",
                                              month: "short",
                                              year: "numeric",
                                            })}
                                        </MyTableCell>
                                        {/* <MyTableCell component="th" scope="row"> */}
                                        
                                        <MyTableCell align="center">
                                          <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                                            {item.Firstname?.charAt(0).toUpperCase() +item.Firstname?.toLowerCase().slice(1) + " " + item.Lastname?.charAt(0) }
                                          </Typography>
                                        </MyTableCell>

                                        <MyTableCell align="center">
                                          <Typography sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0 }}>
                                            {item.COMPANY_NAME.substring(0, 60)}
                                          </Typography>
                                        </MyTableCell>
                                      </TableRow>
                                    </TableBody>
                                  </MyTable2>
                                </Box>
                              </Collapse>
                            </MyTableCell>
                          {/* End Extra information collapsable */}
                          </TableRow>
                          
  )
                          
                          
                        }

// function preventDefault(event) {
//   event.preventDefault();
// }

// //Styled Table -------------------------------------------
// const MyTable = styled(Table)(({ theme }) => ({
//   "& .MuiTableHead-root": {
//     // backgroundColor: "#7a1125",
//     backgroundColor: "#4E1721",

//     "& 	.MuiTableCell-root": {
//       color: "#F5F5F5",
//       fontSize: "0.875rem",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "0.75rem",
//       },
//     },
//   },

//   "& 	.MuiTableBody-root": {
//     "& 	.MuiTableCell-root": {
//       color: "#7A1125",
//       fontSize: "0.875rem",
//       [theme.breakpoints.down("sm")]: {
//         fontSize: "0.75rem",
//       },
//     },
//   },

//   //backgroundColor: "#7a1125",

//   //color: "#270c10",

//   // height: 240,
//   // [theme.breakpoints.down("lg")]: {
//   //   height: 400,
//   // },
//   // [theme.breakpoints.down("md")]: {
//   //   height: 240,
//   // },
// }));
// //Styled Table End ---------------------------------------

// //Styled Row ----------------------------------------

// const MyTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     // backgroundColor: theme.palette.action.hover,
//     //backgroundColor: '#C9C0BB',
//     //backgroundColor: '#FFFAFA',
//     backgroundColor: "#F5F5F5",
//   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

//Styled Row End ------------------------------------

export default function ReportSales(props) {
  //console.log("from Sales report component...", props.reportVariables);
  // console.log(
  //   "from Sales report component the second props with all consultant info from DB...",
  //   props.salesByConsultantList
  // );
  //console.log("type1value",props.reportVariables.selectedDatesFrom,"type2value",props.salesByConsultantList[0].DATE)
  //console.log("type1value", props.reportVariables.selectedDatesFrom);
  //console.log("Date from DATABASE .............",new Date(props.salesByConsultantList[1].DATE))
  const userInfo = useSelector((state) => state.user);

  let numberPagesSold = 0;

  const { salesByConsultantList, reportName, reportType, reportVariables, totalPagesByConsultantHandler } = props;

  //console.log("this is the salesList....", salesByConsultantList )

  //console.log("the sales report Type....", reportType)
  
  //const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  const [open1, setOpen1] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  //Functions for Table Pagination--------------------------------------
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - salesByConsultantList.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      
    };
    //END Functions for TAble Pagination----------------------------------



    const handleCollapse = (event, item) => {
    event.preventDefault();
    setOpen1((prev) => ({ ...prev, [ item.CONFIRMATION_ID + reportName.charAt(0)]: !prev[ item.CONFIRMATION_ID + reportName.charAt(0)] }))
    //test to be deleted------
    //paymentListFiltered(0)
  };


  const containerRef = useRef(null);

  //Finding the total pages sold---------------------------------------
  //console.log("this is the array of sales...",salesByConsultantList )
  const totalPagesSold = salesByConsultantList.reduce((a,b) =>  a = a + b.PRODUCT_VALUE , 0 )
  totalPagesByConsultantHandler(totalPagesSold);

  return (
    <Fragment>
      <Title>{reportName}</Title>
      {salesByConsultantList?.length > 0 ? (
        <Box 
        ref={containerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          
        }}
        >
          {/* <Slide
            //sx={{ height: salesByConsultantList.length > 0 ? "auto" : 0 }}
            // sx={{ height: "auto" }}
            direction="right"
            in={salesByConsultantList.length > 0}
            container={containerRef.current}
          > */}
            <TableContainer 
            component={Paper}
            sx={{ width: matches3?"70vw":matches2 ? "60vw" : "60vw", marginTop: 2, padding:0  }}
            >
              <MyTable size="small">
                <TableHead>
                  <TableRow key={Math.random()}>
                    <StyledTableCell align="left">...</StyledTableCell>
                    <StyledTableCell>Reference</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Company</StyledTableCell>
                    <StyledTableCell>Net Price</StyledTableCell>
                    {!matches3 && reportVariables.consultantSelected_id===0 && <StyledTableCell align="right">Consultant</StyledTableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  ((rowsPerPage > 0 && reportType==="Main")? salesByConsultantList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):
                  salesByConsultantList).map((row) => {
                    //numberPagesSold = numberPagesSold + row.PRODUCT_VALUE;
                    //totalPagesByConsultantHandler(numberPagesSold);
                    //props.totalPagesByConsultantHandler(numberPagesSold);
                    return (
                      
                      // <TableRow key={row.id}>
                      <Fragment>
                      <MyTableRow key={row.CONFIRMATION_ID + reportName.charAt(0)}>
                        <MyTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, row)}
                              >
                                {open1[row.CONFIRMATION_ID + reportName.charAt(0)] ? (
                                  // <KeyboardArrowUpIcon />
                                  <KeyboardArrowUpIcon sx={{fontSize:matches3?"0.6rem":"0.85rem", padding:0}} />
                                ) : (
                                  // <KeyboardArrowDownIcon />
                                  <KeyboardArrowDownIcon sx={{fontSize:matches3?"0.6rem":"0.85rem",padding:0}} />
                                )}
                              </IconButton>
                            </MyTableCell>

                        <MyTableCell>
                        <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem":"0.85rem", padding:0 }}>
                          {row.CONFIRMATION_REFERENCE}
                        </Typography>
                          </MyTableCell>
                        <MyTableCell>
                          <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem":"0.85rem", padding:0 }}>
                          {new Date(row.DATE).toLocaleString("default", {
                            day: "numeric",
                            month: "short",
                          })}
                          </Typography>
                        </MyTableCell>
                        <MyTableCell>
                          <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0 }}>
                          {
                          //matches3?row.COMPANY_NAME.substring(0, 10):
                          matches2?row.COMPANY_NAME.substring(0,10):
                          matches?row.COMPANY_NAME.substring(0,20):
                          row.COMPANY_NAME.substring(0, 60)}
                          </Typography>
                        </MyTableCell>
                        <MyTableCell>
                          <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0 }}>
                            {`£ ${row.NET_AMOUNT.toFixed(2)}`}
                          </Typography>
                        </MyTableCell>
                        {/* <TableCell align="right">{`£ ${row.cumulative_sum}`}</TableCell> */}
                        {!matches3 && reportVariables.consultantSelected_id===0 &&  (
                          <MyTableCell>
                            <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0 }}>
                              {row.Firstname?.charAt(0).toUpperCase() +row.Firstname?.toLowerCase().slice(1) + " " + row.Lastname?.charAt(0) }
                              {/* {item.wOrder.Firstname + "  " + item.wOrder.Lastname.charAt(0)} */}
                            </Typography>
                          </MyTableCell>
                          )}
                      </MyTableRow>
                      
                      {/* <MyTableCell align="right">{` ${numberPagesSold}`}</MyTableCell> */}

                      {open1[row.CONFIRMATION_ID + reportName.charAt(0) ] && <DisplayExtraInfo open1={open1}  item={row} matches = {matches} matches2 = {matches2} matches3 = {matches3}  reportName={reportName} />}
                      </Fragment>

                    );
                    // { </TableRow> }
                  })}

                    {emptyRows > 0 && (
                     <TableRow key={Math.random()} style={{ height: (matches3?20:matches2?30:40) * emptyRows }}>
                       <TableCell colSpan={matches3?5:6} />
                     </TableRow>
                     )}

                </TableBody>

                {/* Footer--------------------------------------------------------------- */}
                {reportType==="Main" ?
                <TableFooter sx={{backgroundColor:theme.palette.common.ludisMain, color:theme.palette.common.ludisLight1}}>
                    <TableRow key={Math.random()+ reportName.charAt(0)}>
                      <TablePagination
                        //component="div"
                        
                        rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                        colSpan={matches3?5:6}
                        count={salesByConsultantList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native:true,
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        //extras------------------------------------------------
                        labelRowsPerPage={<span>{matches3?"":"rows"} </span>}
                        //labelDisplayedRows={({page})=>{return `Page: ${page}`}}
                        labelDisplayedRows={ matches3? ({page})=>{return `Page: ${page}`} :
                        (from=page) => `${from.from}-${from.to === -1 ? from.count : from.to} of ${from.count}`
                      }
                      //sx={{ color:theme.palette.common.ludisLight1 }}
                      sx={{ ".MuiTablePagination-toolbar":{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"baseline",
                              justifyContent:"center",
                              alignContent:"center",
                              
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              pt:1,
                              //margin:"auto"
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              width: "auto",
                              ml: matches3?"0":"0",
                              pl: matches3?"0":"1"
                              
                              
                            },
                            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":{
                              //display:"flex",
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              //margin:"auto"
                              //backgroundColor:"#000"
                            } ,
                            ".MuiTablePagination-selectIcon":{
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.8rem" : matches?"0.9rem": "1rem",
                              //backgroundColor:"#000"
                              
                            },
                            ".MuiTablePagination-menuItem":{
                              color:theme.palette.common.ludisMain,
                              fontSize:"1rem",
                              
                              //backgroundColor:"#000"
                            }
                            
                          }}
                          />
                    </TableRow>
                  </TableFooter>
                  :""}
                  {/* END Footer----------------------------------------------------------- */}

              </MyTable>
            </TableContainer>
          {/* </Slide> */}

          {/* <Link
            color="primary"
            href="#"
            onClick={preventDefault}
            sx={{ mt: 3 }}
            >
            See more...
          </Link> */}
          <Divider />
          {!matches3 && userInfo.permission===6 &&

          <PDFDownloadLink
          style={styles.downloading}
          document={
            <PdfReportDocument
            reportVariables={props.reportVariables}
            salesByConsultantList={salesByConsultantList}
            />
          }
          fileName="report.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Box textAlign="left">
                  <PulseLoader color="#7A1125" size="8" />
                </Box>
              ) : (
                <Button sx={{ color: "#7A1125",  textTransform: 'capitalize'  }}>
                  <FileDownloadOutlinedIcon
                    sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
                    />
                  Download
                </Button>
              ) 
            }
          </PDFDownloadLink>
          
            }
          <Divider />
        </Box>
      ) : (
        <Box
          sx={{
            m: 2,
            textAlign: "center",
          }}
        >
          <Button>
            <ListAltOutlinedIcon
              //baseClassName="fas"
              baseclassname="fas"
              className="fa-plus-circle"
              sx={{ fontSize: 30, color: "#4E1721" }}
            />
          </Button>
        </Box>
      )}
    </Fragment>
  );
}
