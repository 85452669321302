import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";


import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import DashboardIcon from "@mui/icons-material/Dashboard";


//new Icons for Ludis ...
import LaptopIcon from "@mui/icons-material/Laptop";
import CallIcon from "@mui/icons-material/Call";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import SellIcon from "@mui/icons-material/Sell";
import PaymentIcon from '@mui/icons-material/Payment';
import GroupsIcon from "@mui/icons-material/Groups";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import DraftsIcon from '@mui/icons-material/Drafts';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppsIcon from '@mui/icons-material/Apps';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import { Link } from "react-router-dom";

import { styled } from "@mui/styles";

//import theme from "./UI/Theme";





//Test --------------------------------------------

//ListItem Styled -----------------------------------
const MyListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  textDecoration: "none",
}));

//ListItemButton Styled -----------------------------------
const MyListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ theme, selected }) => ({
  textDecoration: "none",
  paddingLeft: 0,

  "& .MuiListItemText-root": {
    ...theme.typography.estimate,
    color: theme.palette.common.ludisMain,
    opacity: 0.7,
    fontSize: "1.2rem",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.ludisMain2,
    },
    ...(selected && {
      opacity: 1,
      color: theme.palette.common.ludisMain,
    }),
  },
}));

//-------------------Accordion Styled -----------------------
const MyAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // display:"flex",

  "& .MuiAccordionSummary-root": {
    width: "240px", //the width is the same as the drawer with when open
  },

  "& .MuiPaper-root": {
    paddingLeft: 0,
    border: "5px solid",
  },

  //   border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export default function SecondaryListItems_Management_Ops(props) {
  const [open, setOpen] = useState(false);
  //const [open2, setOpen2] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const ludisRoutes = [
    {
      name: "Admin",
      link: "/main/admin",
      icon: <LaptopIcon />,
      activeIndex: 1,
      options:[
        {
          name: "Users",
          link: "/main/users",
          activeIndex: 1,
          selectedIndex: 11,
          icon: <AccountCircleOutlinedIcon sx={{fontSize:'medium'}} />,

        }
      ],
      ariaOwns: anchorEl ? "simple-menu" : undefined,
      ariaPopup: anchorEl ? true : undefined,
      mouseOver: (event) => handleClick(event),
    },
    {
      name: "Credit Control",
      link: "/main/creditcontrol",
      icon: <CallIcon />,
      activeIndex: 2,
      options:[
        {
          name:"Summary",
          link:"/main/creditcontrolgeneral",
          activeIncex:2,
          selectedIndex:21,
          icon: <AppsIcon sx={{fontSize:'medium'}} />,
        },
        {
            name:"Orders",
            link:"/main/ccorders",
            activeIncex:3,
            selectedIndex:33,
            icon: <ListAltIcon sx={{fontSize:'medium'}} />,
        },
      ],
    },
    { 
      name: "sales", 
      link: "/main/sales", 
      icon: <SellIcon />, 
      activeIndex: 3,
      options: [
        // {
        //   name: "Orders",
        //   link: "/main/orders",
        //   activeIndex: 3,
        //   selectedIndex: 31,
        //   icon: <ContentCopyOutlinedIcon sx={{fontSize:'medium'}} />,
        // },
        // {
        //   name: "Pre-Orders",
        //   link: "/main/preorders",
        //   activeIndex: 3,
        //   selectedIndex: 32,
        //   icon: <FindInPageOutlinedIcon sx={{fontSize:'medium'}} />,
        // },
        // {
        //   name: "Credit N.",
        //   link: "/main/creditnotes",
        //   activeIndex: 3,
        //   selectedIndex: 33,
        //   icon: <CreditCardOffOutlinedIcon sx={{fontSize:'medium'}} />,
        // },
        // {
        //   name: "Quote",
        //   link: "/main/quote",
        //   activeIndex: 4,
        //   selectedIndex: 44,
        //   icon: <DraftsIcon sx={{fontSize:'medium'}} />,
        // },
      ], 
    },
    {
      name: "Production",
      link: "/main/production",
      icon: <ArchitectureIcon />,
      activeIndex: 4,
      options:[
        {
          name:"Art. Tracker",
          link:"/main/artworktracker",
          activeIndex:4,
          selectedIndex:41,
          icon:<ContentPasteSearchIcon sx={{fontSize:'medium'}} />,
        }
      ]
    },
    {
      name: "Purchase",
      link: "/main/purchase",
      icon: <ShoppingCartIcon />,
      activeIndex: 5,
      options:[
        {
          name: "Summary",
          link: "/main/purchasegeneral",
          activeIndex: 5,
          selectedIndex: 51,
          icon: <AppsIcon sx={{fontSize:'medium'}} />,

        },
        // {
        //   name: "P.Order",
        //   link: "/main/purchaseorder",
        //   activeIndex: 5,
        //   selectedIndex: 52,
        //   icon: <AssignmentIcon sx={{fontSize:'medium'}} />,

        // },
        // {
        //   name: "P.Inv",
        //   link: "/main/purchaseinvoices",
        //   activeIndex: 5,
        //   selectedIndex: 53,
        //   icon: <ReceiptIcon sx={{fontSize:'medium'}} />,

        // },
        // {
        //   name: "Non-Ops",
        //   link: "/main/purchaseinvoicesnonop",
        //   activeIndex: 5,
        //   selectedIndex: 54,
        //   icon: <ReceiptIcon sx={{fontSize:'medium'}} />,

        // }
      ]
    },

    {
      name: "Reports",
      link: "/main/reports",
      icon: <BarChartIcon />,
      activeIndex: 6,
      options: [
        {
          name: "Summary",
          link: "/main/reportsgeneral",
          activeIndex: 6,
          selectedIndex: 61,
          icon: <AppsIcon sx={{fontSize:'medium'}} />,
        },
        {
          name: "Sales",
          link: "/main/salesreport",
          activeIndex: 6,
          selectedIndex: 62,
          icon: <SellIcon sx={{fontSize:'medium'}} />,
        },
        {
          name: "Payments",
          link: "/main/paymentsreport",
          activeIndex: 6,
          selectedIndex: 63,
          icon: <PaymentIcon sx={{fontSize:'medium'}} />,
        },
        // {
        //   name: "Purchase",
        //   link: "/main/customsoftware",
        //   activeIndex: 6,
        //   selectedIndex: 64,
        //   icon: <ShoppingCartIcon sx={{fontSize:'medium'}} />,
        // },
      ],
    },
    {
      name: "Salary",
      link: "/main/salary",
      icon: <GroupsIcon />,
      activeIndex: 7,
      options:[
        {
          name: "Summary",
          link: "/main/salarygeneral",
          activeIndex: 7,
          selectedIndex: 71,
          icon: <AppsIcon sx={{fontSize:'medium'}} />,

        },
        {
          name: "Sal.Report",
          link: "/main/salarylistmenu",
          activeIndex: 7,
          selectedIndex: 72,
          icon: <AssignmentIcon sx={{fontSize:'medium'}} />,

        },
        
      ]
    },
  ];

  // const menuOptions = [
  //   // { name: "Services", link: "/main/services", activeIndex: 1, selectedIndex: 0 },
  //   {
  //     name: "Custom Software Development",
  //     link: "/main/customsoftware",
  //     activeIndex: 1,
  //     selectedIndex: 1,
  //   },
  //   {
  //     name: "Mobile App Development",
  //     link: "/main/mobileapps",
  //     activeIndex: 1,
  //     selectedIndex: 2,
  //   },
  //   {
  //     name: "Website Development",
  //     link: "/main/websites",
  //     activeIndex: 1,
  //     selectedIndex: 3,
  //   },
  // ];

  return (
    <List
      key={`${Math.random()}`}
      disablePadding
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
        ></ListSubheader>
      }
    >
      <ListItem
        key={`${Math.random()}`}
        component={Link}
        // to="/main/dash-main-content"
        to="/main"
        onClick={() => {
          props.setValue(0);
          setExpanded(0);
        }}
      >
        <MyListItemButton 
        key={`${Math.random()}`}
        selected={props.value === 0}
        >
          <ListItemIcon key={`${Math.random()}`}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </MyListItemButton>
      </ListItem>

      {ludisRoutes.map((route, index) => (
        <MyAccordion
          key={`${Math.random()}`}
          elevation={0}
          //key={route.name}
          expanded={expanded === route.activeIndex}
          onChange={handleAccordionChange(route.activeIndex)}
        >
          <Grid
            key={`${Math.random()}`}
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <MyListItem
              key={`${Math.random()}+${route.activeIndex} ${index}`}
              component={Link}
              to={route.link}
              onClick={() => props.setValue(route.activeIndex)}
            >
              <AccordionSummary
                key={`${Math.random()}`}
                expandIcon={<ExpandMoreIcon color="secondary" />}
              >
                <MyListItemButton
                key={`${Math.random()}`} 
                selected={props.value === route.activeIndex}
                >
                  <ListItemIcon key={`${Math.random()}`}>{route.icon}</ListItemIcon>
                  <ListItemText key={`${Math.random()}`} primary={route.name} />
                </MyListItemButton>
              </AccordionSummary>
            </MyListItem>
          </Grid>
          <AccordionDetails key={`${Math.random()}`}>
            {route.options ? (
              <Grid key={`${Math.random()}`}  container direction="column">
                {route.options.map((option, i) => (
                  <Grid key={`${Math.random()}`}   item sx={{ paddingLeft: 1.5 }}>
                    <MyListItem
                      key={`${Math.random()}+${option.selectedIndex} ${i}`}
                      //divider
                      component={Link}
                      to={option.link}
                      onClick={() => {
                        // setOpenDrawer(false);
                        props.setSelectedIndex(option.selectedIndex);
                      }}
                    >
                      <MyListItemButton
                        key={`${Math.random()}`}
                        selected={props.selectedIndex === option.selectedIndex}
                        // props.value === 1 &&
                        // window.location.pathname !== "/admin"
                      >
                        <ListItemIcon key={`${Math.random()}`} >{option.icon}</ListItemIcon>

                        <ListItemText
                          key={`${option} ${option.activeIndex} ${i}`}
                          disableTypography
                        >
                          {option.name}
                        </ListItemText>
                      </MyListItemButton>
                    </MyListItem>
                  </Grid>
                ))}

                
              </Grid>
            ) : (
              "coming Soon..."
            )}
          </AccordionDetails>
        </MyAccordion>
      ))}
    </List>
  );
}
