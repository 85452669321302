import axios from "axios";
import { useEffect, useState, Fragment } from "react";

//loader Imports--------------
import { SyncLoader } from "react-spinners";
import { PropagateLoader } from "react-spinners";

// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";

//IMPORTS FROM MUI-------------------------------
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
//import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
//import { styled } from "@mui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";

import ReportSalesList from "./ReportSalesList";
import ReportSalesGraph from "./ReportSalesGraph";
import SummaryReport from "./SummaryReport";
import theme from "../UI/Theme";
import Title from "../Title";
import { MyTextField, MyPaper } from "../UI/styledComponents/StyledComponents";

import { variables } from "../../Variables";

import SalesByConsultantSummaryGraph from "../graphComponents/SalesByConsultantSummaryGraph";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

let reportVariables = {
  consultantSelected_id: "0",
  selectedDatesFrom: "",
  selectedDatesTo: "",
};

export default function SalesReport() {
  const [consultantList, setConsultantList] = useState([]);
  const [consultantListFiltered, setConsultantListFiltered] = useState([]);
  
  const [consultantSelected, setConsultantSelected] = useState(0);

  const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  const [selectedDateTo, setSelectedCDateTo] = useState(null);

  const [salesByConsultantList, setSalesByConsultantList] = useState([]);
  const [salesCancelledByConsultantList, setSalesCancelledByConsultantList] =
    useState([]);

  const [totalSalesByConsultant, setTotalSalesByConsultant] = useState(null);
  const [totalSalesCancelledByConsultant, setTotalSalesCancelledByConsultant] =
    useState(null);

  const [totalPagesByConsultant, setTotalPagesByConsultant] = useState(null);
  const [totalPagesCancelledByConsultant, setTotalPagesCancelledByConsultant] =useState(null);

  const [notification, setNotification] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsLoading2, setIsLoading2] = useState(false);


  const [showSalesReportList, setShowSalesReportList] = useState(false);

  //const[refreshForm, setRefreshForm]=useState(null)

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  const showSalesReportHandler = (event) => {
    event.preventDefault();
    setShowSalesReportList((prev) => !showSalesReportList);
  };

  //to be deleted....
  const locale = "de";
  //const [locale, setLocale] = useState("de");
  //to be deleted end....

  // const createPdf_1 = () => {
  //   const divToDisplay = document.getElementById("luis");

  //   html2canvas(divToDisplay).then(function (canvas) {
  //     const ImageMargLeft = 0;
  //     const imageMargTop = 5;
  //     const imageWidth = 225;
  //     const imageHeight = 250;

  //     const divImage = canvas.toDataURL("image/png");
  //     const divImage2 = canvas.toDataURL("image/png");
  //     const divImage3 = canvas.toDataURL();

  //     window.open(divImage3);

  //     // const pdf = new jsPDF();
  //     // pdf.addImage(divImage, 'PNG', ImageMargLeft, imageMargTop);
  //     // pdf.addPage()
  //     // pdf.html(divToDisplay, { html2canvas: { scale: 0.30 } })

  //     //   const totalPDFPages = 1;
  //     //   const HTMLContent_Height = '50';
  //     //   const width = '80';
  //     //   const height = '40';
  //     //   for (var i = 1; i <= totalPDFPages; i++) {
  //     //     pdf.addPage();
  //     //     pdf.addImage(divImage, 'JPG', 0, (-HTMLContent_Height*i),width,height)
  //     // }

  //     //pdf.save("download.pdf");
  //   });
  // };
  //PDF document creation end ----------------------------------

  //new alternative

  // const createPdf_2 = () => {
  //   const ImageMargLeft = 0;
  //   const imageMargTop = 5;
  //   const imageWidth = 225;
  //   const imageHeight = 250;

  //   const compLuis = document.getElementById("luis");
  //   const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });

  //   pdf
  //     .html(compLuis, {
  //       margin: [4, 6, 4, 6],
  //       html2canvas: {
  //         scale: 0.35,
  //         allowTaint: true,
  //         scrollY: -window.scrollY,
  //       },
  //     })
  //     .then(() => {
  //       pdf.save("test.pdf");
  //     });
  // };
  //end new alternative

  //-------------- Information for Summary Report---------------//
  const summaryInfo = {
    title: "Net Sales",
    titleSales: "Sales",
    titleCancelations: "cancelations",
    subtitle: "T. Pages",
    subtitle2: "Price/Page",
  };

  const resetReportVariables = () => {
    reportVariables = {
      ...reportVariables,
      consultantSelected_id: "0",
      selectedDatesFrom: "",
      selectedDatesTo: "",
    };
  };

  const getConsultants = () => {
    axios
      // .get("http://localhost:5000/consultants", { withCredentials: true })
      //.get(variables.API_URL+"consultants", { withCredentials: true })
      .get(variables.API_URL + "users/activeusersbytitle/" + "2", {
        withCredentials: true,
      })

      .then((res) => {
        //setConsultantList(res.data.consultantInfo);
        //console.log("list of users... from DB", res.data.activeUsersByTitleId);
        setConsultantList(res.data.activeUsersByTitleId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get List of Sales by Consultant selected--------------
  //"http://localhost:5000/salesbyconsultant/" +
  const getSalesByConsultant = (
    consultantId,
    selectedDatesFrom,
    selectedDatesTo
  ) => {

    if(selectedDatesFrom && selectedDatesTo){

    

    setIsLoading(true);
    axios
      .get(
        variables.API_URL +
          "sales/salesbyconsultant/" +
          consultantId +
          "/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true },
      )
      .then((res) => { 
        
        setSalesByConsultantList(res.data.salesByConsultant)

        if (res.data.salesByConsultant.length === 0) {
          setTotalSalesByConsultant(0);
          setTotalPagesByConsultant(0);
          //console.log("hola")
        } else {
          //console.log("NOT hola", res)
          const totalSales = res.data.salesByConsultant.slice(-1)
          
          // console.log(
          //   "from the function axios.....",
          //   totalSales[0].cumulative_sum
          // );
          //console.log("this is the result from Backend for salesbyconsultant route...")
          setTotalSalesByConsultant(totalSales[0].cumulative_sum)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("there is an error here...",error);
        setNotification("Something went wrong, please try again")
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });


    }else{

      setNotification("Please insert Valid Data or Refresh the Page...")
        setTimeout(() => {
          setNotification(null);
        }, 5000);

    }



      
  };

  //Get List of Cancelled Orders by Consultant Selected--------------
  //"http://localhost:5000/sales/salescancelledbyconsultant/" +
  const getSalesCancelledByConsultant = (
    consultantId,
    selectedDatesFrom,
    selectedDatesTo
  ) => {

    if(selectedDatesFrom && selectedDatesTo){
    //console.log("variables...",consultantId,selectedDatesFrom,selectedDatesTo)
    //console.log("variables...",consultantId,typeof(selectedDatesFrom),selectedDatesTo)
    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          "sales/salescancelledbyconsultant/" +
          consultantId +
          "/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log(res.data);
        setSalesCancelledByConsultantList(res.data.salesCancelledByConsultant);

        if (res.data.salesCancelledByConsultant.length === 0) {
          setTotalSalesCancelledByConsultant(0);
          setTotalPagesCancelledByConsultant(0);
        } else {
          const totalSalesCancelledByConsultant =
            res.data.salesCancelledByConsultant.slice(-1);
          // console.log(
          //   "from the function axios.....",
          //   totalSales[0].cumulative_sum
          // );
          setTotalSalesCancelledByConsultant(
            totalSalesCancelledByConsultant[0].cumulative_sum
          );
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification(
          "Something went wrong when Loading Cancellations, please try again"
        );
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });



    }else{

      //console.log("el error...")
      setSelectedCDateFrom(null)
      setSelectedCDateTo(null)
      //resetReportVariables();
      //setRefreshForm(Math.random())

      // setNotification("Please insert Valid Data or Refresh the Page...")
      //   setTimeout(() => {
      //     setNotification(null);
      //   }, 5000);

    }




  };

  useEffect(() => {
    getConsultants();
    resetReportVariables();
  }, []);

  useEffect(() => {
    if (consultantList.length > 0) {
      const myconsultantListFiltered = consultantList.map((option) => {
        const firstLetter = option.NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      //Add the Option to select All Consultants----
      myconsultantListFiltered.push({
        firstLetter: "1",
        CONSULTANT_ID: 0,
        Firstname: "All",
        Lastname: "Consultants",
      });

      setConsultantListFiltered(myconsultantListFiltered);
    }
  }, [consultantList]);

  const handleConsultantChange = (event, value) => {
    //console.log("value from the function handleconsultant...", value?.CONSULTANT_ID);

    setSalesByConsultantList([]);
    setSalesCancelledByConsultantList([]);
    setTotalSalesByConsultant(null);
    setTotalSalesCancelledByConsultant(null);
    setTotalPagesByConsultant(0);
    setTotalPagesCancelledByConsultant(0);
    //setConsultantSelected(value);
    setShowSalesReportList(false);

    if (value) {
      setConsultantSelected(value.CONSULTANT_ID);
      reportVariables = {
        ...reportVariables,
        consultantSelected_id: value.CONSULTANT_ID,
      };
    } else {
      setConsultantSelected(0);
      reportVariables = {
        ...reportVariables,
        //consultantSelected_id: null,
        consultantSelected_id: "0",
      };
    }
    //console.log("reportVariables after consultant change", reportVariables);
  };

  const handleDatesChangesFromTo = (date, id) => {
    //console.log("value from the DatePicker", date);
    setSalesByConsultantList([]);
    setSalesCancelledByConsultantList([]);
    setTotalSalesByConsultant(null);
    setTotalPagesByConsultant(0);
    setTotalPagesCancelledByConsultant(0);

    const dateIsValid = date !== null && date !== "Invalid Date";

    if (id === 0) {
      setSelectedCDateFrom(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: date,
        };
      }
    } else if (id === 1) {
      setSelectedCDateTo(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: date,
        };
      }
    } else {
      //console.log("error, date not usable only 2 options available");
      return;
    }

    // console.log(
    //   "reportVariables after date change from the second picker....",
    //   reportVariables
    // );
  };

  const handleGetReport_2 = ({ reportVariables }) => {
    if (
      //consultantSelected &&
      selectedDateFrom !== null && selectedDateFrom !=="" &&
      selectedDateTo !== null && selectedDateTo !=="" &&
      selectedDateFrom !== "Invalid Date" &&
      selectedDateTo !== "Invalid Date"
    ) {
      getSalesByConsultant(
        reportVariables.consultantSelected_id,
        reportVariables.selectedDatesFrom,
        reportVariables.selectedDatesTo
      );
      getSalesCancelledByConsultant(
        reportVariables.consultantSelected_id,
        reportVariables.selectedDatesFrom,
        reportVariables.selectedDatesTo
      );
      setNotification(null);
      setShowSalesReportList(true);
    } else {
      //console.log("missing information for the report");
      setSalesByConsultantList([]);
      setSalesCancelledByConsultantList([]);
      setTotalSalesByConsultant(null);
      setTotalPagesByConsultant(0);
      setTotalPagesCancelledByConsultant(0);

      setShowSalesReportList(false);

      setNotification("Please select valid data");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const totalPagesByConsultantHandler = (pages) => {
    setTotalPagesByConsultant(pages);
  };

  const totalPagesCancelledByConsultantHandler = (pages) => {
    setTotalPagesCancelledByConsultant(pages);
  };

  // console.log(
  //   "salesCancelledByConsultantList....",
  //   salesCancelledByConsultantList
  // );

  //console.log("this is the salesByConsultantListCancelled......",salesCancelledByConsultantList)

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Box
        id="luis"
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",

                  //height: matches ? 400 : 250,
                  height: matches ? 400 : 250,

                }}
              >
                <Grid>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <GradingOutlinedIcon
                      //baseClassName="fas"
                      baseclassname="fas"
                      className="fa-plus-circle"
                      sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                    />
                    <Title>Sales Report....</Title>
                  </Box>
                  {notification && (
                    <Alert severity="error">
                      <h4>{notification}</h4>
                    </Alert>
                  )}

                  <Divider />
                </Grid>
                {consultantListFiltered.length > 0 ? (
                  <Grid
                    //container
                    //spacing={1}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      flexDirection: matches ? "column" : "row",

                      margin: "auto",
                    }}
                  >
                    <Autocomplete
                      id="grouped-demo"
                      options={consultantListFiltered.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) =>
                        option.Firstname +
                        "  " +
                        option.Lastname.charAt(0) +
                        "."
                      }
                      sx={
                        matches
                          ? { width: "auto", margin: "0.2em" }
                          : { width: 250, margin: "1em" }
                      }
                      onChange={(event, value) =>
                        handleConsultantChange(event, value)
                      }
                      renderInput={(params) => (
                        <MyTextField {...params} label="Consultant" />
                      )}
                    />

                    <DatePicker
                      mask={maskMap[locale]}
                      format="YYY/MM/dd"
                      label="From"
                      value={selectedDateFrom}
                      onChange={(date) => handleDatesChangesFromTo(date, 0)}
                      renderInput={(params) => (
                        <MyTextField
                          {...params}
                          sx={{ margin: matches ? "0.2em" : "1em" }}
                        />
                      )}
                    />

                    <Box
                      sx={{
                        margin: matches ? "0.2em" : "1em",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      to{" "}
                    </Box>

                    <DatePicker
                      label="To"
                      value={selectedDateTo}
                      onChange={(date) => handleDatesChangesFromTo(date, 1)}
                      renderInput={(params) => (
                        <MyTextField
                          {...params}
                          sx={{ margin: matches ? "0.2em" : "1em" }}
                        />
                      )}
                    />

                    <Button
                      onClick={() => handleGetReport_2({ reportVariables })}
                      variant="outlined"
                      sx={{
                        margin: matches ? "0.2em" : "1em",
                        color: "#7a1125",
                        borderColor: "#7a1125",
                        height: "3.2rem",
                        "&:hover": {
                          borderColor: "#270c10",
                          color: "#fce9ec",
                          backgroundColor: "#7a1125",
                        },
                      }}
                    >
                      GO
                    </Button>
                  </Grid>
                ) : (
                  <PropagateLoader color="#7A1125" size="15" margin="2" />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: matches ? 400 : 250,
                  height: matches2 ? "auto": matches? 400 : 250,
                  justifyContent:"center",
                  //backgroundColor:"#000",
                  //minWidth:240
                }}
              >

                {!IsLoading?
                <SummaryReport
                summaryInfo={summaryInfo}
                totalSalesByConsultant={totalSalesByConsultant}
                totalSalesCancelledByConsultant={totalSalesCancelledByConsultant}
                totalPagesByConsultant={totalPagesByConsultant}
                totalPagesCancelledByConsultant={totalPagesCancelledByConsultant}
                changeDatesHandler=""
                changeDates=""
                link={false}
                boxSize={270}
                boxMargin={0}
                isLoading={IsLoading}
                />
                : 
                <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", heigh:"50vh"}}>
                <PropagateLoader color="#7A1125" size="15" />
                </Box>
              }
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <ReportSalesGraph
                  reportVariables={reportVariables}
                  salesByConsultantList={salesByConsultantList}
                  totalPagesByConsultantHandler={(pages) =>
                    totalPagesByConsultantHandler(pages)
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={12} id="luis2">
              <Paper
                sx={{ p: 2, mb: 1, display: "flex", flexDirection: "column" }}
              >
                {IsLoading && (
                  <Box textAlign="center">
                    <SyncLoader color="#7A1125" size={15} />
                  </Box>
                )}

                {/* options to show or hide the sales report list----------------------- */}
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    showSalesReportHandler(event);
                  }}
                  sx={{ color: "#7A1125", textTransform: "capitalize" }}
                >
                  <TimelineIcon
                    sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
                  />

                  {/* {props.changeDates ? "View This Week" : "View All Year"} */}

                  {showSalesReportList ? "Hide Report" : "Show Report"}
                </Button>

                {showSalesReportList ? (
                  <Fragment>
                    <Box sx={{marginBottom: 5}}>
                      <ReportSalesList
                        reportVariables={reportVariables}
                        salesByConsultantList={salesByConsultantList}
                        totalPagesByConsultantHandler={(pages) =>
                          totalPagesByConsultantHandler(pages)
                        }
                        reportName="Orders"
                        reportType="Main"
                        />

                    </Box>
                    
                    <Divider />

                    <Box sx={{marginBottom: 5}}>
                      <ReportSalesList
                        reportVariables={reportVariables}
                        salesByConsultantList={salesCancelledByConsultantList}
                        totalPagesByConsultantHandler={(pages) =>totalPagesCancelledByConsultantHandler(pages)}
                        reportName="Cancelled Orders"
                        reportType="Main"
                        />
                    </Box>
                  </Fragment>
                ) : (
                  ""
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  mb: 10,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  height: "auto",
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                {salesByConsultantList.length > 0 && consultantSelected === 0 ?
                <Fragment>

                <SalesByConsultantSummaryGraph
                tabInitialDate={reportVariables.selectedDatesFrom}
                tabFinalDate={reportVariables.selectedDatesTo}
                />
                <Toolbar />
                </Fragment>
                :"..."}
                
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LocalizationProvider>
  );
}
