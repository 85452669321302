import axios from "axios";
import { useEffect, useState, Fragment, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

//loader Imports--------------
//import { SyncLoader } from "react-spinners";
//import { PropagateLoader } from "react-spinners";

import html2canvas from "html2canvas";
//import { jsPDF } from "jspdf";

import theme from "../UI/Theme";

import DashboardContainerReport from "../UI/DashboardContainerReport";
//import PaymentsReportByDate from "./PaymentsReportByDate";
import PaymentsReportByDate from "../reportComponents/PaymentsReportByDate";
import SalaryReportByDate from "../reportComponents/SalaryReportByDate";

import { variables } from "../../Variables";
import { MyTextField } from "../UI/styledComponents/StyledComponents";
//import PaymentsIn from "../paymentsComponent/PaymentsIn";
import WagesReportSummary from "../reportComponents/purchaseReports/WagesReportSummary";

import Title from "../Title";

import { SocketContext } from "../../utils/socket-client";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";

import SalesByConsultantSummaryGraph from "../graphComponents/SalesByConsultantSummaryGraph";
import SalaryChart from "../graphComponents/SalaryChart";

//------MUI IMPORTS------------------------------------------
import Box from "@mui/material/Box";
import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

import ReceiptIcon from "@mui/icons-material/Receipt";
import ComputerIcon from "@mui/icons-material/Computer";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GradingIcon from "@mui/icons-material/Grading";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";






const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

let reportVariables = {
  consultantSelected_id: "0",
  selectedDatesFrom: "",
  selectedDatesTo: "",
};

const SalaryList = (props) => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );

  const [consultantListFiltered, setConsultantListFiltered] = useState([]);
  const [consultantSelected, setConsultantSelected] = useState(0);

  const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  const [selectedDateTo, setSelectedCDateTo] = useState(null);

  const [paymentsByDateList, setPaymentsByDateList] = useState([]);
  const [totalPaymentsByDate, setTotalPaymentsByDate] = useState(null);
  //const [showPaymentsList, setShowPaymentsList] = useState(false)

  const [salariesByDateList, setSalariesByDateList] = useState([]);
  const [salariesByDateByConsultantList, setSalariesByDateByConsultantList] = useState([]);
  const [totalSalariesByDate, setTotalSalariesByDate] = useState(null);

  const [wagesInvpaymentsByInvoiceByDate, setWagesInvpaymentsByInvoiceByDate] =
    useState(null);

  const [costTypeList, setCostTypeList] = useState(null);

  const [notification, setNotification] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [updatedPayment, setUpdatedPayment] = useState(null);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));
  //const matches2 = useMediaQuery(theme.breakpoints.down("md"));

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //Variables for the consolidated array with wages invoices and payments on them-----------
  const [
    allWagesInvoicesWithPaymentsFull,
    setAllWagesInvoicesWithPaymentsFull,
  ] = useState([]);
  const [allOutsWagesData, setAllOutsWagesData] = useState([]);
  const [totalWagesPaymentsByDate, setTotalWagesPaymentsByDate] =
    useState(null);
  const [totalWagesBySupplierByDate, setTotalWagesBySupplierByDate] =
    useState(null);
  const [totalOutsWagesBySupplierByDate, setTotalOutsWagesBySupplierByDate] =
    useState(null);

  //end of Varialbes for the consolidated array with wages invoices and payments on them----

  const [consultantList, setConsultantList] = useState([]);

  const { reportTitle, wagesType, reportTitleShort } = props;

  //to be deleted....
  const [locale, setLocale] = useState("de");
  //to be deleted end....

  //Function to manage the state to check if new Payment has been recorded---------------------------------
  const isUpdatedPayment = (payId) => {
    setUpdatedPayment(payId);
  };

  // //to show payments list as per Bank Statements or Recorde Date-----------------------------------
  // const showBankHandler = (event) => {
  //   event.preventDefault();
  //   setShowBank((prev) => !showBank);
  // };

  const resetReportVariables = () => {
    reportVariables = {
      ...reportVariables,
      consultantSelected_id: "0",
      selectedDatesFrom: "",
      selectedDatesTo: "",
    };
  };

  // //Function to manage the state to check if new Wages Payment has been recorded---------------------------------
  // const isUpdatedPurchasePayment = (payId)=>{
  //   setUpdatedPurchasePayment(payId);
  // }

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      //console.log("receiving a message object", data);
      //console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      // console.log(
      //   "receiving a notification object for PAYMENTS INNNNNNNNNNNNNNN",
      //   data
      // );
      //console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  // const getAllPaymentsByDate = (selectedDatesFrom, selectedDatesTo) => {
  //   const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
  //   const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();

  //   setIsLoading1(true);
  //   axios
  //     .get(
  //       variables.API_URL +
  //         "payments/paymentsbydate/" +
  //         // paymentsUrl +
  //         selectedDatesFrom_2 +
  //         "/" +
  //         selectedDatesTo_2 +
  //         "/" +
  //         showBank,
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       //console.log(res.data);
  //       setPaymentsByDateList(res.data.paymentsByDate);

  //       if (res.data.paymentsByDate.length === 0) {
  //         setTotalPaymentsByDate(0);
  //         //setTotalPagesByConsultant(0);
  //       } else {
  //         const totalPaid = res.data.paymentsByDate.slice(-1);
  //         setTotalPaymentsByDate(totalPaid[0].cumulative_sum);
  //       }
  //       setIsLoading1(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setNotification("Something went wrong, please try again");
  //       setTimeout(() => {
  //         setNotification(null);
  //       }, 5000);
  //     });
  // };

  //Get List of consultants depending on FIX or DIR --------------------------------------
  const getConsultants = () => {
    axios
      // .get("http://localhost:5000/consultants", { withCredentials: true })
      //.get(variables.API_URL+"consultants", { withCredentials: true })
      //.get(variables.API_URL + "users/activeusersbytitle/" + "2", {
      .get(variables.API_URL + "users/activeusersbytitle/" + (wagesType + 1), {
        withCredentials: true,
      })

      .then((res) => {
        //setConsultantList(res.data.consultantInfo);
        //console.log("list of users... from DB",res.data.activeUsersByTitleId)
        setConsultantList(res.data.activeUsersByTitleId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All DIR Invoices by Date------------------------------------------------------
  //const getAllByDate_DIR = (selectedDatesFrom, selectedDatesTo) => {
  const getAllByDate_Wages = (
    selectedDatesFrom,
    selectedDatesTo,
    consultantSelected,
    noGroupReport,
  ) => {
    const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
    const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();

    //change the route depending on DIR or FIX Tab selected-------------------------
    let salaryRoute = "";
    let nonop ="";
    if (wagesType === 1) {
      salaryRoute = "dirsalariesbydate";
      nonop=""
    } else {
      salaryRoute = "fixsalariesbydate";
      if(wagesType===3){
        
        nonop ="/" + "true"
        //This variable true for NON OPS Invoices

      }else{
        nonop ="/" + "false"
      }
    }

    setIsLoading1(true);
    axios
      .get(
        variables.API_URL +
          "salaries/" +
          salaryRoute +
          "/" +
          // paymentsUrl +
          selectedDatesFrom_2 +
          "/" +
          selectedDatesTo_2 +
          "/" +
          consultantSelected +
          "/" +
          noGroupReport +
          nonop //Variable for extra parameter when NON Operational invoice selected / wagesType=3
          ,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log("salaries list from axios....", res.data);
        if(noGroupReport===true){

          setSalariesByDateList(res.data.salariesByDate);
        }else{
          setSalariesByDateByConsultantList(res.data.salariesByDate);
        }

        if (res.data.salariesByDate.length === 0) {
          setTotalSalariesByDate(0);
          //setTotalPagesByConsultant(0);
        } else {
          const totalPaid = res.data.salariesByDate.slice(-1);
          setTotalSalariesByDate(totalPaid[0].cumulative_sum);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification("Something went wrong, please try again");
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  };

  //Wages Invoice Payments list By Invoice for the periode of dates ----------------
  const getAllWagesInvPaymentsByInvoiceByDate = (
    selectedDatesFrom,
    selectedDatesTo,
    suppId
  ) => {
    const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
    const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();

    //change the route depending on DIR or FIX Tab selected-------------------------
    let salaryRoute = "";
    if (wagesType === 1) {
      salaryRoute = "dirsalariespaidbydate";
    } else {
      salaryRoute = "fixsalariespaidbydate";
    }

    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          //"salaries/dirsalariespaidbydate/" +
          "salaries/" +
          salaryRoute +
          "/" +
          // paymentsUrl +
          selectedDatesFrom_2 +
          "/" +
          selectedDatesTo_2 +
          "/" +
          "2",
        //true
        // + "/" +
        // showPayDate,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "Wages payments data by Invoice.....",
        //   res.data.salariesPayByDate
        // );
        //setPaymentsByDateList(res.data.purchaseInvBySupplierByDate);
        setWagesInvpaymentsByInvoiceByDate(res.data.salariesPayByDate);

        // if (res.data.paymentsByDate.length === 0) {
        //   setTotalPaymentsByDate(0);
        //   //setTotalPagesByConsultant(0);
        // } else {
        //   const totalPaid = res.data.purchaseInvBySupplierByDate.slice(-1);
        //   setTotalPaymentsByDate(totalPaid[0].cumulative_sum);
        // }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        setNotification("Something went wrong, please try again");
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  };

  //fetching Cost Type from DB-------------------------------------------
  const getCostTypeList = () => {
    //console.log("Get All COST Type list .... from Salary Component")

    axios
      .get(variables.API_URL + "purchase/costtypelist", {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching costs Type...",res.data.costTypeListInfo)
        setCostTypeList(res.data.costTypeListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //------------------------------------------------------------------------------------------------------------------------------------------------------------
  //currently working here... next step to build the route in Back end to fetch the above... the add in the slice the type ops as default.... then continue...

  //Get all Wages Invoices with payments per Wages Invoice -- the final summary info -------------------------------------------------------------

  let allWagesInvoicesWithPayments = [];

  const getAllPaidByWagesInvoice = () => {
    //alert("GetAllPaidByPurchaseInvoice... inside Outstanding component Called !!")

    let wagesInvByInvoiceByDate_sorted = "";
    if (wagesType === 1) {
      wagesInvByInvoiceByDate_sorted = salariesByDateList.sort(
        (a, b) => new Date(a.PAYDATE_DIR) - new Date(b.PAYDATE_DIR)
      );
    } else {
      wagesInvByInvoiceByDate_sorted = salariesByDateList.sort(
        (a, b) => new Date(a.PAYDATE_FIX) - new Date(b.PAYDATE_FIX)
      );
    }

    //console.log("SORTED.....",purchaseInvBySupplierByDate_sorted)

    let allWages = 0; //starting value
    let allWagesPayments = 0; //starting value
    //let allWoff = 0; //starting value
    let allOuts = 0; //starting value
    //let allOuts_first50 = 0; //starting value
    //let allOuts_second50 = 0; //starting value

    let totalBalance = 0; //starting value

    //purchaseInvBySupplierByDate.map((pInv) => {
    wagesInvByInvoiceByDate_sorted?.map((wInv) => {
      //console.log("inthemap wInv....", wInv);
      let totalPaidOnWagesInvoice = 0;
      //let totalWoffOnOrder = 0;
      let totalOuts = 0;
      //let totalOuts_first50 = 0;
      //let totalOuts_second50 = 0;

      let paymentsOnWagesInvoiceList = [];
      //let woffOnOrderList = [];

      //finding all payments on one specific order
      let allPaid2 = [];
      if (wagesType === 1) {
        allPaid2 = wagesInvpaymentsByInvoiceByDate?.filter(
          (wagesPayment) => wInv.DIRINVOICE_ID === wagesPayment.DIRINVOICE_ID
        );
      } else {
        allPaid2 = wagesInvpaymentsByInvoiceByDate?.filter(
          (wagesPayment) => wInv.FIXINVOICE_ID === wagesPayment.FIXINVOICE_ID
        );
      }

      //console.log("after filtered...", allPaid2);

      if (allPaid2?.length > 0) {
        //totalPaidOnWagesInvoice = allPaid2[0].subTotal;
        totalPaidOnWagesInvoice = allPaid2[0].subTotal;
        paymentsOnWagesInvoiceList = allPaid2;
      }

      //finding all written off on one specific order
      // const allWoff2 = woffByDateByOrderList.filter(
      //   (woff) => order.CONFIRMATION_ID === woff.CONFIRMATION_ID
      // );

      // if (allWoff2.length > 0) {
      //   totalWoffOnOrder = allWoff2[0].subTotal;
      //   woffOnOrderList = allWoff2;
      // }

      allWages = parseFloat(allWages) + parseFloat(wInv.TOTALINVOICE_PAID); //TOBE UPDATED--------
      // .toLocaleString("en-US",{minimumFractionDigits: 2,})

      //allPurchase = allPurchase + pInv.TOTALPAID;
      allWagesPayments =
        parseFloat(allWagesPayments) + parseFloat(totalPaidOnWagesInvoice);
      //allWoff = parseFloat(allWoff) + parseFloat(totalWoffOnOrder);
      totalBalance =
        parseFloat(totalBalance) +
        parseFloat(wInv.TOTALINVOICE_PAID) -
        parseFloat(totalPaidOnWagesInvoice);

      totalOuts =
        parseFloat(wInv.TOTALINVOICE_PAID) -
        parseFloat(totalPaidOnWagesInvoice); // When project is completed Full Amount is due from total
      //totalOuts_first50 = 0;
      // totalOuts_second50 =
      // pInv.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total

      allOuts = parseFloat(allOuts) + parseFloat(totalOuts);
      //allOuts_first50 = allOuts_first50 + totalOuts_first50;
      //allOuts_second50 = allOuts_second50 + totalOuts_second50;

      //alert("order..."+order.CONFIRMATION_REFERENCE+"allOut so far...." + allOuts+ "total out order...." + totalOuts)

      allWagesInvoicesWithPayments.push({
        wOrder: wInv,
        totalPaid: totalPaidOnWagesInvoice,
        //totalWoff: totalWoffOnOrder,
        paymentsOnWagesInvoiceList: paymentsOnWagesInvoiceList,
        //woffOnOrderList: woffOnOrderList,
        totalOuts: totalOuts,
        totalBalance: totalBalance,
        //totalBalance: parseFloat(pInv.TOTALPAID) - parseFloat(totalPaidOnOrder) - parseFloat(totalWoffOnOrder),
        //totalBalance: parseFloat(pInv.TOTALPAID) ,
      });
    });

    // console.log("Wages !!! All Outstanding...", allOuts);
    // // console.log("PURCHASE !!! All Outstanding First 50...", allOuts_first50);
    // // console.log("PURCHASE !!! All Outstanding Second 50...", allOuts_second50);

    // console.log("Wages !!! Total Balance...", totalBalance);

    // console.log("Wages !!! All purchase...", allWages);
    // console.log("Wages !!! All payments...", allWagesPayments);
    // // console.log("PURCHASE !!! All Woff...", allWoff);

    // console.log(
    //   "All wages with payments de Luis H....",
    //   allWagesInvoicesWithPayments
    // );

    setAllWagesInvoicesWithPaymentsFull(allWagesInvoicesWithPayments);
    setAllOutsWagesData(allOuts);
    setTotalWagesPaymentsByDate(allWagesPayments);
    setTotalWagesBySupplierByDate(allWages);
    setTotalOutsWagesBySupplierByDate(totalBalance);

    //setAllOutsData_first50(allOuts_first50);
    //setAllOutsData_second50(allOuts_second50);
  };

  //END Get all Purchase payments per Purchase Invoice -------------------------------------------------------------

  useEffect(() => {
    if (salariesByDateList?.length > 0) {
      getAllPaidByWagesInvoice();
    } else {
      // console.log(
      //   "array empty for Wages Invoices by supplier by date / payments..."
      // );
      // console.log(
      //   "Wages InvPaymentsBySupplierByDate",
      //   wagesInvpaymentsByInvoiceByDate
      // );
      //console.log("Wages InvBySupplierByDate", salariesByDateList);

      //test
      setAllWagesInvoicesWithPaymentsFull([]);
      //end test
    }

    //clean up function
    return () => {
      //console.log("clean up N.2")
    };
  }, [salariesByDateList, wagesInvpaymentsByInvoiceByDate]);

  useEffect(() => {
    resetReportVariables();
    getCostTypeList();
    getConsultants();
  }, []);

  useEffect(() => {
    if (consultantList.length > 0) {
      const myconsultantListFiltered_initial = consultantList.filter((item) =>
        wagesType === 1 ? item.TITLE_ID === 2 : item.TITLE_ID !== 2
      );
      // console.log(
      //   "the first filter here....",
      //   myconsultantListFiltered_initial
      // );
      //const myconsultantListFiltered = consultantList.map((option) => {
      const myconsultantListFiltered = myconsultantListFiltered_initial.map(
        (option) => {
          const firstLetter = option.Firstname[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        }
      );

      //Add the Option to select All Consultants----
      myconsultantListFiltered.push({
        firstLetter: "1",
        CONSULTANT_ID: 0,
        Firstname: "All",
        Lastname: "Consultants",
      });

      setConsultantListFiltered(myconsultantListFiltered);
    }
  }, [consultantList]);

  const handleConsultantChange = (event, value) => {
    //console.log("value from the function handleconsultant...", value);

    setPaymentsByDateList([]);
    setTotalPaymentsByDate(null);
    setAllWagesInvoicesWithPaymentsFull([]);
    setSalariesByDateList([]);

    if (value) {
      setConsultantSelected(value.CONSULTANT_ID);
      reportVariables = {
        ...reportVariables,
        consultantSelected_id: value.CONSULTANT_ID,
      };
    } else {
      setConsultantSelected(0);
      reportVariables = {
        ...reportVariables,
        //consultantSelected_id: null,
        consultantSelected_id: "0",
      };
    }
    //console.log("reportVariables after consultant change", reportVariables);
  };

  const handleDatesChangesFromTo = (date, id) => {
    //console.log("value from the DatePicker", date);
    setPaymentsByDateList([]);
    setTotalPaymentsByDate(null);
    // setTotalPagesByConsultant(0);

    const dateIsValid = date !== null && date !== "Invalid Date";

    if (id === 0) {
      setSelectedCDateFrom(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: new Date(
            date.setUTCHours(0, 0, 0, 0)
          ).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesFrom: date,
        };
      }
    } else if (id === 1) {
      setSelectedCDateTo(date);
      if (dateIsValid) {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        };
      } else {
        reportVariables = {
          ...reportVariables,
          selectedDatesTo: date,
        };
      }
    } else {
      //console.log("error, date not usable only 2 options available");
      return;
    }
  };

  const handleGetReport_2 = ({ reportVariables }) => {
    if (
      // consultantSelected &&
      selectedDateFrom !== null &&
      selectedDateTo !== null &&
      selectedDateFrom !== "Invalid Date" &&
      selectedDateTo !== "Invalid Date"
    ) {
      // console.log(
      //   "datefrom",
      //   reportVariables.selectedDatesFrom,
      //   "---date to...",
      //   reportVariables.selectedDatesTo,
      //   "--object...",
      //   reportVariables
      // );

      //getAllPaymentsByDate(selectedDateFrom, selectedDateTo);
      //getAllByDate_DIR(selectedDateFrom, selectedDateTo);
      getAllByDate_Wages(selectedDateFrom, selectedDateTo, consultantSelected,true);
      getAllByDate_Wages(selectedDateFrom, selectedDateTo, consultantSelected,false);

      getAllWagesInvPaymentsByInvoiceByDate(
        selectedDateFrom,
        selectedDateTo,
        consultantSelected
      );

      setNotification(null);
      //console.log("click here... alles ok")
    } else {
      setPaymentsByDateList([]);
      setTotalPaymentsByDate(null);
      setAllWagesInvoicesWithPaymentsFull([]);
      setSalariesByDateList([]);
      setNotification("Please select valid data");
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  //console.log("list of payments by dates ...", paymentsByDateList);

  const box1Content = (
    <Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          sx={{ display: "flex", flexDirection: matches ? "column" : "row" }}
        >
          <Autocomplete
            id="grouped-demo"
            options={consultantListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) =>
              option.Firstname + "  " + option.Lastname.charAt(0) + "."
            }
            sx={
              matches
                ? { width: "auto", margin: "0.2em" }
                : { width: 250, margin: "1em" }
            }
            onChange={(event, value) => handleConsultantChange(event, value)}
            renderInput={(params) => (
              <MyTextField {...params} label="Consultant" />
            )}
          />

          <DatePicker
            mask={maskMap[locale]}
            format="YYY/MM/dd"
            label="From"
            value={selectedDateFrom}
            onChange={(date) => handleDatesChangesFromTo(date, 0)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches ? "0.2em" : "1em" }}
              />
            )}
          />

          <Box
            sx={{
              margin: matches ? "0.2em" : "1em",
              textAlign: "center",
            }}
          >
            {" "}
            to{" "}
          </Box>

          <DatePicker
            label="To"
            value={selectedDateTo}
            onChange={(date) => handleDatesChangesFromTo(date, 1)}
            renderInput={(params) => (
              <MyTextField
                {...params}
                sx={{ margin: matches ? "0.2em" : "1em" }}
              />
            )}
          />

          <Button
            onClick={() => handleGetReport_2({ reportVariables })}
            variant="outlined"
            sx={{
              margin: matches ? "0.2em" : "1em",
              color: "#7a1125",
              borderColor: "#7a1125",
              height: "3.2rem",
              "&:hover": {
                borderColor: "#270c10",
                color: "#fce9ec",
                backgroundColor: "#7a1125",
              },
            }}
          >
            GO
          </Button>
        </Box>

        {/* Button to change from Bank statements to recorded date for payment list---------------------- */}
        <Box
          sx={{
            display: "flex",
            //flexDirection: matches ? "column" : "row",
            flexDirection: "column",
            //alignItems: "flex-start",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          {/* <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {showBank ? (
              <ReceiptIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            ) : (
              <ComputerIcon
                sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
              />
            )}

            <Typography
              sx={{
                p: 1,
                //backgroundColor: "#e7dddd",
                color: theme.palette.common.ludisMain2,
                fontSize: matches ? 16 : 22,
              }}
            >
              {showBank ? "Bank Date" : "Recorded Date"}
            </Typography>
          </Box> */}

          {/* <Button
            onClick={(event) => {
              event.preventDefault();
              showBankHandler(event);
            }}
            sx={{
              color: "#7A1125",
              textTransform: "capitalize",
              marginBottom: 2,
            }}
          >
            {showBank ? (
              <>
                <ComputerIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Recorded Date
                </Typography>
              </>
            ) : (
              <>
                
                <ReceiptIcon
                  sx={{ fontSize: 14, color: "#4E1721", marginRight: 2 }}
                />
                <Typography
                  sx={{
                    p: 1,
                    //backgroundColor: "#e7dddd",
                    color: theme.palette.common.ludisMain,
                    fontSize: 12,
                  }}
                >
                  Change to Bank Date
                </Typography>
              </>
            )}
          </Button> */}
        </Box>
      </Box>
    </Fragment>
  );

  //Box to show Payments list for the dates selected-----------------------------------------
  const box3Content = (
    <SalaryReportByDate
      //salariesByDateList={salariesByDateList}
      salariesByDateList={allWagesInvoicesWithPaymentsFull}
      isLoading1={isLoading1}
      showBank={showBank}
      matches={matches}
      matches2={matches2}
      matches3={matches3}
      isUpdatedPayment={isUpdatedPayment}
      updatePayment={updatedPayment}
      costTypeList={costTypeList}
      socket={socket}
      wagesType={wagesType}
    />
  );

  //Box to Show Summary report on Wages Invoices and Wages payments for the dates selected-----------------------------
  const box2Content =
    salariesByDateList.length > 0 ? (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Total Wages</Title>
        <Divider sx={{ width: "10vw" }} />
        {/* <PurchaseReportSummary  isLoading1 = {isLoading1}  /> */}
        <WagesReportSummary
          isLoading1={isLoading1}
          totalWagesBySupplierByDate={totalWagesBySupplierByDate}
          totalWagesPaymentsByDate={totalWagesPaymentsByDate}
          totalOutsWagesBySupplierByDate={totalOutsWagesBySupplierByDate}
          //salariesByDateList={allWagesInvoicesWithPaymentsFull}
        />
      </Box>
    ) : (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Total Wages</Title>
        <Divider sx={{ width: "10vw" }} />
        <GradingIcon
          sx={{
            color: theme.palette.common.ludisMain,
            fontSize: 50,
            marginTop: 5,
          }}
        />
      </Box>
    );

  const box4Content =
    salariesByDateList.length > 0 && consultantSelected ===0  ? (
      <Grid item xs={12} md={12} lg={12}>
        {/* <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            //height: 270,
            height: "auto",
            paddingBottom: 0,
            minWidth: 230,
            backgroundColor:"#000"
          }}
        > */}
          {/* <SalesByConsultantSummaryGraph
            tabInitialDate={selectedDateFrom}
            tabFinalDate={selectedDateTo}
          /> */}
          <SalaryChart
                //salaryDataDIR={salariesByDateByConsultantList}
                salaryData={salariesByDateByConsultantList}
                //reportType={{ type: "ByDIR", typeId: 3, typeSubId: 0, typeSubIdName: "" }}
                reportType={{ type: reportTitleShort, typeId: wagesType, typeSubId: 0, typeSubIdName: "" }}

              />
        {/* </Paper> */}

        {/* <Toolbar /> */}


      </Grid>
    ) : (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Title>Wages by Consultant</Title>
        <Divider sx={{ width: "10vw" }} />
        <EqualizerIcon
          sx={{
            color: theme.palette.common.ludisMain,
            fontSize: 50,
            marginTop: 5,
          }}
        />
      </Box>
    );



  useEffect(() => {
    if (selectedDateFrom !== null && selectedDateTo !== null) {
      handleGetReport_2({ reportVariables });
    }
  }, [showBank, updatedPayment]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <DashboardContainerReport
        box1={box1Content}
        //box1={<h1>Box 1 info here....</h1>}
        box2={box2Content}
        box3={box3Content}
        box4={box4Content}
        matches={matches}
        matches2={matches2}
        notification={notification}
        ListFiltered={consultantListFiltered}
        handleListItemChange=""
        handleGetReport_2={handleGetReport_2}
        reportVariables={reportVariables}
        IsLoading={false}
        //reportTitle="Salary Report - DIR ..."
        reportTitle={reportTitle}
        reportId={2} //Id for payment report so do not load consultant list
        boxHeight={250}
        boxHeight_container="95vh"
        boxSize={3}
        myMargin={0}
        //myWidth="80vw"
      />
    </LocalizationProvider>
  );
};

export default SalaryList;
