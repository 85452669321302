import { useState, useEffect, Fragment, memo, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { kpisActions } from "../../store/kpis-slice";

import axios from "axios";
import { variables } from "../../Variables";
import theme from "../UI/Theme";

import Receivables from "./Receivables";

//loader Imports--------------
import { SyncLoader } from "react-spinners";
import { PropagateLoader } from "react-spinners";

//MUI IMPORTS--------------------------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";

//import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

//export default function Outstanding() {
const Outstanding = ()=>{  
  const dispatch = useDispatch()
  const currentAllOrdersWithPaymentsFull = useSelector((state) => state.kpis.receivablesInfo.allOrdersWithPaymentsFull);


  //console.log("This is the info in the receivables slice....",currentAllOrdersWithPaymentsFull.length)

  //alert("Outstanding Component Called !!")

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  
  const [update1, setUpdate1] = useState(null);


  //variables for all orders and payments to cross them------
  const [paymentsByDateByOrderList, setPaymentsByDateByOrderList] = useState(
    []
  );
  const [woffByDateByOrderList, setWoffByDateByOrderList] = useState([]);

  const [allOrdersList, setAllOrdersList] = useState([]);

  const [allOrdersWithPaymentsFull, setAllOrdersWithPaymentsFull] = useState(
    []
  );

  const [paymentsByDateByOrderList_latestUpdate, setPaymentsByDateByOrderList_latestUpdate]=useState(null)

  //All Outstanding data ---------
  const [allOutsData, setAllOutsData] = useState(null);
  const [allOutsData_first50, setAllOutsData_first50] = useState(null);
  const [allOutsData_second50, setAllOutsData_second50] = useState(null);

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  //calculate the previous Monday date------------------------------------------------------------
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  let selectedDatesFromInitial = new Date(prevMonday).toISOString();
  const selectedDatesFromInitial_raw = new Date(prevMonday);

  let selectedDatesTo = new Date(Date.now()).toISOString();

  const [tabInitialDate, setTabInitialDate] = useState(
    selectedDatesFromInitial
  );
  //const [tabFinalDate, setTabFinalDate] = useState(selectedDatesTo);

  //Get List of All Historic Orders to cross it with payments later-------------------
  const getAllOrdersActive = () => {
    //alert("getAllOrdersActive inside Outstanding component Called !!")
    //console.log("getAllOrdersActive ............... in the Payments IN component called !!!!")
    setIsLoading1(true);
    const selectedDatesFrom = "2000-01-01"; //for all times information started on 2009
    axios
      //.get(variables.API_URL + "orders/allorders", { withCredentials: true })
      .get(variables.API_URL + "orders/allordersactive/" + selectedDatesFrom, {
        withCredentials: true,
      })
      .then((res) => {
        //setConsultantList(res.data.orderInfo);
        setAllOrdersList(res.data.orderInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo));
        setIsLoading1(false);
        setPaymentsByDateByOrderList_latestUpdate(new Date());
        //setUpdate1(false)
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (Standard) Payments by Date and subtotal by Order------------------------------------
  const getPaymentsByDateByOrder = (selectedDatesFromInitial) => {
    //alert("getPaymentsByDateByOrder inside Outstanding component Called !!")
    const selectedDatesFrom = selectedDatesFromInitial;
    const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);
    setIsLoading2(true);

    axios
      .get(
        variables.API_URL +
          "payments/paymentsbydatebyorder/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log("this is my payments list for receivables response from query....",res)
        setPaymentsByDateByOrderList(res.data.paymentsByDateByOrder);
        //setPaymentsByDateByOrderList_latestUpdate(new Date());
        //setUpdate1(false)
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (Standard) Payments by Date and subtotal by Order------------------------------------
  const getWoffByDateByOrder = (selectedDatesFromInitial) => {
    //alert("getWoffByDateByOrder inside Outstanding component Called !!")
    const selectedDatesFrom = selectedDatesFromInitial;
    const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("the date from ....", selectedDatesFrom);
    setIsLoading3(true);

    axios
      .get(
        variables.API_URL +
          "payments/woffbydatebyorder/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setWoffByDateByOrderList(res.data.woffByDateByOrder);
        setIsLoading3(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get all sales and payments per order -- the final summary info -------------------------------------------------------------
  //let AllOrdersWithPaymentsFinal = [];
  let allOrdersWithPayments = [];

  const getAllPaidByOrder =  () => {

     //alert("GetAllPaidByOrder inside Outstanding component Called !!")


    let allSales = 0; //starting value
    let allPayments = 0; //starting value
    let allWoff = 0; //starting value
    let allOuts = 0; //starting value
    let allOuts_first50 = 0; //starting value
    let allOuts_second50 = 0; //starting value

    let totalBalance = 0; //starting value

    allOrdersList.map((order) => {
      let totalPaidOnOrder = 0;
      let totalWoffOnOrder = 0;
      let totalOuts = 0;
      let totalOuts_first50 = 0;
      let totalOuts_second50 = 0;

      let paymentsOnOrderList = [];
      let woffOnOrderList = [];

      //finding all payments on one specific order
      const allPaid2 = paymentsByDateByOrderList.filter(
        (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
      );

      if (allPaid2.length > 0) {
        totalPaidOnOrder = allPaid2[0].subTotal;
        paymentsOnOrderList = allPaid2;
      }

      //finding all written off on one specific order
      const allWoff2 = woffByDateByOrderList.filter(
        (woff) => order.CONFIRMATION_ID === woff.CONFIRMATION_ID
      );

      if (allWoff2.length > 0) {
        totalWoffOnOrder = allWoff2[0].subTotal;
        woffOnOrderList = allWoff2;
      }

      allSales = allSales + order.TOTAL_AMOUNT;
      allPayments = allPayments + totalPaidOnOrder;
      allWoff = allWoff + totalWoffOnOrder;
      totalBalance =
        totalBalance + order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder;

      if (order.CLUBSTATUS_ID === 7) {
        totalOuts = order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total
        totalOuts_first50 = 0;
        totalOuts_second50 =
          order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total
      } else if (order.CLUBSTATUS_ID < 7) {
        totalOuts =
          order.TOTAL_AMOUNT / 2 - totalPaidOnOrder - totalWoffOnOrder; // when project is NOT completed only 50% is due from total

        totalOuts_first50 =
          order.TOTAL_AMOUNT / 2 - totalPaidOnOrder - totalWoffOnOrder; // when project is NOT completed only 50% is due from total
        totalOuts_second50 = 0;
      } else {
        //alert("no first no second ??????...ref: " + order.CONFIRMATION_REFERENCE )
      }

      allOuts = allOuts + totalOuts;
      allOuts_first50 = allOuts_first50 + totalOuts_first50;
      allOuts_second50 = allOuts_second50 + totalOuts_second50;

      //alert("order..."+order.CONFIRMATION_REFERENCE+"allOut so far...." + allOuts+ "total out order...." + totalOuts)

      allOrdersWithPayments.push({
        order: order,
        totalPaid: totalPaidOnOrder,
        totalWoff: totalWoffOnOrder,
        paymentsOnOrderList: paymentsOnOrderList,
        woffOnOrderList: woffOnOrderList,
        totalOuts: totalOuts,
        totalBalance: order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder,
      });
    });

    // console.log("All Outstanding...", allOuts);
    // console.log("All Outstanding First 50...", allOuts_first50);
    // console.log("All Outstanding Second 50...", allOuts_second50);

    // console.log("Total Balance...", totalBalance);

    // console.log("All sales...", allSales);
    // console.log("All payments...", allPayments);
    // console.log("All Woff...", allWoff);

    setAllOrdersWithPaymentsFull(allOrdersWithPayments);
    setAllOutsData(allOuts);
    setAllOutsData_first50(allOuts_first50);
    setAllOutsData_second50(allOuts_second50);

  }

  //END Get all payments per order -------------------------------------------------------------


 //Update all Receivables KPIS information in the slice-----------------------------------------
  //const handleKpisChange = (event, value, name) => {
  const handleKpisChange = () => {

   
   //if (value) {
     dispatch(
       kpisActions.updateReceivablesInfo({
         allOrdersWithPaymentsFull: allOrdersWithPaymentsFull,
         allOutsData:allOutsData,
         allOutsData_first50:allOutsData_first50,
         allOutsData_second50:allOutsData_second50,
         //lastUpdate_receivables:paymentsByDateByOrderList_latestUpdate
         lastUpdate_receivables:new Date(),
         
        })
        );
      //}
    };
 //Update all Receivables KPIS information in the slice-----------------------------------------
    

   //Reset Purchase Payment In Slice to initial values function to be used after payment is recorded -----
   const resetReceivablesInfo = () => {
    dispatch(kpisActions.resetReceivablesInfo({}));

  };
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

  //refresh receivables information
  const refreshReceivablesInfo = (event)=>{
    //console.log("setting update1 to true....", update1)
    event.preventDefault();
    //setUpdate1(true)
    //functions to get orders and payments in to cross them-------
    getAllOrdersActive();
    getPaymentsByDateByOrder("2000-01-01"); //starting date to include all orders first date in DB 2009
    getWoffByDateByOrder("2000-01-01");
    //End functions to get orders and payments in to cross them-------

    setUpdate1(true); //reset the switch

    
  }





  useEffect(() => {
    if (selectedDatesFromInitial) {
      if (tabInitialDate) {
          // if(currentAllOrdersWithPaymentsFull.length === 0 || update1 === true) {
          if(currentAllOrdersWithPaymentsFull.length === 0) {


          //alert("working inside function update is True ?..."+update1)
          


           //functions to get orders and payments in to cross them-------
           getAllOrdersActive();
           getPaymentsByDateByOrder("2000-01-01"); //starting date to include all orders first date in DB 2009
           getWoffByDateByOrder("2000-01-01");
           //End functions to get orders and payments in to cross them-------

           //setUpdate1(false); //reset the switch
        }else{

          //alert("not working inside function... the update is false? or lenght is not..."+update1)

        }
        
      }
    }

    //clean up function
    return () => {
      //console.log("clean up N1");
      setTabInitialDate(null);
      //setUpdate1(false); //reset the switch
    };
  }, [tabInitialDate, update1]);

  useEffect(() => {
    if (allOrdersList.length > 0 && paymentsByDateByOrderList.length > 0) {
      getAllPaidByOrder();
    } else {
      //console.log("array empty for allOrdersList");
    }

    //clean up function
    return () => {
      //console.log("clean up N.2")
    };
  }, [allOrdersList, paymentsByDateByOrderList]);

  useEffect(()=>{

    //if(currentAllOrdersWithPaymentsFull.length === 0 || update1 === true) {
    if(currentAllOrdersWithPaymentsFull.length === 0) {

  
      handleKpisChange();
      //alert("from handle kpi function It is true...")
    }else if(update1===true){

      handleKpisChange();
      //alert("from handle kpi function It is false...")
      setUpdate1(false);
    }

  },[allOrdersWithPaymentsFull, paymentsByDateByOrderList_latestUpdate, update1])

  return (
    <Fragment>
        {!isLoading1 && !isLoading2 && !isLoading3? 
      <Receivables
        title="Outstanding"
        totalOutstandingData={{
          totalOutstanding: allOutsData,
          totalOutstanding_first50: allOutsData_first50,
          totalOutstanding_second50: allOutsData_second50,
          lastUpdate_receivables:paymentsByDateByOrderList_latestUpdate,
          
        }}
        refreshReceivablesInfo={refreshReceivablesInfo}
        icon={
          <PhoneInTalkIcon
            sx={{
              fontSize: 20,
              color: "#4E1721",
              marginRight: 2,
              marginTop: -1,
            }}
          />
        }
      />
      : 
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15}  />
        </Box> }

    </Fragment>
  );
}


export default memo(Outstanding);
