import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import DashboardContainerReportList from "../UI/DashboardContainerReportList";
import OrderData from "../adminComponents/OrderData";
//import AddPayment from './AddPayment_step1';
//import CreateEmail from "../comunicationComponents/CreateEmail";
import NewEmail from "../comunicationComponents/NewEmail";

import PaymentInfoOrder from "./PaymentInfoOrder";
import PaymentInfoOrderSummary from "./PaymentInfoOrderSummary";
import PaymentStatusOnOrder from "./PaymentStatusOnOrder";
import GeneralInfoOrder from "./GeneralInfoOrder";
import OrdersList from "../generalComponents/OrdersList";
import InvoicingBase from "../adminComponents/InvoicingBase";
import InvoiceChecker from "../adminComponents/invoicingComponents/InvoiceChecker"

//import {commentsActions} from  "../../store/comments-slice";
import { useDispatch } from "react-redux";

import axios from "axios";

import { orderActions } from "../../store/order-slice";

import { variables } from "../../Variables";

import theme from "../UI/Theme";
import Virtualize from "../adminComponents/VirtualList";

//------MUI IMPORTS------------------------------------------
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import AssignmentIcon from "@mui/icons-material/Assignment";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Divider, Grid, Button, Typography } from "@mui/material";
//import { flexbox } from "@mui/system";



const Ccorders = () => {
  const dispatch = useDispatch();

  //const showComments = useSelector(state =>state.comments.commnetsAreVisible);

  const showOrder = useSelector((state) => state.orders.showOrder);
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  

  const [activeOrdersList, setActiveOrdersList] = useState([]);

  const [paymentTypeList, setPaymentTypeList] = useState([]);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //state to check if a new payment has been recorded (using the unique paymentID)--------------------------
  const [newPayment, setNewPayment] = useState(null);
  //state to check if a new payment has been recorded (using the unique paymentID)--------------------------
  const [newPaymentPlan, setNewPaymentPlan] = useState(null);

  //state to check if a new Invoice has been booked (using the unique paymentID)--------------------------
  const [newInvoice, setNewInvoice] = useState(null);

  //state to check if a new Comunication has been recorded (using the unique paymentID)--------------------------
  const [newComunication, setNewComunication] = useState(null);

  //state to check if a new Invoice has been booked (using the unique paymentID)--------------------------
  const [isLoading_BookInvoice, setIsLoading_BookInvoice] = useState(null);


  

  //check the status of the fetching of all orders from DB without restriction of date-----------------------------------
  const [isLoading_orderList, setIsLoading_orderList] = useState(false);

  
  //check the status of the fetching all paid on order on paymentInfoOrder-----------------------------------
  const [isLoading1_allPayOnOrder, setIsLoading1_allPayOnOrder] =
  useState(false);
  
  //check the status of Recording a new Payment Plan Signed on order on AddPplanSignedStep_3-----------------------------------
  const [isLoading1_PayPlanOnOrder, setIsLoading1_PayPlanOnOrder] =
  useState(false);

  //taking the state of order selected from the dropdown of orders..-------
  const[currentOrderSelectedRef, setCurrentOrderSelectedRef]=useState(null)
  
  //Check if the Document Invoice exist in the folders in the server----------------------------------------------------------  
  const[invoiceRecorded, setInvoiceRecorded]=useState(false)  

  //Check if Comunication has been already sent ------------------------------------------------------------------------------
  const[comunicationSent,setComunicationSent]=useState([])
  //Check if Comunication has been already sent ------------------------------------------------------------------------------
  
  
  ////// LIST OF FUNCTIONS--------------------------------------------------------------------------------------------------------------

  //Function to manage the state to check if new Order has been selected from dropdown---------------------------------
  const currentorderSelectedRefFinder = (orderSelected) => {
    setCurrentOrderSelectedRef((prev)=>orderSelected);
  };
  

  //Function to manage the state to check if new Payment has been recorded---------------------------------
  const isNewPayment = (payId) => {
    setNewPayment(payId);
  };

  //Function to manage the state to check if new Payment Plan Signed has been recorded---------------------------------
  const isNewPaymentPlan = (payPlanId) => {
    setNewPaymentPlan(payPlanId);
  };

  //Function to manage the state to check if new Invoice has been Booked---------------------------------
  const isNewInvoice = (invoiceId) => {
    //console.log("isNewInvoice set !!! with Id...",invoiceId)
    setNewInvoice(invoiceId);
  };

  //Function to manage the state to check if new Comunication has been recorded---------------------------------
  const isNewComunication = (comId) => {
    setNewComunication(comId);
  };

  //function to set isLoading1_allPayOnOrder status-----------------------------------------------------------
  const isLoading1_allPayOnOrderHandler = (status) => {
    setIsLoading1_allPayOnOrder(status);
  };

  //function to set isLoading1_allPayPlanOnOrder status-----------------------------------------------------------
  const isLoading1_PayplanOnOrderHandler = (status) => {
    //alert("the status loading inside the handler before..." +  isLoading1_PayPlanOnOrder)
    setIsLoading1_PayPlanOnOrder(status);
    //alert("the status loading inside the handler after..." +  isLoading1_PayPlanOnOrder)
  };

  //function to set isLoading1_allPayPlanOnOrder status-----------------------------------------------------------
  const isLoading_BookInvoiceHandler = (status) => {
    //alert("the status loading inside the handler before..." +  isLoading1_PayPlanOnOrder)
    setIsLoading_BookInvoice(status);
    //alert("the status loading inside the handler after..." +  isLoading1_PayPlanOnOrder)
  };

  

  const refreshOrderList = () => {
    //console.log("Add all Historical Orders....");
    getAllOrders()
  };

  //fetching orders from DB From the last 2 years (2017)-------------------------------------------
  //"http://localhost:5000/orders",
  //const getAllOrders = () => {
  const getAllOrdersActive = () => {
    //console.log("Get All OrdersActive list .... from Income Component")
    setIsLoading_orderList(true)
      //Calculating the Date 2 years ago from today------------------------------------------------
      //const previous2YearsFrom = new Date(new Date().getFullYear() + "-01-01");
      const previous2YearsFrom = new Date(new Date(Date.now()));
      const selectedDatesFrom = new Date(
        new Date(
          previous2YearsFrom.setDate(previous2YearsFrom.getDate() - 730)
        ).setHours(0, 0, 0)
      ).toISOString();
    //const selectedDatesFrom = "2020-09-01"; //for all times information started on 2009
    axios
      //.get(variables.API_URL + "orders/allorders", { withCredentials: true })
      .get(variables.API_URL + "orders/allordersactive/" + selectedDatesFrom, {
        withCredentials: true,
      })
      .then((res) => {
        setActiveOrdersList(res.data.orderInfo);
        dispatch(orderActions.addFullOrdersList(res.data.orderInfo));
        setIsLoading_orderList(false)
      })
      .catch((error) => {
        console.log(error);
      });
      //setIsLoading_orderList(false)
  };




    //fetching All Active orders from DB Without date restriction-------------------------------------------
    const getAllOrders = () => {
      //console.log("Get All OrdersActive list .... from Income Component")
      const selectedDatesFrom = "2000-01-01"; //for all times information started on 2009
      setIsLoading_orderList(true)
      axios
        //.get(variables.API_URL + "orders/allorders", { withCredentials: true })
        .get(variables.API_URL + "orders/allordersactive/" + selectedDatesFrom, {
          withCredentials: true,
        })
        .then((res) => {
          setActiveOrdersList(res.data.orderInfo);
          dispatch(orderActions.addFullOrdersList(res.data.orderInfo));
          setIsLoading_orderList(false)
        })
        .catch((error) => {
          console.log(error);
        });

        //setIsLoading_orderList(false)
    };





  //fetching products from DB-------------------------------------------
  const getPaymentsTypeList = () => {
    //console.log("Get All payments Type list .... from Income Component")

    axios
      .get(variables.API_URL + "payments/paymentstypelist", {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching payments Type...",res.data.paymentTypeListInfo)
        setPaymentTypeList(res.data.paymentTypeListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };



//function to check if URL exist------------------------------------------------------------
const CheckIfUrlExist = (inv)=>{

  //console.log("Get All payments Type list .... from Income Component")
  

  axios
   .get(variables.API_URL + "comunication/recordedinvoice/" + inv , {withCredentials: true,})
    .then((res) => {
      //console.log("response from check if...",res.data.invoiceRecorded)
      setInvoiceRecorded((prev)=>res.data.invoiceRecorded)
    })
    .catch((error) => {
      //console.log("error from check if...",error);
    });
};
//END function to check if URL exist------------------------------------------------------------

const CheckIfComunicationSent = (inv)=>{
  axios
    .get(variables.API_URL + "comunication/recordedcomunication/"+ inv, {withCredentials:true})
    .then((res)=>{
      //console.log("result from query here....",res.data.comunicationRecorded)
      setComunicationSent((prev)=>res.data.comunicationRecorded)
    })
    .catch((error)=>{
      //display error here....
    })


}


  useEffect(() => {
    //getAllOrders();
    getAllOrdersActive();
    getPaymentsTypeList();
    

    return () => {
      dispatch(
        orderActions.selectOrder({
          selectedOrderRef: [],
        })
      );
    };
  }, []);

//updating when changing reference from dropdown and check if the new ref invoice exist
useEffect(() => {
  CheckIfUrlExist(currentOrderSelectedRef)
  CheckIfComunicationSent(currentOrderSelectedRef)
  
}, [currentOrderSelectedRef]);





  //console.log("this is the consultant list....", consultantList);

  //const box1Content = <Virtualize listFiltered={consultantList}  />

  const box1Content = (
    <OrdersList 
    activeOrdersList={activeOrdersList} 
    isLoading_orderList={isLoading_orderList} 
    refreshOrderList = {refreshOrderList}
    currentorderSelectedRefFinder = {currentorderSelectedRefFinder}
    
    />

    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "center",
    //     margin: 0,
    //   }}
    // >

    //   <Virtualize listFiltered={activeOrdersList} />
    //   <Button
    //     onClick={(event) => refreshOrderList(event)}
    //     sx={{ color: "#7A1125", textTransform: "capitalize", marginTop:2 }}
    //   >
    //     <AutorenewIcon
    //       sx={{ fontSize: 15, color: "#4E1721", marginRight: 2 }}
    //     />
    //     {/* {props.changeDates ? "View This Week" : "View All Year"} */}
    //     {"Add All Orders"}
    //   </Button>
    // </Box>
  );
  //const box1Content = 'box1'
  //const box2Content = <AddPayment paymentTypeList={paymentTypeList}/>;
  const box2Content = (
    <NewEmail
      selectedOrder={selectedOrder}
      //paymentTypeList={paymentTypeList}
      isNewComunication={isNewComunication}
      //newPaymentPlan={newPaymentPlan}
      //isNewPaymentPlan={isNewPaymentPlan}
      isLoading1_allPayOnOrder={isLoading1_allPayOnOrder}
      isLoading1_PayPlanOnOrder={isLoading1_PayPlanOnOrder}
      isLoading1_PayplanOnOrderHandler={isLoading1_PayplanOnOrderHandler}
      invoiceRecorded={invoiceRecorded}
      comunicationSent={comunicationSent}
      
    />
  );
  const box2Content_2 = (
    <PaymentInfoOrderSummary
      isLoading1_allPayOnOrder={isLoading1_allPayOnOrder}
    />
  );
  const box2Content_3 = (
    <PaymentStatusOnOrder isLoading1_allPayOnOrder={isLoading1_allPayOnOrder} />
  );

 //Invoicing status.............................................................
  const box2Content_4 = (
     <InvoiceChecker
      currentOrderSelectedRef = {currentOrderSelectedRef}
      paymentTypeList={paymentTypeList}
      isNewPayment={isNewPayment}
      newInvoice={newInvoice}
      isNewInvoice={isNewInvoice}
      isLoading1_allPayOnOrder={isLoading1_allPayOnOrder}
      isLoading_BookInvoice={isLoading_BookInvoice}
      isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
      invoiceRecorded={invoiceRecorded}
    />
  

    // <PaymentStatusOnOrder isLoading1_allPayOnOrder={isLoading1_allPayOnOrder} />
    // <InvoicingBase
    //   paymentTypeList={paymentTypeList}
    //   isNewPayment={isNewPayment}
    //   newInvoice={newInvoice}
    //   isNewInvoice={isNewInvoice}
    //   isLoading1_allPayOnOrder={isLoading1_allPayOnOrder}
    //   isLoading_BookInvoice={isLoading_BookInvoice}
    //   isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
    // />
  
  );

  

  //condition to show order details-----------------------------------------
  const box3Content = showOrder ? (
    <>
      <OrderData selectedOrder={selectedOrder} />
      <GeneralInfoOrder
        selectedOrder={selectedOrder}
        newPaymentPlan={newPaymentPlan}
        newInvoice={newInvoice}
        isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
  
      />
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <AssignmentIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }} />
    </Box>
  );

  //condition to show Payments On Order list-----------------------------------------
  const box4Content = showOrder ? (
    <PaymentInfoOrder
      selectedOrder={selectedOrder}
      newPayment={newPayment}
      isLoading1_allPayOnOrderHandler={isLoading1_allPayOnOrderHandler}
    />
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CreditScoreIcon sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }} />
      <FormatListBulletedIcon
        sx={{ color: "#e7dddd", fontSize: 30, marginLeft: 2 }}
      />
    </Box>
  );

  //alert("the status loading on payplan starting..." +  isLoading1_PayPlanOnOrder)

  //const box4Content = <PaymentInfoOrder selectedOrder={selectedOrder}/>;

  return ( 
    <DashboardContainerReportList
      box1={box1Content}
      box2={box2Content}
      box2A={box2Content_2}
      box2B={box2Content_3}
      box2C={box2Content_4}
      box3={box3Content}
      box4={box4Content}
      matches={matches}
      notification=""
      ListFiltered={activeOrdersList}
      //handleListItemChange={handleOrderSelectedChange}
      handleGetReport_2=""
      reportVariables=""
      IsLoading=""
      isLoading_orderList={isLoading_orderList}
      reportTitle="Orders"
      reportTitleOptions="Actions"
      reportTitleOptions_2A="Paid"
      reportTitleOptions_2B="Status"
      reportTitleOptions_2C="Invoice"
      boxSize={3}
      boxSize2={2.25}

    />
  );
};

export default Ccorders;
