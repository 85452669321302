//import axios from 'axios';
import { useState, useEffect, useContext, Fragment } from "react";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import { SocketContext } from "../../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { variables } from "../../../Variables";



import InvoicingBaseDialog from '../InvoicingBaseDialog';
import InvoiceBaseActions from '../InvoiceBaseActions';

import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../../store/notifications-slice";

import { paymentInActions } from "../../../store/payment-slice";
//import { paymentPlanActions } from "../../store/paymentPlan-slice";
import { invoicingActions } from "../../../store/invoicing-slice";


//MUI IMPORTS--------------------------------------

import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Button from "@mui/material/Button";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const InvoiceChecker = (props) => {
  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  //const userInfo = useSelector((state) => state.user);
  const currentOrderSelected = useSelector(
    (state) => state.orders.selectedOrder
  );
//   const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const currentInvoiceInfo = useSelector((state) => state.invoicing);
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );

  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);


  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );


  const [usersTitleList, setUsersTitleList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const {
    paymentTypeList,
    isNewInvoice,
    newInvoice,
    isLoading_BookInvoiceHandler,
    isLoading_BookInvoice,
    selectedOrder,
    currentOrderSelectedRef,
    invoiceRecorded,
  } = props;

  const [showPplanSigned, setShowPplanSigned] = useState(false);
  const [showPplanSet, setShowPplanSet] = useState(false);
  const [showPplanSignedDetails, setShowPplanSignedDetails] = useState(false);
  
  const [showCreateNewInvoice, setShowCreateNewInvoice] = useState(false);

  const[invoiceNumber, setInvoiceNumber]=useState(null)
  
  //const[invoiceRecorded, setInvoiceRecorded]=useState(false)


  //console.log("this is the invoice number",currentOrderSelectedRef?.CONFIRMATION_REFERENCE)
  //console.log("here all props at InvoiceCheker...",props)


  const showInvoiceHandler = (event, inv)=>{
    event.preventDefault();

    //console.log("download invoice here....")
    axios
    .get(variables.API_URL + "comunication/recordedinvoice/" + inv , {withCredentials: true,})
     .then((res) => {
       //console.log("response from the link to download...",res.data.invoiceRecorded)
       //setInvoiceRecorded((prev)=>res.data.invoiceRecorded)
     })
     .catch((error) => {
       console.log("error from check if...",error);
     });

  }
  


  //Show the modal with form to Create / Book a New Invoice on Order selected-----------
  const showBookInvoiceModalHandler = (event, action, inv) => {
    if (event) {
      event.preventDefault();
    }
    if (action === 1) {
      //console.log("this is the variable inv...",inv)
      setShowCreateNewInvoice((prev) => !showCreateNewInvoice);
      setInvoiceNumber(inv)
    } else if (action === 2) {
      setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);

    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      //console.log("receiving a notification object for invoicechecker", data);
      //console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  //Reseting the slice for paymentsIn redux
  useEffect(() => {
    //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
    dispatch(
      paymentInActions.resetPaymentIn({
        paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        //paymentDate: new Date(currentPaymentInfo.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

    //Reset The Payment Plan Slice to initial values function to be used after payment is recorded -----
    dispatch(
      invoicingActions.resetInvoice({
        invoiceBookDate: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  }, []);



  return (
    <Fragment>
      <div>

        <Divider />

        {!isLoading_BookInvoice ? (
          <Fragment>

          {invoiceRecorded?
          <Button
          onClick={(event) => {
            event.preventDefault();
            showInvoiceHandler(event, currentOrderSelectedRef);
          }}
          sx={{ color: "#7A1125", textTransform: "capitalize" }}
          disabled={!invoiceRecorded ? true : false}
        >
          <NoteAddIcon
            sx={{
              fontSize: 20,
              color: !invoiceRecorded ? "#ccc" : "#4E1721",
              marginRight: 2,
              marginTop: 2,
            }}
          />
          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, fontSize: 12, }}
          >
            View Invoice
          </Typography>
        </Button>
          :
          <h1>...</h1>
          }

        <Divider></Divider>

            </Fragment>

          ) : (
            <Box textAlign="center" sx={{marginTop:5}}>
              <SyncLoader color="#7A1125" size={5} />
            </Box>
          )}



        <InvoicingBaseDialog
        showCreateNewInvoice = {showCreateNewInvoice} 
        showBookInvoiceModalHandler = {showBookInvoiceModalHandler}
        socket={socket}
        paymentTypeList={paymentTypeList}
        isNewInvoice={isNewInvoice}
        newInvoice={newInvoice}
        isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
        isLoading_BookInvoice = {isLoading_BookInvoice}
        invoiceNumber={invoiceNumber}
        usersTitleList={usersTitleList}  //to be reviewd and find out if it is really need it...

        />


        {/* Modal for case: Show payment plan signed Details ---------------------- */}
        <Dialog
          open={showPplanSignedDetails}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showBookInvoiceModalHandler(event, 4)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Signed P. Plan Details</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <Fragment>
                <DialogContentText sx={{ margin: 2 }}>
                  On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME}
                </DialogContentText>
                
                <Typography
                  align="center"
                  //variant="body2"
                  //color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                   Payment Plan Signed.  please see details as follows...
                </Typography>
               

                {/* <PaymentPlanInfo currentPaymentPlanInfo={currentPaymentPlanInfo}/> */}

              </Fragment>
            )}
            {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};



export default InvoiceChecker;