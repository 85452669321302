import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

//MY IMPORTS-----------------------------------------
import MyTabs from "../UI/MyTabs";
import ArtworkTrackerTabs from "../UI/tabsContent/ArtworkTrackerTabs"
import { format } from "date-fns";
import theme from "../UI/Theme";
//import '../../cssFiles/artTracker.css'
import { variables } from "../../Variables";
import DesignerList from "./DesignerList";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//MUI IMPORTS
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Box,
  Paper
} from "@mui/material";

const ArtTracker = () => {
  let selectedDatesTo = new Date(Date.now()).toISOString();
  let selectedDatesFromInitial = new Date(Date.now()).toISOString();

  const [tabInitialDate, setTabInitialDate] = useState(
    format(new Date(selectedDatesFromInitial), "yyyy-MM-dd")
  );

  const [tabFinalDate, setTabFinalDate] = useState(
    format(new Date(selectedDatesTo), "yyyy-MM-dd")
  );

  const [designersList, setDesignersList] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportTitle, setReportTitle] = useState("")

  const [myFilteredOrders2, setmyFilteredOrders2] = useState([]);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));



  const filteredOrdersByDesigner = async(designerId) => {
    if (designerId) {
      setIsLoading((prev)=>!prev)
      axios
        .get(
          variables.API_URL +
            "production" +
            "/allordersbydesigner/" +
            designerId
        )
        .then((res) => {
          setFilteredOrders(res.data.orderInfo);
          setIsLoading((prev)=>!prev)
          
          //setFilteredName(res.data);
          // console.log(
          //   "the data here from the function orders filteredbydesigner....",
          //   res.data.orderInfo
          // );
        })
        .catch((err) => console.error(err));
        setIsLoading((prev)=>!prev)
    }
  };

  useEffect(() => {
    axios
      .get(variables.API_URL + "users" + "/activeusersbytitle" + "/7")
      .then((res) => {
        setDesignersList(res.data);
        //setFilteredName(res.data);
        //console.log("the data here....",res.data)
      })
      .catch((err) => console.error(err));

      // Cleanup function
    return () => {
      setDesignersList([]);
      // Clean up any resources (e.g., remove event listeners, cancel subscriptions, etc.)
    };


  }, []);


  const handleNameChange = (event) => {
    setIsLoading(true)
    setFilteredOrders([]);
    setSelectedName(event.target.value);
    
    if (event.target.value === "") {
      setIsLoading(false)
      // setFilteredName(designersList);
    } else {
      //console.log(event.target.value.NAME);
      //console.log(event.target.value);
      //console.log("designerlist..", designersList);

      // setTimeout(() => {
      //   // Place your action here
      //   setIsLoading(true)
        
      // }, 1000); // Delay in milliseconds (1 second in this example)

      
      filteredOrdersByDesigner(event.target.value.CONSULTANT_ID);

      //setFilteredName(designersList.activeUsersByTitleId?.filter(designer => designer.NAME.toLowerCase().includes(event.target.value.NAME.toLowerCase())));
    }
  };

  const changeTabsHandler = (index) => {
    if (index === 0) {
      setReportTitle("No Artwork")
      //   selectedDatesFromInitial = new Date(prevMonday).toISOString(); //working version on 21.Feb.24
      //   selectedDatesTo = new Date(Date.now()).toISOString();//working version on 21.Feb.24
      //   setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
    } else if (index === 1) {
      setReportTitle("Artwork Received")
      //setIsLoading((prev)=>!prev)
      //   //Previous Week-----------------
      //   let prevWeek = subWeeks(new Date(), 1)
      //   selectedDatesFromInitial = startOfISOWeek(prevWeek);
      //   selectedDatesTo = endOfISOWeek(prevWeek);
      //   setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
    } else if (index === 2) {
      setReportTitle("Artwork Proof")
      //   selectedDatesFromInitial =
      //     new Date(Date.now()).getUTCFullYear() +
      //     "-" +
      //     (new Date(Date.now()).getUTCMonth() + 1) +
      //     "-01";
      //   selectedDatesTo = new Date(Date.now()).toISOString();
      //   setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
    } else if (index === 3) {
      setReportTitle("Artwork Approved")
      //   let myMonth = subMonths(new Date(), 1)
      //   let startMyMonth = startOfMonth(new Date(myMonth))
      //   let endMyMonth = endOfMonth(new Date(myMonth))
      //   setTabInitialDate(format(new Date(startMyMonth), "yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(endMyMonth), "yyyy-MM-dd"));
    } else if (index === 4) {
      //   selectedDatesTo = new Date(Date.now()).toISOString();
      //   let startMyYear = startOfYear(new Date())
      //   setTabInitialDate(format(new Date(startMyYear),"yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(selectedDatesTo),"yyyy-MM-dd"));
    } else if (index === 5) {
      //   let previousYear = subYears(new Date(), 1)
      //   let startMyYear = startOfYear(new Date(previousYear))
      //   let endMyYear = endOfYear(new Date(previousYear))
      //   setTabInitialDate(format(new Date(startMyYear),"yyyy-MM-dd"));
      //   setTabFinalDate(format(new Date(endMyYear),"yyyy-MM-dd"));
    } else if (index === 6) {
    }
  };

  //Set the dates from another component --- with DataSelector component---------------------------------
  const dateSelectorHandler = (dateFrom, dateTo) => {
    let dateFrom2 = new Date(
      new Date(dateFrom.setUTCDate(dateFrom.getUTCDate())).setHours(0, 0, 0)
    ).toISOString();
    let dateTo2 = new Date(
      new Date(dateTo.setUTCDate(dateTo.getUTCDate())).setHours(23, 59, 59)
    ).toISOString();

    //setTabInitialDate(dateFrom2);
    //setTabFinalDate(dateTo2);
    setTabInitialDate(format(new Date(dateFrom2), "yyyy-MM-dd"));
    setTabFinalDate(format(new Date(dateTo2), "yyyy-MM-dd"));
  };


  //Get all payments asociated with the order from order ID----------------------------------------------------
  
  const getPaymentsOnOrder = async() => {
    
    //console.log("info...", filteredOrders)
    setIsLoading((prev)=>!prev)

    try{

      
      const responses = await Promise.all(  

        filteredOrders?.map((order)=>

          axios.get(variables.API_URL + "payments/paymentsonorder/" + order.CONFIRMATION_ID, {withCredentials: true,})

      )
    )

    const updatedOrders = responses?.map((response,i)=>{

      //console.log("myresponseAfterAxios...",response.data.paymentsOnOrder)
      //console.log("myresponseAfterAxios...IIII",i)

      if(response.data.paymentsOnOrder.length >0){
  
        const totalPaid =response?.data.paymentsOnOrder[response.data.paymentsOnOrder.length - 1].cumulative_sum;

        return{
          ...filteredOrders[i], "totalPaid":totalPaid

        }
      }else{
        return{

          ...filteredOrders[i], "totalPaid":0
          

        }
      }

    })

    console.log("updatedOrders...",updatedOrders)

    setmyFilteredOrders2(updatedOrders)
    

    }catch(error){
      console.error('error',error)
    }
    setIsLoading((prev)=>!prev)

  };
  //Get all payments asociated with the order from order ID - END-------------------------------------------------



  useEffect(()=>{

    if (filteredOrders?.length > 0 ) {
      getPaymentsOnOrder();
    }

    // Cleanup function
    return () => {
      setmyFilteredOrders2([])
      // Clean up any resources (e.g., remove event listeners, cancel subscriptions, etc.)
    };


  },[filteredOrders])

  useEffect(()=>{

    if (myFilteredOrders2?.length > 0 ) {

      console.log("finalResultArray....",myFilteredOrders2)
      
    }

  },[myFilteredOrders2])

  return (
    <Fragment>
      <Paper
        sx={{
          p: 2,
          //mt: 4,
          //ml:4,
          display: "flex",
          flexDirection: "column",
          height: "auto",
          //  backgroundImage:
          //    "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
          width: matches3
            ? "80vw" || "75vw"
            : matches2
            ? "85vw"
            : matches
            ? "80vw"
            : "80vw" || "50vw",
          //marginBottom: 5,
          margin:"auto",
          
        }}
      >
      {/* <MyTabs
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: 0,
          width: matches3 ? "95vw" : "auto",
        }}
        tabContent="ArtworkTrackerTaps"
        changeTabsHandler={changeTabsHandler}
        dateSelectorHandler={dateSelectorHandler} 
      >  
      </MyTabs> */}
      <ArtworkTrackerTabs
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: 0,
          width: matches3 ? "95vw" : "auto",
          
        }}
        tabContent="ArtworkTrackerTaps"
        changeTabsHandler={changeTabsHandler}
        dateSelectorHandler={dateSelectorHandler}
        designersList={designersList}
        selectedName={selectedName}
        //filteredOrders={filteredOrders}
        filteredOrders={myFilteredOrders2}
        handleNameChange={handleNameChange}
        isLoading={isLoading}
        reportTitle={reportTitle}
      >

      </ArtworkTrackerTabs>

      {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            height: "100%",
          }}
        > */}

      {/* <DesignerList
        designersList={designersList}
        selectedName={selectedName}
        filteredOrders={filteredOrders}
        handleNameChange={handleNameChange}
        isLoading={isLoading}
        reportTitle={reportTitle}
      /> */}
      {/* </Box> */}
      </Paper>
    </Fragment>
  );
};

export default ArtTracker;
