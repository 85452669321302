import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import DashboardGraph from "../reportComponents/DashboardGraph";
import {variables} from "../../Variables";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI Imports-------------------------------
import Box from "@mui/material/Box";

const SalesVsTargetGraph = (props) => {
  const [salesByDateList, setSalesByDateList] = useState([]);
  const [salesCancelledByDateList, setSalesCancelledByDateList] = useState([]);
  const [salesByDateListTarget, setSalesByDateListTarget] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);


  const {tabInitialDate , tabFinalDate} = props;

  //console.log("initial date...", tabInitialDate)
  //console.log("Final date...", tabFinalDate)


  const graphType = {"graph":"Sales Vs Target", "graphId": 1}

  //Get List of Sales by Consultant selected--------------
  const getSalesByDate = (selectedDatesFrom, selectedDatesTo) => {
    //console.log("getSalesByDate ............... in the SalesVsTarget component called !!!!")
    setIsLoading1(true);
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    axios
      .get(
        variables.API_URL +
          "sales/salesbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        //console.log("getsales after axios call....",res.data.salesByDate)
        setSalesByDateList(res.data.salesByDate);

        if (res.data.salesByDate.length === 0) {
        //   setTotalSalesByDate(0);
        //   setTotalPagesByDate(0);
        } else {
          const totalSales = res.data.salesByDate.slice(-1);
          //setTotalSalesByDate(totalSales[0].cumulative_sum);
          //setTotalPagesByDate(totalSales[0].cumulative_sum_pages);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        //setNotification("Something went wrong, please try again");
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };

  //Get List of Cancelled Orders by Consultant Selected--------------
  const getSalesCancelledByDate = (selectedDatesFrom, selectedDatesTo) => {
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    //console.log("getSalesCancelledByDate ............... in the SalesVsTarget component called !!!!")
    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          "sales/salescancelledbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setSalesCancelledByDateList(res.data.salesCancelledByDate);

        if (res.data.salesCancelledByDate.length === 0) {
          //setTotalSalesCancelledByDate(0);
          //setTotalPagesCancelledByDate(0);
        } else {
          const totalSalesCancelledByDate =
            res.data.salesCancelledByDate.slice(-1);
        //   setTotalSalesCancelledByDate(
        //     totalSalesCancelledByDate[0].cumulative_sum
        //   );
        //   setTotalPagesCancelledByDate(
        //     totalSalesCancelledByDate[0].cumulative_sum_pages
        //   );
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        // setNotification(
        //   "Something went wrong when Loading Cancellations, please try again"
        // );
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };


  //Calculate Target Figure for the Dates selected (only Working Days)--------------
  const getTargetInfo = (selectedDatesFrom, selectedDatesTo) => {
    //console.log("getTargetInfo ............... in the SalesVsTarget component called !!!!")
    
    const startDate = new Date(selectedDatesFrom);
    const endDate = new Date(selectedDatesTo);

    //endDate.setDate(endDate.getDate() + 1);

    const targetByDate = [];

    //let i = 0;
    const targetDaily = 3000;
    let targetAcc = 0;

    //while (i < 7 && startDate < endDate) {
    while (startDate <= endDate) {
      if (!(startDate.getDay() === 6 || startDate.getDay() === 0)) {
        targetAcc = targetAcc + targetDaily;
        //daysOfWeek.push(startDate.getDay());
        targetByDate.push({
          date: new Date(startDate).toISOString(),
          target: targetDaily,
          targetAcc,
        });
      }

      startDate.setDate(startDate.getDate() + 1);
      //i++;
    }

    setSalesByDateListTarget(targetByDate);

    
  };

  //END of Calculate Target Figure for the Dates selected (only Working Days)--------------




  //-------------------------------------
  useEffect(() => {
    //  alert("inside UseEffect in sales Vs Target")
    if (tabInitialDate && tabFinalDate) {
      
        
        getSalesByDate(tabInitialDate, tabFinalDate);
        getSalesCancelledByDate(tabInitialDate, tabFinalDate);
        getTargetInfo(tabInitialDate, tabFinalDate);
        

    }
    
    return () => {};

  }, [tabInitialDate,tabFinalDate]);
  //-------------------------------------

  return (
    <Fragment>

        {!isLoading1 && !isLoading2 ?

      <DashboardGraph
        salesByDateList={salesByDateList}
        salesByDateListTarget={salesByDateListTarget}
        salesCancelledByDateList={salesCancelledByDateList}
        graphType={graphType}
      />

: 
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15}  />
        </Box>
      }

    </Fragment>
  );
};

export default SalesVsTargetGraph;
