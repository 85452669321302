import React, { useState, useEffect, useMemo, useCallback } from "react";


//----------REDUX IMPORTS --------------------------------
import { useSelector } from "react-redux";

import LiveFeeds from "./LiveFeeds";
import theme from "./UI/Theme";
import PaymentsInMain from "../components/paymentsComponent/PaymentsInMain";
import PaymentsOut from "../components/purchaseComponents/PaymentsOut";
import SalesVsTargetGraph from "./graphComponents/SalesVsTargetGraph";
import MyTabs from "./UI/MyTabs";
import SalesByConsultantSummaryGraph from "./graphComponents/SalesByConsultantSummaryGraph";
import SalesSummary from "./salesComponents/SalesSummary";

//OTHER IMPORTS------------------------------------------
import { 
  format, 
  compareAsc, 
  addDays,
  formatDistance, 
  subDays, 
  parse, 
  add, 
  subMonths, 
  startOfMonth, 
  endOfMonth, 
  startOfYear, 
  endOfYear, 
  subYears, 
  startOfISOWeek, 
  endOfISOWeek,
  subWeeks,
  } from "date-fns";


//MUI IMPORTS--------------------------------------------
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";



//export default function MainContent(props) {
const MainContent = () => {
  const userInfo = useSelector((state) => state.user);
  
  const[refresh,setRefresh]=useState(null);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


  //Set the dates from another component --- with DataSelector component---------------------------------
  const dateSelectorHandler = (dateFrom, dateTo)=>{
    let dateFrom2 = new Date(new Date(dateFrom.setUTCDate(dateFrom.getUTCDate())).setHours(0, 0, 0)).toISOString();
    let dateTo2 = new Date(new Date(dateTo.setUTCDate(dateTo.getUTCDate())).setHours(23, 59, 59)).toISOString();
    
    //setTabInitialDate(dateFrom2);
    //setTabFinalDate(dateTo2);
    setTabInitialDate(format(new Date(dateFrom2),"yyyy-MM-dd"));
    setTabFinalDate(format(new Date(dateTo2),"yyyy-MM-dd"));
  }


  //calculate the previous Monday date------------------------------------------------------------
  let prevMonday = new Date();
  prevMonday = new Date(prevMonday.setUTCDate(prevMonday.getUTCDate() - ((prevMonday.getUTCDay() + 6) % 7)));
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  let selectedDatesFromInitial = new Date(prevMonday).toISOString(); // Version working on 22.Feb.24
  //let selectedDatesFromInitial = new Date(prevMonday.getUTCDate()).toISOString();

  let selectedDatesTo = new Date(Date.now()).toISOString();

  //const [tabInitialDate, setTabInitialDate] = useState(new Date()); 
  const [tabInitialDate, setTabInitialDate] = useState(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd")); //// Version working on 22.Feb.24
  //const [tabFinalDate, setTabFinalDate] = useState(new Date());
  const [tabFinalDate, setTabFinalDate] = useState(format(new Date(selectedDatesTo), "yyyy-MM-dd"));// Version working on 22.Feb.24

  // changeTabs Handler---------------------------------------------------------

  const changeTabsHandler = (index) => {
    if (index === 0) {
      selectedDatesFromInitial = new Date(prevMonday).toISOString(); //working version on 21.Feb.24
      selectedDatesTo = new Date(Date.now()).toISOString();//working version on 21.Feb.24

      //setTabInitialDate(selectedDatesFromInitial); //working version on 21.Feb.24
      setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd")); 
      //setTabFinalDate(selectedDatesTo);//working version on 21.Feb.24
      setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
    } else if (index === 1) {
      //Previous Week-----------------
      let prevWeek = subWeeks(new Date(), 1)
      //selectedDatesFromInitial = new Date(new Date(prevMonday.setUTCDate(prevMonday.getUTCDate() - 7)).setUTCHours(23, 59, 59)).toISOString();
      selectedDatesFromInitial = startOfISOWeek(prevWeek);
      
      //selectedDatesTo = new Date(new Date(prevMonday.setUTCDate(prevMonday.getUTCDate() + 7)).setUTCHours(22, 59, 59)).toISOString();
      selectedDatesTo = endOfISOWeek(prevWeek);

      setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd"));
      setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
      //setTabInitialDate(selectedDatesFromInitial);//working version on 21.Feb.24
      //setTabFinalDate(selectedDatesTo);//working version on 21.Feb.24
      
    } else if (index === 2) {
      selectedDatesFromInitial =
        new Date(Date.now()).getUTCFullYear() +
        "-" +
        (new Date(Date.now()).getUTCMonth() + 1) +
        "-01";

      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(format(new Date(selectedDatesFromInitial), "yyyy-MM-dd"));
      setTabFinalDate(format(new Date(selectedDatesTo), "yyyy-MM-dd"));
      //setTabInitialDate(selectedDatesFromInitial);
      //setTabFinalDate(selectedDatesTo);
      
    } else if (index === 3) {
      // let myMonth = "";
      // let myFinalMonth = "";
      // let myYear = "";
      // myMonth = new Date(Date.now()).getUTCMonth() + 1; //January is month zero
      // if (myMonth === 1) {
      //   myYear = new Date(Date.now()).getUTCFullYear() - 1;
      //   myFinalMonth = "12";
      // } else {
      //   myYear = new Date(Date.now()).getUTCFullYear();
      //   myFinalMonth = myMonth;
      // }
      // selectedDatesFromInitial = myYear + "-" + myFinalMonth + "-01";
      // const firstDay = new Date(
      //   new Date(Date.now()).getUTCFullYear() +
      //     "-" +
      //     (new Date(Date.now()).getUTCMonth() + 1) +
      //     "-01"
      // );
      // selectedDatesTo = new Date(
      //   firstDay.setUTCDate(firstDay.getUTCDate() - 1)
      // ).toISOString();

      let myMonth = subMonths(new Date(), 1)
      let startMyMonth = startOfMonth(new Date(myMonth))
      let endMyMonth = endOfMonth(new Date(myMonth))


      setTabInitialDate(format(new Date(startMyMonth), "yyyy-MM-dd"));
      setTabFinalDate(format(new Date(endMyMonth), "yyyy-MM-dd"));
    } else if (index === 4) {
      //selectedDatesFromInitial = new Date().getUTCFullYear() + "-01-01";
      
      selectedDatesTo = new Date(Date.now()).toISOString();
      let startMyYear = startOfYear(new Date())

      setTabInitialDate(format(new Date(startMyYear),"yyyy-MM-dd"));
      setTabFinalDate(format(new Date(selectedDatesTo),"yyyy-MM-dd"));

    } else if (index === 5) {

      // const previousYearFrom = new Date(new Date().getUTCFullYear() + "-01-01");
      // const previousYearTo = new Date(new Date().getUTCFullYear() + "-12-31");
      // selectedDatesFromInitial = new Date(
      //   new Date(
      //     previousYearFrom.setUTCDate(previousYearFrom.getUTCDate() - 365)
      //   ).setHours(0, 0, 0)
      // ).toISOString();
      // selectedDatesTo = new Date(
      //   new Date(
      //     previousYearTo.setUTCDate(previousYearTo.getUTCDate() - 366)
      //   ).setHours(0, 0, 0)
      // ).toISOString();

      let previousYear = subYears(new Date(), 1)
      let startMyYear = startOfYear(new Date(previousYear))
      let endMyYear = endOfYear(new Date(previousYear))

      setTabInitialDate(format(new Date(startMyYear),"yyyy-MM-dd"));
      setTabFinalDate(format(new Date(endMyYear),"yyyy-MM-dd"));

    } else if (index === 6) {
      
    }
  };



  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        backgroundImage:
          "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        marginTop: 0,
      }}
    >
      {matches3 ? "" : <Toolbar />}

      {/* Start of tab code......--- */}

      {/* </MyTabs> */}

      {/* End of Tab code----------- */}

      <Container maxWidth="lg" sx={{ mt: matches3 ? 0 : 4, mb: 3 }}>
        <MyTabs 
        changeTabsHandler={changeTabsHandler}
        dateSelectorHandler={dateSelectorHandler}
        tabContent = "MainDashboardTaps"
        >
          {/* <MyTabs> */}
          {/* {alert("in the tab container....")} */}

          <Typography
            align="center"
            variant="body2"
            color="text.secondary"
            sx={{ margin: "auto", mb:3 }}
          >
            {!matches3 && "Information "} 
            from: -
            {new Date(tabInitialDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
            - to -
            {new Date(tabFinalDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}{" "}
            {/* --
            {new Date(Date.now()).toLocaleString("default", {
              year: "numeric",
            })}
            -
            {new Date(Date.now()).toLocaleString("default", {
              month: "numeric",
            })}
            -
            {new Date(Date.now()).toLocaleString("default", { day: "numeric" })} */}
          </Typography>

          <Grid container spacing={3}>

            {/* Recent Orders */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  //height: 325,
                  //height: matches2?"auto":matches?10:335,
                  height: matches2 ? "auto" : matches ? 370 : 335,

                  paddingBottom: 0,
                  minWidth: 230,
                  //backgroundColor:"#00c"
                }}
              >
                <SalesSummary
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                />

                
              </Paper>
            </Grid>

            {/* Recent Deposits */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 325,
                  //height: 335,
                  height: matches2 ? "auto" : matches ? 370 : 335,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                {/* <PaymentsIn /> */}

                {/* {!isLoading2 ? ( */}
                <PaymentsInMain
                  //original data working
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                  refresh={refresh}
                  MainContentReport={true}
                />

              </Paper>
            </Grid>

            {/* Recent Payments Out */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  //height: 325,
                  height: 335,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                <PaymentsOut
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                  refresh={refresh}
                />
              </Paper>
            </Grid>

            {/* Recent Receivables */}

            {matches2 ? (
              ""
            ) : (
              <Grid item xs={12} md={6} lg={3}>
                <Paper
                  sx={{
                    //p: 2,
                    display: "flex",
                    flexDirection: "column",
                    //height: 270,
                    //height: 325,
                    height: 335,
                    paddingBottom: 0,
                    minWidth: 230,
                    backgroundImage:
                      "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludis-Logo-2020_85kTiekBI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650977432909)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {/* {userInfo.permission === -1 &&<p>...</p>} */}
                  {/* {userInfo.permission === 0 &&<p>...</p>} */}

                 
                </Paper>
              </Grid>
            )}


            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  height: "auto",
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                <SalesByConsultantSummaryGraph
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                />
              </Paper>
            </Grid>

            {/* Graphs related N.2 */}

            <Grid item xs={12}>
              {/* {!isLoading ? ( */}
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <SalesVsTargetGraph
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                />
                
              </Paper>
              
            </Grid>

            {/* Recent Activity */}

            <Grid item xs={12} sx={{ marginBottom: 5 }}>
              <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}>
                <LiveFeeds 
                  selectedDatesFrom={tabInitialDate}
                  selectedDatesTo={tabFinalDate}
                 />
              </Paper>
            </Grid>
          </Grid>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </MyTabs>
      </Container>
    </Box>
  );

}

export default React.memo(MainContent);
