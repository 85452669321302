import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    userEmail:"",
    userPicURL:"",
    userId:"",
    firstName:"",
    lastName:"",
    permission:"",
    consultantId:"",

  },
  reducers: {
    logUser: (state, action) => {
      const { payload } = action;

      //console.log("inside the user slice....see payload",payload)

      state.username = payload.username;
      state.userEmail = payload.userEmail;
      state.userPicURL = payload.userPicURL;
      state.userId = payload.userId;
      state.firstName= payload.userFirstName;
      state.lastName= payload.userLastName;
      state.permission= payload.userPermission;
      state.consultantId= payload.userConsultantId;

    },
    
  },
});

export const {logUser} = userSlice.actions;

export default userSlice;
