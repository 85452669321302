import { useState, useEffect } from "react";
// import axios from "axios";
// import { variables } from "../Variables";
import theme from "./UI/Theme";
import MyTabs from "./UI/MyTabs";
import PaymentsIn from "../components/paymentsComponent/PaymentsIn";
import Outstanding from "./creditControlComponents/Outstanding";
import CreditControlAgeReport from "./creditControlComponents/CreditControlAgeReport";

// import LiveFeeds from "./LiveFeeds";
// import SummaryReport from "./reportComponents/SummaryReport";
// import PaymentsOut from "../components/PurchaseComponents/PaymentsOut";
// import Receivables from "./creditControlComponents/Receivables";
// import DashboardGraph from "./reportComponents/DashboardGraph";
// import SalesByConsultantSummaryGraph from "./graphComponents/SalesByConsultantSummaryGraph";

//loader Imports--------------
// import { SyncLoader } from "react-spinners";
// import { PropagateLoader } from "react-spinners";

//MUI IMPORTS--------------------------------------------

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";

// import { MyTable, MyTableRow } from "./UI/styledComponents/StyledComponents";
// import TableBody from "@mui/material/TableBody";
// import TableContainer from "@mui/material/TableContainer";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";

// import CreditScoreIcon from "@mui/icons-material/CreditScore";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

export default function MainContent_CreditControl(props) {
  //const [selectedDateFrom, setSelectedCDateFrom] = useState(null);
  //const [selectedDateTo, setSelectedCDateTo] = useState(null);

  // const [salesByDateList, setSalesByDateList] = useState([]);
  // const [salesCancelledByDateList, setSalesCancelledByDateList] = useState([]);
  // const [salesByDateListTarget, setSalesByDateListTarget] = useState([]);

  // const [totalSalesByDate, setTotalSalesByDate] = useState(null);
  // const [totalSalesCancelledByDate, setTotalSalesCancelledByDate] =
  //   useState(null);

  // const [totalPagesByDate, setTotalPagesByDate] = useState(null);
  // const [totalPagesCancelledByDate, setTotalPagesCancelledByDate] =
  //   useState(null);

  // const [notification, setNotification] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  //const [changeDates, setChangeDates] = useState(null);
  const [showSalesGraph, setShowSalesGraph] = useState(false);

  //variables for all orders and payments to cross them------
  // const [paymentsByDateByOrderList, setPaymentsByDateByOrderList] = useState(
  //   []
  // );
  // const [woffByDateByOrderList, setWoffByDateByOrderList] = useState([]);

  // const [allOrdersList, setAllOrdersList] = useState([]);

  // const [allOrdersWithPaymentsFull, setAllOrdersWithPaymentsFull] = useState(
  //   []
  // );

  // //All Outstanding data ---------
  // const [allOutsData, setAllOutsData] = useState(null);
  // const [allOutsData_first50, setAllOutsData_first50] = useState(null);
  // const [allOutsData_second50, setAllOutsData_second50] = useState(null);

  //set all orders with payment information -------------------------------------
  //const[allOrdersWithPaymentsList,setAllOrdersWithPaymentsList]=useState([])

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  //calculate the previous Monday date------------------------------------------------------------
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  // sets the date object to last Monday, if the current day is Monday,
  // set it to the current date
  prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  let selectedDatesFromInitial = new Date(prevMonday).toISOString();
  const selectedDatesFromInitial_raw = new Date(prevMonday);

  let selectedDatesTo = new Date(Date.now()).toISOString();

  // const changeDatesHandler = () => {
  //   setChangeDates(!changeDates);
  // };
  // const showSalesGraphHandler = () => {
  //   setShowSalesGraph(!showSalesGraph);
  // };

  const [tabInitialDate, setTabInitialDate] = useState(
    selectedDatesFromInitial
  );
  const [tabFinalDate, setTabFinalDate] = useState(selectedDatesTo);

  const changeTabsHandler = (index) => {
    // console.log(
    //   "my index from the Tab on Main content....................................",
    //   index
    // );
    if (index === 0) {
      selectedDatesFromInitial = new Date(prevMonday).toISOString();
      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 1) {
      //Previous Week-----------------
      selectedDatesFromInitial = new Date(
        new Date(prevMonday.setDate(prevMonday.getDate() - 7)).setHours(0, 0, 0)
      ).toISOString();

      selectedDatesTo = new Date(
        new Date(prevMonday.setDate(prevMonday.getDate() + 7)).setHours(0, 0, 0)
      ).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 2) {
      selectedDatesFromInitial =
        new Date(Date.now()).getFullYear() +
        "-" +
        (new Date(Date.now()).getMonth() + 1) +
        "-01";

      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 3) {

      let myMonth = ""
      let myFinalMonth=""
      let myYear = ""
      //new Date(Date.now()).getFullYear() + "-" + new Date(Date.now()).getMonth() + "-01";
      //"2022-12-01"
      myMonth =  new Date(Date.now()).getMonth() + 1
      if(myMonth===1){
        myYear = new Date(Date.now()).getFullYear() - 1 
        myFinalMonth = "12" 
      }else{
        
        myYear = new Date(Date.now()).getFullYear()
        myFinalMonth = myMonth 
      }



      selectedDatesFromInitial =
        myYear + "-" + myFinalMonth + "-01"
        // new Date(Date.now()).getFullYear() +"-" +new Date(Date.now()).getMonth() +"-01";

      const firstDay = new Date(
        new Date(Date.now()).getFullYear() +
          "-" +
          (new Date(Date.now()).getMonth() + 1) +
          "-01"
      );
      //selectedDatesTo = new Date(firstDay.setDate(firstDay.getDate()-1)).toISOString();
      selectedDatesTo = new Date(
        new Date(firstDay.setDate(firstDay.getDate())).setHours(0, 0, 0)
      ).toISOString();

      //alert('working index ===1....' + selectedDatesTo + "vs..." + firstDay)

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 4) {
      selectedDatesFromInitial = new Date().getFullYear() + "-01-01";

      selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    } else if (index === 5) {
      //selectedDatesFromInitial = new Date().getFullYear() + "-01-01";

      const previousYearFrom = new Date(new Date().getFullYear() + "-01-01");
      const previousYearTo = new Date(new Date().getFullYear() + "-12-31");

      selectedDatesFromInitial = new Date(
        new Date(
          previousYearFrom.setDate(previousYearFrom.getDate() - 365)
        ).setHours(0, 0, 0)
      ).toISOString();

      selectedDatesTo = new Date(
        new Date(
          previousYearTo.setDate(previousYearTo.getDate() - 363)
        ).setHours(0, 0, 0)
      ).toISOString();

      //selectedDatesTo = new Date(Date.now()).toISOString();

      setTabInitialDate(selectedDatesFromInitial);
      setTabFinalDate(selectedDatesTo);
    }
  };

  // function Copyright(props) {
  //   return (
  //     <Typography
  //       variant="body2"
  //       color="text.secondary"
  //       align="center"
  //       {...props}
  //     >
  //       {"Copyright © "}
  //       <Link color="inherit" href="https://s4b-consulting.de" target="blank">
  //         S4B Consulting
  //       </Link>{" "}
  //       {new Date().getFullYear()}
  //       {"."}
  //     </Typography>
  //   );
  // }

  //-------------- Information for Summary Report---------------//
  // const summaryInfo = {
  //   title: "Net Sales",
  //   titleSales: "Sales",
  //   titleCancelations: "cancelations",
  //   subtitle: "T. Pages",
  //   subtitle2: "Price/Page",
  // };

  //Calculate Target Figure for the Dates selected (only Working Days)--------------

  //const getTargetInfo = (sDate) => {
  // const getTargetInfo = (selectedDatesFrom, selectedDatesTo) => {
  //   const startDate = new Date(selectedDatesFrom);
  //   const endDate = new Date(selectedDatesTo);

  //   //endDate.setDate(endDate.getDate() + 1);

  //   const targetByDate = [];

  //   //let i = 0;
  //   const targetDaily = 3000;
  //   let targetAcc = 0;

  //   //while (i < 7 && startDate < endDate) {
  //   while (startDate < endDate) {
  //     if (!(startDate.getDay() === 6 || startDate.getDay() === 0)) {
  //       targetAcc = targetAcc + targetDaily;
  //       //daysOfWeek.push(startDate.getDay());
  //       targetByDate.push({
  //         date: new Date(startDate).toISOString(),
  //         target: targetDaily,
  //         targetAcc,
  //       });
  //     }

  //     startDate.setDate(startDate.getDate() + 1);
  //     //i++;
  //   }

  //   // console.log(
  //   //   "days of week..........................................................",
  //   //   targetByDate
  //   // );
  //   // console.log(
  //   //   "Total days of week..........................................................",
  //   //   targetByDate.length
  //   // );

  //   setSalesByDateListTarget(targetByDate);

  //   //return targetByDate;
  // };

  //Get List of Sales by Consultant selected--------------
  // const getSalesByDate = (selectedDatesFrom, selectedDatesTo) => {
  //   setIsLoading(true);
  //   //const selectedDatesTo = new Date(Date.now()).toISOString();
  //   axios
  //     .get(
  //       variables.API_URL +
  //         "sales/salesbydate/" +
  //         selectedDatesFrom +
  //         "/" +
  //         selectedDatesTo,
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       setSalesByDateList(res.data.salesByDate);

  //       if (res.data.salesByDate.length === 0) {
  //         setTotalSalesByDate(0);
  //         setTotalPagesByDate(0);
  //       } else {
  //         const totalSales = res.data.salesByDate.slice(-1);
  //         setTotalSalesByDate(totalSales[0].cumulative_sum);
  //         setTotalPagesByDate(totalSales[0].cumulative_sum_pages);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setNotification("Something went wrong, please try again");
  //       setTimeout(() => {
  //         setNotification(null);
  //       }, 5000);
  //     });
  // };

  // //Get List of Cancelled Orders by Consultant Selected--------------
  // const getSalesCancelledByDate = (selectedDatesFrom, selectedDatesTo) => {
  //   //const selectedDatesTo = new Date(Date.now()).toISOString();
  //   setIsLoading(true);
  //   axios
  //     .get(
  //       variables.API_URL +
  //         "sales/salescancelledbydate/" +
  //         selectedDatesFrom +
  //         "/" +
  //         selectedDatesTo,
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       setSalesCancelledByDateList(res.data.salesCancelledByDate);

  //       if (res.data.salesCancelledByDate.length === 0) {
  //         setTotalSalesCancelledByDate(0);
  //         setTotalPagesCancelledByDate(0);
  //       } else {
  //         const totalSalesCancelledByDate =
  //           res.data.salesCancelledByDate.slice(-1);
  //         setTotalSalesCancelledByDate(
  //           totalSalesCancelledByDate[0].cumulative_sum
  //         );
  //         setTotalPagesCancelledByDate(
  //           totalSalesCancelledByDate[0].cumulative_sum_pages
  //         );
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setNotification(
  //         "Something went wrong when Loading Cancellations, please try again"
  //       );
  //       setTimeout(() => {
  //         setNotification(null);
  //       }, 5000);
  //     });
  // };

  //Get List of All Historic Orders to cross it with payments later-------------------
  // const getAllOrdersActive = () => {
  //   setIsLoading(true);
  //   const selectedDatesFrom = "2000-01-01"; //for all times information started on 2009
  //   axios
  //     //.get(variables.API_URL + "orders/allorders", { withCredentials: true })
  //     .get(variables.API_URL + "orders/allordersactive/" + selectedDatesFrom, {
  //       withCredentials: true,
  //     })
  //     .then((res) => {
  //       //setConsultantList(res.data.orderInfo);
  //       setAllOrdersList(res.data.orderInfo);
  //       //dispatch(orderActions.addFullOrdersList(res.data.orderInfo));
  //       setIsLoading(true);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //Get All (Standard) Payments by Date and subtotal by Order------------------------------------
  // const getPaymentsByDateByOrder = (selectedDatesFromInitial) => {
  //   const selectedDatesFrom = selectedDatesFromInitial;
  //   const selectedDatesTo = new Date(Date.now()).toISOString();

  //   //console.log("the date from ....", selectedDatesFrom);

  //   axios
  //     .get(
  //       variables.API_URL +
  //         "payments/paymentsbydatebyorder/" +
  //         selectedDatesFrom +
  //         "/" +
  //         selectedDatesTo,
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       // console.log(
  //       //   "From Report ....all Payments by Date list...",
  //       //   res.data.paymentsByDate
  //       // );
  //       setPaymentsByDateByOrderList(res.data.paymentsByDateByOrder);

  //       if (res.data.paymentsByDateByOrder.length === 0) {
  //         //setTotalPaymentsByDate(0);
  //       } else {
  //         //const totalStandardPayments = res.data.paymentsByDate.slice(-1);
  //         //setTotalPaymentsByDate(totalStandardPayments[0].cumulative_sum);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //Get All (Standard) Payments by Date and subtotal by Order------------------------------------
  // const getWoffByDateByOrder = (selectedDatesFromInitial) => {
  //   const selectedDatesFrom = selectedDatesFromInitial;
  //   const selectedDatesTo = new Date(Date.now()).toISOString();

  //   //console.log("the date from ....", selectedDatesFrom);

  //   axios
  //     .get(
  //       variables.API_URL +
  //         "payments/woffbydatebyorder/" +
  //         selectedDatesFrom +
  //         "/" +
  //         selectedDatesTo,
  //       { withCredentials: true }
  //     )
  //     .then((res) => {
  //       // console.log(
  //       //   "From Report ....all Payments by Date list...",
  //       //   res.data.paymentsByDate
  //       // );
  //       setWoffByDateByOrderList(res.data.woffByDateByOrder);

  //       if (res.data.woffByDateByOrder.length === 0) {
  //         //setTotalPaymentsByDate(0);
  //       } else {
  //         //const totalStandardPayments = res.data.paymentsByDate.slice(-1);
  //         //setTotalPaymentsByDate(totalStandardPayments[0].cumulative_sum);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //Get all sales and payments per order -- the final summary info -------------------------------------------------------------
  // let AllOrdersWithPaymentsFinal = [];
  // let allOrdersWithPayments = [];

  // const getAllPaidByOrder = () => {
  //   //let allOrdersWithPayments=[]

  //   // let paidOnOrder = []
  //   // const allPaid2 = paymentsByDateList.filter(
  //   //     (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //   //   );

  //   //AllOrdersWithPaymentsFinal = allOrdersList.map(async (order) => {

  //   let allSales = 0; //starting value
  //   let allPayments = 0; //starting value
  //   let allWoff = 0; //starting value
  //   let allOuts = 0; //starting value
  //   let allOuts_first50 = 0; //starting value
  //   let allOuts_second50 = 0; //starting value

  //   let totalBalance = 0; //starting value

  //   allOrdersList.map((order) => {
  //     let totalPaidOnOrder = 0;
  //     let totalWoffOnOrder = 0;
  //     let totalOuts = 0;
  //     let totalOuts_first50 = 0;
  //     let totalOuts_second50 = 0;

  //     let paymentsOnOrderList = [];
  //     let woffOnOrderList = [];

  //     //finding all payments on one specific order
  //     const allPaid2 = paymentsByDateByOrderList.filter(
  //       (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //     );

  //     if (allPaid2.length > 0) {
  //       totalPaidOnOrder = allPaid2[0].subTotal;
  //       paymentsOnOrderList = allPaid2;
  //     }

  //     //finding all written off on one specific order
  //     const allWoff2 = woffByDateByOrderList.filter(
  //       (woff) => order.CONFIRMATION_ID === woff.CONFIRMATION_ID
  //     );

  //     if (allWoff2.length > 0) {
  //       totalWoffOnOrder = allWoff2[0].subTotal;
  //       woffOnOrderList = allWoff2;
  //     }

  //     allSales = allSales + order.TOTAL_AMOUNT;
  //     allPayments = allPayments + totalPaidOnOrder;
  //     allWoff = allWoff + totalWoffOnOrder;
  //     totalBalance =
  //       totalBalance + order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder;

  //     if (order.CLUBSTATUS_ID === 7) {
  //       totalOuts = order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total
  //       totalOuts_first50 = 0;
  //       totalOuts_second50 =
  //         order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder; // When project is completed Full Amount is due from total
  //     } else if (order.CLUBSTATUS_ID < 7) {
  //       totalOuts =
  //         order.TOTAL_AMOUNT / 2 - totalPaidOnOrder - totalWoffOnOrder; // when project is NOT completed only 50% is due from total

  //       totalOuts_first50 =
  //         order.TOTAL_AMOUNT / 2 - totalPaidOnOrder - totalWoffOnOrder; // when project is NOT completed only 50% is due from total
  //       totalOuts_second50 = 0;
  //     } else {
  //       //alert("no first no second ??????...ref: " + order.CONFIRMATION_REFERENCE )
  //     }

  //     allOuts = allOuts + totalOuts;
  //     allOuts_first50 = allOuts_first50 + totalOuts_first50;
  //     allOuts_second50 = allOuts_second50 + totalOuts_second50;

  //     //alert("order..."+order.CONFIRMATION_REFERENCE+"allOut so far...." + allOuts+ "total out order...." + totalOuts)

  //     allOrdersWithPayments.push({
  //       order: order,
  //       totalPaid: totalPaidOnOrder,
  //       totalWoff: totalWoffOnOrder,
  //       paymentsOnOrderList: paymentsOnOrderList,
  //       woffOnOrderList: woffOnOrderList,
  //       totalOuts: totalOuts,
  //       totalBalance: order.TOTAL_AMOUNT - totalPaidOnOrder - totalWoffOnOrder,
  //     });

  //     //last version working returning promise------------------------------------

  //     // const allPaid2 = paymentsByDateList.filter(
  //     //   (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //     // );

  //     // let sum =0;

  //     // const allPaid =  await paymentsByDateList.filter(
  //     //   (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //     // ).reduce((suma, number)=> {
  //     //   suma = suma + number.AMOUNTPAID
  //     //   return sum = suma
  //     // }, 0)

  //     //END last version working returning promise------------------------------------

  //     //console.log("this is the allpaid from the filtered...",await allPaid)

  //     //console.log("this is reduce... value outside reduce on order...",order.CONFIRMATION_REFERENCE,"and total paid...", sum)

  //     // const final = async(allPaid)=> {

  //     //   if (await allPaid.length > 0){

  //     //     allOrdersWithPayments.push({
  //     //       order: order,
  //     //       totalPaid: sum,
  //     //       paymentsOnOrderList: allPaid2,
  //     //       totalOuts: order.TOTAL_AMOUNT - sum,
  //     //     })

  //     //   }

  //     // }

  //     // final(await allPaid);

  //     //  allOrdersWithPayments_final = allOrdersWithPayments.push({
  //     //     order: order,
  //     //     totalPaid: sum,
  //     //     paymentsOnOrderList: allPaid2,
  //     //     totalOuts: order.TOTAL_AMOUNT - sum,
  //     //   })

  //     // setAllOrdersWithPaymentsList(allOrdersWithPaymentsList => [...allOrdersWithPaymentsList,{
  //     //   order: order,
  //     //   totalPaid: sum,
  //     //   paymentsOnOrderList: allPaid2,
  //     //   totalOuts: order.TOTAL_AMOUNT - sum,
  //     // }] );

  //     //setAllOrdersWithPaymentsList(allOrdersWithPaymentsFinal)

  //     //working with this method-----------------------------------------------------
  //     //   let luisTotalPaid = 0;
  //     //   let counter =0;

  //     //   const allPaid = paymentsByDateList.filter(
  //     //     (payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //     //   ).map((pay)=>{
  //     //   luisTotalPaid = luisTotalPaid + pay.AMOUNTPAID
  //     //   counter = counter + 1
  //     //   console.log("myorder",order,"total paid",luisTotalPaid,"counter",counter)
  //     //   return luisTotalPaid
  //     // });

  //     //End of working with this method-----------------------------------------------------

  //     //let sum =0;

  //     // const allPaid = await paymentsByDateList.filter(
  //     //   async(payment) => order.CONFIRMATION_ID === payment.CONFIRMATION_ID
  //     // ).reduce(async(sum,number)=>{
  //     //   const myTotalPaid = await sum + number.TOTAL_AMOUNT
  //     //   return myTotalPaid
  //     // })

  //     //totalPaidOrder = totalPaidOrder + payment.TOTAL_AMOUNT
  //     //alert("the total paid so far..."+"on"+order.CONFIRMATION_ID+"is..."+totalPaidOrder)
  //     //console.log("the total function result here. with order....",order,"paid...", luisTotalPaid)

  //     // if (allPaid.length > 0) {
  //     //   //const numbers = [2, 4, 6];

  //     //   await allPaid.reduce((sum, number)=> {

  //     //     sum = sum + number.AMOUNTPAID
  //     //     console.log("this is reduce... value inside reduce on order...",order.CONFIRMATION_REFERENCE,"and total paid...", sum)
  //     //     return sum
  //     //   }, 0);

  //     // //   //  console.log("Allpaid array here...", paidOnOrder)
  //     // //   //console.log("this is the reduce part of the array with order number...", order.CONFIRMATION_REFERENCE,"totalpayments of....",sum)
  //     //  }

  //     // allOrdersWithPayments.push({
  //     //   order: order,
  //     //   totalPaid: sum,
  //     //   paymentsOnOrderList: allPaid,

  //     // })

  //     //console.log("this is the filtered array...", allPaid);

  //     //console.log("the final object with orders with payments from map......", allOrdersWithPayments)
  //     //console.log("the final object with orders with payments lenght....", allOrdersWithPayments.length)
  //   });

    // console.log("All Outstanding...", allOuts);
    // console.log("All Outstanding First 50...", allOuts_first50);
    // console.log("All Outstanding Second 50...", allOuts_second50);

    // console.log("Total Balance...", totalBalance);

    // console.log("All sales...", allSales);
    // console.log("All payments...", allPayments);
    // console.log("All Woff...", allWoff);

    // setAllOrdersWithPaymentsFull(allOrdersWithPayments);
    // setAllOutsData(allOuts);
    // setAllOutsData_first50(allOuts_first50);
    // setAllOutsData_second50(allOuts_second50);

    //console.log("payment on order",order.CONFIRMATION_REFERENCE," is equal to...",totalPaidOrder)
    //console.log("the final object with orders with payments....", allOrdersWithPayments_final)
    // console.log(
    //   "the final object with orders with payments from map......",
    //   allOrdersWithPayments
    // );
    // //console.log("the final object with orders with payments lenght....", allOrdersWithPayments_final.length)
    // console.log(
    //   "the final object with orders with payments lenght....",
    //   allOrdersWithPayments.length
    // );

    //setAllOrdersWithPaymentsList( allOrdersWithPayments_final)

    //let sum_outs=0
    //   if(allOrdersWithPaymentsList.length>0){

    //     console.log("heyyyyyyyyyyyyyyyyyyyy", allOrdersWithPaymentsList.length)

    //     // allOrdersWithPayments.reduce((suma_outs, number_outs)=> {
    //     //   suma_outs = suma_outs + number_outs.totalOuts
    //     //   console.log("the final outstanding is....",number_outs.totalOuts)
    //     //   return sum_outs = suma_outs
    //     // }, 0)

    //   }else{

    //     console.log("NOIyyyyyyyyyyyyyyyyyyyy",allOrdersWithPaymentsList.length)

    //   }

    //   //console.log("the final outstanding is....",sum_outs)
  // };

  // if (AllOrdersWithPaymentsFinal) {
  //   //console.log("payment on order",order.CONFIRMATION_REFERENCE," is equal to...",totalPaidOrder)
  //   //console.log("the final object with orders with payments....", allOrdersWithPayments_final)
  //   console.log(
  //     "the final object with orders with payments from map outside function......",
  //     AllOrdersWithPaymentsFinal
  //   );
  //   //console.log("the final object with orders with payments lenght....", allOrdersWithPayments_final.length)
  //   console.log(
  //     "the final object with orders with payments lenght outside function....",
  //     AllOrdersWithPaymentsFinal.length
  //   );
  // }

  //END Get all payments per order -------------------------------------------------------------

  // useEffect(() => {
  //   setChangeDates(false);
  // }, []);

  // useEffect(() => {
  //   if (selectedDatesFromInitial) {
  //     if (tabInitialDate) {
  //       //functions to get orders and payments in to cross them-------
  //       getPaymentsByDateByOrder("2000-01-01"); //starting date to include all orders first date in DB 2009
  //       getWoffByDateByOrder("2000-01-01");
  //       getAllOrdersActive();
  //       //End functions to get orders and payments in to cross them-------

  //       getSalesByDate(tabInitialDate, tabFinalDate);
  //       getSalesCancelledByDate(tabInitialDate, tabFinalDate);
  //       getTargetInfo(tabInitialDate, tabFinalDate);

  //       //alert("in the useEffect first...."+ paymentsByDateList)
  //       //alert("in the useEffect second...."+ allOrdersList)
  //     }

  //     // else {
  //     //   const myDateFrom = new Date().getFullYear() + "-01-01";
  //     //   //console.log("is false...!!! and myDateFrom is..... yesssssssssssssssssssssssss",myDateFrom);

  //     //   getSalesByDate(myDateFrom, tabFinalDate);
  //     //   getSalesCancelledByDate(myDateFrom, tabFinalDate);
  //     //   getTargetInfo(myDateFrom, tabFinalDate);
  //     // }
  //   }

  //   //clean up function
  //   return () => {
  //     console.log("clean up N1");
  //     setTabInitialDate(null);
  //   };

  //   //return () => {};
  //   //}, [changeDates, tabInitialDate]);
  // }, [tabInitialDate]);

  // useEffect(() => {
  //   //console.log("length from payments array here....", paymentsByDateByOrderList.length)
  //   //alert("working inside useeffect for getallpaidbyorder")
  //   if (allOrdersList.length > 0 && paymentsByDateByOrderList.length > 0) {
  //     getAllPaidByOrder();
  //   } else {
  //     console.log("array empty for allOrdersList");
  //   }

  //   //clean up function
  //   return () => console.log("clean up N.2");
  // }, [allOrdersList, paymentsByDateByOrderList]);

  // console.log(
  //   "in the useEffect first for payments...............",
  //   paymentsByDateByOrderList
  // );
  // console.log("in the useEffect Second...............", allOrdersList);

  //alert("in the useEffect first...."+ paymentsByDateList)
  //alert("in the useEffect second...."+ allOrdersList)

  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        backgroundImage:
          "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        
      }}
    >
      {matches ? "" : <Toolbar />}

      {/* Start of tab code......--- */}

      {/* </MyTabs> */}

      {/* End of Tab code----------- */}

      <Container maxWidth="lg" sx={{ mt: 4, mb: 3 }}>
        <MyTabs changeTabsHandler={changeTabsHandler}>
          <Typography
            align="center"
            variant="body2"
            color="text.secondary"
            sx={{ margin: "auto" }}
          >
            Information from: -
            {new Date(tabInitialDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
            - to -
            {new Date(tabFinalDate).toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>

          <Grid container spacing={3}>
            {/* Recent Deposits */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 325,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                {/* <PaymentsIn /> */}
                {/* <PaymentsIn
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                /> */}

                <PaymentsIn
                  tabInitialDate={tabInitialDate}
                  tabFinalDate={tabFinalDate}
                />

              </Paper>
            </Grid>

            {/* Recent Receivables */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  //height: 270,
                  height: 325,
                  paddingBottom: 0,
                  minWidth: 230,
                }}
              >
                <Outstanding />

                {/* <Receivables
                title="Outstanding"
                totalOutstandingData={{totalOutstanding:allOutsData,totalOutstanding_first50:allOutsData_first50,totalOutstanding_second50:allOutsData_second50, }}
                icon={
                  <PhoneInTalkIcon
                    sx={{
                      fontSize: 20,
                      color: "#4E1721",
                      marginRight: 2,
                      marginTop: -1,
                    }}
                  />
                }
                 /> */}
              </Paper>
            </Grid>

            {/* Credit Control Age Report Image... */}

            {!showSalesGraph ? ( 
               

               <Grid item xs={12} md={12} lg={12}>
                 <Paper
                   sx={{
                     p: 2,
                     display: "flex",
                     flexDirection: "column",
                     //height: 270,
                     height: "auto",
                     paddingBottom: 0,
                     minWidth: 230,
                   }}
                 >

                  <CreditControlAgeReport/>
                  {/* <SalesByConsultantSummaryGraph
                    tabInitialDate={tabInitialDate}
                    tabFinalDate={tabFinalDate}
                  /> */}
               </Paper>
               </Grid>
             ) : (
               ""
             )}

            {/* Graphs related N.2 */}

            {/* <Grid item xs={12}>
              {!isLoading ? (
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}> */}
            {/* <DashboardGraph
                    salesByDateList={salesByDateList}
                    salesByDateListTarget={salesByDateListTarget}
                    salesCancelledByDateList={salesCancelledByDateList}
                    //changeDates={changeDates}

                    //changeDatesHandler={changeDatesHandler}
                    // reportVariables={reportVariables}
                    // salesByConsultantList={salesByConsultantList}
                  /> */}
            {/* </Paper>
              ) : (
                <Box textAlign="center">
                  <SyncLoader color="#7A1125" size={15} />
                </Box>
              )}
            </Grid> */}

            {/* Recent Activity */}

            <Grid item xs={12}>
              <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}>
                {/* <TableContainer component={Paper}>
                  <MyTable size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>REf</TableCell>
                        <TableCell
                        // align="right"
                        >
                          Total Price
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          Total Outs
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {allOrdersWithPaymentsFull.length > 0
                        ? allOrdersWithPaymentsFull?.map((item) => {
                            if (item.totalOuts > 0.5) {
                              return ( */}
                                {/*// <MyTableRow key={Math.random()}>
                                //   <TableCell
                                //     // align="right"
                                //     style={{ fontWeight: 500 }}
                                //   >
                                //     {item.order.CONFIRMATION_REFERENCE}
                                //   </TableCell>

                                //   <TableCell
                                //     style={{
                                //       color: "#4E1721",
                                //       fontWeight: 500,
                                //     }}
                                //     // align="left"
                                //   >
                                //     {/* £{" "} 
                                //     {item.order.TOTAL_AMOUNT.toLocaleString(
                                //       "en-US",
                                //       {
                                //         minimumFractionDigits: 2,
                                //       }
                                //     )}
                                //   </TableCell>

                                //   <TableCell
                                //     style={{
                                //       color: "#4E1721",
                                //       fontWeight: 500,
                                //     }}
                                //     // align="left"
                                //   >
                                //     {/* £{" "} 
                                //     {item.totalOuts.toLocaleString("en-US", {
                                //       minimumFractionDigits: 2,
                                //     })}
                                //   </TableCell>
                                // </MyTableRow>
                //               );
                //             }
                //           })
                //         : "array empty..."}
                //     </TableBody>
                //   </MyTable>
                // </TableContainer>

                {/* 
      <Grid item xs={12}>
    <Paper xs={{ p: 2, display: "flex", flexDirection: "column" }}> */}
                {/* <LiveFeeds /> */}
              </Paper>
            </Grid>
          </Grid>
          {/* <Copyright sx={{ pt: 4 }} /> */}
        </MyTabs>
      </Container>
    </Box>
  );
}
