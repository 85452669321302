export const variables={
    //API_URL: "/",

    //local version ------------------------------------------
    //API_URL:"http://localhost:5000/",
    //API_URL_MONGO: "http://localhost:5003/",

    //live version-------------------------------------------
    API_URL: "https://www.ludis-system.online/",
    API_URL_MONGO: "https://ludisgroup-back-mongo.onrender.com/",
    
    
    //API_URL: "https://backend.theludisgroup.com/", old Version
    //API_URL:"https://ludisgroup-erp-back.herokuapp.com/",
    //API_URL:"http://s4b-consulting.com/",
    //API_URL:"https://ludisgroup-erp-back-2.herokuapp.com/",
    
    
    //PHOTO_URL: "http://localhost:5000/photos/"
}