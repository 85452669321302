import { useState, useEffect, Fragment } from "react";

//REDUX IMPORTS------------------------------------------
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { variables } from "../../Variables";

//MUI IMPORTS--------------------------------------
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid, Container } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ComputerIcon from "@mui/icons-material/Computer";
import BrushIcon from "@mui/icons-material/Brush";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const UserDetails = (props) => {
  const userInfo = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);

  const {matches3} = props

  const handleExpandClick = () => {
    if(userInfo.permission === 6 ){
      setExpanded(!expanded);
    }
  };

  //get All active users to display them------------------------------------------
  //fetching User Teams List from DB-------------------------------------------
  const getActiveUsers = () => {
    axios
      .get(variables.API_URL + "users/activeusers", { withCredentials: true })

      .then((res) => {
        //console.log("fetching Active Users...", res.data.activeUsers);
        setActiveUsers(res.data.activeUsers);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getActiveUsers();
  }, []);

  return (
    <Fragment>
      <Grid
        container
        // className="border-2"
        sx={{
          display: "flex",
          justifyContent: "center",
          
        }}
      >
        {activeUsers?.length > 0
          ? activeUsers?.map((user) => (

              <Grid
                // className="border"
                item
                //key={crypto.randomUUID()}
                key={user.CONSULTANT_ID}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width:"85vw"
                  //backgroundColor:"#ccc",
                  
                }}
                //xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={4}
              >
                <Card sx={{ maxWidth: 345, m: 1, width: matches3? "95vw":"50vw", overflow:"unset" }}>
                  <CardHeader
                    avatar={
                      // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      <Avatar
                        sx={{ bgcolor: user.TEAM_COLORCODE }}
                        aria-label="recipe"
                      >
                        {user.DEPARTMENT_NICK}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={user.NAME}
                    subheader="September 14, 2016"
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={
                      user.USER_PIC ||
                      "https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludis-Logo-2020_85kTiekBI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650977432909"
                    }
                    alt="User Pic"
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {user.TITLE}
                      {/* This impressive paella is a perfect party dish and a fun meal to
            cook together with your guests. Add 1 cup of frozen peas along with
          the mussels, if you like. */}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user.TEAM}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {user.DEPARTMENT_ID === 1 && <LocalOfferIcon />}
                      {user.DEPARTMENT_ID === 2 && <BrushIcon />}
                      {user.DEPARTMENT_ID === 3 && <ComputerIcon />}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph>Method:</Typography>
                      <Typography paragraph>
                        Heat 1/2 cup of the broth in a pot until simmering, add
                        saffron and set aside for 10 minutes.
                      </Typography>
                      <Typography paragraph>
                        Heat oil in a (14- to 16-inch) paella pan or a large,
                        deep skillet over medium-high heat. Add chicken, shrimp
                        and chorizo, and cook, stirring occasionally until
                        lightly browned, 6 to 8 minutes. Transfer shrimp to a
                        large plate and set aside, leaving chicken and chorizo
                        in the pan. Add pimentón, bay leaves, garlic, tomatoes,
                        onion, salt and pepper, and cook, stirring often until
                        thickened and fragrant, about 10 minutes. Add saffron
                        broth and remaining 4 1/2 cups chicken broth; bring to a
                        boil.
                      </Typography>
                      <Typography paragraph>
                        Add rice and stir very gently to distribute. Top with
                        artichokes and peppers, and cook without stirring, until
                        most of the liquid is absorbed, 15 to 18 minutes. Reduce
                        heat to medium-low, add reserved shrimp and mussels,
                        tucking them down into the rice, and cook again without
                        stirring, until mussels have opened and rice is just
                        tender, 5 to 7 minutes more. (Discard any mussels that
                        don’t open.)
                      </Typography>
                      <Typography>
                        Set aside off of the heat to let rest for 10 minutes,
                        and then serve.
                      </Typography>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>

              
            ))
          : " ... No Active Users"}
      </Grid>
    </Fragment>
  );
};

export default UserDetails;
