import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
//import { paymentInActions } from "../../store/payment-slice";
//import { purchasePaymentOutActions } from "../../store/purchasePayment-slice";
import { wagesPaymentActions } from '../../store/wagesPayment-slice'

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";

//My IMPORTS----------------
import theme from "../UI/Theme";
import Title from "../Title";
import { variables } from "../../Variables";

//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const AddWagesPaymentStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const currentUserInfo = useSelector((state) => state.user)
//   const currentPurchasePaymentInfo = useSelector((state) => state.purchasePaymentOut.purchasePaymentOutInfo)
//   const currentSupplierSelected = useSelector((state) => state.supplier.supplierInfo)

  const currentWagesPaymentInfo = useSelector(
    (state) => state.wagesPayment.wagesPaymentInfo
  );

  //const [newPurchasePaymentStatus, setNewPurchasePaymentStatus] = useState({})
  const [newWagesPaymentStatus, setNewWagesPaymentStatus]=useState({})

  const { socket, showWagesPaymentModalHandler, isUpdatedPayment, wagesType } = props

  const matches = useMediaQuery(theme.breakpoints.down("sm"))


  //Mongo info----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"

  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
        if(res.status === 201){
            
            //getPost()
        }
    })
 }

  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");

//     socket.emit("sendNotification", {
//       senderName: currentUserInfo.username,
//       senderPic: currentUserInfo.userPicURL,
//       receiverName: "All",
//       type: info.type,
//       name: info.name,
//       notificationDate: info.notificationDate,
//     });
//   };

    socket.emit("sendNotification", {
    //senderName: currentUserInfo.username,
    senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
    senderPic: currentUserInfo.userPicURL,
    receiverName: "All",
    type: info.type,
    name: info.name,
    notificationDate: info.notificationDate,
    notificationTime: info.notificationTime,
    notificationDetails_Purchase: {
        "wagesInvoice": (wagesType===1? "DIR - ": "FIX - ") + info.notificationDetails_Purchase.wagesInvoice,
        "wagesInvoiceId":currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_ID, 
        "consultant":info.notificationDetails_Purchase.consultant,
        "consultantId": currentWagesPaymentInfo.selectedWagesInvoice[0].CONSULTANT_ID,
        "wagesPaid":info.notificationDetails_Purchase.wagesPaid,
        "isWages":info.notificationDetails_Purchase.isWages,
        "wagesType":currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID,
    }
  })

    //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: currentUserInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      //notificationDetails_Purchase: {
      notificationDetails: {
        "wagesInvoice": (wagesType===1? "DIR - ": "FIX - ") + info.notificationDetails_Purchase.wagesInvoice,
        "wagesInvoiceId":currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_ID, 
        "consultant":info.notificationDetails_Purchase.consultant,
        "consultantId": currentWagesPaymentInfo.selectedWagesInvoice[0].CONSULTANT_ID,
        "wagesPaid":info.notificationDetails_Purchase.wagesPaid,
        "isWages":info.notificationDetails_Purchase.isWages,
        "wagesType":currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID,
    },
      // receiverName: "All",
    }
    createPost(noty)



};


  //Reset Wages Payment Slice to initial values function to be used after Wages payment is recorded -----
  
  const resetWagesPayment = () => {
    dispatch(
        wagesPaymentActions.resetWagesPayment({
          wagesPaymentDate: new Date().toISOString(),
      })
    );
  };
  //END Reset Wages Payment Slice to initial values function to be used after Wages payment is recorded -----

   



  //Function to create (post) the New Wages Payment in the DB ----------
  const createNewWagesPayment = () => {

    setIsLoading(true)


    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };

    // const data = {
    //     DIRINVOICE_ID: currentWagesPaymentInfo.selectedWagesInvoice[0].DIRINVOICE_ID,
    //     PAYDATE_DIR: currentWagesPaymentInfo.wagesPaymentDate,
    //     TOTALPAID_DIR: currentWagesPaymentInfo.wagesInvoiceAmountPaid,
    //     PAYREF_DIR: currentWagesPaymentInfo.wagesPaymentReference,
    //     CONSULTANT_ID:currentWagesPaymentInfo.selectedWagesInvoice[0].CONSULTANT_ID,
    //     COMMENTS: currentWagesPaymentInfo.wagesComments,
    //     FIRMA_ID:4,
    //     M_USER:currentUserInfo.userId,
    //     TIME: new Date(),
    //     //PAYTYPE_ID:currentPurchasePaymentInfo.purchasePaymentType.PAYTYPE_ID, -- ONLY FOR FIX INV
    // };

    const dataDIR = {
        DIRINVOICE_ID: currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_ID,
        PAYDATE_DIR: currentWagesPaymentInfo.wagesPaymentDate,
        TOTALPAID_DIR: currentWagesPaymentInfo.wagesInvoiceAmountPaid,
        PAYREF_DIR: currentWagesPaymentInfo.wagesPaymentReference,
    }

    const dataFIX = {
        FIXINVOICE_ID: currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_ID,
        PAYDATE_FIX: currentWagesPaymentInfo.wagesPaymentDate,
        TOTALPAID_FIX: currentWagesPaymentInfo.wagesInvoiceAmountPaid,
        PAYREF_FIX: currentWagesPaymentInfo.wagesPaymentReference,
        TYPECOST_ID: currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID,
     }

    const data2 = {
        CONSULTANT_ID:currentWagesPaymentInfo.selectedWagesInvoice[0].CONSULTANT_ID,
        COMMENTS: currentWagesPaymentInfo.wagesComments,
        FIRMA_ID:4,
        M_USER:currentUserInfo.userId,
        TIME: new Date(),
    }

    const data = {... (wagesType===1?dataDIR:dataFIX),...data2, }

    // let routeType ="" 
    // if(wagesType===1){
    //   routeType="dir"
    // }else{
    //   routeType="fix"
    // }

    //console.log("this is the data for the request....",data)

    //Create (post) a New Payment ------------------------------
    //.post(variables.API_URL + "purchase/newwagespayment/dir", data, config)
    axios
      .post(variables.API_URL + "purchase/newwagespayment/" + wagesType, data, config)
      .then((response) => {
        setNewWagesPaymentStatus({
          message: " Wages Payment saved Successfully",
          status: true,
        });

        //Submit the notification to the socket - notificationType = 5 for New Wages Consultant Operational Payment and notificationType = 6 for NONOPS ..............
        submitNotification({
            //type: 1,
            type: {generalType:1, notificationType:currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID===2?6:5},
            name: currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID===2?"NON-OPS Payment":"Wages Payment",
            notificationDate: new Date().toLocaleString("default", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            notificationTime: new Date().toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              second: '2-digit',
            }),
            notificationDetails_Purchase: {
                "wagesInvoice": currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_REF , 
                "wagesInvoiceId":currentWagesPaymentInfo.selectedWagesInvoice[0].INVOICE_ID,
                "consultant": currentWagesPaymentInfo.selectedWagesInvoice[0].Firstname +  " " + currentWagesPaymentInfo.selectedWagesInvoice[0].Lastname.charAt(0),
                "consultantId": currentWagesPaymentInfo.selectedWagesInvoice[0].CONSULTANT_ID,
                "wagesPaid" : "£ " + parseFloat(currentWagesPaymentInfo.wagesInvoiceAmountPaid).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  }),
                  "isWages":true,
                  "wagesType":currentWagesPaymentInfo.wagesPaymentType.COSTTYPE_ID,
            },
                //"purchasePayment" : currentPurchasePaymentInfo.purchaseInvoiceAmountPaid,

          });

        //send the paymentID to the parent component to refresh the list of payments including new one-----
        isUpdatedPayment(Math.random())



        //Reset Wages Payment Information----------
        //resetPurchasePaymentInfo()
        resetWagesPayment()

        

        setTimeout(() => {
          //setNotification(null);
          const showWagesPaymentModalHandler_local = (event)=>showWagesPaymentModalHandler(event,1);
          showWagesPaymentModalHandler_local()
          //showAddPaymentModalHandler(1);
          //console.log("This is the end message before closing.....")
      }, 2000);

        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewWagesPaymentStatus({
          message: "  Something went Wrong ! Wages Payment NOT saved",
          status: false,
        });

      });

      setIsLoading(false)


  };
  // end of create the function to create (post) the New User and save It in the DB ----------



  //Step I Creating New User as consultant
  useEffect(() => {
    createNewWagesPayment();
  }, []);


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 

      Object.keys(newWagesPaymentStatus).length !== 0 ?



        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >




{
        newWagesPaymentStatus.status ? (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            <Title sx={{ margin: "auto" }}>
              WAGES PAYMENT SUCCESSFULLY RECORDED !
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              WAGES PAYMENT NOT SAVED, CONTACT YOUR ADMIN TEAM !
            </Title>
          </Box>
        )}




          <ListItem>
            {newWagesPaymentStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}


            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
              <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Payment Status:"
              secondary={
                newWagesPaymentStatus.message ? newWagesPaymentStatus.message : "No Info..."
              }
            />
          </ListItem>
        </Box>

        : 
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      }
    </Fragment>
  );
};

export default AddWagesPaymentStep_3;
