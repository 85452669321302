

import axios from 'axios';
import {useState, useEffect, useContext} from 'react';

import DashboardContainer from "../UI/DashboardContainer";
import UserDetails from "../adminComponents/UserDetails";
import {variables} from "../../Variables";
import PoSteps from "./PoSteps";
import PoStep_1 from "./PoStep_1";
import UserStep_2 from "../adminComponents/UserStep_2";
import UserStep_3 from "../adminComponents/UserStep_3";
import UserStep_4 from "../adminComponents/UserStep_4";



import { SocketContext } from "../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";
import { updateMessagesList, updateNotificationsTypeList } from "../../store/notifications-slice";



//MUI IMPORTS--------------------------------------
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import PostAddIcon from '@mui/icons-material/PostAdd';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../UI/Theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



const PurchaseOrder = () => {

  //New Code here......
  const [suppliersList, setSuppliersList] = useState([]);
  
  const [ projectList,  setProjectList] = useState([]);


  //end New Code Here...





  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const messageListInitial = useSelector((state) => state.notifications.messages);
  const notificationsListInitial = useSelector((state)=>state.notifications.notificationsType);
  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(notificationsListInitial);


  const [value, setValue] = useState(0);

  //const [projectList, setProjectList] = useState([]);
  const [usersCommissionList, setUsersCommissionList] = useState([]);
  const [usersTeamList, setUsersTeamList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));


    //fetching User Teams List from DB-------------------------------------------
  // const getUsersTeamList = () => {
  //   axios
  //     .get(
  //       variables.API_URL + "users/usersteamlist", 
  //       { withCredentials: true })

  //     .then((res) => {
  //       //console.log("fetching projects...",res.data.projectListInfo)
  //       setUsersTeamList(res.data.usersTeamList);
  //       //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //fetching products from DB-------------------------------------------
  // const getUsersCommissionList = () => {
  //   axios
  //     .get(
  //       variables.API_URL + "users/userscommissionlist", 
  //       { withCredentials: true })

  //     .then((res) => {
  //       //console.log("fetching products...",res.data.productListInfo)
  //       setUsersCommissionList(res.data.usersCommissionList);
  //       //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //fetching Markets from DB-------------------------------------------
  const getSuppliersList = () => {
    axios
      .get(
        variables.API_URL + "purchase/supplierslist", 
        {
        withCredentials: true,
      })

      .then((res) => {
        //console.log("fetching products...",res.data.productListInfo)
        setSuppliersList(res.data.suppliersList);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetching projects from DB-------------------------------------------
  const getProjects = () => {
    axios
      .get(
        variables.API_URL + "projects", 
        { withCredentials: true })

      .then((res) => {
        console.log("fetching projects...",res.data.projectListInfo)
        setProjectList(res.data.projectListInfo);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getSuppliersList();
    getProjects();
    //getUsersTitleList();
    //getProjects();
    //getProducts();
    //getUsersTeamList();
    //getUsersCommissionList();
    // getCompanyTypeList();
  }, []);


  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      console.log("receiving a message object", data);
      console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      console.log("receiving a notification object", data);
      console.log("receiving a notification", data.type);
    });
  }, [socket]);
  

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);


  //CODE FOR THE SOCKET END---------------------------






    //Steps for creating a new user-------------------------------------
const steps = [
  {
    label: "PO Details",
    info: <PoStep_1  suppliersList={suppliersList} projectList={projectList} />,
    icon: <SettingsIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
    iconCompleted: <SettingsIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
    id: 0
  },
  {
    label: "User Details",
    info: <UserStep_2  usersTeamList={usersTeamList} usersCommissionList={usersCommissionList} />,
    icon: <GroupAddIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
    iconCompleted: <GroupAddIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
    id: 1
  },
  { 
    label: "User Summary", 
    info:<UserStep_3 />, 
    icon: <ScreenSearchDesktopIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
    iconCompleted: <ScreenSearchDesktopIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
    id: 2
  },
  { 
    label: "Confirmation", 
    info: <UserStep_4 socket={socket} userInfo={userInfo} />, 
    icon:  <MarkEmailReadIcon sx={{color:"#e7dddd",  fontSize: 30}}/>,
    iconCompleted:  <MarkEmailReadIcon sx={{color:"#7A1125",  fontSize: 30}}/>,
    id: 3
  },
];





  return (
    <DashboardContainer 
    reportTitle="Purchase Order Information"
    myMarginTop={5}
    icon={<AssignmentIcon
      sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
    />}

    >
      {/* tabcode */}

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            
          >



            <Tab icon={<PlaylistAddCheckCircleIcon />} iconPosition="start" label="PO List" {...a11yProps(0)} />
            <Tab icon={<PostAddIcon />} iconPosition="start" label="Create New PO" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          
          <UserDetails />
         
        </TabPanel>
        <TabPanel value={value} index={1}>

         

          <DashboardContainer 
          //reportTitle="Create a New User"
          //myMarginTop= {0}
          // icon={<GroupAddIcon
          //   sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
          // />}
          >
            <PoSteps steps={steps} />
          </DashboardContainer>
          
        </TabPanel>
      </Box>
      {/* tabcode end */}
    </DashboardContainer>
  );
};

export default PurchaseOrder;








// const PurchaseOrder = ()=>{

//     return(
//         <h1>Purchase Order...</h1>
//     )
// }

// export default PurchaseOrder;




