import React, { Fragment, useState, useEffect, memo, useCallback} from "react";
import axios from "axios";
import { variables } from "../../Variables";
import theme from "../UI/Theme";
import Deposits from "../Deposits";

import { SocketContext } from "../../utils/socket-client";

//loader Imports--------------
import { SyncLoader } from "react-spinners";
import { PropagateLoader } from "react-spinners";

//MUI IMPORTS-------------------------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Box from "@mui/material/Box";

// function preventDefault(event) {
//   event.preventDefault();
// }




const PaymentsIn = (props) => {

  //console.log("All props in payments in component....",props)

  const mySocket = React.useContext(SocketContext)
  const [socket, setSocket] = useState(null)

  //alert("Payments IN component called !!!!")
  //console.log("Payments IN component called !!!!")


  const [paymentsByDateList, setPaymentsByDateList] = useState([]);
  const [paymentsUnallocatedByDateList, setPaymentsUnallocatedByDateList] =
    useState([]);
  const [paymentsByTypeByDateList, setPaymentsByTypeByDateList] = useState([]);
  const [
    paymentsUnallocatedByTypeByDateList,
    setPaymentsUnallocatedByTypeByDateList,
  ] = useState([]);

  //const [totalPaymentsByDate, setTotalPaymentsByDate] = useState([]);
  const [totalStandardPaymentsByDate, setTotalStandardPaymentsByDate] =
    useState([]);
  const [totalPaymentsUnallocatedByDate, setTotalPaymentsUnallocatedByDate] =
    useState([]);

  //const [changeDates, setChangeDates] = useState(null);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);

  const [showDetails, setShowDetails] = useState(null)
  const [autoRefresh, setAutoRefresh] = useState(null)


  //const [isStarting, setIsStarting] = useState(true);

  
  
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  
  // let netSales = 0;
  // let BarChartData = [];
  
  
  
  
  
  
  // let prevMonday = new Date();
  // prevMonday = new Date(
  //   prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  // );
  // // sets the date object to last Monday, if the current day is Monday,
  // // set it to the current date
  // prevMonday = new Date(prevMonday.setHours(0, 0, 0)); // sets hours, mins, secs to 0

  // // let selectedDatesFromInitial = new Date(prevMonday).toISOString();
  // // let selectedDatesTo = new Date(Date.now()).toISOString();

  //console.log("props for payments in....................",props)

  const selectedDatesFromInitial = props.tabInitialDate;
  const selectedDatesTo = props.tabFinalDate;
  const {showBank, updatePayment, refresh, showBankHandler, MainContentReport }=props
  


  const showDetailsHandler = ()=>{
    setShowDetails((prev)=> !showDetails)
  }

  // console.log(
  //   "luisdates within the paymentsin Component...........",
  //   selectedDatesFromInitial,
  //   "and....",
  //   selectedDatesTo,
  //   "showbank....",showBank,
  // );

  // //Get All (Standard) Payments by Date------------------------------------
  const getPaymentsByDate = (selectedDatesFromInitial, selectedDatesTo) => {

    // if(props.isStarting){}else{
    //   console.log("doing nothing.......")
    // }


    //console.log("getPaymentsByDate ............... in the Payments IN component called !!!! --showbank",showBank)

     setIsLoading1(true);
     //props.loadingHandler2(true)
    //setIsStarting(false);
    //props.handlerIsStarting()
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    //const selectedDatesTo = "2022-03-31";

    //console.log("the date from ....", selectedDatesFrom);

    //select the correct URL for Bank Date or Recorded Date 
    // let paymentsUrl = "payments/paymentsbydate/";
    // showBank
    //   ? (paymentsUrl = "payments/paymentsbydate/")
    //   : (paymentsUrl = "payments/paymentsbydateonbank/");

    axios
      .get(
        variables.API_URL +
          "payments/paymentsbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
           "/" +
           showBank,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Axios Report ....all Payments by Date list...",
        //   res.data.paymentsByDate
        // );

        setPaymentsByDateList(res.data.paymentsByDate);

        if (res.data.paymentsByDate.length === 0) {
          setTotalStandardPaymentsByDate(0);
          
        } else {

          //setPaymentsByDateList(res.data.paymentsByDate);

          const totalStandardPayments = res.data.paymentsByDate.slice(-1);
          setTotalStandardPaymentsByDate(
            totalStandardPayments[0].cumulative_sum
          );

          // console.log(
          //   "From Report-II ....all Payments by Date list...",
          //   totalStandardPayments[0].cumulative_sum
          // );
          
        }

       

        setIsLoading1(false);
        //props.loadingHandler2(false)


        
      })
      .catch((error) => {
        console.log(error);
      });


    

  }

  //Get All (Unallocated) Payments by Date------------------------------------
  const getPaymentsUnallocatedByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {

    //console.log("getPaymentsUnallocatedByDate ............... in the Payments IN component called !!!!")
    setIsLoading2(true);
    //props.loadingHandler2(true)

    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    //const selectedDatesTo = "2022-03-31";

     //select the correct URL for Bank Date or Recorded Date 
    //  let paymentsUrl_2 = "payments/paymentsbydateunallocated/";
    //  showBank
    //    ? (paymentsUrl_2 = "payments/paymentsbydateunallocated/")
    //    : (paymentsUrl_2 = "payments/paymentsbydateunallocated/");  //to be changed by new url once bankend is updated....

    axios
      .get(
        variables.API_URL +
          "payments/paymentsbydateunallocated/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
          "/" +
          showBank,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments Unallocated by Date ...",
        //   res.data.paymentsByDateUnallocated
        // );
        setPaymentsUnallocatedByDateList(res.data.paymentsByDateUnallocated);

        if (res.data.paymentsByDateUnallocated.length === 0) {
          setTotalPaymentsUnallocatedByDate(0);
        } else {
          const totalUnallocatedPayments =
            res.data.paymentsByDateUnallocated.slice(-1);
          setTotalPaymentsUnallocatedByDate(
            totalUnallocatedPayments[0].cumulative_sum
          );
        }

        setIsLoading2(false);
        //props.loadingHandler2(false)


      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (Standard) Payments BY TYPE +  by Date------------------------------------
  const getPaymentsByTypeByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {

    //console.log("getPaymentsByTypeByDate ............... in the Payments IN component called !!!!")

    setIsLoading3(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    //const selectedDatesTo = "2022-03-31";

    //select the correct URL for Bank Date or Recorded Date 
    // let paymentsUrl_3 = "payments/paymentsbytypebydate/";
    // showBank
    //   ? (paymentsUrl_3 = "payments/paymentsbytypebydate/")
    //   : (paymentsUrl_3 = "payments/paymentsbytypebydate/");  //to be changed by new url once bankend is updated....

    axios
      .get(
        variables.API_URL +
          "payments/paymentsbytypebydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo +
          "/" +
          showBank,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by TYPE and By Date ...",
        //   res.data.paymentsByTypeByDate
        // );
        setPaymentsByTypeByDateList(res.data.paymentsByTypeByDate);

        // if (res.data.paymentsByTypeByDate.length === 0) {
        //   setTotalPaymentsUnallocatedByDate(0);
        // } else {
        //   const totalUnallocatedPayments =
        //     res.data.paymentsByDateUnallocated.slice(-1);
        //   setTotalPaymentsUnallocatedByDate(
        //     totalUnallocatedPayments[0].cumulative_sum
        //   );
        // }
        setIsLoading3(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All (Unallocated) Payments BY TYPE +  by Date------------------------------------
  const getPaymentsUnallocatedByTypeByDate = (
    selectedDatesFromInitial,
    selectedDatesTo
  ) => {

    //console.log("getPaymentsUnallocatedByTypeByDate ............... in the Payments IN component called !!!!")

    setIsLoading4(true);
    const selectedDatesFrom = selectedDatesFromInitial;
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    //const selectedDatesTo = "2022-03-31";

    //select the correct URL for Bank Date or Recorded Date 
    let paymentsUrl_4 = "payments/paymentsbytypebydateunallocated/";
    showBank
      ? (paymentsUrl_4 = "payments/paymentsbytypebydateunallocated/")
      : (paymentsUrl_4 = "payments/paymentsbytypebydateunallocated/");  //to be changed by new url once bankend is updated....

    axios
      .get(
        variables.API_URL +
          // "payments/paymentsbytypebydateunallocated/" +
          paymentsUrl_4 +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "From Report ....all Payments by TYPE and By Date ...",
        //   res.data.paymentsByTypeByDateUnallocated
        // );
        setPaymentsUnallocatedByTypeByDateList(
          res.data.paymentsByTypeByDateUnallocated
        );

        // if (res.data.paymentsByTypeByDate.length === 0) {
        //   setTotalPaymentsUnallocatedByDate(0);
        // } else {
        //   const totalUnallocatedPayments =
        //     res.data.paymentsByDateUnallocated.slice(-1);
        //   setTotalPaymentsUnallocatedByDate(
        //     totalUnallocatedPayments[0].cumulative_sum
        //   );
        // }
        setIsLoading4(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const changeDatesHandler = () => {
  //   setChangeDates(!changeDates);
  // };

  // useEffect(() => {
  //   if (selectedDatesFromInitial) {
  //     getPaymentsByDate();
  //     getPaymentsUnallocatedByDate();
  //   }
  // }, []);

  // useEffect(() => {
  //   setChangeDates(false);
  // }, []);

  useEffect(() => {
    //console.log("first time switch...", changeDates);

    if (selectedDatesFromInitial) {
      //console.log("inside the use Effect....", selectedDatesFromInitial);

      // if (!changeDates) {
        // if(props.isStarting === false){
        // }

        // if(props.isStarting === false){
        // } 
        
        // if(totalStandardPaymentsByDate.length === 0){} 
          
          getPaymentsByDate(selectedDatesFromInitial, selectedDatesTo);
          
          
          getPaymentsUnallocatedByDate(selectedDatesFromInitial, selectedDatesTo);
          getPaymentsByTypeByDate(selectedDatesFromInitial, selectedDatesTo);
          getPaymentsUnallocatedByTypeByDate(
            selectedDatesFromInitial,
            selectedDatesTo
        );
        //console.log("is true...!!!");
      // } else {
      //   //console.log("is false...!!!");
      //   const myDateFrom = new Date().getFullYear() + "-01-01";
      //   getPaymentsByDate(myDateFrom, selectedDatesTo);
      //   getPaymentsUnallocatedByDate(myDateFrom, selectedDatesTo);
      //   getPaymentsByTypeByDate(myDateFrom, selectedDatesTo);
      //   getPaymentsUnallocatedByTypeByDate(myDateFrom, selectedDatesTo);
      //   // getPaymentsByDate("2022-01-01");
      //   //getPaymentsUnallocatedByDate("2022-01-01");
      // }
    }

    //cleanUp function
    return () => {
      setPaymentsByDateList([]);
      setTotalStandardPaymentsByDate([]);
      

    };

  // }, [changeDates]);
    }, [showBank, updatePayment,refresh,autoRefresh]);


  //console.log("TheFinal Total payments in figure...",totalStandardPaymentsByDate)


  //Socket logic------------------------------------------------------------------------
  useEffect(() => {
    //setSocket(io('http://localhost:5002'));
    setSocket(mySocket);
    //console.log("connecting...");
  }, [mySocket]);

  //listen notifications
  //Listening to Notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      if(data.type.notificationType===1){

        console.log("this is a payment",data)
        setAutoRefresh(Math.random())
      }
      // else if(data.type.notificationType===4){

      //   console.log("this is a payment to supplier update...",data)

      // }
  
    });
  }, [socket]);
  //END Socket Logic--------------------------------------------------------------------


  return (
    <Fragment>
      
      {/* {!props.isLoading2?  */}
      {!isLoading1 && !isLoading2 && !isLoading3 && !isLoading4 ? 

      <Deposits
        title="Payments In"
        selectedDatesFromInitial = {selectedDatesFromInitial}
        selectedDatesTo  = {selectedDatesTo }
        paymentsInByDateList = {paymentsByDateList}
        paymentsUnallocatedByDateList = {paymentsUnallocatedByDateList}
        totalPaymentsByDate={
          totalStandardPaymentsByDate + totalPaymentsUnallocatedByDate
        }
        totalStandardPaymentsByDate={totalStandardPaymentsByDate}
        totalPaymentsUnallocatedByDate={totalPaymentsUnallocatedByDate}
        paymentsByTypeByDateList={paymentsByTypeByDateList}
        paymentsUnallocatedByTypeByDateList={
          paymentsUnallocatedByTypeByDateList
        }
        //changeDates={changeDates}
        changeDates=""
        changeDatesHandler=""
        showDetails={showDetails}
        //changeDatesHandler={changeDatesHandler}
        showDetailsHandler={showDetailsHandler}
        showBankHandler={showBankHandler}
        MainContentReport={MainContentReport}

        icon={
          <CreditScoreIcon
            sx={{
              fontSize: 20,
              color: "#4E1721",
              marginRight: 2,
              marginTop: -1,
            }}
          />
        }
        showBank={showBank}
      />


      : 
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15}  />
        </Box>
      }





    </Fragment>
  );





};

//export default React.memo(PaymentsIn);
export default PaymentsIn

