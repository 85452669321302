// import { IKContext, IKImage, IKUpload } from 'imagekitio-react';

// function UploadImageKit() {
//   const publicKey = "public_LDw4I+b4mHHWv1lcoaoBhpPlM6Q=";
//   let urlEndpoint = "https://ik.imagekit.io/bwcdq46tkc8";
//   const authenticationEndpoint = "http://localhost:8000/auth";

//   return (
//     <div className="App">
//       <IKContext publicKey={publicKey} urlEndpoint={urlEndpoint} authenticationEndpoint={authenticationEndpoint} >
//         <IKUpload fileName="abc.jpg" tags={["tag1"]} useUniqueFileName={true} isPrivateFile= {false} />
//       </IKContext>
//     </div>
//   );
// }

// export default UploadImageKit;
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
//import Button from "@mui/material/Button";

import { IKContext, IKImage, IKUpload } from "imagekitio-react";

import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

const publicKey = "public_LDw4I+b4mHHWv1lcoaoBhpPlM6Q=";
let urlEndpoint = "https://ik.imagekit.io/bwcdq46tkc8";
const authenticationEndpoint = "http://localhost:8000/auth";

// const onError = (err) => {
//   console.log("Error", err);
// };

// const onSuccess = (res) => {
//   console.log("Success", res);
//   setUserPicURL(res.url)
// };

function UploadImageKit(props) {
  const [preview, setPreview] = useState(null);
  const [userPicURL, setUserPicURL] = useState(null);

  const { picRef1, picRef2, userImage, handleImageChange, handleUserChange } = props;

  const onError = (err) => {
    console.log("Error", err);
  };

  const onSuccess = (res) => {
    console.log("Success", res);
    console.log("Success url...", res.url);
    setUserPicURL(res.url);
  };

  console.log(
    "from the UploadButton for IMAGEKIT folder .....yessssssssssss ...............",
    props
  );
  console.log(
    "from the UploadButton for IMAGEKIT PicRef1 .....yessssssssssss ...............",
    picRef1
  );

  useEffect(() => {
    if (userImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(userImage);
    } else {
      setPreview(null);
    }
  }, [userImage]);

  return (
    <div className="App">
      <IKContext
        publicKey="public_LDw4I+b4mHHWv1lcoaoBhpPlM6Q="
        urlEndpoint="https://ik.imagekit.io/bwcdq46tkc8"
        transformationPosition="path"
        authenticationEndpoint="http://localhost:8000/auth"
        //ref={picRef2}
      >
        <IKUpload
          id="userPicUrl"
          ref={picRef2}
          style={{ display: "none" }}
          name= "userPicUrl"
          useUniqueFileName={false}
          //fileName="userPic.png"
          fileName={picRef1}
          folder= 'LudisGroup/users'
          onError={onError}
          //onSuccess={onSuccess}
          onSuccess={
            //(res)=>console.log("myresponseinsuccess....",res,`${res.url}?${Date.now()}`,picRef2.current.props.name)
            (res, event) =>
              handleUserChange(event, `${res.url}?${Date.now()}`, picRef2.current.props.name)
          }
          onChange={(event) => handleImageChange(event.target.files[0])}
        />
      </IKContext>

      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="icon-button-file">
          {/* <Input ref={picRef2} accept="image/*" id="icon-button-file" type="file" onChange={(event)=>handleImageChange(event.target.files[0])} /> */}
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={(event) => {
              event.preventDefault();
              document.getElementById("userPicUrl").click();
            }}
          >
            <PhotoCamera />
          </IconButton>
        </label>

        <Avatar
          alt="user pic"
          src={preview}
          sx={{ width: "5vw", height: "5vw", cursor: "pointer" }}
          onClick={(event) => {
            event.preventDefault();
            document.getElementById("userPicUrl").click();
          }}
        />
      </Stack>
    </div>
  );
}

export default UploadImageKit;
