import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import Title from "../Title";
import theme from "../UI/Theme";

//MUI IMPORTS---------------------------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";


//Toottip Customized-----------
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log("payload...", payload[0].payload)
    return (
      <div className="custom-tooltip">
        <p
          style={{ color: `${theme.palette.common.ludisMain}` }}
          className="label"
        >{`${label} - ${historicYears[4].myYear} : £ ${payload[0].payload.line1.toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
        <p
          style={{ color: `${theme.palette.primary.main}` }}
          className="label"
        >{`${label} - ${historicYears[5].myYear} : £ ${payload[0].payload.line2.toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
        <p
          style={{ color: `${theme.palette.common.GraphLine1}` }}
          className="label"
        >{`${label} - ${historicYears[6].myYear} : £ ${payload[0].payload.line3.toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
        <p
          style={{ color: `${theme.palette.common.GraphLine2}` }}
          className="label"
        >{`${label} - ${historicYears[7].myYear} : £ ${payload[0].payload.line4.toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
        <p
          style={{ color: `${theme.palette.common.GraphLine3}` }}
          className="label"
        >{`${label} - ${historicYears[8].myYear} : £ ${payload[0].payload.line5.toLocaleString("en-US", {minimumFractionDigits: 2,})}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

// Generate Sales Data
function createData(time, line1, line2, line3, line4, line5) {
  return { time, line1, line2, line3, line4, line5 };
}

// const data = [
//   createData("00:00", 0),
//   createData("03:00", 300),
//   createData("06:00", 600),
//   createData("09:00", 800),
//   createData("12:00", 1500),
//   createData("15:00", 2000),
//   createData("18:00", 2400),
//   createData("21:00", 2400),
//   createData("24:00", undefined),
// ];

const months = [
  "Jan",
  "Febr",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const historicYears = [
    { id: 1, myYear: "2014" },
    { id: 2, myYear: "2015" },
    { id: 3, myYear: "2016" },
    { id: 4, myYear: "2017" },
    { id: 5, myYear: "2018" },
    { id: 6, myYear: "2019" },
    { id: 7, myYear: "2020" },
    { id: 8, myYear: "2021" },
    { id: 9, myYear: "2022" },

  ];

export default function GeneralChartMultiple(props) {

  
  
  const theme = useTheme();
  let totalSalesAcc = null;
  let graphData = null;
  const {
    salesByDateList,
    //salesByConsultantList,
    //salesByConsultantByDate,
    salesGraphData,
    salesHistoric1,
    salesHistoric2,
    salesHistoric3,
    //SalesByYearListAll,
    //changeGraphData,
  } = props;
  
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  //console.log('LAST COMPONENT....checkingggggggggggggggggggggggggggggggggggg...............',salesGraphData)
  // console.log('checkingggggggggggggggggggggggggggggggggggg...............',props)
  
  //console.log("from the chart last one... aquiiiiiiiiiiiiiiiiiiiiiiiiiiiii!!!!!!!!!!!!!!!!!!!!!!!!!", props);
  //console.log("dates in Chart component example....",new Date(salesByConsultantByDate[3].DATE).getDate())
  
  if (salesGraphData.length > 0) {
    //console.log("from the chart after if....", salesByConsultantByDate);
    //console.log("from the chart after if....", salesGraphData);

    //console.log("from the chart after if BEFORE....", salesByDateList);

    graphData = salesGraphData.map((item, i) => {
      //let x = i;

      let graphDate = "";

    //   graphDate = new Date(item.DATE).toLocaleString("default", {
    //     day: "numeric",
    //     month: "short",
    //   });
      graphDate = item.month;

      // if (changeGraphData) {
      //   graphDate = months[item.DATE];
      // } else {
      //   graphDate = new Date(item.DATE).toLocaleString("default", {
      //     day: "numeric",
      //     month: "short",
      //   });
      // }

      //totalSalesAcc = totalSalesAcc + item.amountNet;

      //for original chart--------------
      // let x = graphDate;
      // let y = item.amountNet;
      // let y2 = totalSalesAcc;

      //for accumulative chart--------------
      let x = graphDate;
      let y =  item.y5;
      let y2 = item.y6;
      let y3 = item.y7;
      let y4 = item.y8;
      let y5 = item.y9;


      //console.log("test info...",y3)


      return createData(x, y, y2, y3, y4, y5);
    });
    //console.log("the graph data ... from chart component...", graphData);
  }

  //console.log("from the chart last one... aquiiiiiiiiiiiiiiiiiiiiiiiiiiiii!!!!!!!!!!!!!!!!!!!!!!!!!", graphData);

  return (
    <>
      {/* {salesByConsultantList.length > 0 ? ( */}

      {salesGraphData.length > 0  ? (
        <Fragment>
          {/* {changeGraphData ? (
            <Title>Sales By Month</Title>
          ) : (
            <Title>Sales By Day</Title>
          )} */}

          <Title>Historical Yearly Sales by Month</Title>

          <ResponsiveContainer height={300}>
            <LineChart
              //data={data}
              data={graphData}
              margin={{
                top: 16,
                //right: 16,
                right: matches3?0:16,
                bottom: 0,
                //left: 24,
                left: matches3?0:24,
              }}
            >
              <XAxis
                dataKey="time"
                stroke={theme.palette.text.secondary}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
                //style={theme.typography.body2}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
                //style={theme.typography.body2}
              >

                

                <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: "middle",
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                  }}
                  >
                  {matches3? "": "Sales ($)"}
                </Label>
                

              </YAxis>

              <Legend verticalAlign="bottom" height={36} wrapperStyle={{fontSize: matches3?'0.5rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}  />

              <Tooltip
                cursor={{ fill: "#e7dddd" }}
                content={<CustomTooltip />}
                wrapperStyle={{
                  width: "auto",
                  backgroundColor: "#e7dddd",
                  borderRadius: 3,
                  fontSize: "0.8rem",
                  padding: 5,
                }}
              />
              {/* <Legend
                width={100}
                wrapperStyle={{
                  top: 30,
                  right: 20,
                  backgroundColor: "#e7dddd",
                  border: "1px solid #d5d5d5",
                  borderRadius: 3,
                  lineHeight: "40px",
                }}
              /> */}
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

              <Line
                //isAnimationActive={false}
                name={historicYears[4].myYear}
                type="monotone"
                dataKey="line1"
                stroke="#4E1721"
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                name={historicYears[5].myYear}
                type="monotone"
                dataKey="line2"
                //stroke="#82ca9d"
                stroke={theme.palette.primary.main}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                name={historicYears[6].myYear}
                type="monotone"
                dataKey="line3"
                //stroke="#155C2E"
                stroke={theme.palette.common.GraphLine1}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                name={historicYears[7].myYear}
                type="monotone"
                dataKey="line4"
                //stroke="#155C2E"
                stroke={theme.palette.common.GraphLine2}
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                name={historicYears[8].myYear}
                type="monotone"
                dataKey="line5"
                //stroke="#155C2E"
                stroke={theme.palette.common.GraphLine3}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Fragment>
      ) : (
        "loading"
      )}
    </>
  );
}
