import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
//import { useDispatch } from "react-redux";
//import { paymentInActions } from "../../store/payment-slice";
import { comunicationInfoActions } from "../../../store/comunication-slice";



// MUI IMPORTS---------------------------------------------------

import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "@mui/material";
import TextField from '@mui/material/TextField';

import frLocale from "date-fns/locale/fr";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import theme from "../../UI/Theme";
import { MyTextField, MyTextField2 } from "../../UI/styledComponents/StyledComponents";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const localeMap = {
  en: enLocale,
  fr: frLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  de: "__.__.____",
};

const CreateEmailStep_1 = (props) => {
  const dispatch = useDispatch();
  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  //console.log("here is the selected order...",selectedOrder)
  

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(new Date());

  const [enteredEmailHeadersInput, setEnteredEmailHeadersInput] = useState(null);
  const [enteredEmailHeadersInputName, setEnteredEmailHeadersInputName] = useState(null);
  const [enteredMessageInput, setEnteredMessageInput] = useState(null);
  const [enteredMessageInputName, setEnteredMessageInputName] = useState(null);

  //default Email for Create Email option------------------------------------------------ 
  let defaultEmailMessage =
  "\n" + 
  "Dear, " + selectedOrder[0].MAIN_CONTACT + "\n" + "\n" + 
  "Thank you for your time today. "+ "\n" + "\n" + 
  "Please see your invoice attached with payment details included. " + "\n" +  "\n" +
  "Kind Regards"

  let defaultEmailSubject= selectedOrder[0].CLUB_CODE+" - "+selectedOrder[0].CONFIRMATION_REFERENCE+" - "+selectedOrder[0].COMPANY_NAME
  
  let defaultEmail= selectedOrder[0].ClientEmail

 
  //END default Email for Create Email option------------------------------------------------ 


  //const { paymentTypeList } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = "de";

 


  //--------------------------------------------------------datemanager---------
//   const handleDatesChangesFromTo = (date) => {
//     const dateIsValid = date !== null && date !== "Invalid Date";

//     //console.log("choosen date from Add Signed P.plan component....", date);

//     setSelectedPaymentDate(date);

//     if (dateIsValid) {
//       //Add the Selected Order to the Payments In information
//       dispatch(
//         comunicationInfoActions.updateComunicationCreateEmail({
//           paymentPlanSignedDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
//         })
//       );

//     } else {
//       console.log("error, date not usable only 2 options available");
//       return;
//     }
//   };
  //-------------------------------------------------------END datemanager------


    const handleInputChangeEmailHeaders = (value,name) => {

    setEnteredEmailHeadersInput(value);
    setEnteredEmailHeadersInputName(name);

    
    };

  const handleInputChangeMessage = (value,name) => {

    setEnteredMessageInput(value);
    setEnteredMessageInputName(name);

  };


    //Debouncer..............

    useEffect(() => {
        const identifier = setTimeout(() => {

          dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({  
              [enteredEmailHeadersInputName]: enteredEmailHeadersInput,
              [enteredMessageInputName]: enteredMessageInput,
          })
        );

      
          // if(enteredMessageInput){
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //           [enteredMessageInputName]: enteredMessageInput, // to upgraded in a later stage...
          //           // comunicationMessage: enteredMessageInput,
          //           // comunicationEmail : enteredEmailInput,
          //           // comunicationEmailCC: "",
          //           // comunicationEmailSubject: "",
          //     })
          //   );  
          // }else{
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({  
          //       comunicationMessage: defaultEmailMessage,
          //       //comunicationEmail : enteredEmailInput,
          //     })
          //   );
            
          // }
            
        }, 500);
      
        return () => {
          // console.log("CleanUp");
          clearTimeout(identifier);
        };
      }, [enteredEmailHeadersInput, enteredEmailHeadersInputName, enteredMessageInput, enteredMessageInputName]);
    
    //Debouncer End...............................................................

    //Setting initial variables i.e email address and generic messsage..............
    useEffect(() => {

        dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({
                selectedOrder: selectedOrder,
            })
          );

          dispatch(
            comunicationInfoActions.updateComunicationCreateEmail({
            comunicationMessage: defaultEmailMessage,
            comunicationEmail : defaultEmail,
            comunicationEmailCC : "",
            comunicationEmailSubject : defaultEmailSubject,
            comunicationEmailDate: new Date().toISOString(),
          })
        );   

          // if(enteredMessageInput){
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //           comunicationMessage: enteredMessageInput,
          //           //comunicationEmail : enteredEmailInput,
          //     })
          //   );  
          // }else{
          //   dispatch(
          //       comunicationInfoActions.updateComunicationCreateEmail({
          //       comunicationMessage: defaultEmailMessage,
          //       comunicationEmail : defaultEmail,
          //       comunicationEmailCC : "",
          //       comunicationEmailSubject : defaultEmailSubject,

          //     })
          //   ); 
          // }
    
      }, []);
    
      //Inicial Variables set End..................................................      

  



  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={localeMap[locale]}
    >
      <Fragment>
        
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >



        <Box
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            '& .MuiTextField-root': { 
              m: 1, 
              width: '20vw',
              [theme.breakpoints.down("lg")]: {
                width: '42vw',
              }, 
              [theme.breakpoints.down("md")]: {
                width: '50vw',
              }, 
            },
          }}
          
        >
          

          {/* Email Address */}
        <MyTextField2
          name="comunicationEmail"
          id="outlined-search"
          label="email"
          type="search"
          defaultValue={defaultEmail}
          // sx={
          //   matches
          //     ? { width: "50vw", margin: "0.2em" }
          //     : { width: "40vw", margin: "auto" }
          // }
          onChange={(event) =>handleInputChangeEmailHeaders(event.target.value, event.target.name)}
          
        />

        <MyTextField2
          name="comunicationEmailCC"
          id="outlined-search"
          label="CCemail"
          type="search"
          // sx={
          //   matches
          //     ? { width: "50vw", margin: "0.2em" }
          //     : { width: "40vw", margin: "auto" }
          // }
          onChange={(event) =>handleInputChangeEmailHeaders(event.target.value, event.target.name)}
          
        />

        <MyTextField2 
          name="comunicationEmailSubject"
          id="outlined-search"
          label="Subject"
          type="search"
          defaultValue={defaultEmailSubject}
          // sx={
          //   matches
          //     ? { width: "50vw", margin: "0.2em" }
          //     : { width: "40vw", margin: "auto" }
          // }
          onChange={(event) =>handleInputChangeEmailHeaders(event.target.value, event.target.name)}
          
        />

        </Box>

        

        <Divider />

        <Box
        component="form"
          sx={{
            '& .MuiTextField-root': { 
              m: 1, 
              width: '24vw',
              [theme.breakpoints.down("lg")]: {
                width: '48vw',
              }, 
              [theme.breakpoints.down("md")]: {
                width: '50vw',
              }, 
            },
          }}
          noValidate
          autoComplete="off"
        >  
        
        <MyTextField2
          id="filled-multiline-static"
          name="comunicationMessage"
          label="Type your email here...."
          multiline
          rows={10}
          defaultValue={defaultEmailMessage}
          variant="filled"
          onChange={(event) =>handleInputChangeMessage(event.target.value, event.target.name)}
        />
        
        </Box>


        

</Box>

       
      </Fragment>
    </LocalizationProvider>
  );
};

export default CreateEmailStep_1;
