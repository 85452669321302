import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import { paymentPlanActions } from "../../store/paymentPlan-slice";
import { invoicingActions } from "../../store/invoicing-slice";

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";

//My IMPORTS----------------
import theme from "../UI/Theme";
import Title from "../Title";
import { variables } from "../../Variables";

//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const InvoicingStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const currentUserInfo = useSelector((state) => state.user);
  //const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  //const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);
  const currentInvoiceInfo = useSelector((state) => state.invoicing.invoicingInfo);

  //const [newPayPlanSignedStatus, setNewPayPlanSignedStatus] = useState({});
  const [newInvoiceBookedStatus, setNewInvoiceBookedStatus] = useState({});


  const { 
    socket, 
    userInfo, 
    showBookInvoiceModalHandler, 
    isNewInvoice, 
    isLoading_BookInvoiceHandler, 
    isLoading_BookInvoice,
    invoiceNumber,
    } = props;

  const matches = useMediaQuery(theme.breakpoints.down("sm"));


  //Mongo info----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"

  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
        if(res.status === 201){
            
            //getPost()
        }
    })
 }

 

  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");

    socket.emit("sendNotification", {
      //senderName: userInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails: {
        "ref": info.notificationDetails.ref, 
        "confoId": currentInvoiceInfo.selectedOrder[0].CONFIRMATION_ID,
        "preConfoId": currentInvoiceInfo.selectedOrder[0].PRECONFIRMATION_ID,
        "company":info.notificationDetails.company,
        "clientId": currentInvoiceInfo.selectedOrder[0].CLIENT_ID,
      }
    })


    //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: userInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails: {
        "ref": info.notificationDetails.ref, 
        "confoId": currentInvoiceInfo.selectedOrder[0].CONFIRMATION_ID,
        "preConfoId": currentInvoiceInfo.selectedOrder[0].PRECONFIRMATION_ID,
        "company":info.notificationDetails.company,
        "clientId": currentInvoiceInfo.selectedOrder[0].CLIENT_ID,
    },
      // receiverName: "All",
    }
    createPost(noty)




  };


  //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  const resetPaymentPlanInfo = ()=>{
    //dispatch(paymentPlanActions.resetPaymentPlan({paymentPlanSignedDate: new Date().toISOString(),}))
    dispatch(
        invoicingActions.resetInvoice({
          invoiceBookDate: new Date().toISOString(),
        })
      );
    
   }
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

   


  //Function to create (post) the New Payment in the DB ----------
  const bookNewInvoice =  () => {

    setIsLoading(true)
    //isLoading1_PayplanOnOrderHandler(true)
    isLoading_BookInvoiceHandler(true)


    //alert("the status loading on payplan starting - III..." +  isLoading1_PayPlanOnOrder)


    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };

    let data = {}

    if(invoiceNumber===1){
      
      data = {
          CONFIRMATION_INV1_REFERENCE: currentInvoiceInfo.selectedOrder[0].CLUB_CODE + " " +currentInvoiceInfo.selectedOrder[0].CONFIRMATION_REFERENCE+"-"+invoiceNumber,
          PRECONFIRMATION_ID: currentInvoiceInfo.selectedOrder[0].PRECONFIRMATION_ID,
          CONFIRMATION_INV1_DATE:currentInvoiceInfo.invoiceBookDate,
          NET_AMOUNT:(currentInvoiceInfo.selectedOrder[0].NET_AMOUNT)/2,
          VAT_AMOUNT:(currentInvoiceInfo.selectedOrder[0].VAT_AMOUNT)/2,
          M_USER:currentUserInfo.userId,
          TIME: new Date(),
  
      };
    
    } else {

      data = {
        CONFIRMATION_INV2_REFERENCE: currentInvoiceInfo.selectedOrder[0].CLUB_CODE + " " +currentInvoiceInfo.selectedOrder[0].CONFIRMATION_REFERENCE+"-"+invoiceNumber,
        PRECONFIRMATION_ID: currentInvoiceInfo.selectedOrder[0].PRECONFIRMATION_ID,
        CONFIRMATION_INV2_DATE:currentInvoiceInfo.invoiceBookDate,
        NET_AMOUNT:(currentInvoiceInfo.selectedOrder[0].NET_AMOUNT)/2,
        VAT_AMOUNT:(currentInvoiceInfo.selectedOrder[0].VAT_AMOUNT)/2,
        M_USER:currentUserInfo.userId,
        TIME: new Date(),

    };

    }



    //console.log("this is the data for the request on new pay plan signed....",data)

    //Create (post) a New Payment ------------------------------
    axios
      .post(variables.API_URL + "orders/newinvoice/" + invoiceNumber , data, config)

      .then((response) => {
        console.log("result of post on invoice book sys...",response)
        setNewInvoiceBookedStatus({
          message: " New Invoice booked Successfully",
          status: true,
        });

        //Submit the notification to the socket  - notificationType = 3 for Booking New Invoice..............
        submitNotification({
          //type: 1,
          type: {generalType:1, notificationType:3},
          name: "Invoice Booked",
          notificationDate: new Date().toLocaleString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }),
          notificationTime: new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: '2-digit',
          }),
          notificationDetails: {
              "ref":currentInvoiceInfo.selectedOrder[0].CLUB_CODE + " " +currentInvoiceInfo.selectedOrder[0].CONFIRMATION_REFERENCE+"-"+invoiceNumber , 
              "confoId": currentInvoiceInfo.selectedOrder[0].CONFIRMATION_ID,
              "preConfoId": currentInvoiceInfo.selectedOrder[0].PRECONFIRMATION_ID,
              "company":currentInvoiceInfo.selectedOrder[0].COMPANY_NAME,
              "clientId": currentInvoiceInfo.selectedOrder[0].CLIENT_ID,
            },
        });

        //send the invoiceID to the parent component to refresh the list of invoices including the new one-----
        isNewInvoice(Math.random()) 

        isLoading_BookInvoiceHandler(false)

        //Reset Payment In Information----------
        resetPaymentPlanInfo()



        

        setTimeout(() => {
          //setNotification(null);
          const showAddPaymentModalHandler_local = (event)=>showBookInvoiceModalHandler(event,1);
          showAddPaymentModalHandler_local()
          //console.log("This is the end message before closing.....")
      }, 1500);

        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewInvoiceBookedStatus({
          message: "  Something went Wrong ! New Invoice NOT saved",
          status: false,
        });

        isLoading_BookInvoiceHandler(false)

       
      });

      setIsLoading(false)
      //isLoading1_PayplanOnOrderHandler(false)


  };
  // end of create the function to create (post) the New User and save It in the DB ----------



  //Step I Creating New User as consultant
  useEffect(() => {
    bookNewInvoice();
  }, []);

  


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 
      
      Object.keys(newInvoiceBookedStatus).length !== 0 ?
    
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >




      {
        newInvoiceBookedStatus.status ? ( 
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            
            <Title sx={{ margin: "auto" }}>
              NEW INVOICE BOOKED SUCCESSFULLY ! 
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              NEW INVOICE NOT SAVED, CONTACT YOUR ADMIN TEAM ! 
            </Title>
          </Box>
        )}




          <ListItem>
            {newInvoiceBookedStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}


            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
              <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Payment Status:"
              secondary={
                newInvoiceBookedStatus.message ? newInvoiceBookedStatus.message : "No Info..."
              }
            />
          </ListItem>
        </Box>

        :

        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>




      }
    </Fragment>
  );
};

export default InvoicingStep_3;









