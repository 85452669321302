import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name:'orders',
    initialState:{
        ordersList:[],
        selectedOrder:[],
        //selectedOrderTotalPaid:0,
        showOrder:false,
    },
    reducers:{

        addFullOrdersList(state,action){
            const fullOrdersList = action.payload;
            state.ordersList = fullOrdersList;
        },

        selectOrder(state, action){

            if(state.ordersList.length > 0){

                const selectedOrder = state.ordersList.filter((item)=>item.CONFIRMATION_REFERENCE.toString() === action.payload.selectedOrderRef);
                state.selectedOrder = selectedOrder;
            } else{

                const selectedOrder=[];
                state.selectedOrder = selectedOrder;
                
            }
        },
   

        showOrder(state){
            if(state.selectedOrder.length > 0 ){
                state.showOrder = true;
            }else{
                state.showOrder = false;
            }
        },

        resetSelectedOrderInfo: (state, action) => {
      
            state.selectedOrder = [];
            

          },



    }
})

export const orderActions = orderSlice.actions;

export default orderSlice;