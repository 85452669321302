import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { Divider } from "@mui/material";
//import DraftsIcon from "@mui/icons-material/Drafts";

import Title from "../Title";

export default function DashboardContainer(props) {
  const { reportTitle, myMarginTop, icon, myWidth, myMaxWidth, matches,matches2,matches3, myHeight } = props;

  //console.log("my propsssssssssssssssssssss siiiiiiiiiiiiiiiiii...", props)
  


  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: (theme) =>
        //   theme.palette.mode === "light"
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[900],
        //backgroundColor:"#000",
        backgroundImage:
          "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
        flexGrow: 1,
        //height: "98vh",
        overflow: "auto",
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'flex-start',
        height: myHeight ||"95vh",
        
      }}
    >
      {/* <Toolbar /> */}
      {/* <Grid item xs={12} md={myWidth} lg={myWidth}> */}

        {/* <Container maxWidth="lg" sx={{ mt: myMarginTop, mb: 4 }}> */}
        {/* <Container maxWidth="xlg" sx={{ mt: 4, mb: 4, width:  "95vw"}}>   */}

          {/* <Grid item xs={12} md={12} lg={12}> */}
            <Paper
              sx={{
                p: 2,
                mt: myMarginTop ||4,
                display: "flex",
                flexDirection: "column",
                //alignItems:matches3?"center":"",
                //height: 800,
                height: "auto",
                //  backgroundImage:
                //    "url(https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/Ludisbackground-2021_F9WodH70V.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1648720587132)",
                //backgroundColor:"#ccc",  
                //width: matches3? "75vw" : matches2? myWidth||"70vw" : "55vw",
                width: matches3 ? myWidth||"75vw" : matches2 ? "70vw" : matches ? "60vw" : myMaxWidth||"50vw",
                //margin:"auto"
                marginBottom:5
                
                
              }}
            >
              {reportTitle && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    //backgroundColor:"#000"
                  }}
                >
                  {/* <DraftsIcon
                //baseClassName="fas"
                //className="fa-plus-circle"
                sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
              /> */}

                  {/* {props.icon}  */}

                  {icon}
                  <Title>{reportTitle}</Title>
                </Box>
              )}

              <Divider />

              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  //backgroundColor:"#000",
                  //height:"auto"
                }}
              >
                {props.children}
              </Box>
            </Paper>
          {/* </Grid> */}
        {/* </Container> */}
      {/* </Grid> */}
    </Box>
  );
}
