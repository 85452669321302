import {Fragment, useState} from 'react';
import { MyTable, MyTableRow, StyledTableCell, MyTableCell } from "../UI/styledComponents/StyledComponents";

import theme from "../UI/Theme";
import Title from "../Title";
import {TablePaginationActions} from "../UI/Pagination";

//MUI IMPORTS-----------------------------------------------------------------
import TableBody from "@mui/material/TableBody";
//import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
//import { current } from "@reduxjs/toolkit";
import Typography from "@mui/material/Typography";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { Divider, Table } from "@mui/material";
//import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
//import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
//import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
//import IconButton from '@mui/material/IconButton';
// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// import LastPageIcon from '@mui/icons-material/LastPage';
// import PropTypes from 'prop-types';
// import { useTheme } from '@mui/material/styles';


// //Table Pagination Code External Function ----------------------------------------------------------
// function TablePaginationActions(props) {
//   const theme = useTheme();
//   const { count, page, rowsPerPage, onPageChange } = props;

//   //const matches = useMediaQuery(theme.breakpoints.down("lg"));
//   //const matches2 = useMediaQuery(theme.breakpoints.down("md"));
//   const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleFirstPageButtonClick = (event) => {
//     event.preventDefault()
//     onPageChange(event, 0);
//   };

//   const handleBackButtonClick = (event) => {
//     event.preventDefault()
//     onPageChange(event, page - 1);
//   };

//   const handleNextButtonClick = (event) => {
//     event.preventDefault()
//     onPageChange(event, page + 1);
//   };

//   const handleLastPageButtonClick = (event) => {
//     event.preventDefault()
//     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   return (
//     <Box sx={{ flexShrink: 0, ml: 2.5 }}>
//       <IconButton
//         onClick={handleFirstPageButtonClick}
//         disabled={page === 0}
//         aria-label="first page"
        
//       >
//         {theme.direction === 'rtl' ? 
//         <LastPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
//         <FirstPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
//       </IconButton>
//       <IconButton
//         onClick={handleBackButtonClick}
//         disabled={page === 0}
//         aria-label="previous page"
//       >
//         {theme.direction === 'rtl' ? 
//         <KeyboardArrowRight sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
//         <KeyboardArrowLeft sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
//       </IconButton>
//       <IconButton
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === 'rtl' ? 
//         <KeyboardArrowLeft sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
//         <KeyboardArrowRight sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
//       </IconButton>
//       <IconButton
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === 'rtl' ? 
//         <FirstPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} /> : 
//         <LastPageIcon sx={{ fontSize:matches3?"0.7rem":"1rem",color:theme.palette.common.ludisLight1}} />}
//       </IconButton>
//     </Box>
//   );
// }

// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
  
// };
// //END Table Pagination Code External Function ------------------------------------------------------


const PaymentsInInfo = (props)=>{

    const {currentPaymentsInInfo} = props;

    //console.log("PAYMENT IN !!!OUR PROPS for payment In info.....", currentPaymentsInInfo)

    //const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    //const matches = useMediaQuery(theme.breakpoints.down("md"));


    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

    const [open1, setOpen1] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    //const repDate= new Date();
    //const repDate1= new Date();

    //Functions for Table Pagination--------------------------------------
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentPaymentsInInfo.length) : 0;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      
    };
    //END Functions for TAble Pagination----------------------------------


    const handleCollapse = (event, item) => {
    event.preventDefault();
    //console.log("el eventoooooo....", event.currentTarget);
    //console.log("el eventoooooote....", item.PURCHASEINVOICE_ID);

    setOpen1((prev) => ({
      ...prev,
      [item.CLIENTPAYMENT_ID]: !prev[item.CLIENTPAYMENT_ID],
    }));

    //test to be deleted------
    //paymentListFiltered(0)

    //setOpen1((prev) => !open1);
  };




  const Paymenticons = [
    <AccountBalanceOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <ReceiptLongOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <CreditCardOffOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PaymentOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <ContactlessOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <AlternateEmailOutlinedIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    <PhonelinkRingIcon
      sx={{ color: "#e7dddd", fontSize: matches3?15:30, marginLeft: 2 }}
    />,
    
  ];


    
    return(
        <Box
            sx={{
              display: "flex",
              //flexDirection: matches ? "column" : "row",
              flexDirection: "column",
              alignItems: "center",
              
            }}
          >
            <Title>Orders (Invoices) Paid ...</Title>

            <Divider sx={{ width: "60vw" }} />

            {/* {purchaseInvBySupplierByDate?.length > 0 ? ( */}
            {currentPaymentsInInfo?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: matches3?"70vw":matches2 ? "60vw" : "60vw", marginTop: 2,  }}
              >
                {/* <Table aria-label="collapsible table"> */}
                <MyTable size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">...</StyledTableCell>
                      <StyledTableCell align="left">Order</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell align="left">
                        Amount&nbsp;(£)
                      </StyledTableCell>
                      <StyledTableCell align={matches?"left":"center"}>Company</StyledTableCell>
                      {!matches3&&<StyledTableCell align="right">P. Type</StyledTableCell>}
                      {/* <StyledTableCell align="right">Action</StyledTableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* {orderInfo1.map((info) => ( */}
                    {/* {purchaseInvBySupplierByDate.map((item) => { */}
                    {
                    (rowsPerPage > 0? currentPaymentsInInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage):
                    currentPaymentsInInfo).map((item) => {

                      //console.log("this is the item inside the map.....",item)

                    //   showPayDate
                    //     ? (repDate = item.pOrder.PURCHASEINVOICE_PAYDAY)
                    //     : (repDate = item.pOrder.PURCHASEINVOICE_DATE);
                    //   showPayDate
                    //     ? (repDate1 = item.pOrder.PURCHASEINVOICE_DATE)
                    //     : (repDate1 = item.pOrder.PURCHASEINVOICE_PAYDAY);
                      return (

                        <Fragment>
                          <MyTableRow key={item.CLIENTPAYMENT_ID}>
                            {/* <TableCell sx={{padding:0}}> */}
                            <MyTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                //onClick={() => setOpen1(!open1)}
                                onClick={(event) => handleCollapse(event, item)}
                                
                                >
                                {open1[item.CLIENTPAYMENT_ID] ? (
                                    <KeyboardArrowUpIcon sx={{fontSize:matches3?"0.6rem":"1rem", padding:0}} />
                                  ) : (
                                    <KeyboardArrowDownIcon sx={{fontSize:matches3?"0.6rem":"1rem",padding:0}} />
                                    )}
                              </IconButton>
                            {/* </TableCell> */}
                            </MyTableCell>  

                            <MyTableCell>
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0 }}>
                                {item.CONFIRMATION_REFERENCE}
                              </Typography>
                            </MyTableCell>

                            <MyTableCell>
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0, }}>
                                {new Date(item.PAYMENTDATE).toLocaleString("default", {
                                  day: "numeric",
                                  month: "short",
                                  //year: "numeric",
                                })}
                              </Typography>
                            </MyTableCell>

                            <MyTableCell>
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0, }}>
                                £{" "}
                                {parseFloat(item.AMOUNTPAID).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            </MyTableCell>
                            
                            <MyTableCell >
                              <Typography
                                // aria-owns={
                                //   open ? "mouse-over-popover" : undefined
                                // }
                                // aria-haspopup="true"
                                // onMouseEnter={(event) =>
                                //   handlePopoverOpen(event, item.CHEQUEREF)
                                // }
                                // onMouseLeave={handlePopoverClose}
                                sx={{ fontSize: matches2 ? "0.7rem" :matches?"0.8rem": "0.85rem", padding:0, }}
                              >
                                {
                                //matches3?item.COMPANY_NAME.substring(0, 10):
                                matches2? item.COMPANY_NAME.substring(0, 10):
                                matches? item.COMPANY_NAME.substring(0, 20):
                                item.COMPANY_NAME.substring(0, 60)}
                              </Typography>
                            </MyTableCell>
                            {!matches3 &&
                            <StyledTableCell align="right">
                              <Typography sx={{ fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem", padding:0, }}>
                              {matches2 ? "" : item.CLIENTPAYMENTTYPE_REF}{" "}
                              {Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}
                              </Typography>

                            </StyledTableCell>
                            }

                            {/* <StyledTableCell align="right">
                              <Button
                                //key = {item.CLIENTPAYMENT_ID}
                                onClick={(event) => {
                                  event.preventDefault();
                                  showModalHandler(event, 1, item);
                                }}
                                sx={{
                                  color: theme.palette.common.ludisMain,
                                  //textTransform: "capitalize",
                                  marginBottom: 2,
                                  marginTop: 2,
                                }}
                              >
                                <ManageAccountsIcon
                                  sx={{
                                    fontSize: 25,
                                    //color: "#4E1721",
                                    marginLeft: 1,
                                  }}
                                />
                              </Button>
                            </StyledTableCell> */}
                          </MyTableRow>

                          {/* {open1[ item.CLIENTPAYMENT_ID] && <DisplayExtraInfo open1={open1}  item={item}/>} */}
                          {open1[item.CLIENTPAYMENT_ID] && (
                            

                              
                              
                              <TableRow>
                                <MyTableCell colSpan={matches3?3:4}>
                                  {"..." + "Co: " + item.COMPANY_NAME.substring(0, 30)}
                                </MyTableCell>
                                <MyTableCell>{Paymenticons[item.CLIENTPAYMENTTYPE_ID - 1]}</MyTableCell>
                                <MyTableCell></MyTableCell>
                                
                                
                                </TableRow>
                              
                              
                              
                              
                            
                              
                            // <DisplayExtraInfo open1={open1} item={item} />
                          )}

                          
                        </Fragment>
                        


                      );
                    })}
                    {emptyRows > 0 && (
                     <TableRow style={{ height: (matches3?20:matches2?30:40) * emptyRows }}>
                       <TableCell colSpan={matches3?5:6} />
                     </TableRow>
                     )}
                  </TableBody>

                  {/* Footer--------------------------------------------------------------- */}
                  <TableFooter sx={{backgroundColor:theme.palette.common.ludisMain, color:theme.palette.common.ludisLight1}}>
                    <TableRow key={Math.random()}>
                      <TablePagination
                        //component="div"
                        
                        rowsPerPageOptions={[15, 30, 60, { label: 'All', value: -1 }]}
                        colSpan={matches3?5:6}
                        count={currentPaymentsInInfo.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native:true,
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        //extras------------------------------------------------
                        labelRowsPerPage={<span>{matches3?"":"rows"} </span>}
                        //labelDisplayedRows={({page})=>{return `Page: ${page}`}}
                        labelDisplayedRows={ matches3? ({page})=>{return `Page: ${page}`} :
                          (from=page) => `${from.from}-${from.to === -1 ? from.count : from.to} of ${from.count}`
                        }
                        //sx={{ color:theme.palette.common.ludisLight1 }}
                        sx={{ ".MuiTablePagination-toolbar":{
                              display:"flex",
                              flexWrap:"wrap",
                              alignItems:"baseline",
                              justifyContent:"center",
                              alignContent:"center",

                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              pt:1,
                              //margin:"auto"
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              width: "auto",
                              ml: matches3?"0":"0",
                              pl: matches3?"0":"1"


                            },
                            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":{
                              //display:"flex",
                              //alignItems:"flex-end",
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.7rem" : matches?"0.8rem": "0.85rem",
                              //margin:"auto"
                              //backgroundColor:"#000"
                            } ,
                            ".MuiTablePagination-selectIcon":{
                              color:theme.palette.common.ludisLight1,
                              fontSize: matches2 ? "0.8rem" : matches?"0.9rem": "1rem",
                              //backgroundColor:"#000"

                            },
                            ".MuiTablePagination-menuItem":{
                              color:theme.palette.common.ludisMain,
                              fontSize:"1rem",
                              
                              //backgroundColor:"#000"
                            }

                      }}
                      />
                    </TableRow>
                  </TableFooter>
                  {/* END Footer----------------------------------------------------------- */}


                </MyTable>
                {/* </Table> */}
              </TableContainer>
            ) : (
              <CreditCardOffIcon
                sx={{ color: "#e7dddd", fontSize: 30, marginTop: 2 }}
              />
            )}
          </Box>
    )
    
    

}

export default PaymentsInInfo;