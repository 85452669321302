import { createSlice } from "@reduxjs/toolkit";

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    supplierInfo: {

      selectedSupplier: {},
      
    },
    supplierAccount:{

      selectedSupplierTotalPurchases:0,
      selectedSupplierTotalPaid:0,

    },
  },
  reducers: {
    updateSupplier: (state, action) => {
      const { payload } = action;

      //state.supplierInfo = { ...state.supplier, ...payload };
      state.supplierInfo = { ...state.supplierInfo, ...payload };


      //console.log("full payload from quote reducer new state...",payload)
    },

    updateSupplierAccount: (state, action) => {
      const { payload } = action;

      state.supplierAccount = { ...state.supplierAccount, ...payload };

      //console.log("full payload from quote reducer new state...",payload)
    },

    resetSupplier: (state, action) => {

      const { payload } = action;

      //console.log("inside the slice for reset payments in....",payload)

      state.supplierInfo = {
        selectedSupplier: {},
      };

      state.supplierAccount={

        selectedSupplierTotalPurchases:0,
        selectedSupplierTotalPaid:0,
  
      };

      
    },
  },
});

export const supplierActions = supplierSlice.actions;

export default supplierSlice;
