import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import theme from "../../UI/Theme";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI IMPORTS----------------------------
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const WagesReportSummary = (props) => {
  //const dispatch = useDispatch();
  //const selectedOrder = useSelector((state) => state.orders.selectedOrder);
//   const currentSupplierSelected = useSelector(
//     (state) => state.supplier.supplierInfo
//   );
//   const currentSupplierSelectedAccount = useSelector(
//     (state) => state.supplier.supplierAccount
//   );
  //   const currentPaymentInfo = useSelector(
  //     (state) => state.paymentIn.paymentInfo
  //   );

  const { isLoading1, totalWagesBySupplierByDate, totalWagesPaymentsByDate, totalOutsWagesBySupplierByDate } = props;

  //console.log("Selected Order from the summary payments...", selectedOrder);

  return (
    <Fragment>
      {!isLoading1 ? (
        // Object.keys(currentSupplierSelected).length !== 0 &&
        // Object.keys(currentSupplierSelectedAccount).length !== 0 ? (
          <Grid
            item
            xs
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            
              <Fragment>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.common.ludisMain, marginTop: 2 }}
                >
                  {"£ " +
                    totalWagesBySupplierByDate?.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                </Typography>

                <Divider sx={{ width: "80%" }} />

                <Typography
                  //color="text.secondary.main"
                  color= {theme.palette.common.okGreen}
                  component="p"
                  sx={{ fontSize: "0.8rem", marginTop: 2 }}
                >
                  {"T. Paid: £ " +
                    totalWagesPaymentsByDate?.toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                </Typography>
                




                {/* <Typography
                  color="text.secondary"
                  component="p"
                  sx={{ fontSize: "0.8rem", marginTop: 1 }}
                >
                  {"Balance £ " +
                    (
                      currentSupplierSelectedAccount?.selectedSupplierTotalPurchases -
                      currentSupplierSelectedAccount?.selectedSupplierTotalPaid
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                </Typography> */}

                <Typography
                  color="text.secondary.main"
                  component="p"
                  sx={{ fontSize: "0.8rem", marginTop: 2 }}
                >
                  {"Balance £ " +
                    totalOutsWagesBySupplierByDate?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                </Typography>
                



              </Fragment>
            
          </Grid>
        // ) : (
        //   ""
        // )
      ) : (
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15} />
        </Box>
      )}
    </Fragment>
  );
};

export default WagesReportSummary;
