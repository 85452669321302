import React, { useState, useEffect, Fragment } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../store/notifications-slice";

import { SocketContext } from "../utils/socket-client";

import DashboardContainer from "./UI/DashboardContainer";

import Notification from "./Notification";
import MyPagination from "./UI/MyPagination";

import {variables} from "../Variables"

//------------MUI IMPORTS ----------------------------------------------------
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import Typography from "@mui/material/Typography";

import theme from "./UI/Theme";

const LiveFeeds = (props) => {
  const userInfo = useSelector((state) => state.user);
  const messagesListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );

  const dispatch = useDispatch();

  const mySocket = React.useContext(SocketContext)

  const [socket, setSocket] = useState(null)
  const [messagesList, setMessagesList] = useState(messagesListInitial)
  const [notificationsList, setNotificationsList] = useState(notificationsListInitial)
  const [notificationsListMongo, setNotificationsListMongo]=useState([])

  const [activeUsers, setActiveUsers] = useState([])

  const [loading, setLoading]=useState(null)

  const {selectedDatesFrom,selectedDatesTo} = props

  //console.log("dates for ranges from props  ...",selectedDatesFrom,selectedDatesTo)

  // const matches = useMediaQuery(theme.breakpoints.down("lg"));
  // const matches2 = useMediaQuery(theme.breakpoints.down("sm"));

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));
  const matches4 = useMediaQuery(theme.breakpoints.down("xs"));


  //Pagination Code------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);
  //End Pagination Code--------------------------------------------------

  const selectedDatesFrom_2 = new Date(selectedDatesFrom).toISOString();
  //const selectedDatesTo_2 = new Date(selectedDatesTo).toISOString();
  const selectedDatesTo_2 = new Date(new Date(selectedDatesTo).setHours(23, 59, 59)).toISOString();
  

  //console.log("dates for ranges...",selectedDatesFrom_2,selectedDatesTo_2)

  //Fetch Notifications from Mongo DB------------------------------------
  const url= variables.API_URL_MONGO + "notifications" + "/" + selectedDatesFrom_2 + "/" + selectedDatesTo_2
  const getNofificationsFromDB = ()=>{
    setLoading(true)

    axios.get(url)
    .then((res)=>{
        setNotificationsListMongo(res.data)
        setLoading(false)
        //console.log("this is the data from mongo...",res.data)
    })

    

}

  // console.log("the current user is...", userInfo.username);
  // console.log("the current socket is...", socket);

  useEffect(() => {
    //setSocket(io('http://localhost:5002'));
    setSocket(mySocket);
    //console.log("connecting...");
  }, [mySocket]);

  //getting the list of users online....
  useEffect(() => {
    socket?.on("getActiveUsers", (data) => {
      //console.log("returned data from socket", data.onlineUsers);

      setActiveUsers(data.onlineUsers);
    });
  }, [socket]);

  //console.log("all active users...", activeUsers);

  //listen messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      // console.log("receiving a message object", data);
      // console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listen notifications
  //Listening to Notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      // setNotificationsList((prev) => [...prev, data]);
      setNotificationsList((prev) => [...prev , data]);
      // console.log("receiving a notification object", data);
      // console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );

    //when notificationsListchanges then fetch MongoDB----
    getNofificationsFromDB()

  }, [notificationsList]);

  //GET CURRENT NOTIFICATIONS FOR PAGINATION....................

    //const notificationsListSorted = notificationsList.slice(0).sort((a, b) => {
    const notificationsListSorted = notificationsListMongo.slice(0).sort((a, b) => {
    const dateTimeA = new Date(`${a.notificationDate} ${a.notificationTime}`);
    const dateTimeB = new Date(`${b.notificationDate} ${b.notificationTime}`);
    return dateTimeB - dateTimeA ;
  });


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // const currentPosts = notificationsList.slice(
  const currentPosts = notificationsListSorted.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (event, value) => {
    setCurrentPage(value);
    //console.log("my paginate function...", value);
  };

  //END GET CURRENT NOTIFICATIONS FOR PAGINATION................

  return (
    <Box>
      <DashboardContainer
        reportTitle="Recent Activity (Live Feeds...)"
        myMarginTop={0.1}
        icon={<RssFeedIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />}
        myWidth={matches3?"77.7vw":"80vw"}
        matches={matches}
        matches2={matches2}
        matches3={matches3}
        myHeight="auto"
        //myHeight={matches3?"auto":"95vh"}
        
      >
        {/* <h6>Live Feeds and Notifications here...</h6> */}
        {/* <Typography variant="headerInfo">{matches2?"Live Feeds..." : "Live Feeds and Notifications here..."}</Typography> */}

        {/* {messagesList.map((message)=><h6>{message}</h6>)} */}
        {/* <h3> {messagesList}</h3> */}

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexWrap:"wrap",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: matches3 ? "65vw" : matches2 ? "60vw" : matches ? "50vw" :"45vw",
            //backgroundColor:"#c00"
          }}
        >
          <List key={`${Math.random()}`}  >
            {messagesList.map((message) => {
              return (
                <Fragment key={`${Math.random()}`}>
                  <ListItem
                    key={`${Math.random()}`}
                    sx={{ width: matches ? "100%" : 400 }}
                  >
                    <ListItemAvatar key={`${Math.random()}`}>
                      <Avatar sx={{ bgcolor: "#e7dddd" }}>
                        <AccountCircleIcon
                          key={`${Math.random()}`}
                          sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      key={`${Math.random()}`}
                      primary={message}
                      //secondary={newClientStatus ? newClientStatus : "No Info..."}
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
          </List>

          {/* NOTIFICATION COMPONENT------------------------------------------------------------------------------- */}

          <Notification 
            notificationsList={currentPosts} 
            matches={matches}
            matches2={matches2}
            matches3={matches3}
          />

          <MyPagination
            postsPerPage={postsPerPage}
            //totalPosts={notificationsList.length}
            totalPosts={notificationsListMongo.length}
            paginate={paginate}
            matches2={matches2}
            
          />

          {/* END NOTIFICATION COMPONENT--------------------------------------------------------------------------- */}
        </Box>
      </DashboardContainer>
    </Box>
  );
};

export default LiveFeeds;
