import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/users-slice";

import theme from "../UI/Theme";
import { MyTextField } from "../UI/styledComponents/StyledComponents";
import { variables } from "../../Variables";

//-------------------IMPORTS MUI---------------------------------------
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';




const PoStep_1 = (props) => {
  const dispatch = useDispatch();
  //const quoteinfo = useSelector((state) => state.quote);
  const AutoCompleteSuppliersList = useRef();
  const AutoCompletePurchaseProductsList = useRef();
  //const AutoCompleteSupplierOptionsList = useRef();
  const AutoCompleteProductOptionsList = useRef();
  const AutoCompleteProjects = useRef();

  //console.log("myprops in postep1...", props);

  const { suppliersList, projectList } = props;
  const [suppliersListFiltered, setSuppliersListFiltered] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});

  const [purchaseProductsBySupplier, setPurchaseProductsBySupplier] = useState(
    []
  );
  const [
    purchaseProductsBySupplierFiltered,
    setPurchaseProductsBySupplierFiltered,
  ] = useState([]);

  const [purchaseOptionsByProduct, setPurchaseOptionsByProduct] = useState([]);
  const [
    purchaseOptionsByProductFiltered,
    setPurchaseOptionsByProductFiltered,
  ] = useState([]);

  const [projectListFiltered, setProjectListFiltered] = useState([]);

  console.log("here is the suppliers list...", suppliersList);

  //const [projectListFiltered, setProjectListFiltered] = useState([]);
  //const [productListFiltered, setProductListFiltered] = useState([]);

  const [enteredNetAmount, setEnteredNetAmount] = useState(null);
  const [enteredInput, setEnteredInput] = useState(null);
  const [enteredInputName, setEnteredInputName] = useState(null);

  const [vat, setVat] = useState(null);
  const [TotalPurchase, setTotalPurchase] = useState(null);

  const [checkBoxVat, setCheckBoxVat] =useState(true)


  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //for the supplier list----
  useEffect(() => {
    if (suppliersList?.length > 0) {
      const mySuppliersListFiltered = suppliersList.map((option) => {
        const firstLetter = option.SUPPLIER_NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setSuppliersListFiltered(mySuppliersListFiltered);
    }
  }, [suppliersList]);

  //for the Purchase Products by supplier selected
  useEffect(() => {
    if (purchaseProductsBySupplier?.length > 0) {
      const mypurchaseProductsBySupplierFiltered =
        purchaseProductsBySupplier.map((option) => {
          //const firstLetter = option.PURCHASEPRODUCTOPTIONS[0].toUpperCase();
          const firstLetter = option.PURCHASE_PRODUCT[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        });

      setPurchaseProductsBySupplierFiltered(
        mypurchaseProductsBySupplierFiltered
      );
    }
  }, [purchaseProductsBySupplier]);

  //for the Purchase Options by Product selected
  useEffect(() => {
    if (purchaseOptionsByProduct?.length > 0) {
      const mypurchaseOptionsByProductFiltered = purchaseOptionsByProduct.map(
        (option) => {
          //const firstLetter = option.PURCHASEPRODUCTOPTIONS[0].toUpperCase();
          const firstLetter = option.PURCHASEPRODUCTOPTIONS[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
            ...option,
          };
        }
      );

      setPurchaseOptionsByProductFiltered(mypurchaseOptionsByProductFiltered);
    }
  }, [purchaseOptionsByProduct]);

  //for the project list
  useEffect(() => {
    if (projectList.length > 0) {
      const myProjectListFiltered = projectList.map((option) => {
        const firstLetter = option.CLUB_NAME[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...option,
        };
      });

      setProjectListFiltered(myProjectListFiltered);
    }
  }, [projectList]);

  //for the project list
  //   useEffect(() => {
  //     if (projectList.length > 0) {
  //       const myProjectListFiltered = projectList.map((option) => {
  //         const firstLetter = option.CLUB_NAME[0].toUpperCase();
  //         return {
  //           firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //           ...option,
  //         };
  //       });

  //       setProjectListFiltered(myProjectListFiltered);
  //     }
  //   }, [projectList]);

  //for product list
  //   useEffect(() => {
  //     if (productList.length > 0) {
  //       const myProductListFiltered = productList.map((option) => {
  //         const firstLetter = option.product_name[0].toUpperCase();
  //         return {
  //           firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
  //           ...option,
  //         };
  //       });

  //       setProductListFiltered(myProductListFiltered);
  //     }
  //   }, [productList]);

  //on Supplier change set the supplier selected--------------------------------
  const handleSupplierChange = (event, value, name) => {
    console.log("value from the function handleSuppliersChange...", value);

    console.log("name from the function handleSuppliersChange...", name);

    //dispatch to be updated....
    if (value) {
      //set the value to the selected supplier----
      setSelectedSupplier(value);

      dispatch(
        updateUser({
          [name]: value,
        })
      );
    } else {
      setSelectedSupplier({});
      setSelectedProduct({});
    }
  };

  //on Purchase Product change set the P. Product selected--------------------------------
  const handleProductChange = (event, value, name) => {
    //console.log("value from the function handleProductChange...", value);

    //console.log("name from the function handleProductChange...", name);

    //dispatch to be updated....
    if (value) {
      //set the value to the selected supplier----
      setSelectedProduct(value);

      dispatch(
        updateUser({
          [name]: value,
        })
      );
    } else {
      setSelectedProduct({});
    }
  };

  //handle Project Change---------------------
  const handleProjectChange = (event, value, name) => {
    console.log("value from the function handleProjectsChange...", value);

    console.log("name from the function handleProjectsChange...", name);

    if (value) {
      //   dispatch(
      //     updateQuote({
      //       [name]: value,
      //     })
      //   );
    }
  };

  //test to be deleted
  const handleInputChange = (value, name) => {
    console.log("value from the NET Amount......", value);
    console.log("Type value from the NET Amount......", typeof value);

    //console.log("name from the function handlpriceChange...", name);

    if (value) {
      setEnteredNetAmount(value);

      setEnteredInput(value);
      setEnteredInputName(name);
    } else {
      setEnteredNetAmount(null);
    }
  };

  //test to be deleted
  const handleInputChange2 = (value, name) => {
    console.log("value from the NET Amount......", value);
    console.log("Type value from the NET Amount......", typeof value);

    //console.log("name from the function handlpriceChange...", name);
  };

  //handle CheckBox for VAT Zero or Standard Rate ---------------------------------------
  const handleCheckBox = (event)=>{

    setCheckBoxVat(event.target.checked);

    //console.log(event.target.checked)

  }



  //Get Purchase Products by Supplier---------------
  const getPurchaseProductsBySupplier = (suppId) => {
    axios
      .get(
        variables.API_URL + "purchase/purchaseproductsbysupplier/" + suppId,
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        //console.log("fetching purchase options by supplier...", res.data);
        setPurchaseProductsBySupplier(res.data.purchaseProductsBySupplier);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get Purchase Options by Product---------------
  const getPurchaseOptionsByProduct = (productId) => {
    axios
      .get(variables.API_URL + "purchase/purchaseopsbyproduct/" + productId, {
        withCredentials: true,
      })

      .then((res) => {
        console.log(
          "fetching purchase options by Product...",
          res.data.purchaseOptionsByProduct
        );
        setPurchaseOptionsByProduct(res.data.purchaseOptionsByProduct);
        //dispatch(orderActions.addFullOrdersList(res.data.orderInfo))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get VAT from NET Amount----------------------
  const getVat = (net) => {
    if (net) {
        
      const myVat = net * 0.2;
      const myTotal = parseFloat(net) + parseFloat(myVat);
      if(checkBoxVat){

          setVat(myVat);
          setTotalPurchase(myTotal)
      }else{
        setVat(0);
        setTotalPurchase(net)

      }
    }

    //console.log("Vat auto calculated,...",vat)
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      console.log("Checking input...");

      console.log("price info and key", enteredInput, enteredInputName);

      if (enteredInput) {
        dispatch(
          updateUser({
            [enteredInputName]: enteredInput,
          })
        );
      } else {
        dispatch(
          updateUser({
            [enteredInputName]: null,
          })
        );
      }
    }, 500);

    return () => {
      // console.log("CleanUp");
      clearTimeout(identifier);
    };
  }, [enteredInput, enteredInputName]);

  //end test to be deleted

  // const handleProductChange = (event, value) => {
  //   console.log("value from the function handleProductsChange...", value);
  // };

  //Show Purchase Products once the supplier is selected-----------------------
  useEffect(() => {
    if (Object.keys(selectedSupplier).length !== 0) {
      getPurchaseProductsBySupplier(selectedSupplier.SUPPLIER_ID);
    }

    return () => {
      //your cleanup code codes here
      // setSelectedSupplier({});
      // setSelectedProduct({});
    };
  }, [selectedSupplier]);

  //Show Products Options once the product is selected-----------------------
  useEffect(() => {
    if (Object.keys(selectedProduct).length !== 0) {
      getPurchaseOptionsByProduct(selectedProduct.PURCHASEPRODUCT_ID);
    }
  }, [selectedProduct]);

  //Show VAT once the Net Amount is typed-----------------------
  useEffect(() => {
    if (
      enteredNetAmount !== 0 ||
      enteredNetAmount !== null ||
      enteredNetAmount !== ""
    ) {
      getVat(enteredNetAmount);
    } else {
      getVat(null);
    }
  }, [enteredNetAmount, checkBoxVat]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              // backgroundColor: "#ccc",
              width: "100%",
            }}
          >
            {/* Suppliers List  */}
            <Autocomplete
              ref={AutoCompleteSuppliersList}
              name="Suppliers"
              margin="auto"
              id="grouped-demo"
              options={suppliersListFiltered.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.SUPPLIER_NAME}
              sx={
                matches
                  ? { width: "100%", margin: "0.2em" }
                  : { width: 350, margin: "1em" }
              }
              onChange={(event, value, name) =>
                handleSupplierChange(
                  event,
                  value,
                  AutoCompleteSuppliersList.current.getAttribute("name")
                )
              }
              //onSelect={alert("selected")}
              renderInput={(params) => (
                <MyTextField {...params} label="Suppliers" />
              )}
            />

            {/* User First Name */}
            {/* <MyTextField
            name="firstName"
            id="outlined-search"
            label="User First Name"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          /> */}

            {/* User Last Name */}
            {/* <MyTextField
            name="lastName"
            id="outlined-search"
            label="User Last Name"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>console.log(event, event.target.value, event.target.name)}
            //   onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          /> */}
          </Box>

          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              // backgroundColor: "#ccc",
              width: "100%",
            }}
          >
            {Object.keys(selectedSupplier).length !== 0 && (
              // Purchase Products by Supplier-------
              <Autocomplete
                //ref={AutoCompleteSupplierOptionsList}
                ref={AutoCompletePurchaseProductsList}
                name="Products"
                margin="auto"
                id="grouped-demo"
                options={purchaseProductsBySupplierFiltered.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                //getOptionLabel={(option) => option.PURCHASEPRODUCTOPTIONS}
                getOptionLabel={(option) => option.PURCHASE_PRODUCT}
                sx={
                  matches
                    ? { width: "100%", margin: "0.2em" }
                    : { width: 350, margin: "1em" }
                }
                onChange={(event, value, name) =>
                  handleProductChange(
                    event,
                    value,
                    AutoCompletePurchaseProductsList.current.getAttribute(
                      "name"
                    )
                  )
                }
                renderInput={(params) => (
                  <MyTextField {...params} label="Product" />
                )}
              />
            )}

            {/* User Email Address */}
            {/* <MyTextField
            name="userEmail"
            id="outlined-search"
            label="User Email"
            type="search"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>console.log(event, event.target.value, event.target.name)}
            // onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name) }
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          /> */}

            {/* <Divider /> */}
          </Box>

          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: matches ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
              // backgroundColor: "#ccc",
              width: "100%",
            }}
          >
            {Object.keys(selectedProduct).length !== 0 && (
              // Purchase Options by Product-------
              <Autocomplete
                ref={AutoCompleteProductOptionsList}
                name="PurchaseOptions"
                margin="auto"
                id="grouped-demo"
                options={purchaseOptionsByProductFiltered.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                //getOptionLabel={(option) => option.PURCHASEPRODUCTOPTIONS}
                getOptionLabel={(option) => option.PURCHASEPRODUCTOPTIONS}
                sx={
                  matches
                    ? { width: "100%", margin: "0.2em" }
                    : { width: 350, margin: "1em" }
                }
                //   onChange={(event, value, name) =>
                //     handlePurchaseOptionChange(
                //       event,
                //       value,
                //       AutoCompleteSupplierOptionsList.current.getAttribute("name")
                //     )
                //   }
                renderInput={(params) => (
                  <MyTextField {...params} label="P.Options" />
                )}
              />
            )}

            {/* User Mobile Number */}
            {/* <MyTextField
            name="userMobile"
            id="outlined-number"
            label="Mobile"
            type="number"
            sx={
              matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 350, margin: "1em" }
            }
            //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
                handleInputChange(event.target.value, event.target.name)
            }
            /> */}

            {/* User Password */}
            {/* <MyTextField
            name="userPassword"
            id="outlined-search"
            label="User Password"
            type="password"
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 350, margin: "1em" }
            }
            //onChange={(event) =>handleQuoteChange(event, event.target.value, event.target.name)}
            onChange={(event) =>
              handleInputChange(event.target.value, event.target.name)
            }
          /> */}
          </Box>

          {/* <Autocomplete
          
          ref={AutoCompleteProjects}
          name="project"
          margin="auto"
          // id="grouped-demo"
          id="project"
          options={projectListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.CLUB_NAME + " " + option.YEAR}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
           onChange={(event, value ) => handleQuoteChange(event, value, AutoCompleteProjects.current.getAttribute("name"))}
          

           renderInput={(params) => <MyTextField {...params} label="Projects" name='project' onSelect={(event)=>console.log(event.target.name)} />}
          

          renderOption={(props, option) => {
            //display value in Popper elements
            
            return (
              <li
                {...props}
                key={option.PROJECT_ID}
              >{`${option.CLUB_NAME}  ${option.YEAR} `}</li>
            );
          }}
        />

        <Divider /> */}

          {/* <Autocomplete
          ref={AutoCompleteProducts}
          name="product"
          margin="auto"
          id="grouped-demo"
          options={productListFiltered.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.product_name}
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          onChange={(event, value, name ) => handleQuoteChange(event, value,  AutoCompleteProducts.current.getAttribute("name"))}
          renderInput={(params) => <MyTextField {...params} label="Products"  />}
        />

        <Divider /> */}

          {/* <MyTextField
          name='price'
          id="outlined-number"
          label="£ Net Price"
          type="number"
          sx={
            matches
              ? { width: "100%", margin: "0.2em" }
              : { width: 400, margin: "1em" }
          }
          //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
          onChange={(event)=>handleInputChange(event.target.value, event.target.name)}
          


        /> */}
        </Box>

        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* Projects List */}
          <Autocomplete
            ref={AutoCompleteProjects}
            name="project"
            margin="auto"
            // id="grouped-demo"
            id="project"
            options={projectListFiltered.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.CLUB_NAME + " " + option.YEAR}
            sx={
              matches
                ? { width: "100%", margin: "0.2em" }
                : { width: 400, margin: "1em" }
            }
            onChange={(event, value) =>
              handleProjectChange(
                event,
                value,
                AutoCompleteProjects.current.getAttribute("name")
              )
            }
            renderInput={(params) => (
              <MyTextField
                {...params}
                label="Projects"
                name="project"
                onSelect={(event) => console.log(event.target.name)}
              />
            )}
            renderOption={(props, option) => {
              //display value in Popper elements

              return (
                <li
                  {...props}
                  key={option.PROJECT_ID}
                >{`${option.CLUB_NAME}  ${option.YEAR} `}</li>
              );
            }}
          />

            <MyTextField
              name="price"
              id="outlined-number"
              label="£ Net Price"
              type="number"
              sx={
                  matches
                  ? { width: "100%", margin: "0.2em" }
                  : { width: 400, margin: "1em" }
              }
              //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
              onChange={(event) =>
                handleInputChange(event.target.value, event.target.name)
              }
            />

                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
            {enteredNetAmount && (
              <>

                <FormControlLabel control={<Checkbox checked={checkBoxVat}  onChange={handleCheckBox} />} label={checkBoxVat? "Vat" : "Zero Vat"} /> 



                <MyTextField
                  name="vat"
                  id="outlined-number"
                  label="£ Vat"
                  type="number"
                  value={vat}
                  sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 100, margin: "1em" }
                  }
                  //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
                  // onChange={(event) =>
                  //     handleInputChange2(event.target.value, event.target.name)
                  // }
                />

                <MyTextField
                  name="Total"
                  id="outlined-number"
                  label="£ Total"
                  type="number"
                  value={TotalPurchase }
                  sx={
                    matches
                      ? { width: "100%", margin: "0.2em" }
                      : { width: 100, margin: "1em" }
                  }
                  //onChange={(event)=>handleQuoteChange(event, event.target.value, event.target.name)}
                  // onChange={(event) =>
                  //     handleInputChange2(event.target.value, event.target.name)
                  // }
                />
              </>
            )}
          </Box>

        </Box>
      </Box>
    </>
  );
};

export default PoStep_1;
