import React from 'react';
//MUI IMPORTS-------------------------------------------
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


//OTHER IMPORTS------------------------------------------
import { formatDistanceToNowStrict, format, differenceInDays } from "date-fns";


//MY IMPORTS---------------------------------------------
import theme from "../UI/Theme";
import { MyTable, MyTableRow, MyTableCell } from "../UI/styledComponents/StyledComponents";

const ArtTable = ({ data }, props) => {
    

    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    const matches2 = useMediaQuery(theme.breakpoints.down("md"));
    const matches3 = useMediaQuery(theme.breakpoints.down("sm"));


    const getColor = (date) => {
      const result = differenceInDays(
        new Date(Date.now()),
        new Date(date)
      )
      if (result >= 10) return 'red';
      if (result >= 5 && result <=10) return 'orange';
      if (result < 5) return '';

      return '';
    };

   
    

  return (
    <TableContainer
      component={Paper}
      sx={{
        //margin:"auto",
        marginBottom: 15,
        width: matches3 ? "60vw" : "70vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "auto",
        //backgroundColor:"#000"
      }}
    >
      <MyTable>
        <TableHead>
          <MyTableRow>
            <MyTableCell align={matches3 ? "center" : ""}>Ref</MyTableCell>
            <MyTableCell align={matches3 ? "center" : ""}>Company</MyTableCell>
            <MyTableCell align={matches3 ? "center" : ""}>
              {matches3 ? "Date" : "Date Art. Received"}
            </MyTableCell>
            <MyTableCell align={matches3 ? "center" : ""}>
              {matches3 ? "N.Days" : "N.Days From Art.Rec"}
            </MyTableCell>
            <MyTableCell align={matches3 ? "center" : ""}>
              {matches3 ? "£" : "Paid"}
            </MyTableCell>
            <MyTableCell align={matches3 ? "center" : ""}>
              {matches3 ? "..." : "Status"}
            </MyTableCell>
          </MyTableRow>
        </TableHead>
        <TableBody>
          {data
            .filter((item) => item.ARTWORKSTAGE_ID === 2)
            .sort((a, b) => new Date(a.ARTWORK_DATE) - new Date(b.ARTWORK_DATE))
            .map((item, index) => (
              <MyTableRow key={index}>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>
                  {item.CLUB_CODE + "-" + item.CONFIRMATION_REFERENCE}
                </TableCell>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>{item.COMPANY_NAME}</TableCell>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>
                  {format(
                    new Date(item.ARTWORK_DATE),
                    matches3 ? "dd-LLL" : "dd-LLL-yy"
                  )}
                </TableCell>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>
                  {formatDistanceToNowStrict(new Date(item.ARTWORK_DATE))}
                </TableCell>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>
                  £{" "}
                  {parseFloat(item.totalPaid).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </TableCell>
                <TableCell style={{ color: getColor(item.ARTWORK_DATE) }}>
                  {Math.round(item.totalPaid) === 0 && (
                    <ReportGmailerrorredIcon
                      sx={{
                        fontSize: 25,
                        color: theme.palette.common.ludisRed2,
                        marginLeft: 1,
                      }}
                    />
                  )}
                </TableCell>
              </MyTableRow>
            ))}
        </TableBody>
      </MyTable>
    </TableContainer>
  );
};

export default ArtTable;
