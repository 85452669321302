import { Fragment, useState, useEffect } from "react";

import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import { paymentInActions } from "../../store/payment-slice";

import { PropagateLoader } from "react-spinners";
import { GridLoader } from "react-spinners";



//My IMPORTS----------------
import theme from "../UI/Theme";
import Title from "../Title";
import { variables } from "../../Variables";

//--MUI IMPORTS--------------------------------------------
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArticleIcon from "@mui/icons-material/Article";




const AddPaymentStep_3 = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const currentUserInfo = useSelector((state) => state.user);
  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);

  const [newPaymentStatus, setNewPaymentStatus] = useState({});

  //const { socket, userInfo, showAddPaymentModalHandler, isNewPayment } = props;
  const { socket, showAddPaymentModalHandler, isNewPayment } = props;



  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  //Mongo info----------------------------------------------------------------------
  const url= variables.API_URL_MONGO + "notifications"

  const createPost = (newPost)=>{    
    axios.post(url, newPost)
    .then((res)=>{
        if(res.status === 201){
            
            //getPost()
        }
    })
 }

  //handle notifications
  const submitNotification = (info) => {
    //console.log("handleNotification starting from payments IN step 3...");


    socket.emit("sendNotification", {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      senderPic: currentUserInfo.userPicURL,
      receiverName: "All",
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      notificationDetails_Payment: {
        "confo": currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE, 
        "confoId": currentPaymentInfo.selectedOrder[0].CONFIRMATION_ID, 
        "client": currentPaymentInfo.selectedOrder[0].COMPANY_NAME,
        "clientId": currentPaymentInfo.selectedOrder[0].CLIENT_ID,
        "amountPaid":currentPaymentInfo.amountPaid,
    }
    });




    //Saving in the DB Mongo Notifications--------------------------------------
    //get the objetc for the Notification Content---------------------------------
    const noty = {
      //senderName: currentUserInfo.username,
      senderName: {userName:currentUserInfo.firstName, userLastName:currentUserInfo.lastName, userId:currentUserInfo.userId, consultantId:currentUserInfo.consultantId},
      type: info.type,
      name: info.name,
      notificationDate: info.notificationDate,
      notificationTime: info.notificationTime,
      senderPic: currentUserInfo.userPicURL,
      notificationDetails: {
        "confo": currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE, 
        "confoId": currentPaymentInfo.selectedOrder[0].CONFIRMATION_ID, 
        "client": currentPaymentInfo.selectedOrder[0].COMPANY_NAME,
        "clientId": currentPaymentInfo.selectedOrder[0].CLIENT_ID,
        "amountPaid":currentPaymentInfo.amountPaid,
    },
      // receiverName: "All",
    }
    createPost(noty)



  };


  //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  const resetPaymentInfo = ()=>{
    console.log("this is the last date....",new Date(currentPaymentInfo.paymentDate).toISOString())
    console.log("this is the updated date from redux....",new Date(currentPaymentInfo.paymentDateUpdated).toISOString())

    dispatch(
      paymentInActions.resetPaymentIn({
        //paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDate: new Date(currentPaymentInfo.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),

      })
    );

   }
  //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

   



  //Function to create (post) the New Payment in the DB ----------
  const createNewPayment = () => {

    setIsLoading(true)


    const config = {
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
      },
    };
    const data = {
        CONFIRMATION_ID: currentPaymentInfo.selectedOrder[0].CONFIRMATION_ID,
        AMOUNTPAID: currentPaymentInfo.amountPaid,
        PAYMENTDATE:new Date(),
        PAYMENTTYPE_ID:currentPaymentInfo.paymentType.CLIENTPAYMENTTYPE_ID,
        CHEQUEREF:currentPaymentInfo.paymentReference,
        DATEONSTATEMENT:currentPaymentInfo.paymentDate,
        FIRMA_ID:4,
        M_USER:currentUserInfo.userId,
        TIME: new Date(),

    };

    //console.log("this is the data for the request....",data)

    //Create (post) a New Payment ------------------------------
    axios
      .post(variables.API_URL + "payments/newpayment", data, config)

      .then((response) => {
        setNewPaymentStatus({
          message: " Payment saved Successfully",
          status: true,
        });

        //Submit the notification to the socket -NotificationType = 1 for payment.......
        submitNotification({
          // type:1
          type: {generalType:1, notificationType:1},
          name: "Payment",
          notificationDate: new Date().toLocaleString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }),
          notificationTime: new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: '2-digit',
          }),
          notificationDetails_Payment: {
            "confo": currentPaymentInfo.selectedOrder[0].CONFIRMATION_REFERENCE,
            "confoId": currentPaymentInfo.selectedOrder[0].CONFIRMATION_ID, 
            "client": currentPaymentInfo.selectedOrder[0].COMPANY_NAME,
            "clientId": currentPaymentInfo.selectedOrder[0].CLIENT_ID,
            "amountPaid":currentPaymentInfo.amountPaid,
          } ,



        });

        //send the paymentID to the parent component to refresh the list of payments including new one-----
        isNewPayment(Math.random()) 



        //Reset Payment In Information----------
        resetPaymentInfo()

        

        setTimeout(() => {
          //setNotification(null);
          const showAddPaymentModalHandler_local = (event)=>showAddPaymentModalHandler(event,1);
          showAddPaymentModalHandler_local()
          //showAddPaymentModalHandler(1);
          //console.log("This is the end message before closing.....")
      }, 2000);

        //setOkImage(true);
        //setLastClientId(response.data.newClient.insertId);
      })
      .catch(function (error) {
        console.log(error);
        setNewPaymentStatus({
          message: "  Something went Wrong ! Payment NOT saved",
          status: false,
        });

        // setSaveStatus({
        //   message: "  There were a problem, Quote NOT Saved ",
        //   status: false,
        // });
        // //setOkImage(false);
        // setEmailStatus({
        //   message: "Quote Email NOT sent, Please contact your Admin Team !",
        //   status: false,
        // });
      });

      setIsLoading(false)


  };
  // end of create the function to create (post) the New User and save It in the DB ----------



  //Step I Creating New User as consultant
  useEffect(() => {
    createNewPayment();
  }, []);


  return (
    <Fragment>
      {isLoading ? (
        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <PropagateLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      ) : 
      
      
      Object.keys(newPaymentStatus).length !== 0 ?
        
      

        <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >




{
        newPaymentStatus.status ? (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 40, color: "#82b74b", mr: 1 }}
            />
            <Title sx={{ margin: "auto" }}>
              PAYMENT SUCCESSFULLY SAVED !
            </Title>
          </Box>
        ) : (
          <Box sx={{display:'flex', flexDirection:'row', marginBottom: matches? 0 : '2rem', marginTop: matches? 0 : '2rem'}}>
            <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            <Title sx={{ margin: "auto" }}>
              PAYMENT NOT SAVED, CONTACT YOUR ADMIN TEAM !
            </Title>
          </Box>
        )}




          <ListItem>
            {newPaymentStatus.status ? (
              <DoneIcon sx={{ fontSize: 40, color: "#82b74b", mr: 1 }} />
            ) : (
              <ErrorIcon sx={{ fontSize: 40, color: "#c83349", mr: 1 }} />
            )}


            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "#e7dddd" }}>
              <ArticleIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />
                {/* <AccountCircleIcon
                  sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                /> */}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Payment Status:"
              secondary={
                newPaymentStatus.message ? newPaymentStatus.message : "No Info..."
              }
            />
          </ListItem>
        </Box>
      : 
      <Box
          // textAlign="center"
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          {/* <SyncLoader color="#7A1125" size="8" /> */}
          <GridLoader color="#7A1125" size="8" />
          <Alert severity="info">Saving... ! — Please wait...</Alert>
        </Box>
      }


    


    </Fragment>
  );
};

export default AddPaymentStep_3;
