import axios from "axios";
import { useEffect, useState, Fragment } from "react";

import DashboardContainer from "./UI/DashboardContainer";
import theme from "./UI/Theme";

import BarChart from "./BarChart";

import { variables } from "../Variables";

import { MyListItemText } from "./UI/styledComponents/StyledComponents"

//-----IMPORTS MUI ------------------------
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const SalesGeneral = () => {
  const [salesByConsultantsList, setSalesByConsultantsList] = useState([]);
  const [cancellationsByConsultantsList, setCancellationsByConsultantsList] =
    useState([]);

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  let netSales = 0;

  let BarChartData = [];

  //Get All sales by consultant------------------------------------
  //"http://localhost:5000/sales/salesbyconsultantsummary/" +
  const getSalesByConsultantSummary = () => {
    const selectedDatesFrom = "2024-01-01";
    //const selectedDatesTo = "2022-02-28";
    const selectedDatesTo = new Date(Date.now()).toISOString();

    axios
      .get(
        variables.API_URL +
          "sales/salesbyconsultantsummary/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "all sales by consultant summary...",
        //   res.data.SalesByConsultantSummary
        // );
        setSalesByConsultantsList(res.data.SalesByConsultantSummary);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get All Cancelations by Consultant ------------------------------------
  //"http://localhost:5000/sales/cancellationsbyconsultantsummary/" +
  const getCancellationsByConsultantSummary = () => {
    const selectedDatesFrom = new Date().getFullYear() + "-01-01";
    //const selectedDatesTo = "2022-02-28";
    const selectedDatesTo = new Date(Date.now()).toISOString();

    //console.log("this is the selected date to....",selectedDatesTo)

    axios
      .get(
        variables.API_URL +
          "sales/cancellationsbyconsultantsummary/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        // console.log(
        //   "all cancellations by consultant summary...",
        //   res.data.cancellationsByConsultantSummary
        // );
        setCancellationsByConsultantsList(
          res.data.cancellationsByConsultantSummary
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSalesByConsultantSummary();
    getCancellationsByConsultantSummary();
  }, []);

  //console.log("cancellationsByConsultantsList....",cancellationsByConsultantsList)

  return (
    <DashboardContainer
      reportTitle="Sales Information (Current Year)"
      myMarginTop={10}
      icon={<LocalOfferIcon sx={{ fontSize: 30, color: "#4E1721", mr: 1 }} />}
      myWidth="80vw"
      matches={matches}
      matches2={matches2}
      matches3={matches3}
      //myHeight="98vh"
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          width: matches ? "100%" : 400,
          //backgroundColor:"#000"
        }}
      >
        <List key={`${Math.random()}`}>
          {salesByConsultantsList.map((consultant) => {
            const canx = cancellationsByConsultantsList.filter(
              (item) => item.CONSULTANT_ID === consultant.CONSULTANT_ID
            );
            if (canx.length > 0) {
              //console.log("CANX",canx[0].subTotal)
              netSales = consultant.subTotal - canx[0].subTotal;
              //netSales = consultant.subTotal
            } else {
              netSales = consultant.subTotal;
            }

            BarChartData.push({
              //Name: consultant.NAME,
              Name: (matches3?
                consultant.Firstname.charAt(0).toUpperCase():
                consultant.Firstname.charAt(0).toUpperCase() + consultant.Firstname.toLowerCase().slice(1)) + 
                " " +consultant.Lastname.charAt(0),
              NetSales: netSales,
              Sales: netSales.toLocaleString("en-US", {
                minimumFractionDigits: 2,
              }),
            });

            return (
              <Fragment key={`${Math.random()}`}>
                <ListItem
                  key={`${Math.random()}`}
                  //sx={{ width: matches ? "100%" : 400, backgroundColor:"#ccc" }}
                  sx={{ width:"auto" }}

                >
                  <ListItemAvatar key={`${Math.random()}`}>
                    <Avatar sx={{ bgcolor: "#e7dddd" }}>
                      <AccountCircleIcon
                        key={`${Math.random()}`}
                        sx={{ fontSize: 30, color: "#4E1721", mr: 1 }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <MyListItemText
                    key={`${Math.random()}`}
                    primary={
                      //consultant.NAME + ":  £ " +
                      consultant.Firstname.charAt(0).toUpperCase() + consultant.Firstname.toLowerCase().slice(1) +
                      " " + consultant.Lastname.charAt(0) + " :   £ " +
                      netSales.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })
                    }
                    //secondary={newClientStatus ? newClientStatus : "No Info..."}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </Box>
      <Box>
        <BarChart BarChartData={BarChartData} />
      </Box>
    </DashboardContainer>
  );
};

export default SalesGeneral;
