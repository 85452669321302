import { Fragment } from "react";
import { useTheme } from "@mui/material/styles";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import Title from "../Title";
import theme from "../UI/Theme";

//MUI IMPORTS---------------------------
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@mui/material/Box";
//Toottip Customized-----------
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log("payload...", payload[0].payload)
    return (
      <div className="custom-tooltip">
        <p
          style={{ color: `${theme.palette.common.ludisMain}` }}
          className="label"
        >{`${label} : £ ${payload[0].payload.amount}`}</p>
        <p
          style={{ color: `${theme.palette.primary.main}` }}
          className="label"
        >{`${label} : £ ${payload[0].payload.acc}`}</p>
        {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        {/* <p className="desc">Anything you want can be displayed here.</p> */}
      </div>
    );
  }

  return null;
};

// Generate Sales Data
function createData(time, amount, acc) {
  return { time, amount, acc };
}

// const data = [
//   createData("00:00", 0),
//   createData("03:00", 300),
//   createData("06:00", 600),
//   createData("09:00", 800),
//   createData("12:00", 1500),
//   createData("15:00", 2000),
//   createData("18:00", 2400),
//   createData("21:00", 2400),
//   createData("24:00", undefined),
// ];

const months = [
  "Jan",
  "Febr",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function GeneralChart(props) {
  const theme = useTheme();
  let totalSalesAcc = null;
  let graphData = null;
  const {
    salesByDateList,
    //salesByConsultantList,
    //salesByConsultantByDate,
    salesGraphData,
    salesHistoric1,
    salesHistoric2,
    salesHistoric3,
    //changeGraphData,
  } = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  // console.log('checkingggggggggggggggggggggggggggggggggggg...............',changeDates)
   //console.log('checkingggggggggggggggggggggggggggggggggggg...............',props)

  //console.log("from the chart last one... aquiiiiiiiiiiiiiiiiiiiiiiiiiiiii!!!!!!!!!!!!!!!!!!!!!!!!!", props);
  //console.log("dates in Chart component example....",new Date(salesByConsultantByDate[3].DATE).getDate())

  if (salesByDateList.length > 0) {
    //console.log("from the chart after if....", salesByConsultantByDate);
    //console.log("from the chart after if....", salesGraphData);

    //console.log("from the chart after if BEFORE....", salesByDateList);

    graphData = salesByDateList.map((item, i) => {
      //let x = i;

      let graphDate = "";

      graphDate = new Date(item.DATE).toLocaleString("default", {
        day: "numeric",
        month: "short",
      });

      // if (changeGraphData) {
      //   graphDate = months[item.DATE];
      // } else {
      //   graphDate = new Date(item.DATE).toLocaleString("default", {
      //     day: "numeric",
      //     month: "short",
      //   });
      // }

      //totalSalesAcc = totalSalesAcc + item.amountNet;

      //for original chart--------------
      // let x = graphDate;
      // let y = item.amountNet;
      // let y2 = totalSalesAcc;

      //for accumulative chart--------------
      let x = graphDate;
      let y = item.targetSalesAcc;
      let y2 = item.salesAcc;

      return createData(x, y, y2);
    });
    //console.log("the graph data ... from chart component...", graphData);
  }

  //console.log("from the chart last one... aquiiiiiiiiiiiiiiiiiiiiiiiiiiiii!!!!!!!!!!!!!!!!!!!!!!!!!", graphData);

  return (
    <>
      {/* {salesByConsultantList.length > 0 ? ( */}

      {salesGraphData.length > 0  ? (
        <Fragment>
          {/* {changeGraphData ? (
            <Title>Sales By Month</Title>
          ) : (
            <Title>Sales By Day</Title>
          )} */}

          <Title>Sales By Day</Title>

          <ResponsiveContainer >
            <LineChart
              //data={data}
              data={graphData}
              margin={{
                top: 16,
                right: matches3?0:16,
                bottom: 0,
                left: matches3?0:24,
              }}
              
              
            >
              <XAxis
                dataKey="time"
                stroke={theme.palette.text.secondary}
                //style={theme.typography.body2}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                //style={theme.typography.body2}
                style={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: "Roboto, sans-serif",}}
              >
                <Label
                  angle={270}
                  position="left"
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.primary,
                    ...theme.typography.body1,
                    
                  }}
                >
                  {matches3? "": "Sales ($)"}
                </Label>
              </YAxis>

              {/* {matches3?"":<Legend verticalAlign="bottom" height={36}   />} */}
              
              <Legend 
              verticalAlign="top" 
              height={50} 
              iconSize={matches3?"0.6rem":"0.85rem"} 
              wrapperStyle={{ fontSize: matches3?'0.6rem':'0.85rem', fontFamily: 'Roboto, sans-serif' }}   
              />
              

              <Tooltip
                cursor={{ fill: "#e7dddd" }}
                content={<CustomTooltip />}
                wrapperStyle={{
                  width: "auto",
                  backgroundColor: "#e7dddd",
                  borderRadius: 3,
                  fontSize: "0.8rem",
                  padding: 5,
                }}
              />
              {/* <Legend
                width={100}
                wrapperStyle={{
                  top: 30,
                  right: 20,
                  backgroundColor: "#e7dddd",
                  border: "1px solid #d5d5d5",
                  borderRadius: 3,
                  lineHeight: "40px",
                }}
              /> */}
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

              <Line
                //isAnimationActive={false}
                name={matches3?"T":"Sales Target"}
                type="monotone"
                dataKey="amount"
                stroke="#4E1721"
                dot={false}
                activeDot={{ r: 4 }}
              />
              <Line
                name={matches3?"S":"Sales Acc"}
                type="monotone"
                dataKey="acc"
                //stroke="#82ca9d"
                stroke={theme.palette.primary.main}
                dot={false}
                activeDot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Fragment>
      ) : (
        "loading"
      )}
    </>
  );
}
