import { createSlice } from "@reduxjs/toolkit";

export const comunicationSlice = createSlice({
  name: "comunicationCreateEmail",
  initialState: {
    comunicationCreateEmailInfo: {
      selectedOrder: [],
      //selectedOrderTotalPaid:0,
      //amountPaid: 0,
    //   paymentType: 
    //     {
    //       firstLetter: "B",
    //       CLIENTPAYMENTTYPE_ID: 1,
    //       CLIENTPAYMENTTYPE: "BACS TRANSFER",
    //       CLIENTPAYMENTTYPE_REF: "Bacs",
    //     },
      
      //comunicationEmailDate: new Date(),
      comunicationEmailDate: "",
      comunicationEmail: "",
      comunicationEmailCC: "",
      comunicationEmailSubject: "",
      comunicationMessage: "",

    },
  },
  reducers: {
    updateComunicationCreateEmail: (state, action) => {
      const { payload } = action;

      state.comunicationCreateEmailInfo = { ...state.comunicationCreateEmailInfo, ...payload };

      

      //console.log("full payload from quote reducer new state...",payload)
    },

    resetComunicationCreateEmail: (state, action) => {

      const { payload } = action;

      //console.log("inside the slice for reset payments in....",payload)

      state.comunicationCreateEmailInfo = {
        selectedOrder: [],
        //selectedOrderTotalPaid:0,
        //amountPaid: 0,
        //paymentType: 
        //   {
        //     CLIENTPAYMENTTYPE: "BACS TRANSFER",
        //     CLIENTPAYMENTTYPE_ID: 1,
        //     CLIENTPAYMENTTYPE_REF: "Bacs",
        //     firstLetter: "B",
        //   },
        
        comunicationEmailDate: payload.comunicationEmailDate,
        comunicationEmail: "",
        comunicationEmailCC: "",
        comunicationEmailSubject: "",
        comunicationMessage: "",


          //paymentReference: "",
          //paymentDateUpdated:payload.paymentDate,
          //selectedPayment_id: "",
      };
    },
  },
});

export const comunicationInfoActions = comunicationSlice.actions;

export default comunicationSlice;
