import React, { Fragment, useState, memo, useEffect } from "react";
import SummaryReport from "../reportComponents/SummaryReport";

import axios from "axios";
import { variables } from "../../Variables";
import MyDialog from "../UI/Dialog"
import theme from "../UI/Theme";

import { format, compareAsc, addDays,formatDistance, subDays, parse, add, previousMonday } from "date-fns";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

//MUI Imports--------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SalesSummary = (props) => {
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const[showDetails, setShowDetails]=useState(null)


  
  //for Sales information-------------------------------------------
  const [salesByDateList, setSalesByDateList] = useState([]);
  const [salesCancelledByDateList, setSalesCancelledByDateList] = useState([]);
  
  const [totalSalesByDate, setTotalSalesByDate] = useState(null);
  const [totalPagesByDate, setTotalPagesByDate] = useState(null);

  const [totalSalesCancelledByDate, setTotalSalesCancelledByDate] = useState(null);
  const [totalPagesCancelledByDate, setTotalPagesCancelledByDate] = useState(null);

  const [totalPagesByConsultant, setTotalPagesByConsultant] = useState(null);
  const [totalPagesCancelledByConsultant, setTotalPagesCancelledByConsultant] =useState(null);

  const {tabInitialDate , tabFinalDate} = props;

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const matches2 = useMediaQuery(theme.breakpoints.down("md"));
  const matches3 = useMediaQuery(theme.breakpoints.down("sm"));

  const showDetailsHandler = ()=>{
    setShowDetails((prev)=> !showDetails)
  }
  

  //-------------- Information for Summary Report---------------//
  const summaryInfo = {
    title: "Net Sales",
    titleSales: "Sales",
    titleCancelations: "cancelations",
    subtitle: "T. Pages",
    subtitle2: "Price/Page",
  };
  
  //Get List of Sales by Consultant selected--------------
  const getSalesByDate = (selectedDatesFrom, selectedDatesTo) => {
    //console.log("date from....",selectedDatesFrom)
    //console.log("date to....",selectedDatesTo)

    setIsLoading1(true);
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    axios
      .get(
        variables.API_URL +
          "sales/salesbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setSalesByDateList(res.data.salesByDate);

        if (res.data.salesByDate.length === 0) {
          setTotalSalesByDate(0);
          setTotalPagesByDate(0);
        } else {
          const totalSales = res.data.salesByDate.slice(-1);
          setTotalSalesByDate(totalSales[0].cumulative_sum);
          setTotalPagesByDate(totalSales[0].cumulative_sum_pages);
        }
        setIsLoading1(false);
      })
      .catch((error) => {
        console.log(error);
        //setNotification("Something went wrong, please try again");
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };




  //Get List of Cancelled Orders by Consultant Selected--------------
  const getSalesCancelledByDate = (selectedDatesFrom, selectedDatesTo) => {
    //const selectedDatesTo = new Date(Date.now()).toISOString();
    setIsLoading2(true);
    axios
      .get(
        variables.API_URL +
          "sales/salescancelledbydate/" +
          selectedDatesFrom +
          "/" +
          selectedDatesTo,
        { withCredentials: true }
      )
      .then((res) => {
        setSalesCancelledByDateList(res.data.salesCancelledByDate);

        if (res.data.salesCancelledByDate.length === 0) {
          setTotalSalesCancelledByDate(0);
          setTotalPagesCancelledByDate(0);
        } else {
          
          //const cumulativeCancelationsArray=(res.data.salesCancelledByDate).map(item=>item.cumulative_sum)
          //const totalCumulativeCancelations=Math.max(...cumulativeCancelationsArray)          
          const totalCumulativeCancelations_amount=Math.max(...(res.data.salesCancelledByDate).map(item=>item.cumulative_sum))
          const totalCumulativeCancelations_pages=Math.max(...(res.data.salesCancelledByDate).map(item=>item.cumulative_sum_pages))
          // console.log("sales cancelled info from backend....",res.data.salesCancelledByDate)
          // console.log("sales cancelled info from backend sliced.....",res.data.salesCancelledByDate.slice(-1))
           //console.log("sales cancelled Total maxVal.....",totalCumulativeCancelations_pages)
          //const totalSalesCancelledByDate = res.data.salesCancelledByDate.slice(-1);
          //setTotalSalesCancelledByDate( totalSalesCancelledByDate[0].cumulative_sum);
          setTotalSalesCancelledByDate(totalCumulativeCancelations_amount);
          //setTotalPagesCancelledByDate(totalSalesCancelledByDate[0].cumulative_sum_pages);
          setTotalPagesCancelledByDate(totalCumulativeCancelations_pages);
        }
        setIsLoading2(false);
      })
      .catch((error) => {
        console.log(error);
        // setNotification(
        //   "Something went wrong when Loading Cancellations, please try again"
        // );
        setTimeout(() => {
          //setNotification(null);
        }, 5000);
      });
  };



  const totalPagesByConsultantHandler = (pages) => {
    setTotalPagesByConsultant(pages);
  };

  const totalPagesCancelledByConsultantHandler = (pages) => {
    setTotalPagesCancelledByConsultant(pages);
  };







  //-------------------------------------
  useEffect(() => {
    // alert("inside UseEffect in sales summary")
    if (tabInitialDate && tabFinalDate) {
      
        
        getSalesByDate(tabInitialDate, tabFinalDate);
        getSalesCancelledByDate(tabInitialDate, tabFinalDate);
        //getTargetInfo(tabInitialDate, tabFinalDate);
        

    }
    
    return () => {};

  }, []);
  //-------------------------------------





  return (
    <Fragment>
      {!isLoading1 && !isLoading2 ? (
        <Fragment>
        <SummaryReport
         summaryInfo={summaryInfo}
         totalSalesByConsultant={totalSalesByDate}
         totalSalesCancelledByConsultant={totalSalesCancelledByDate}
         totalPagesByConsultant={totalPagesByDate}
         totalPagesCancelledByConsultant={totalPagesCancelledByDate}
         salesByDateList={salesByDateList}
         salesCancelledByDateList={salesCancelledByDateList}
         tabInitialDate={tabInitialDate}
         tabFinalDate = {tabFinalDate}
         //showGraphHandler={showSalesGraphHandler}
         //showGraph={showSalesGraph}
        // //changeDatesHandler={changeDatesHandler}
        // //changeDates={changeDates}
         link={true}
         boxSize={matches2?"auto":325}
         boxMargin={2}
        />

        <Button
        // onClick={() => props.showDetailsHandler()}
        onClick={() => showDetailsHandler()}
        sx={{ color: "#7A1125", textTransform: "capitalize", fontSize:"0.7rem" }}
        >
        <FormatListBulletedIcon
          sx={{ fontSize: 20, color: "#4E1721", marginRight: 2 }}
          />
        {/* {props.showDetails ? "Hide Details" : "View Details"} */}
        {/* {showDetails ? "Hide Details" : "View Details"} */}
        {showDetails ? "Hide" : "more..."}


        </Button>

        <MyDialog 
        salesByDateList={salesByDateList}
        salesCancelledByDateList={salesCancelledByDateList}
        showDetails={showDetails}
        showDetailsHandler={showDetailsHandler}
        title="Sales"
        tabInitialDate={tabInitialDate}
        tabFinalDate = {tabFinalDate}
        totalPagesByConsultantHandler={totalPagesByConsultantHandler}
        totalPagesCancelledByConsultantHandler={totalPagesCancelledByConsultantHandler}
        />

      </Fragment>


      ) : (
        <Box textAlign="center">
          <SyncLoader color="#7A1125" size={15} />
        </Box>
      )}
    </Fragment>
  );
};

export default React.memo(SalesSummary);
